import { createSlice } from '@reduxjs/toolkit'

const brokerSlice = createSlice({
  name: 'broker',
  initialState: {
    featuredBrokers: [],
  },
  reducers: {
    fetchFeaturedMortgageBrokersSuccess: (state, action) => {
      state.featuredBrokers = action.payload;
    },
  },
});


export default brokerSlice