import * as React from "react"

const Eye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.252}
    height={15}
    viewBox="0 0 22.252 15"
    {...props}
  >
    <path
      d="M13.376,7.383a11.964,11.964,0,0,0-11.126,7.5,12,12,0,0,0,22.252,0A11.964,11.964,0,0,0,13.376,7.383Zm0,12.5a5,5,0,1,1,5.056-5A5.043,5.043,0,0,1,13.376,19.884Zm0-8a3,3,0,1,0,3.035,3A3.027,3.027,0,0,0,13.376,11.883Z"
      transform="translate(-2.25 -7.383)"
      fill={props.color || "#fff"}
    />
  </svg>
)


export default Eye
