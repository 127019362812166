import * as React from "react";

const Manage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.001}
    height={13}
    viewBox="0 0 13.001 13"
    {...props}
  >
    <defs>
      <style>{".a{fill:#2a2a2a;}"}</style>
    </defs>
    <path
      fill={props.color || "#2a2a2a;"}
      d="M14.812,10.525a4.065,4.065,0,0,0,.033-.65c0-.228-.033-.422-.033-.65l1.4-1.072a.3.3,0,0,0,.067-.422l-1.33-2.243a.324.324,0,0,0-.4-.13l-1.662.65a4.838,4.838,0,0,0-1.13-.65l-.233-1.722a.356.356,0,0,0-.332-.26H8.529a.356.356,0,0,0-.332.26L7.93,5.357a5.629,5.629,0,0,0-1.13.65l-1.662-.65a.311.311,0,0,0-.4.13L3.409,7.73a.393.393,0,0,0,.067.422L4.905,9.225c0,.228-.033.422-.033.65s.033.422.033.65L3.509,11.6a.3.3,0,0,0-.067.422l1.33,2.243a.324.324,0,0,0,.4.13l1.662-.65a4.838,4.838,0,0,0,1.13.65l.266,1.722a.322.322,0,0,0,.332.26h2.659a.356.356,0,0,0,.332-.26l.266-1.722a5.626,5.626,0,0,0,1.13-.65l1.662.65a.311.311,0,0,0,.4-.13l1.33-2.243a.392.392,0,0,0-.067-.422l-1.463-1.072ZM9.858,12.15a2.276,2.276,0,1,1,2.327-2.275A2.287,2.287,0,0,1,9.858,12.15Z"
      transform="translate(-3.375 -3.375)"
    />
  </svg>
);


export default Manage;
