import Seprator from 'components/Separator';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import NoUser from 'assets/images/no_user.jpg'
import './agentContacts.scss'
import Icons from 'components/Icons';
import { useNavigate } from 'react-router-dom';
import AgentInfoModal from '../AgentInfoModal';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { formatCurrency } from 'utils/modules/Handlers';


const AgentContacts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { connections: { data: agents } } = useSelector((state) => state.agent);
    const [agentModal, setAgentModal] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState({})
    const [profile, setProfile] = useState({})

    const fetchProfile = async() => {
        const data = await dispatch(actions.buyer.fetchBuyerProfile());
        if (data.length > 0) {
            setProfile(data[0]);
        }
    }
    
    const init = async() => {
        fetchProfile()
        await dispatch(actions.agent.fetchBuyerAgentConnection())
    }
    
    useEffect(() => {
        init();
    }, [])


    const onAgentSelect = (item) => {
        setSelectedAgent(item);
        setAgentModal(prev => !prev);
    }

    const changeDefaultAgent = async(item) => {
        try {
            const data = { id: item.id }
            await dispatch(actions.buyer.setDefaultAgent(data)).then(() => {
                toast.success('New default Buyer\'s Agent has been set', {
                    position: toast.POSITION.TOP_CENTER
                });
                init();
            });
        } catch (error) {
            toast.error(<ToastMessage messages={'Something went wrong.'} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <div className="agent-contacts-container">
            <AgentInfoModal modal={agentModal} setModal={setAgentModal} selectedData={selectedAgent} />
            <p className='header'>Buyer's Agent Connections</p>
            <Seprator height={23} />
            {/* <div className='filters'>
                <input className="search" type="text" style={{ width: 800 }} placeholder="Search your agents" />
                <button 
                    className='button primary small no-glow' 
                    style={{ width: 160}} 
                    onClick={() => {}}
                >
                    Invite Contact
                </button>
            </div> */}
            <Seprator height={50} />
            <div className='back-link' onClick={() => navigate('/contacts')}>
                <Icons.BackArrow /> 
                <p> Back to Connections</p>
            </div>
            <Seprator height={55} />
            <div className='item-container'>
                <div className='header-container'>
                    <p className='header'>Agents</p>
                </div>
                <Seprator height={30} />
                <div className='items'>
                    {
                        agents.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className='details'>
                                        <div>
                                            <img src={NoUser} alt="" />
                                            <div className='info'>
                                                <p className='name' onClick={() => onAgentSelect(item)}>{item.agent.first_name} {item.agent.last_name}</p>
                                                <Seprator height={10} />
                                                <p className='email'>{item.agent.email}</p>
                                            </div>
                                        </div>
                                        {
                                            (item.agent.agent_data?.buyer_agent_compensation_type === 'cash' && item.agent.agent_data?.buyer_agent_compensation_cash)
                                            && <>
                                                <div className='compensation'>
                                                    <Icons.Compensation width={12} color={'#FAC91F'} />
                                                    <Seprator width={5} />
                                                    <span>{formatCurrency(item.agent.agent_data?.buyer_agent_compensation_cash)}</span>
                                                </div>
                                            </>
                                        }
                                        {
                                            (item.agent.agent_data?.buyer_agent_compensation_type === 'percent' && item.agent.agent_data?.buyer_agent_compensation_percent)
                                            && <>
                                                <div className='compensation'>
                                                    <Icons.Compensation width={12} color={'#FAC91F'} />
                                                    <Seprator width={5} />
                                                    <span>{`${item.agent.agent_data?.buyer_agent_compensation_percent}%`}</span>
                                                </div>
                                            </>
                                        }
                                        <div>
                                        {
                                            profile.default_buyer_agent?.id !== item.agent.id 
                                            ? <p className='set' onClick={() => changeDefaultAgent(item.agent)}>Set Default</p>
                                            : <p className='current'>Default</p>
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default AgentContacts;