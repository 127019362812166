import VerifyRoute from "./modules/Verify";
import SetPasswordRoute from "./modules/SetPassword";
import ConfirmRoute from "./modules/Confirm";
import ApproveShowingRoute from "./modules/ApproveShowing";
import SetAccountRoute from "./modules/SetAccount";
import PageLoadingRoute from "./modules/PageLoading";
import ReActivateAccountRoute from "./modules/ReActivateAccount";
import AccountDeletedRoute from "./modules/AccountDeleted";
import RequestSubmittedRoute from "./modules/RequestSubmitted";


export const Verify = VerifyRoute
export const SetPassword = SetPasswordRoute
export const Confirm = ConfirmRoute
export const ApproveShowing = ApproveShowingRoute
export const SetAccount = SetAccountRoute
export const PageLoading = PageLoadingRoute
export const ReActivateAccount = ReActivateAccountRoute
export const AccountDeleted =  AccountDeletedRoute
export const RequestSubmitted =  RequestSubmittedRoute
