import './formHeader.scss'
import house1 from 'assets/images/house1.jpg'
import Seprator from 'components/Separator';
import { checkImageUrl } from 'utils/modules/Handlers';

const FormHeader = ({ data }) => {
    const formatCurency = (value) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
        return formatter.format(value);
    }

    return (
        <div className='form-header-container'>
            <img className='image' src={ checkImageUrl(data.primary_photo_url, house1)} alt="" />
            <Seprator width={25} />
            <div className='details'>
                <div>
                    <p className='id'>#{data.mls_number || data.id}</p>
                    <Seprator height={10} />
                    <p className='name'>{data.property_type_display} in {data.city}</p>
                    <Seprator height={10} />
                    <p className='address'>
                    {
                        data.mls_number 
                        ? <>{data.full_address}</>
                        : <>{`${data.address}, ${data.city} ${data.state} ${data.zip_code}`}</>
                    }
                    </p>
                </div>
                <p className='price'>{formatCurency(data.price)}</p>
            </div>
        </div>
    )
}


export default FormHeader;