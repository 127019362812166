import Icons from 'components/Icons';
import './statusBlock.scss'
import BuyerAgentIndicator from 'components/Icons/modules/BuyerAgentIndicator';
import ListingAgentIndicator from 'components/Icons/modules/ListingAgentIndicator';
import SellerIndicator from 'components/Icons/modules/SellerIndicator';
import { useState } from 'react';

const StatusBlock = ({ data }) => {
    const [showMessage, setShowMessage] = useState(false);

    return (
        <div className="status-block-container">
            <div className={`status-info ${showMessage && 'show'}`}>
                <div className='close' onClick={() => setShowMessage(prev => !prev)}>
                    <Icons.CircleClose width={10} height={10} color="#EF4546" />
                </div>
                {
                    (data.status_data.message && data.status_data.message !== 'Waiting for Buyer\'s Agent approval.') &&
                    <>
                        <div className={`status-label`}>{data.status_data.message}</div>
                    </>
                }
            </div>
            {(data.status_display === 'Cancelled' || data.status_display === 'Rejected') && (
                <div style={{ fontSize: 12, color: '#949494' }}>Reason: {data.cancellation || data.buyer_agent_cancellation || data.listing_agent_cancellation || data.seller_cancellation}</div>
            )}
            {(data.status_display !== 'Rejected' && data.status_display !== 'Cancelled') && (
                <>
                    {
                        data.status === 'upcoming' &&
                        <>
                            {
                                data.buyer_agent && data.listing_data.showing_approval_type === 'auto' &&
                                <div className='status-checks'>
                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                        <Icons.Approve height={12} width={12} />
                                        <BuyerAgentIndicator width={14} height={14} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Icons.Approve height={12} width={12} />
                                        <ListingAgentIndicator width={14} height={14} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Icons.Approve height={12} width={12} />
                                        <SellerIndicator width={14} height={14} />
                                    </div>
                                </div>
                            }
                            {
                                !data.buyer_agent &&
                                <>
                                    <div className='status-checks'>
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                            <Icons.Pending height={12} width={12} color={'#BEBEBE'} />
                                            <BuyerAgentIndicator color={'#BEBEBE'} width={14} height={14} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Icons.Approve height={12} width={12} />
                                            <ListingAgentIndicator width={14} height={14} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Icons.Approve height={12} width={12} />
                                            <SellerIndicator width={14} height={14} />
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {
                        data.status !== 'upcoming' &&
                        <div className='status-checks'>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                {data.buyer_agent ? (
                                    data.buyer_agent_approved ?
                                        <>
                                            <Icons.Approve height={12} width={12} />
                                            <BuyerAgentIndicator width={14} height={14} />
                                        </>
                                        : <>
                                            <Icons.Ellipsis height={12} width={12} color={'#707070'} />
                                            <BuyerAgentIndicator width={14} height={14} />
                                        </>
                                ) :
                                    <>
                                        <Icons.Pending height={12} width={12} color={'#BEBEBE'} />
                                        <BuyerAgentIndicator color={'#BEBEBE'} width={14} height={14} />
                                    </>

                                }
                            </div>
                            {
                                data.buyer_agent
                                    ? <>
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                            {(data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'la_only') ? (
                                                (data.state === 'ShowingState.APPROVED' || data.state === 'ShowingState.SELLER_AGENT_APPROVED') ? (
                                                    <>
                                                        <Icons.Approve height={12} width={12} />
                                                        <ListingAgentIndicator width={14} height={14} />
                                                    </>
                                                ) : <>
                                                    <Icons.Ellipsis color={'#707070'} />
                                                    <ListingAgentIndicator width={14} height={14} />
                                                </>
                                            ) : <>
                                                <Icons.Pending height={12} width={12} color={'#BEBEBE'} />
                                                <ListingAgentIndicator width={14} height={14} color={'#BEBEBE'} />
                                            </>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                            {(data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'owner_only') ? (
                                                (data.state === 'ShowingState.APPROVED' || data.state === 'ShowingState.SELLER_CLIENT_APPROVED') ? (
                                                    <>
                                                        <Icons.Approve width={12} height={12} />
                                                        <SellerIndicator width={14} height={14} />
                                                    </>
                                                ) : <>
                                                    <Icons.Ellipsis width={12} height={12} color={'#707070'} />
                                                    <SellerIndicator width={14} height={14} />
                                                </>
                                            ) : <>
                                                <Icons.Pending height={12} width={12} color={'#BEBEBE'} />
                                                <SellerIndicator width={14} height={14} color={'#BEBEBE'} />
                                            </>
                                            }
                                        </div>
                                    </>
                                    : <>
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                            {(data.listing_data.buyer_only_showing_approval_type === 'both' || data.listing_data.buyer_only_showing_approval_type === 'la_only') ? (
                                                (data.state === 'ShowingState.APPROVED' || data.state === 'ShowingState.SELLER_AGENT_APPROVED') ? (
                                                    <>
                                                        <Icons.Approve height={12} width={12} />
                                                        <ListingAgentIndicator width={14} height={14} />
                                                    </>
                                                ) : <>
                                                    <Icons.Ellipsis color={'#707070'} />
                                                    <ListingAgentIndicator width={14} height={14} />
                                                </>
                                            ) : <>
                                                <Icons.Pending height={12} width={12} color={'#BEBEBE'} />
                                                <ListingAgentIndicator width={14} height={14} color={'#BEBEBE'} />
                                            </>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                                            {(data.listing_data.buyer_only_showing_approval_type === 'both' || data.listing_data.buyer_only_showing_approval_type === 'owner_only') ? (
                                                (data.state === 'ShowingState.APPROVED' || data.state === 'ShowingState.SELLER_CLIENT_APPROVED') ? (
                                                    <>
                                                        <Icons.Approve width={12} height={12} />
                                                        <SellerIndicator width={14} height={14} />
                                                    </>
                                                ) : <>
                                                    <Icons.Ellipsis width={12} height={12} color={'#707070'} />
                                                    <SellerIndicator width={14} height={14} />
                                                </>
                                            ) : <>
                                                <Icons.Pending height={12} width={12} color={'#BEBEBE'} />
                                                <SellerIndicator width={14} height={14} color={'#BEBEBE'} />
                                            </>
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    }
                    <div className='show-message' onClick={() => setShowMessage(prev => !prev)}>
                        <Icons.Info width={12} height={12} color={'#FAC91F'} />
                    </div>
                </>
            )}
        </div>
    )
}


export default StatusBlock;