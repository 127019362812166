import * as React from "react";

const DashboardOverview = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M2.95325 2.95325C3.24346 2.66304 3.63708 2.5 4.0475 2.5H7.61917C8.02959 2.5 8.4232 2.66304 8.71341 2.95325C9.00363 3.24346 9.16667 3.63708 9.16667 4.0475V7.61917C9.16667 8.02959 9.00363 8.4232 8.71341 8.71341C8.4232 9.00363 8.02959 9.16667 7.61917 9.16667H4.0475C3.1925 9.16667 2.5 8.47417 2.5 7.61917V4.0475C2.5 3.63708 2.66304 3.24346 2.95325 2.95325Z"
      fill={props.color || "white"}
    />
    <path
      d="M11.2866 2.95325C11.5768 2.66304 11.9704 2.5 12.3808 2.5H15.9525C16.3629 2.5 16.7565 2.66304 17.0467 2.95325C17.337 3.24346 17.5 3.63708 17.5 4.0475V7.61917C17.5 8.02959 17.337 8.4232 17.0467 8.71341C16.7565 9.00363 16.3629 9.16667 15.9525 9.16667H12.3808C11.5258 9.16667 10.8333 8.47417 10.8333 7.61917V4.0475C10.8333 3.63708 10.9964 3.24346 11.2866 2.95325Z"
      fill={props.color || "white"}
    />
    <path
      d="M2.95325 11.2866C3.24346 10.9964 3.63708 10.8333 4.0475 10.8333H7.61917C8.02959 10.8333 8.4232 10.9964 8.71341 11.2866C9.00363 11.5768 9.16667 11.9704 9.16667 12.3808V15.9525C9.16667 16.3629 9.00363 16.7565 8.71341 17.0467C8.4232 17.337 8.02959 17.5 7.61917 17.5H4.0475C3.1925 17.5 2.5 16.8075 2.5 15.9525V12.3808C2.5 11.9704 2.66304 11.5768 2.95325 11.2866Z"
      fill={props.color || "white"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3808 11.6667C12.1914 11.6667 12.0098 11.7419 11.8758 11.8758C11.7503 12.0014 11.6763 12.1689 11.6675 12.3454C11.667 12.3572 11.6667 12.369 11.6667 12.3808V15.9525C11.6667 16.2971 11.9108 16.5857 12.2358 16.6521C12.2819 16.6616 12.3303 16.6667 12.3808 16.6667H15.9525C16.1419 16.6667 16.3236 16.5914 16.4575 16.4575C16.5914 16.3236 16.6667 16.1419 16.6667 15.9525V12.3808C16.6667 12.2151 16.6091 12.0553 16.505 11.9282C16.4901 11.9101 16.4743 11.8926 16.4575 11.8758C16.3236 11.7419 16.1419 11.6667 15.9525 11.6667H12.3808ZM12.3808 10.8333C11.9704 10.8333 11.5768 10.9964 11.2866 11.2866C11.0145 11.5587 10.8542 11.9216 10.8352 12.3041C10.834 12.3296 10.8333 12.3552 10.8333 12.3808V15.9525C10.8333 16.7006 11.3635 17.3243 12.0689 17.4686C12.1696 17.4892 12.274 17.5 12.3808 17.5H15.9525C16.3629 17.5 16.7565 17.337 17.0467 17.0467C17.337 16.7565 17.5 16.3629 17.5 15.9525V12.3808C17.5 12.0217 17.3752 11.6755 17.1495 11.4001C17.1173 11.3608 17.083 11.3229 17.0467 11.2866C16.7565 10.9964 16.3629 10.8333 15.9525 10.8333H12.3808Z"
      fill={props.color || "white"}
    />
  </svg>
);


export default DashboardOverview;
