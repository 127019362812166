import * as React from "react"

const Invites = (props) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1667 0.5C13.8044 0.499964 14.418 0.743604 14.882 1.18107C15.3459 1.61854 15.6252 2.21676 15.6626 2.85333L15.6667 3V6.625L16.1617 6.405C16.6734 6.1775 17.2634 6.53 17.3284 7.07L17.3334 7.16667V13.8333C17.3335 14.2538 17.1747 14.6588 16.8888 14.9671C16.6029 15.2754 16.211 15.4643 15.7917 15.4958L15.6667 15.5H2.33341C1.91293 15.5001 1.50794 15.3413 1.19962 15.0554C0.891307 14.7695 0.702451 14.3776 0.670915 13.9583L0.666748 13.8333V7.16667C0.666748 6.60667 1.22925 6.21083 1.74758 6.37083L1.83925 6.405L2.33341 6.625V3C2.33338 2.36232 2.57702 1.74874 3.01448 1.28478C3.45195 0.820828 4.05017 0.541577 4.68675 0.504167L4.83341 0.5H13.1667ZM13.1667 2.16667H4.83341C4.6124 2.16667 4.40044 2.25446 4.24416 2.41074C4.08788 2.56702 4.00008 2.77899 4.00008 3V7.36583L9.00008 9.58833L14.0001 7.36583V3C14.0001 2.77899 13.9123 2.56702 13.756 2.41074C13.5997 2.25446 13.3878 2.16667 13.1667 2.16667ZM9.00008 4.66667C9.21248 4.6669 9.41677 4.74823 9.57122 4.89404C9.72567 5.03985 9.81861 5.23913 9.83106 5.45116C9.8435 5.6632 9.77452 5.87198 9.63819 6.03486C9.50187 6.19774 9.30849 6.30241 9.09758 6.3275L9.00008 6.33333H7.33341C7.12101 6.3331 6.91672 6.25177 6.76227 6.10596C6.60783 5.96015 6.51488 5.76087 6.50244 5.54884C6.48999 5.3368 6.55898 5.12802 6.6953 4.96514C6.83163 4.80226 7.025 4.69759 7.23591 4.6725L7.33341 4.66667H9.00008Z"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
)


export default Invites
