import * as React from "react"

const Coins = (props) => (
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
    <path
      d="M0 11.874V13.125C0 14.1593 2.51925 15 5.625 15C8.73075 15 11.25 14.1593 11.25 13.125V11.874C10.0402 12.7267 7.8285 13.125 5.625 13.125C3.4215 13.125 1.20975 12.7267 0 11.874ZM9.375 3.75C12.4808 3.75 15 2.90925 15 1.875C15 0.84075 12.4808 0 9.375 0C6.26925 0 3.75 0.84075 3.75 1.875C3.75 2.90925 6.26925 3.75 9.375 3.75ZM0 8.8005V10.3125C0 11.3468 2.51925 12.1875 5.625 12.1875C8.73075 12.1875 11.25 11.3468 11.25 10.3125V8.8005C10.0402 9.7965 7.8255 10.3125 5.625 10.3125C3.4245 10.3125 1.20975 9.79725 0 8.8005ZM12.1875 9.123C13.866 8.7975 15 8.1945 15 7.5V6.249C14.1436 6.77917 13.1854 7.1236 12.1875 7.26V9.123ZM5.625 4.6875C2.51925 4.6875 0 5.736 0 7.03125C0 8.3265 2.51925 9.375 5.625 9.375C8.73075 9.375 11.25 8.325 11.25 7.03125C11.25 5.7375 8.73075 4.6875 5.625 4.6875ZM12.0495 6.3375C13.8075 6.02025 15 5.4 15 4.6875V3.4365C13.563 4.24479 11.941 4.66676 10.2922 4.66125C11.0707 4.97445 11.6996 5.57406 12.0495 6.33675V6.3375Z"
      fill={props.color || "white"}
    />
  </svg>
)


export default Coins
