import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Packages
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
// Utils
import { checkImageUrl, formatCurrency } from 'utils/modules/Handlers';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import RequestStepper from 'components/RequestStepper';
import Footer from 'components/Footer';
// Assets
import './selectAgent.scss';
import NoUser from 'assets/images/no_user.jpg'

const SelectAgent = () => {
    const { id: listingID, mls } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const { showing } = useSelector((state) => state.showing)
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const init = () => {
    //         new Splide( '#select-agent', {
    //             type: myAgents.length < 3 ? 'slide' : 'loop',
    //             rewind: true,
    //             pagination: false,
    //             arrows: true,
    //             perPage: myAgents.length < 3 ? myAgents.length : 3,
    //             focus: 'left',
    //             fixedWidth: 320,
    //         }).mount();
    //         setLoad(true);
    //     }

    //     if (!load) {
    //         init();
    //     }

    // }, [myAgents])

    useEffect(() => {
        const init = async() => {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id)) 
            await dispatch(actions.agent.fetchBuyerAgents())            
        }
        init();
    }, [dispatch])

    const onSelectAgent = async(agent, showing) => {
        const body = {
            showing: showing.id,
            buyer_agent: agent.id,
        }
        await dispatch(actions.showing.showingRequestStep2Agent(body))
        navigate(`/request/${mls}/${listingID}/${urlParams.get('next')}`)
    }


    return (
        <section className="select-agents-container">
            {/* Back & Next */}
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p className='back-button' onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`)}>Back</p>
                    <h1 className="title">Select from my Agents</h1>
                    {/* <button 
                        className="button medium primary next-button" 
                        style={{ width: 120 }}
                        onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent`)}
                    >
                        Next
                    </button> */}
                </div>
                <p className='subtitle'>Here are the agents you have worked before.</p>
            </div>
            <div className='contents'>
            <Splide 
                hasTrack={ false }
                aria-label="..."
                    options={ {
                        type: myAgents.length <= 3 ? 'slide' : 'loop',
                        rewind: true,
                        pagination: false,
                        arrows: true,
                        perPage: myAgents.length <= 3 ? myAgents.length : 3,
                        focus: 'left',
                        fixedWidth: 320,
                } }
            >
                <SplideTrack>
                    {

                        myAgents.map(agent => (
                            <SplideSlide key={agent.id}>
                                <div className="selectbox agent" onClick={() => onSelectAgent(agent, showing)}>
                                    <div className="agent-company">
                                        <img src={agent.company_logo} alt="" />
                                    </div>
                                    <div className="agent-profile--image solo">
                                        <div className="agent-profile--avatar" style={{backgroundImage: `url(${checkImageUrl(agent.profile_photo, NoUser)})`}}>
                                            {agent.verified_buyer_agent && <Icons.MiniVerified width={24} height={24} />}
                                        </div>
                                    </div>
                                    <Separator height={10} />
                                    <div className="agent-profile--info">
                                        <p className="name">{agent.first_name} {agent.last_name}</p>
                                        <Separator height={10} />
                                        <span className="company-name">{agent.company_name}</span>
                                        <Separator height={10} />
                                        {
                                            (agent.agent_data?.buyer_agent_compensation_type === 'cash' && agent.agent_data?.buyer_agent_compensation_cash)
                                            && <>
                                                <div className='compensation'>
                                                    <Icons.Compensation width={12} color={'#FAC91F'} />
                                                    <Separator width={5} />
                                                    <span className="company-name">{formatCurrency(agent.agent_data?.buyer_agent_compensation_cash)}</span>
                                                </div>
                                            </>
                                        }
                                        {
                                            (agent.agent_data?.buyer_agent_compensation_type === 'percent' && agent.agent_data?.buyer_agent_compensation_percent)
                                            && <>
                                                <div className='compensation'>
                                                    <Icons.Compensation width={12} color={'#FAC91F'} />
                                                    <Separator width={5} />
                                                    <span className="company-name">{`${agent.agent_data?.buyer_agent_compensation_percent}%`}</span>
                                                </div>
                                            </>
                                        }
                                        <Separator height={20} />
                                        <div>
                                            <div className="email">
                                                <Icons.EmailSmall />
                                                <Separator width={10} />
                                                <span>{agent.email || 'Not Available'}</span>
                                            </div>
                                            <Separator height={10} />
                                            <div className="phone">
                                                <Icons.PhoneSmall />
                                                <Separator width={10} />
                                                <span>{agent.phone_number || 'Not Available'}</span>
                                            </div>
                                        </div>
                                        <Separator height={20} />
                                    </div>                         
                                </div>
                            </SplideSlide>
                        ))
                    }
                </SplideTrack>

                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                        <Icons.BoxArrowLeft color={'#808080'} />
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                        <Icons.BoxArrowRight color={'#808080'} />
                    </button>
                </div>
            </Splide>


                {/* <div id="select-agent" className="splide agent-slider">
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                            <Icons.BoxArrowLeft color={'#808080'} />
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                            <Icons.BoxArrowRight color={'#808080'} />
                        </button>
                    </div>
                    <div className="splide__track">
                        <ul className="splide__list">
                            { 
                                myAgents.map(agent => (
                                    <li key={agent.id} className="splide__slide">
                                        <div className="selectbox agent" onClick={() => onSelectAgent(agent, showing)}>
                                            <div className="agent-company">
                                                <img src={agent.company_logo} alt="" />
                                            </div>
                                            <div className="agent-profile--image solo">
                                                <div className="agent-profile--avatar" style={{backgroundImage: `url(${checkImageUrl(agent.profile_photo, NoUser)})`}}>
                                                    {agent.verified_buyer_agent && <Icons.MiniVerified width={24} height={24} />}
                                                </div>
                                            </div>
                                            <Separator height={10} />
                                            <div className="agent-profile--info">
                                                <p className="name">{agent.first_name} {agent.last_name}</p>
                                                <Separator height={10} />
                                                <span className="company-name">{agent.company_name}</span>
                                                <Separator height={20} />
                                                <div>
                                                    <div className="email">
                                                        <Icons.EmailSmall />
                                                        <Separator width={10} />
                                                        <span>{agent.email || 'Not Available'}</span>
                                                    </div>
                                                    <Separator height={10} />
                                                    <div className="phone">
                                                        <Icons.PhoneSmall />
                                                        <Separator width={10} />
                                                        <span>{agent.phone_number || 'Not Available'}</span>
                                                    </div>
                                                </div>
                                                <Separator height={20} />
                                            </div>                         
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div> */}
                <div className="more-options-container">
                    <div 
                        className="selectbox more-options" 
                        onClick={() => navigate(`/request/${mls}/${listingID}/invite-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}&from=select-agent`)}
                    >
                        <Icons.Add />
                        <Separator height={25} />
                        <h1 className="title">I'd like to invite my agent</h1>
                        <p className="subtitle">If you already have an agent that we can work with, please fill out the agent's contact details and we'll get things ready for you.</p>
                    </div>
                </div>
            </div>
            <Separator height={100} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Separator height={90} />
            <Footer subfooter />
        </section>
    )
}


export default SelectAgent;