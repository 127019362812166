import './disclosurePages.scss';

import TermsOfUseRoute from "./modules/TermsOfUse";
import PrivacyCenterRoute from "./modules/PrivacyCenter";
import CookiePolicyRoute from "./modules/CookiePolicy";


export const TermsOfUse = TermsOfUseRoute
export const PrivacyCenter = PrivacyCenterRoute
export const CookiePolicy = CookiePolicyRoute
