import { Instance } from "utils";


const fetchTeams = () => {
    return Instance.get('/team/');
}

const fetchOwnedTeams = (id) => {
    let url = '/team/owned_teams/'
    
    if (id) url = url + `?user=${id}`

    return Instance.get(url);
}

const retrieveTeam = (id) => {
    return Instance.get(`/team/${id}/`);
}

const postTeam = (data) => {
    return Instance.post('/team/', data);
}

const fetchJoinedTeams = () => {
    return Instance.get('/team-member/joined_teams/');
}

const fetchTeamMembers = (team) => {
    return Instance.get(`/team-member/?team=${team}`);
}

const fetchAllTeamMembers = (teams) => {
    return Instance.get(`/team-member/all-team-members/?teams=${teams}`);
}

const fetchMemberInvites = (data) => {
    return Instance.get('/team-member-application/');
}

const addMember = (data) => {
    return Instance.post('/team-member/', data);
}

const addMemberApplication = (data) => {
    return Instance.post('/team-member-application/', data);
}

const acceptMemberInvite = (id) => {
    return Instance.post(`/team-member-application/${id}/accept_invite/`);
}

const declineMemberInvite = (id) => {
    return Instance.post(`/team-member-application/${id}/decline_invite/`);
}


const team = {
    fetchTeams,
    fetchOwnedTeams,
    fetchJoinedTeams,
    retrieveTeam,
    postTeam,
    fetchTeamMembers,
    fetchAllTeamMembers,
    fetchMemberInvites,
    addMember,
    addMemberApplication,
    acceptMemberInvite,
    declineMemberInvite,
}


export default team;
