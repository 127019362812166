import './pageWrapper.scss';

const PageWrapper = ({ children, style }) => {
    return (
        <div className="page-wrapper" style={style}>
            <div className='inner'>
                {children}
            </div>
        </div>
    )
}


export default PageWrapper;