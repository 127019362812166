import * as React from "react";

const Connections = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7142 2.40249C11.251 2.04854 8.74984 2.04854 6.28666 2.40249C5.69332 2.48756 5.146 2.77025 4.73318 3.20484C4.32035 3.63944 4.06615 4.20055 4.01166 4.79749L3.90666 5.96082C3.66222 8.64805 3.66222 11.3519 3.90666 14.0392L4.01249 15.2025C4.06695 15.7993 4.32103 16.3603 4.73369 16.7949C5.14634 17.2295 5.69347 17.5122 6.28666 17.5975C8.74916 17.9517 11.2508 17.9517 13.7133 17.5975C14.3067 17.5124 14.854 17.2297 15.2668 16.7951C15.6796 16.3605 15.9338 15.7994 15.9883 15.2025L16.0933 14.0392C16.3375 11.3517 16.3375 8.64749 16.0933 5.96082L15.9875 4.79749C15.9332 4.20062 15.6792 3.63953 15.2665 3.20492C14.8538 2.77031 14.3066 2.48759 13.7133 2.40249M8.33333 7.49999C8.33333 7.05796 8.50892 6.63404 8.82148 6.32147C9.13404 6.00891 9.55797 5.83332 9.99999 5.83332C10.442 5.83332 10.8659 6.00891 11.1785 6.32147C11.4911 6.63404 11.6667 7.05796 11.6667 7.49999C11.6667 7.94201 11.4911 8.36594 11.1785 8.6785C10.8659 8.99106 10.442 9.16665 9.99999 9.16665C9.55797 9.16665 9.13404 8.99106 8.82148 8.6785C8.50892 8.36594 8.33333 7.94201 8.33333 7.49999ZM6.66666 12.9167C6.66666 12.2536 6.93005 11.6177 7.39889 11.1489C7.86773 10.68 8.50362 10.4167 9.16666 10.4167H10.8333C11.4964 10.4167 12.1323 10.68 12.6011 11.1489C13.0699 11.6177 13.3333 12.2536 13.3333 12.9167C13.3333 13.1377 13.2455 13.3496 13.0893 13.5059C12.933 13.6622 12.721 13.75 12.5 13.75H7.49999C7.27898 13.75 7.06702 13.6622 6.91074 13.5059C6.75446 13.3496 6.66666 13.1377 6.66666 12.9167Z"
      fill={props.color || "#949494"}
    />
  </svg>
);


export default Connections;
