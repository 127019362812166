import * as React from "react"

const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33.757}
    height={30}
    viewBox="0 0 33.757 30"
    {...props}
  >
    <path
      fill={props.color || "#2a2a2a"}
      d="M23.594,4.877l5.286,5.286a.573.573,0,0,1,0,.809l-12.8,12.8-5.439.6a1.14,1.14,0,0,1-1.26-1.26l.6-5.439,12.8-12.8A.573.573,0,0,1,23.594,4.877Zm9.494-1.342L30.229.675a2.292,2.292,0,0,0-3.235,0L24.919,2.75a.573.573,0,0,0,0,.809l5.286,5.286a.573.573,0,0,0,.809,0L33.088,6.77a2.292,2.292,0,0,0,0-3.235ZM22.5,20.29v5.966H3.751V7.5H17.218a.721.721,0,0,0,.5-.205l2.344-2.344a.7.7,0,0,0-.5-1.2H2.813A2.814,2.814,0,0,0,0,6.565V27.194a2.814,2.814,0,0,0,2.813,2.813H23.442a2.814,2.814,0,0,0,2.813-2.813V17.946a.7.7,0,0,0-1.2-.5L22.71,19.792A.721.721,0,0,0,22.5,20.29Z"
      transform="translate(0 -0.007)"
    />
  </svg>
)


export default Edit
