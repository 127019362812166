import * as React from "react"

const MenuMessage = (props) => (
  <svg
    width={25}
    height={20}
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="menu-messages-icon"
      d="M22.6228 0H2.38114C1.75018 0.00184689 1.14558 0.257825 0.699424 0.712013C0.253266 1.1662 0.00181423 1.78168 0 2.424V17.575C0.00181423 18.2173 0.253266 18.8328 0.699424 19.287C1.14558 19.7412 1.75018 19.9972 2.38114 19.999H22.6228C23.2531 19.9961 23.8567 19.7397 24.302 19.2856C24.7473 18.8315 24.9982 18.2166 25 17.575V2.424C24.9982 1.78237 24.7473 1.16749 24.302 0.713418C23.8567 0.259344 23.2531 0.00290066 22.6228 0V0ZM22.3281 5.152L12.5049 11.819L2.68173 5.152V2.727L12.5049 9.394L22.3281 2.727V5.152Z"
      fill={props.color || "#FAC91F"}
    />
  </svg>
)


export default MenuMessage
