import * as React from "react";

const Ellipsis = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.11111 5C1.5 5 1 5.5 1 6.11111C1 6.72222 1.5 7.22222 2.11111 7.22222C2.72222 7.22222 3.22222 6.72222 3.22222 6.11111C3.22222 5.5 2.72222 5 2.11111 5ZM9.88889 5C9.27778 5 8.77778 5.5 8.77778 6.11111C8.77778 6.72222 9.27778 7.22222 9.88889 7.22222C10.5 7.22222 11 6.72222 11 6.11111C11 5.5 10.5 5 9.88889 5ZM6 5C5.38889 5 4.88889 5.5 4.88889 6.11111C4.88889 6.72222 5.38889 7.22222 6 7.22222C6.61111 7.22222 7.11111 6.72222 7.11111 6.11111C7.11111 5.5 6.61111 5 6 5Z"
      fill={props.color || "#5F5F5F"}
    />
  </svg>
);


export default Ellipsis;
