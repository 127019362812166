// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showing-bee-logo > img {
  height: 20px;
  object-fit: contain;
}

@media (min-width: 481px) and (max-width: 767px), (min-width: 320px) and (max-width: 480px) {
  .showing-bee-logo > img {
    height: 15px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/logo.scss","webpack://./src/assets/styles/_mixins.scss"],"names":[],"mappings":"AAGI;EACI,YAAA;EACA,mBAAA;AAFR;;ACAY;EDUJ;IACI,uBAAA;EANV;AACF","sourcesContent":["@import 'assets/styles/_mixins.scss';\n\n.showing-bee-logo {\n    > img {\n        height: 20px;\n        object-fit: contain;\n    }\n}\n\n\n@include media(tablet) {}\n@include media(mobile) {\n    .showing-bee-logo {\n        > img {\n            height: 15px !important;\n        }\n    }\n    \n}","@mixin media($keys...) {\r\n    @each $key in $keys {\r\n        @if ($key==mobile) {\r\n            @media (min-width: 481px) and (max-width: 767px),\r\n            (min-width: 320px) and (max-width: 480px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==tablet) {\r\n            @media (min-width: 768px) and (max-width: 1024px),\r\n            (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==nitty-gritt) {\r\n            @media (width: 1024px) and (max-height: 833px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==small-desktop) {\r\n            @media (max-width: 1440px) and (max-height: 833px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==desktop) {\r\n            @media (min-width: 1024px) and (max-width: 1440px) {\r\n                @content;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
