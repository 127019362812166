// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalogue-container {
  padding: 32px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}
.catalogue-container.light {
  background-color: #F9F9F9;
}
.catalogue-container.primary {
  background: linear-gradient(102deg, #FAC91F -11%, #FAC91F 47.16%, #F8A728 100%);
}`, "",{"version":3,"sources":["webpack://./src/components/CatalogueContainer/catalogueContainer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,yBAAA;AAER;AAAI;EACI,+EAAA;AAER","sourcesContent":[".catalogue-container {\n    padding: 32px;\n    border-radius: 24px;\n    display: flex;\n    flex-direction: column;\n    &.light {\n        background-color: #F9F9F9;\n    }\n    &.primary {\n        background: linear-gradient(102deg, #FAC91F -11%, #FAC91F 47.16%, #F8A728 100%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
