import * as React from "react";

const AgentIcon = (props) => (
  <svg
    width={36}
    height={28}
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13 0L25.1244 7V21L13 28L0.875641 21V7L13 0Z" fill="white" />
    <path d="M23 0L35.1244 7V21L23 28L10.8756 21V7L23 0Z" fill="white" />
    <path
      d="M29 9.28571L18 3L7 9.28571H10.8824V20.6L18 25L25.7647 20.6V9.28571H29Z"
      fill="#FAC91F"
    />
  </svg>
)


export default AgentIcon;