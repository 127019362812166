import * as React from "react"

const ProBadge = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={45}
    viewBox="0 0 76 45"
    {...props}
  >
    <g id="Pro_Badge" data-name="Pro Badge" transform="translate(-1090 -1029)">
      <rect
        id="Rectangle_603"
        data-name="Rectangle 603"
        width={76}
        height={45}
        rx={10}
        transform="translate(1090 1029)"
        fill="#fac91f"
      />
      <path
        id="Path_320"
        data-name="Path 320"
        d="M179.409,0s-2.171,10.872-11.472,4.839c0,0,5.687,5.279,6.253,9.489h10.439c.566-4.21,6.253-9.489,6.253-9.489C181.58,10.872,179.409,0,179.409,0"
        transform="translate(948.709 1033.852)"
        fill="#fff"
      />
      <text
        id="PRO"
        transform="translate(1109 1065.148)"
        fill="#fff"
        fontSize={19}
        fontFamily="ProximaNova-Bold, Proxima Nova"
        fontWeight={700}
      >
        <tspan x={0} y={0}>
          {"PRO"}
        </tspan>
      </text>
    </g>
  </svg>
)


export default ProBadge
