import * as React from "react"

const ODT = (props) => (
  <svg
    width={21}
    height={25}
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.503 0C1.12635 0 0.0125153 1.125 0.0125153 2.5L0 22.5C0 23.875 1.11383 25 2.49048 25H17.521C18.8976 25 20.024 23.875 20.024 22.5V7.5L12.515 0H2.503ZM11.2635 8.75V1.875L18.1467 8.75H11.2635Z"
      fill={props.color || "#2A2A2A"}
    />
    <path
      d="M7.12074 15.4545C7.12074 16.0097 7.01551 16.482 6.80504 16.8714C6.59624 17.2609 6.3112 17.5584 5.94993 17.7638C5.59032 17.9677 5.18596 18.0696 4.73686 18.0696C4.28445 18.0696 3.87843 17.9669 3.51882 17.7614C3.15921 17.5559 2.875 17.2584 2.66619 16.869C2.45739 16.4795 2.35298 16.008 2.35298 15.4545C2.35298 14.8994 2.45739 14.4271 2.66619 14.0376C2.875 13.6482 3.15921 13.3516 3.51882 13.1477C3.87843 12.9422 4.28445 12.8395 4.73686 12.8395C5.18596 12.8395 5.59032 12.9422 5.94993 13.1477C6.3112 13.3516 6.59624 13.6482 6.80504 14.0376C7.01551 14.4271 7.12074 14.8994 7.12074 15.4545ZM6.02947 15.4545C6.02947 15.0949 5.97562 14.7917 5.8679 14.5447C5.76184 14.2978 5.61186 14.1106 5.41797 13.983C5.22408 13.8554 4.99704 13.7915 4.73686 13.7915C4.47668 13.7915 4.24964 13.8554 4.05575 13.983C3.86186 14.1106 3.71106 14.2978 3.60334 14.5447C3.49728 14.7917 3.44425 15.0949 3.44425 15.4545C3.44425 15.8142 3.49728 16.1174 3.60334 16.3643C3.71106 16.6113 3.86186 16.7985 4.05575 16.9261C4.24964 17.0537 4.47668 17.1175 4.73686 17.1175C4.99704 17.1175 5.22408 17.0537 5.41797 16.9261C5.61186 16.7985 5.76184 16.6113 5.8679 16.3643C5.97562 16.1174 6.02947 15.8142 6.02947 15.4545ZM9.72275 18H7.91806V12.9091H9.73766C10.2497 12.9091 10.6905 13.011 11.0601 13.2148C11.4297 13.417 11.7139 13.7079 11.9127 14.0874C12.1133 14.4669 12.2135 14.9209 12.2135 15.4496C12.2135 15.9799 12.1133 16.4356 11.9127 16.8168C11.7139 17.1979 11.428 17.4904 11.0551 17.6942C10.6839 17.8981 10.2398 18 9.72275 18ZM8.99441 17.0778H9.678C9.99618 17.0778 10.2638 17.0214 10.4809 16.9087C10.6997 16.7944 10.8637 16.6179 10.9731 16.3793C11.0841 16.139 11.1396 15.8291 11.1396 15.4496C11.1396 15.0734 11.0841 14.766 10.9731 14.5273C10.8637 14.2887 10.7005 14.113 10.4834 14.0004C10.2663 13.8877 9.99867 13.8313 9.68049 13.8313H8.99441V17.0778ZM12.5845 13.7965V12.9091H16.7656V13.7965H15.207V18H14.1431V13.7965H12.5845Z"
      fill={props.text || "white"}
    />
  </svg>
)


export default ODT
