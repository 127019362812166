import * as React from "react"

const Pending = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={3}
    viewBox="0 0 10 3"
    {...props}
  >
    <rect fill={props.color || "#707070"} width={10} height={3} rx={0.5} />
  </svg>
)


export default Pending
