import * as React from "react"

const Bath = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M5.83342 4.99829C5.83342 4.77743 5.92121 4.56561 6.07749 4.40944C6.23377 4.25327 6.44573 4.16553 6.66675 4.16553H7.50008V4.99829C7.50008 5.21916 7.58788 5.43097 7.74416 5.58715C7.90044 5.74332 8.1124 5.83106 8.33342 5.83106C8.55443 5.83106 8.76639 5.74332 8.92267 5.58715C9.07895 5.43097 9.16675 5.21916 9.16675 4.99829V4.16553C9.16675 3.7238 8.99115 3.30017 8.67859 2.98782C8.36603 2.67547 7.94211 2.5 7.50008 2.5H6.66675C6.00371 2.5 5.36782 2.76321 4.89898 3.23173C4.43014 3.70025 4.16675 4.33571 4.16675 4.99829L4.16675 9.16212H3.33341C2.89139 9.16212 2.46746 9.33759 2.1549 9.64994C1.84234 9.96229 1.66675 10.3859 1.66675 10.8276L1.66675 11.6604C1.66672 12.4751 1.86602 13.2774 2.24729 13.9975C2.62856 14.7175 3.18023 15.3335 3.85425 15.7918L3.57758 16.0682C3.49799 16.1451 3.4345 16.2369 3.39083 16.3385C3.34716 16.4401 3.32417 16.5494 3.32321 16.66C3.32224 16.7706 3.34333 16.8802 3.38523 16.9826C3.42713 17.0849 3.48901 17.1779 3.56725 17.2561C3.6455 17.3343 3.73854 17.3961 3.84095 17.438C3.94337 17.4799 4.0531 17.5009 4.16375 17.5C4.2744 17.499 4.38375 17.476 4.48542 17.4324C4.58709 17.3887 4.67904 17.3253 4.75591 17.2458L5.48591 16.5171C5.86425 16.6087 6.26008 16.657 6.66675 16.657H13.3334C13.7401 16.657 14.1359 16.6087 14.5142 16.5171L15.2442 17.2458C15.4014 17.3975 15.6119 17.4814 15.8304 17.4795C16.0489 17.4776 16.2579 17.39 16.4124 17.2356C16.5669 17.0812 16.6546 16.8724 16.6565 16.654C16.6584 16.4357 16.5744 16.2253 16.4226 16.0682L16.1459 15.7918C16.8199 15.3335 17.3716 14.7175 17.7529 13.9975C18.1341 13.2774 18.3334 12.4751 18.3334 11.6604V10.8276C18.3334 10.3859 18.1578 9.96229 17.8453 9.64994C17.5327 9.33759 17.1088 9.16212 16.6667 9.16212L5.83342 9.16212L5.83342 4.99829Z"
            fill={props.color || "#2A2A2A"}
        />
    </svg>
)


export default Bath
