import DashboardRoute from "./modules/Dashboard";
import ManageShowingsRoute from "./modules/ManageShowings";
import ManageListingsRoute from "./modules/ManageListings";
import ManageConatctsRoute from "./modules/Contacts";
import InquiriesRoute from "./modules/Inquiries";


export const Dashboard = DashboardRoute
export const ManageShowings = ManageShowingsRoute
export const ManageListings = ManageListingsRoute
export const Contacts = ManageConatctsRoute
export const Inquiries = InquiriesRoute
