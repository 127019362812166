import * as React from "react"

const ThinClose = (props) => (
  <svg
    width={9}
    height={9}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 5.375L1.4375 8.4375C1.32292 8.55208 1.17708 8.60938 1 8.60938C0.822917 8.60938 0.677083 8.55208 0.5625 8.4375C0.447916 8.32292 0.390625 8.17708 0.390625 8C0.390625 7.82292 0.447916 7.67708 0.5625 7.5625L3.625 4.5L0.5625 1.4375C0.447916 1.32292 0.390625 1.17708 0.390625 1C0.390625 0.822917 0.447916 0.677083 0.5625 0.5625C0.677083 0.447916 0.822917 0.390625 1 0.390625C1.17708 0.390625 1.32292 0.447916 1.4375 0.5625L4.5 3.625L7.5625 0.5625C7.67708 0.447916 7.82292 0.390625 8 0.390625C8.17708 0.390625 8.32292 0.447916 8.4375 0.5625C8.55208 0.677083 8.60938 0.822917 8.60938 1C8.60938 1.17708 8.55208 1.32292 8.4375 1.4375L5.375 4.5L8.4375 7.5625C8.55208 7.67708 8.60938 7.82292 8.60938 8C8.60938 8.17708 8.55208 8.32292 8.4375 8.4375C8.32292 8.55208 8.17708 8.60938 8 8.60938C7.82292 8.60938 7.67708 8.55208 7.5625 8.4375L4.5 5.375Z"
      fill="white"
    />
  </svg>
)


export default ThinClose
