import * as React from "react"

const PDF = (props) => (
  <svg
    width={21}
    height={25}
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.503 0C1.12635 0 0.0125153 1.125 0.0125153 2.5L0 22.5C0 23.875 1.11383 25 2.49048 25H17.521C18.8976 25 20.024 23.875 20.024 22.5V7.5L12.515 0H2.503ZM11.2635 8.75V1.875L18.1467 8.75H11.2635Z"
      fill={props.color || "#2A2A2A"}
    />
    <path
      d="M3.44247 18V12.9091H5.45099C5.83712 12.9091 6.16607 12.9828 6.43786 13.1303C6.70964 13.2762 6.91679 13.4792 7.0593 13.7393C7.20348 13.9979 7.27557 14.2962 7.27557 14.6342C7.27557 14.9723 7.20265 15.2706 7.05682 15.5291C6.91099 15.7876 6.69969 15.989 6.42294 16.1332C6.14785 16.2773 5.81475 16.3494 5.42365 16.3494H4.14347V15.4869H5.24964C5.45679 15.4869 5.62749 15.4512 5.76172 15.38C5.89761 15.3071 5.9987 15.2068 6.06499 15.0792C6.13293 14.9499 6.1669 14.8016 6.1669 14.6342C6.1669 14.4652 6.13293 14.3177 6.06499 14.1918C5.9987 14.0642 5.89761 13.9656 5.76172 13.896C5.62583 13.8247 5.45348 13.7891 5.24467 13.7891H4.51882V18H3.44247ZM9.77939 18H7.9747V12.9091H9.7943C10.3064 12.9091 10.7472 13.011 11.1167 13.2148C11.4863 13.417 11.7705 13.7079 11.9694 14.0874C12.1699 14.4669 12.2702 14.9209 12.2702 15.4496C12.2702 15.9799 12.1699 16.4356 11.9694 16.8168C11.7705 17.1979 11.4846 17.4904 11.1118 17.6942C10.7406 17.8981 10.2964 18 9.77939 18ZM9.05105 17.0778H9.73464C10.0528 17.0778 10.3205 17.0214 10.5376 16.9087C10.7563 16.7944 10.9204 16.6179 11.0297 16.3793C11.1408 16.139 11.1963 15.8291 11.1963 15.4496C11.1963 15.0734 11.1408 14.766 11.0297 14.5273C10.9204 14.2887 10.7571 14.113 10.54 14.0004C10.3229 13.8877 10.0553 13.8313 9.73713 13.8313H9.05105V17.0778ZM13.0675 18V12.9091H16.4382V13.7965H14.1438V15.0096H16.2145V15.897H14.1438V18H13.0675Z"
      fill={props.text || "white"}
    />
  </svg>
)


export default PDF
