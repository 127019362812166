import { useCallback, useState } from 'react';
import moment from 'moment'

//FilePond
import { FilePond, registerPlugin } from 'react-filepond';
//FilePond Packages
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
//FilePond Styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';

import './uploadDocument.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';

// FilePond Register Plug-ins
registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode,
    FilePondPluginMediaPreview
)

const UploadDocument = ({ modal, setModal, selectedData }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([])

    const onFileUpload = (files) => {
        let fileData = []
        try {
            files.map(file => {
                return fileData.push(file.getFileEncodeDataURL())
            })
            if (fileData.length > 0) setFiles(fileData);
            else setFiles([])
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = useCallback(async() => {
        const data = { 
            approval_document: files[0],
            approval_document_expiry: moment().startOf('day').add(30, 'days').format('YYYY-MM-DDTHH:mm:ss')
        }
        try {
            await dispatch(actions.buyer.patchBuyerMortgageConnection({id: selectedData.id, data}));
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            setModal(prev => !prev);
            toast.success('Document successfully uploaded!', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            // toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
            //     position: toast.POSITION.TOP_CENTER
            // });
            console.log(error)
        }
    }, [files])

    return (
        <div className={`upload-document-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Upload Approval Document
                    <span>({selectedData.buyer?.first_name} {selectedData.buyer?.last_name})</span>
                </p>
                <Seprator height={50} />
                <div className='form-input'>
                    <label>Approval Document</label>
                    <FilePond
                        files={files}
                        oninit={() => {}}
                        maxFileSize="5MB"
                        imagePreviewHeight={110}
                        allowMultiple={false}
                        allowFileTypeValidation={true}
                        acceptedFileTypes={['image/*', 'application/pdf']}
                        onupdatefiles={(fileItems) => {
                            onFileUpload(fileItems);
                        }}
                    />
                </div>
                <Seprator height={30} />
                <button className='button primary small no-glow' onClick={onSubmit} disabled={files.length === 0}>
                    Upload
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default UploadDocument;