import { useNavigate } from "react-router-dom";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './reActivate.scss'
import ToastMessage from "components/ToastMessage";
import { useCallback, useState } from "react";
import actions from "store/actions";
import { getErrorMessage } from "utils/modules/Handlers";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import DeactIllus from 'assets/images/deactivate-acc.svg'
import ActIllus from 'assets/images/acticvate-acc.svg'
import Icons from "components/Icons";

const ReActivateAccount = () => {
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);

    const onSubmit = useCallback(async() => {
        try {
            await setActive(prev => !prev);
            await dispatch(actions.auth.activateAccount()).then(async() => {
                await setTimeout(() => {
                    window.location.reload();
                    // navigate("/");
                }, 5000);
            });

        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [])

    const logout = async() => {
        await dispatch(actions.auth.logout())
        window.location.reload();
    }


    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="re-activate-container">
                <div className={`active-con ${active ? 'open' : 'close'}`}>
                    <img src={ActIllus} alt="" />
                    <Seprator height={50} />
                    <p className="header">Your account has been re-activated!</p>
                    <Seprator height={26} />
                    <p className="subheader">Welcome back to Showing Bee. <br />Please wait while we prepare your things.</p>
                    <Seprator height={50} />
                    <Icons.Loading height={100} width={100} background={'none'} />
                </div>
                <div className={`deact-con ${!active ? 'open' : 'close'}`}>
                    <img src={DeactIllus} alt="" />
                    <Seprator height={50} />
                    <p className="header">Your account is currently deactivated!</p>
                    <Seprator height={26} />
                    <p className="subheader">Do you want to re-activate your account?</p>
                    <Seprator height={150} />
                    <button className="button primary medium no-glow browse" onClick={onSubmit}>Activate Now</button>
                    <Seprator height={15} />
                    <p className="cancel" onClick={logout}>Cancel</p>
                </div>
            </div>
        </Section>
    )
}


export default ReActivateAccount;