import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
// Redux
import { useDispatch } from "react-redux";
import actions from "store/actions";
// Utils
import { getErrorMessage, inputChange } from "utils/modules/Handlers";
// Components
import Icons from "components/Icons";
import Section from "components/Section";
import Seprator from "components/Separator";
import ToastMessage from "components/ToastMessage";
// Assets
import './setAccount.scss'
import InviteIllus from 'assets/images/invite-illus.svg'

const SetAccount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        password: {
            value: '',
            error: '',
        },
        confirm_password: {
            value: '',
            error: '',
        },
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onSubmit = useCallback(async(event) => {
        event.preventDefault();

        const { password, confirm_password, first_name, last_name, phone_number } = form
        let isValid = true

        if (confirm_password.value !== password.value) {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: 'Password does not match'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: ''})
        }

        if ((password.value && confirm_password.value) && confirm_password.value === password.value) {
            isValid = true
        }

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (password.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: ''})
        }
        
                
        if (!validatePhone(phone_number.value)) {
            inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
        }

        if (isValid) {
            setLoading(true);
            try {
                let type;
                if (urlParams.get('type') === 'Buyer') { type = 'buyer'; }
                else if (urlParams.get('type') === 'Seller') { type = 'seller'; }
                else if (urlParams.get('type') === 'Mortgage Broker') { type = 'mortgage_broker'; }
                else { type = 'agent'; }
                
                const data = {
                    uidb64: urlParams.get('uidb64'),
                    token: urlParams.get('token'),
                    password: password.value,
                    first_name: first_name.value,
                    last_name: last_name.value,
                    phone_number: `1${phone_number.value}`,
                    user_type: type,
                }
                await dispatch(actions.auth.setAccount(data));
                setLoading(false);
                navigate('/confirm');
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [dispatch, form, navigate])

    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="set-account-container">
                {/* <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} /> */}
                <img src={InviteIllus} alt="verified" />
                <Seprator height={50} />
                <p className="header">Account Setup</p>
                <Seprator height={10} />
                <p className="subheader">Please complete your ShowingBee account!</p>
                <Seprator height={25} />
                <div className="form-container">
                    <div className="name">
                        <div className='form-input'>
                            <input 
                                id='first_name' 
                                placeholder='Enter First Name'
                                autoComplete="new-password"
                                type='text' 
                                className='small' 
                                value={form.first_name.value}
                                onChange={(event) => onChangeInput({event, type: "first_name"})} 
                            />
                            <span className='error-message' style={{ width: 240 }}>{form.first_name.error}</span>
                        </div>
                        <Seprator width={30} />
                        <div className='form-input'>
                            <input 
                                id='last_name'
                                autoComplete="new-password"
                                placeholder='Enter Last Name' 
                                type='text' 
                                className='small' 
                                value={form.last_name.value}
                                onChange={(event) => onChangeInput({event, type: "last_name"})} 
                            />
                            <span className='error-message' style={{ width: 240 }}>{form.last_name.error}</span>
                        </div>
                    </div>
                    <Seprator height={30} />
                    <div className='form-input'>
                        <input 
                            id='phone_number' 
                            placeholder='Enter 10-digit Phone Number' 
                            type='text'
                            autoComplete="new-password"
                            className='small' 
                            maxLength={10} 
                            value={form.phone_number.value}
                            onChange={(event) => onChangeInput({event, type: "phone_number"})} 
                        />
                        <span className='error-message' style={{ alignSelf: 'flex-start' }}>{form.phone_number.error}</span>
                    </div>
                    <Seprator height={30} />
                    <div className="password small">
                        <input 
                            type={viewPassword ? 'text' : 'password'} 
                            placeholder='Password'
                            autoComplete="new-password"
                            className='small' 
                            onChange={(event) => onChangeInput({event, type: "password"})}
                        />
                        <span className='error-message'>{form.password.error}</span>
                        <Icons.Eye color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />
                    </div>
                    <Seprator height={30} />
                    <div className="password small">
                        <input 
                            type={viewPassword2 ? 'text' : 'password'} 
                            className='small'
                            autoComplete="new-password"
                            placeholder='Confirm Password' 
                            onChange={(event) => onChangeInput({event, type: "confirm_password"})}
                        />
                        <span className='error-message'>{form.confirm_password.error}</span>
                        <Icons.Eye color={viewPassword2 ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword2(prev => !prev)} />
                    </div>
                    <Seprator height={50} />
                    <button className="button primary small no-glow browse" disabled={loading} onClick={onSubmit}>
                        {
                            loading 
                            ? <Icons.Loading height={40} width={40} background={'none'} />
                            : 'Submit'
                        }
                    </button>
                </div>
            </div>
        </Section>
    )
}


export default SetAccount;