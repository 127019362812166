// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-header-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-header-component .header {
  font-size: 35px;
  color: var(--secondary-color-1);
  font-weight: 700;
}
.table-header-component div.header-button {
  background-color: white;
  font-weight: 700;
  color: var(--secondary-color-1);
  padding: 8px 18px;
  font-size: 14px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.table-header-component div.header-button:hover {
  opacity: 0.75;
}
.table-header-component div.header-button > div {
  display: flex;
  align-items: center;
}
.table-header-component div.header-button > div > p {
  margin: 0;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/TableHeader/tableHeader.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;AACD;AAAI;EACI,eAAA;EACA,+BAAA;EACA,gBAAA;AAER;AACI;EACI,uBAAA;EACA,gBAAA;EACA,+BAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,4BAAA;AACR;AACQ;EACI,aAAA;AACZ;AAEQ;EACI,aAAA;EACA,mBAAA;AAAZ;AAEY;EACI,SAAA;EACA,iBAAA;AAAhB","sourcesContent":[".table-header-component {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n    .header {\n        font-size: 35px;\n        color: var(--secondary-color-1);\n        font-weight: 700;\n    }\n\n    div.header-button {\n        background-color: white;\n        font-weight: 700;\n        color: var(--secondary-color-1);\n        padding: 8px 18px;\n        font-size: 14px;\n        border: 1px solid #BEBEBE;\n        border-radius: 7px;\n        cursor: pointer;\n        transition: .2s ease-in-out;\n\n        &:hover {\n            opacity: .75;\n        }\n\n        >div {\n            display: flex;\n            align-items: center;\n\n            >p {\n                margin: 0;\n                margin-left: 10px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
