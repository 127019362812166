import React, { useState, useRef, useEffect } from 'react';
import './accordion.scss';
import Separator from '../Separator';

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setHeight(contentRef.current.scrollHeight);
        } else {
            setHeight(0);
        }
    }, [isOpen]);

    return (
        <div className='accordion-item-container'>
            <div className="accordion-item">
                <button
                    className="accordion-title"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {title}
                    <div className='arrow-container'>
                        {
                            !isOpen
                                ? <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.93934 8.93934C6.52513 8.35355 7.47487 8.35355 8.06066 8.93934L12 12.8787L15.9393 8.93934C16.5251 8.35355 17.4749 8.35355 18.0607 8.93934C18.6464 9.52513 18.6464 10.4749 18.0607 11.0607L13.0607 16.0607C12.4749 16.6464 11.5251 16.6464 10.9393 16.0607L5.93934 11.0607C5.35355 10.4749 5.35355 9.52513 5.93934 8.93934Z"
                                        fill="#5F5F5F"
                                    />
                                </svg>
                                : <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4.5 12C4.5 11.1716 5.17157 10.5 6 10.5H18C18.8284 10.5 19.5 11.1716 19.5 12C19.5 12.8284 18.8284 13.5 18 13.5H6C5.17157 13.5 4.5 12.8284 4.5 12Z"
                                        fill="#5F5F5F"
                                    />
                                </svg>
                        }
                    </div>
                </button>
                <div
                    className="accordion-content"
                    style={{ height: `${height}px` }}
                >
                    <div ref={contentRef}>
                        {content}
                        <Separator height={16} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Accordion = ({ items }) => {
    return (
        <div className="accordion">
            {items.map((item, index) => (
                <AccordionItem key={index} title={item.title} content={item.content} />
            ))}
        </div>
    );
};

export default Accordion;