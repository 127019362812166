// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
}
.accordion .accordion-item-container {
  padding: 32px 32px 16px 32px;
  border-radius: 24px;
  background-color: #F9F9F9;
  margin-bottom: 16px;
}
.accordion .accordion-title {
  padding: 0;
  padding-bottom: 16px;
  background-color: #F9F9F9;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #2A2A2A;
}
.accordion .accordion-content {
  overflow: hidden;
  transition: height 0.3s ease-out;
  color: #949494;
  line-height: 24px;
  width: 688px;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion/accordion.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,cAAA;EACA,4BAAA;AACJ;AACI;EACI,4BAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACR;AAGI;EACI,UAAA;EACA,oBAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADR;AAII;EACI,gBAAA;EACA,gCAAA;EACA,cAAA;EACA,iBAAA;EACA,YAAA;AAFR","sourcesContent":[".accordion {\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto;\n    transition: .2s ease-in-out;\n\n    .accordion-item-container {\n        padding: 32px 32px 16px 32px;\n        border-radius: 24px;\n        background-color: #F9F9F9;\n        margin-bottom: 16px;\n\n    }\n\n    .accordion-title {\n        padding: 0;\n        padding-bottom: 16px;\n        background-color: #F9F9F9;\n        width: 100%;\n        text-align: left;\n        border: none;\n        outline: none;\n        cursor: pointer;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        font-size: 16px;\n        font-weight: 700;\n        color: #2A2A2A;\n    }\n\n    .accordion-content {\n        overflow: hidden;\n        transition: height 0.3s ease-out;\n        color: #949494;\n        line-height: 24px;\n        width: 688px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
