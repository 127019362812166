import * as React from "react"

const Document = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.23}
    height={24.999}
    viewBox="0 0 19.23 24.999"
    {...props}
  >
    <path
      fill={props.color || '#2a2a2a'}
      d="M18.288,3.375H9.154a2.4,2.4,0,0,0-2.4,2.4V25.97a2.4,2.4,0,0,0,2.4,2.4H23.576a2.4,2.4,0,0,0,2.4-2.4v-14.9Zm-.962,8.654V5.3l6.731,6.731Z"
      transform="translate(-6.75 -3.375)"
    />
  </svg>
)


export default Document
