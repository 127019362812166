import * as React from "react";

const Waggle = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0002 2C7.02954 2 3 5.8226 3 10.5378C3 14.4891 5.82984 17.8131 9.6718 18.7865C9.68664 18.8228 9.70401 18.8592 9.72422 18.8946L11.2249 21.544C11.5694 22.152 12.4306 22.152 12.7751 21.544L14.2758 18.8946C14.296 18.8589 14.3134 18.8228 14.3282 18.7865C18.1702 17.8131 21 14.4891 21 10.5378C21.0003 5.8226 16.9708 2 12.0002 2ZM17.0665 14.3874C16.7289 14.763 16.1447 14.7926 15.7288 14.4624C15.2412 14.0749 14.7798 13.6416 14.253 13.3188C12.6036 12.3074 10.9656 12.433 9.38822 13.5067C9.01779 13.7588 8.68242 14.0707 8.35683 14.3826C7.95388 14.7685 7.35387 14.8023 6.96891 14.427C6.59059 14.0581 6.57227 13.4398 6.94965 13.0407C8.16356 11.7567 9.61117 10.9198 11.3761 10.7067C11.5742 10.6829 11.7756 10.6865 11.9481 10.6787C13.6227 10.6858 15.0258 11.3357 16.2855 12.3589C16.5359 12.5624 16.7775 12.7783 17.0096 13.0027C17.4091 13.3883 17.4277 13.9847 17.0658 14.3877L17.0665 14.3874ZM18.0723 8.91068C17.9775 9.70506 17.2067 10.0601 16.5618 9.63425C16.0559 9.29982 15.5532 8.94544 15.0119 8.68729C12.3583 7.42136 9.85938 7.79441 7.52345 9.56666C6.87512 10.0585 6.05658 9.76622 5.93374 8.95606C5.87563 8.57303 6.01332 8.25244 6.30258 8.01554C7.48524 7.04701 8.81442 6.40938 10.2841 6.11776C10.8428 6.00703 11.3118 5.91105 11.979 5.89341C14.3472 5.83079 15.9779 6.71709 17.6807 8.00427C17.9677 8.22122 18.1177 8.53408 18.0729 8.91068H18.0723Z"
      fill={props.color || "#949494"}
    />
  </svg>
);


export default Waggle;
