import * as React from "react"

const SavedSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.828}
    height={7.828}
    viewBox="0 0 7.828 7.828"
    {...props}
  >
    <defs>
      <style>
        {
          ".saved-search{fill:none;stroke:#fac91f;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}"
        }
      </style>
    </defs>
    <g transform="translate(-9.086 -9.086)">
      <path className="saved-search" d="M10.5,15.5l5-5" />
      <path className="saved-search" d="M10.5,10.5h5v5" />
    </g>
  </svg>
)


export default SavedSearch
