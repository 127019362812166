import * as React from "react"

const HouseAlt = (props) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99998 0.5L0.333313 5.5V15.5H4.49998V9.66667H9.49998V15.5H13.6666V5.5L6.99998 0.5Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default HouseAlt
