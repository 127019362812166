import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import './messages.scss'
import ChatBox from './modules/ChatBox';
import InBox from './modules/InBox';
import API from 'API';
import { checkImageUrl } from 'utils/modules/Handlers';
import NoUser from 'assets/images/no_user.jpg'
import Talk from 'talkjs';
import NoConvo from 'assets/images/select-convo.svg';
import Seprator from 'components/Separator';
import { Config } from 'utils';


const Messages = () => {
    const dispatch = useDispatch();
    const [other, setOther] = useState(null);
    const { user } = useSelector((state) => state.auth);
    const [agents, setAgents] = useState([]);
    const [buyers, setBuyers] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [convo, setConvo] = useState([]);
    const [selectedConvo, setSelectedConvo] = useState(null);
    const [session, setSession] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [talkLoaded, markTalkLoaded] = useState(false);

    useEffect(() => {
        const init = () => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (user.id && talkLoaded) {
                const currentUserData = new Talk.User({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    photoUrl: checkImageUrl(user.profile_photo, NoUser),
                    role: user.user_type,
                });
                
                const sessionData = new Talk.Session({
                    appId: Config.TalkJS_APP_ID,
                    me: currentUserData,
                });

                setCurrentUser(currentUserData);
                setSession(sessionData)
            }
        }

        init();
    }, [user, talkLoaded])

    const fetchData = async() => {
        if (user.user_type === 'buyer' || user.user_type === 'seller') {
            const agentData = await API.agent.fetchBuyerAgentConnection({ offset: 0, limit: 6 });
            const brokerData = await API.buyer.fetchBuyerMortgageConnection({ offset: 0, limit: 6 });
            let agentsData = []
            let brokersData = []
            await agentData.data.results.map(item => {
                agentsData.push(item.agent)
            })
            await brokerData.data.results.map(item => {
                brokersData.push(item.mortgage_broker)
            })
            setAgents(agentsData);
            setBrokers(brokersData);

        } else if (user.user_type === 'agent') {
            const agentData = await API.agent.fetchBuyerAgentConnection({ offset: 0, limit: 6 });
            let buyersData = []
            let tesmMembersData = []
            await agentData.data.results.map(item => {
                buyersData.push(item.buyer)
            })
            await dispatch(actions.team.fetchJoinedTeams()).then(async(item) => {
                let team_ids = []
                await item.map((team) => team_ids.push(team.id))
                await dispatch(actions.team.fetchAllTeamMembers(team_ids)).then(async(data) => {
                    data.map((item) => {
                        tesmMembersData.push(item.member)
                    })
                });
            });
            await dispatch(actions.team.fetchOwnedTeams(user.id)).then(async(item) => {
                let team_ids = []
                await item.map((team) => team_ids.push(team.id))
                await dispatch(actions.team.fetchAllTeamMembers(team_ids)).then(async(data) => {
                    data.map((item) => {
                        tesmMembersData.push(item.member)
                    })
                });
            });

            setBuyers(buyersData);
            setTeamMembers(tesmMembersData);
        } else if (user.user_type === 'mortgage_broker') {
            const brokerData = await API.buyer.fetchBuyerMortgageConnection({ offset: 0, limit: 6 });
            let buyersData = []
            await brokerData.data.results.map(item => {
                buyersData.push(item.buyer)
            })
            setBuyers(buyersData);
        }
    }

    const fetchConvo = async() => {
        await dispatch(actions.message.fetchConversations(user.id)).then((res) => {
            setConvo(res.data)
        });        
    }

    useEffect(() => {
        const init = async() => {
            if (user.id && session) {
                fetchConvo();
                fetchData();
                const inbox = session.createInbox();
                inbox.mount(document.getElementById('inbox-container'));
                inbox.onSelectConversation((data) => {
                    setSelectedConvo(data);
                    setOther(null);
                    data.preventDefault();
                })
                inbox.select(null);
            }
        }

        init();
    }, [user, session])

    return (
        <div className="messages-container">
            <div className="nav">
                <InBox 
                    other={other} 
                    setOther={setOther} 
                    selectedConvo={selectedConvo} 
                    setSelectedConvo={setSelectedConvo} 
                    agents={agents}
                    brokers={brokers}
                    buyers={buyers}
                    teamMembers={teamMembers}
                />
            </div>

            <div className="form">
            {
                (other || selectedConvo) ?
                <ChatBox 
                    other={other}
                    convo={convo}
                    currentUser={currentUser}
                    session={session}
                    selectedConvo={selectedConvo}
                />
                : <div className='no-convo'>
                    <img src={NoConvo} alt="" />
                    <Seprator height={20} /> 
                    <p>No conversation selected</p>
                    <span>Select a conversation or user to start chatting</span>
                </div>
            }
            </div>
        </div>
    )
}


export default Messages;