import * as React from "react";

const HouseBeeTier = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <g className="house-bee-badge" transform="translate(-8113 -1163)">
      <path
        data-name="Path 978"
        d="M452.312.519h0a1.714,1.714,0,0,0,1.7.381,1.9,1.9,0,0,1,2.365,1.116,1.682,1.682,0,0,0,1.366,1.067,1.86,1.86,0,0,1,1.637,2.01,1.643,1.643,0,0,0,.758,1.543,1.828,1.828,0,0,1,.584,2.507,1.626,1.626,0,0,0,0,1.712,1.828,1.828,0,0,1-.584,2.507,1.643,1.643,0,0,0-.758,1.543,1.86,1.86,0,0,1-1.637,2.01,1.682,1.682,0,0,0-1.366,1.067,1.9,1.9,0,0,1-2.365,1.116,1.714,1.714,0,0,0-1.7.381,1.917,1.917,0,0,1-2.625,0,1.714,1.714,0,0,0-1.7-.381,1.9,1.9,0,0,1-2.365-1.116,1.682,1.682,0,0,0-1.367-1.067,1.86,1.86,0,0,1-1.637-2.01,1.643,1.643,0,0,0-.758-1.543,1.828,1.828,0,0,1-.584-2.507,1.625,1.625,0,0,0,0-1.712,1.828,1.828,0,0,1,.584-2.507,1.643,1.643,0,0,0,.758-1.543,1.86,1.86,0,0,1,1.637-2.01,1.682,1.682,0,0,0,1.367-1.067A1.9,1.9,0,0,1,447.983.9a1.714,1.714,0,0,0,1.7-.381,1.917,1.917,0,0,1,2.625,0"
        transform="translate(7672 1163)"
        fill="#2a2a2a"
      />
      <g
        data-name="Group 2451"
        transform="translate(8116.333 1168.031)"
      >
        <path
          data-name="Path 979"
          d="M554.2,229.564a5.409,5.409,0,0,1-1.176.87l-.044.025a5.488,5.488,0,0,1-1.339.519.569.569,0,0,0,.615.642,2.014,2.014,0,0,0,1.283-.628c.055-.053.107-.108.156-.164a1.681,1.681,0,0,0,.505-1.263"
          transform="translate(-549.209 -222.849)"
          fill="#fff"
        />
        <path
          data-name="Path 980"
          d="M508.391,175.112l.078-.042a4.875,4.875,0,0,0,1.149-.853c.065-.066.126-.133.184-.2s.113-.137.163-.206a2.428,2.428,0,0,0,.371-.712,3.443,3.443,0,0,1-1.973-1.485c-.038.006-.076.013-.114.02a4.87,4.87,0,0,0-1.371.5c-1.442.783-2.254,2.074-1.814,2.883a1.468,1.468,0,0,0,1.369.622,3.352,3.352,0,0,0,.5-.039q.145-.022.3-.057t.322-.087a5.026,5.026,0,0,0,.843-.349"
          transform="translate(-504.943 -167.92)"
          fill="#fff"
        />
        <path
          data-name="Path 981"
          d="M665.877,230.457l-.044-.024a5.409,5.409,0,0,1-1.176-.87,1.681,1.681,0,0,0,.505,1.263c.048.056.1.111.156.164a2.014,2.014,0,0,0,1.283.628.569.569,0,0,0,.615-.642,5.487,5.487,0,0,1-1.339-.519"
          transform="translate(-656.319 -222.848)"
          fill="#fff"
        />
        <path
          data-name="Path 982"
          d="M660.7,172.139a4.869,4.869,0,0,0-1.371-.5c-.038-.008-.076-.015-.114-.02a3.443,3.443,0,0,1-1.973,1.485,2.427,2.427,0,0,0,.371.712c.05.069.1.138.163.206s.119.134.184.2a4.873,4.873,0,0,0,1.149.853l.078.042a5.021,5.021,0,0,0,.843.349q.163.051.322.087t.3.057a3.354,3.354,0,0,0,.5.039,1.468,1.468,0,0,0,1.369-.622c.44-.81-.372-2.1-1.814-2.883"
          transform="translate(-649.297 -167.921)"
          fill="#fff"
        />
        <path
          data-name="Path 983"
          d="M573.9,103.907a2.14,2.14,0,0,0,.119.254,2.208,2.208,0,0,0,.167.259,2.979,2.979,0,0,0,1.445,1.007c.087.029.176.056.267.079s.173.041.261.057a3.9,3.9,0,0,0,1.368,0c.089-.016.176-.035.261-.057s.18-.05.267-.079a2.979,2.979,0,0,0,1.445-1.007,2.192,2.192,0,0,0,.167-.259,2.121,2.121,0,0,0,.119-.254l.017-.046a1.941,1.941,0,0,0,.117-.663c0-1.337-1.381-2.424-3.078-2.424s-3.078,1.087-3.078,2.424a1.942,1.942,0,0,0,.117.663l.017.046"
          transform="translate(-570.179 -100.774)"
          fill="#fff"
        />
        <path
          data-name="Path 994"
          d="M608.369,203.81a1.11,1.11,0,0,0-.122-.357c.07-.091.135-.183.193-.276a2.783,2.783,0,0,0,.321-.694,4.394,4.394,0,0,0,1.532,0,2.782,2.782,0,0,0,.321.694c.059.093.123.186.193.276a1.109,1.109,0,0,0-.125.374,3.823,3.823,0,0,1-2.313-.017"
          transform="translate(-602.861 -197.18)"
          fill="#fff"
        />
        <path
          data-name="Path 995"
          d="M604.587,238.778a2.418,2.418,0,0,0,.238-.429.535.535,0,0,0,.019-.053c.017-.058.032-.116.043-.173a4.363,4.363,0,0,0,2.348.022c.01.05.023.1.038.151q.007.023.017.048a2.659,2.659,0,0,0,.262.444,3.807,3.807,0,0,1-2.965-.01"
          transform="translate(-599.392 -230.963)"
          fill="#fff"
        />
        <path
          data-name="Path 996"
          d="M595.4,259.286h0a2.628,2.628,0,0,1-1.4.783l-.751,1.151-.753-1.155a2.659,2.659,0,0,1-1.422-.813s.2-.159.206-.164l.142-.177.051-.066a4.318,4.318,0,0,0,3.587.014c.133.173.26.328.342.429"
          transform="translate(-586.577 -250.602)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);


export default HouseBeeTier;
