import * as React from "react"

const CaretDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.812}
    height={6.06}
    viewBox="0 0 9.812 6.06"
    className="arrow-down"
    {...props}
  >
    <path
      fill={props.color || '#2a2a2a'}
      stroke={props.color || '#2a2a2a'}
      d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
      transform="translate(15.441 -10.746) rotate(90)"
    />
  </svg>
)


export default CaretDown
