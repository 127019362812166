import * as React from "react";

const ForagerBeeTier = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <g className="forager-bee-badge" transform="translate(-7672 -1163)">
      <path
        data-name="Path 974"
        d="M11.312.519h0a1.714,1.714,0,0,0,1.7.381,1.9,1.9,0,0,1,2.365,1.116,1.683,1.683,0,0,0,1.366,1.067,1.86,1.86,0,0,1,1.637,2.01,1.643,1.643,0,0,0,.758,1.543h0a1.828,1.828,0,0,1,.584,2.507,1.626,1.626,0,0,0,0,1.712,1.828,1.828,0,0,1-.584,2.507h0a1.643,1.643,0,0,0-.758,1.543,1.86,1.86,0,0,1-1.637,2.01,1.682,1.682,0,0,0-1.366,1.067A1.9,1.9,0,0,1,13.016,19.1a1.714,1.714,0,0,0-1.7.381,1.917,1.917,0,0,1-2.625,0,1.714,1.714,0,0,0-1.7-.381,1.9,1.9,0,0,1-2.365-1.116,1.682,1.682,0,0,0-1.367-1.067,1.86,1.86,0,0,1-1.637-2.01h0a1.643,1.643,0,0,0-.758-1.543,1.828,1.828,0,0,1-.584-2.507,1.625,1.625,0,0,0,0-1.712A1.828,1.828,0,0,1,.856,6.637a1.643,1.643,0,0,0,.758-1.543h0a1.86,1.86,0,0,1,1.637-2.01A1.682,1.682,0,0,0,4.618,2.016,1.9,1.9,0,0,1,6.983.9a1.714,1.714,0,0,0,1.7-.381,1.917,1.917,0,0,1,2.625,0"
        transform="translate(7672 1163)"
        fill="#d4d4d4"
      />
      <g
        data-name="Group 2453"
        transform="translate(7675.926 1168.03)"
      >
        <path
          data-name="Path 975"
          d="M79.852,178.976a3.5,3.5,0,0,1-1.476-1.062q-.137.012-.28.039a4.048,4.048,0,0,0-1.181.429c-1.2.654-1.884,1.734-1.516,2.411a1.226,1.226,0,0,0,1.144.52,3.662,3.662,0,0,0,1.665-.458l.039-.021a2.914,2.914,0,0,0,1.606-1.857"
          transform="translate(-75.298 -173.892)"
          fill="#fff"
        />
        <path
          data-name="Path 976"
          d="M223.942,178.467a4.049,4.049,0,0,0-1.181-.429c-.078-.015-.156-.026-.231-.034a3.512,3.512,0,0,1-1.522,1.076,2.935,2.935,0,0,0,1.6,1.838l.039.021a3.661,3.661,0,0,0,1.665.458,1.226,1.226,0,0,0,1.144-.52c.368-.677-.311-1.757-1.516-2.411"
          transform="translate(-213.41 -173.977)"
          fill="#fff"
        />
        <path
          data-name="Path 977"
          d="M133.58,104.262a2.3,2.3,0,0,0,.2.27,2.6,2.6,0,0,0,.324.323,2.971,2.971,0,0,0,.559.37,3.287,3.287,0,0,0,.364.162q.141.053.289.095a3.86,3.86,0,0,0,2.008.015q.146-.038.286-.088a3.374,3.374,0,0,0,.345-.144,3.03,3.03,0,0,0,.662-.43,2.584,2.584,0,0,0,.3-.307,2.33,2.33,0,0,0,.193-.265,2.166,2.166,0,0,0,.128-.237,1.959,1.959,0,0,0,.185-.828c0-1.337-1.381-2.424-3.078-2.424s-3.078,1.087-3.078,2.424a1.953,1.953,0,0,0,.169.792,2.072,2.072,0,0,0,.144.273"
          transform="translate(-130.245 -100.773)"
          fill="#fff"
        />
        <path
          data-name="Path 991"
          d="M165.917,202.151l.013-.019a2.269,2.269,0,0,0,.321-.778,4.368,4.368,0,0,0,2.034.012,2.288,2.288,0,0,0,.319.766l.02.031a3.293,3.293,0,0,1-2.706-.011"
          transform="translate(-161.192 -196.11)"
          fill="#fff"
        />
        <path
          data-name="Path 992"
          d="M152.94,225.8a3.5,3.5,0,0,1-4.543-.013,4.126,4.126,0,0,0,.566-.512,4.275,4.275,0,0,0,3.411.019,4.158,4.158,0,0,0,.566.506"
          transform="translate(-144.586 -218.783)"
          fill="#fff"
        />
        <path
          data-name="Path 993"
          d="M142.647,241.157a2.783,2.783,0,0,1-2.1,2.088l-.286.439a.485.485,0,0,1-.066.1l-.4.612-.753-1.155a2.811,2.811,0,0,1-2.1-2.073l.13-.074a4.138,4.138,0,0,0,5.493.018l.076.043s0,0,0,0Z"
          transform="translate(-133.733 -233.778)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);


export default ForagerBeeTier;
