let SERVER_URL;
let CLIENT_URL;
let TalkJS_APP_ID;
let TalkJS_KEY;

SERVER_URL = 'https://www.showingbee.com';
CLIENT_URL = 'https://www.showingbee.com';
TalkJS_APP_ID = 'tsWAB7LI';
TalkJS_KEY = 'sk_test_hSZWcQRYNWCOp5PDgWwh6YNb84LfQXWe'
// if (process.env.NODE_ENV === 'development') {
//   SERVER_URL = `http://localhost:5500`;
//   CLIENT_URL = `http://localhost:3000`;
// } else {
//   SERVER_URL = 'https://www.showingbee.com';
//   CLIENT_URL = 'https://www.new.showingbee.com';
// }

const API_URL = `${SERVER_URL}/api`;

const GOOGLE_MAP_KEY = 'AIzaSyBOlFCLyfmdwTCcnEthWc1jKbMQizTF0U4';

const Config = {
  API_URL,
  SERVER_URL,
  CLIENT_URL,
  GOOGLE_MAP_KEY,
  TalkJS_APP_ID,
  TalkJS_KEY,
}

export default Config;

