import { createSlice } from '@reduxjs/toolkit'

const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    data: [],
    saved: [],
    listing: {},
    for_agents: {},
    is_end: false,
    offset: 0,
    events: [],
    calendarEvents: [],
    next: null,
    previous: null,
    total: 0,
    images: [],
    public: {
      data: [],
      listing: {},
      is_end: false,
      offset: 0,
      loading: false,
    },
    search: {
      data: [],
      is_end: false,
      offset: 0,
    }
  },
  reducers: {
    fetchListingsSuccess: (state, action) => {
      state.data = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.total = action.payload.count;
      state.offset = 0;
      state.is_end = false;
    },
    onLoadMoreFetchListingsSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.results];
      state.is_end = action.payload.is_end;
      state.offset = action.payload.offset;
    },
    fetchSavedListingsSuccess: (state, action) => {
      state.saved = action.payload.results;
    },
    retrieveListingSuccess: (state, action) => {
      state.listing = action.payload;
    },
    retrieveForAGentsListingSuccess: (state, action) => {
      state.for_agents = action.payload;
    },
    retrieveForAGentsListingError: (state, action) => {
      state.for_agents = {};
    },
    fetchListingEventsSuccess: (state, action) => {
      state.events = action.payload;
    },
    fetchListingCalendarEventsSuccess: (state, action) => {
      state.calendarEvents = action.payload.results;
    },
    fetchPublicListingsInit: (state) => {
      state.public.loading = true;
    },
    fetchPublicListingsSuccess: (state, action) => {
      state.public.loading = false;
      state.public.offset = 0;
      state.public.is_end = false;
      state.public.data = action.payload.results;
    },
    fetchPublicListingsFailed: (state) => {
      state.public.loading = false;
      state.public.data = [];
    },
    onLoadMoreFetchPublicListingsSuccess: (state, action) => {
      state.public.data = [...state.public.data, ...action.payload.results];
      state.public.is_end = action.payload.is_end;
      state.public.offset = action.payload.offset;
    },
    retrievePublicListingInit: (state) => {
      state.public.loading = true;
    },
    retrievePublicListingSuccess: (state, action) => {
      state.public.loading = false;
      state.public.listing = action.payload;
    },
    retrievePublicListingFailed: (state) => {
      state.public.loading = false;
      state.public.listing = {};
    },
    fetchSavedSearchesSuccess: (state, action) => {
      state.search.data = action.payload.results;
      state.search.offset = 0;
      state.search.is_end = false;
    },
  },
});


export default listingSlice