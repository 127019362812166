import * as React from "react"

const General = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 8H0V12.9C0 13.9 0.7 14.8 1.7 15C2.9 15.2 4 14.2 4 13V8Z"
      fill={props.color || "#2A2A2A"}
    />
    <path
      d="M15 1H7.00002C6.40002 1 6.00002 1.4 6.00002 2V13C6.00002 13.7 5.80002 14.4 5.40002 15H13C14.7 15 16 13.7 16 12V2C16 1.4 15.6 1 15 1Z"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
)


export default General
