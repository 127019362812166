import * as React from "react"

const EmailSmall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.5}
    height={10}
    viewBox="0 0 12.5 10"
    {...props}
  >
    <path
      id="Icon_material-mail"
      data-name="Icon material-mail"
      d="M14.25,6h-10A1.248,1.248,0,0,0,3.006,7.25L3,14.75A1.254,1.254,0,0,0,4.25,16h10a1.254,1.254,0,0,0,1.25-1.25V7.25A1.254,1.254,0,0,0,14.25,6Zm0,2.5-5,3.125L4.25,8.5V7.25l5,3.125,5-3.125Z"
      transform="translate(-3 -6)"
      fill={props.color || "#2a2a2a"}
    />
  </svg>
)


export default EmailSmall
