import API from 'API'

const fetchConversations = (id) => async dispatch => {
  const response = await API.message.fetchConversations(id);
  const payload = response.data;
  return payload
}

const sendMessage = ({id, data}) => async dispatch => {
  const response = await API.message.sendMessage({ id, data });
  const payload = response.data;
  return payload
}

const createConversation = ({id, data}) => async dispatch => {
  const response = await API.message.createConversation({ id, data });
  const payload = response.data;
  return payload
}

const updateCreateUser = ({id, data}) => async dispatch => {
  const response = await API.message.updateCreateUser({ id, data });
  const payload = response.data;
  return payload
}

const uploadFiles = ({data}) => async dispatch => {
  const response = await API.message.uploadFiles({ data });
  const payload = response.data;
  return payload
}

const teamActions = {
    fetchConversations,
    sendMessage,
    createConversation,
    updateCreateUser,
    uploadFiles

}


export default teamActions;