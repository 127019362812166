import * as React from "react"

const Settings = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.2437 5.93757L5.7637 4.59632C5.74315 4.4175 5.73418 4.23755 5.73682 4.05757V4.04507L1.7837 0.0913203C1.75467 0.0622183 1.72019 0.039129 1.68222 0.023375C1.64425 0.00762097 1.60355 -0.000488281 1.56245 -0.000488281C1.52134 -0.000488281 1.48064 0.00762097 1.44268 0.023375C1.40471 0.039129 1.37023 0.0622183 1.3412 0.0913203L0.0911983 1.34132C0.0620962 1.37035 0.039007 1.40483 0.0232529 1.4428C0.0074989 1.48077 -0.000610352 1.52147 -0.000610352 1.56257C-0.000610352 1.60368 0.0074989 1.64438 0.0232529 1.68234C0.039007 1.72031 0.0620962 1.75479 0.0911983 1.78382L4.2437 5.93757Z"
      fill={props.color || "#6C767C"}
    />
    <path
      d="M12.5669 9C12.3947 9.05773 12.2197 9.10654 12.0425 9.14625L11.9713 9.15875C11.6748 9.20293 11.3754 9.22528 11.0756 9.22563C10.8576 9.22544 10.6398 9.21334 10.4231 9.18938L8.16315 11.73L10.6831 14.25C10.9124 14.4851 11.186 14.6725 11.4881 14.8012C11.7902 14.9299 12.1148 14.9975 12.4431 15H12.4694C12.9675 14.9965 13.4535 14.8461 13.8665 14.5677C14.2795 14.2893 14.6012 13.8952 14.7913 13.4349C14.9814 12.9745 15.0314 12.4682 14.9352 11.9795C14.8389 11.4908 14.6005 11.0414 14.25 10.6875L12.5669 9Z"
      fill={props.color || "#6C767C"}
    />
    <path
      d="M14.768 2.76712C14.7496 2.71831 14.7193 2.67488 14.6799 2.64077C14.6404 2.60667 14.5931 2.58297 14.5421 2.57185C14.4912 2.56073 14.4383 2.56254 14.3882 2.5771C14.3381 2.59167 14.2925 2.61853 14.2555 2.65524L12.4648 4.43774L10.563 2.54274L12.3523 0.761493C12.3886 0.725616 12.4153 0.681326 12.4303 0.632576C12.4452 0.583826 12.4479 0.532134 12.438 0.482118C12.428 0.43147 12.4057 0.384072 12.373 0.344155C12.3403 0.304239 12.2982 0.273048 12.2505 0.253368C11.7396 0.0309333 11.1775 -0.0468986 10.6255 0.0283676C9.61913 0.115954 8.68362 0.582435 8.00812 1.33347C7.33262 2.08451 6.96754 3.06405 6.98671 4.07399C6.98207 4.40668 7.03168 4.73789 7.13358 5.05462L0.854832 10.5871C0.351145 11.0368 0.0465621 11.668 0.00800597 12.3421C-0.0305502 13.0161 0.200074 13.678 0.649207 14.1821L0.702957 14.2409C1.15414 14.7117 1.77371 14.9844 2.42568 14.9989C3.07765 15.0134 3.70875 14.7687 4.18046 14.3184C4.25206 14.2504 4.31928 14.178 4.38171 14.1015L9.95296 7.83837C10.5535 7.98701 11.1775 8.01569 11.7892 7.92274C12.5065 7.77568 13.17 7.43535 13.7078 6.93855C14.2457 6.44174 14.6376 5.80737 14.8411 5.10399C15.0733 4.33805 15.0476 3.51704 14.768 2.76712Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default Settings
