import * as React from "react"

const Twitter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <path
      data-name="Icon awesome-twitter-square"
      d="M26.786 0H3.214A3.215 3.215 0 0 0 0 3.214v23.572A3.215 3.215 0 0 0 3.214 30h23.572A3.215 3.215 0 0 0 30 26.786V3.214A3.215 3.215 0 0 0 26.786 0Zm-3.275 10.634c.013.187.013.382.013.569a12.41 12.41 0 0 1-12.5 12.5 12.438 12.438 0 0 1-6.738-1.973 9.252 9.252 0 0 0 1.058.054 8.805 8.805 0 0 0 5.451-1.875 4.4 4.4 0 0 1-4.1-3.047 4.733 4.733 0 0 0 1.982-.08 4.392 4.392 0 0 1-3.516-4.313v-.054a4.389 4.389 0 0 0 1.982.556 4.383 4.383 0 0 1-1.955-3.656 4.337 4.337 0 0 1 .6-2.217 12.47 12.47 0 0 0 9.054 4.594 4.4 4.4 0 0 1 7.493-4.011 8.606 8.606 0 0 0 2.786-1.058 4.38 4.38 0 0 1-1.929 2.417 8.74 8.74 0 0 0 2.531-.683 9.244 9.244 0 0 1-2.212 2.277Z"
      fill={props.color || "#fffefb"}
    />
  </svg>
)


export default Twitter