import './formHeader.scss'
import house1 from 'assets/images/house1.jpg'
import Seprator from 'components/Separator';
import { checkImageUrl, formatCurrency } from 'utils/modules/Handlers';
import ToastMessage from 'components/ToastMessage';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import actions from 'store/actions';
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import Icons from 'components/Icons';


const FormHeader = ({ showing, form, setForm, addBuyer, currentShowing }) => {
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)


    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const Item = ({type, cash, percent, agent_type}) => {
        return (
            <>
                {
                    (type !== 'none' && ((type === 'cash' && cash) || (type === 'percent' && percent)))
                    ? <div className='items'>
                        <span className="tooltip" data-text="Agent compensation amount." >
                            <div className='item'>
                                <Icons.Compensation color={'#FAC91F'} />
                                <p>{type === 'cash' ? formatCurrency(cash) : `${percent}%`}</p>
                            </div>
                        </span>
                    </div>
                    : <div className='items'>
                        <span className="tooltip" data-text="Agent compensation amount." >
                            <div className='item'>
                                <Icons.Compensation color={'#FAC91F'} />
                                <p className='no-compensation'>
                                    {
                                        agent_type === 'listing_agent' 
                                        ? 'No Compensation Listed for Listing Agent'
                                        : 'No Compensation Listed'
                                    }
                                </p>
                            </div>
                        </span>
                    </div>
                }
            </>
        )
    }

    const ItemBlock = ({ type }) => {
        if (type === 'outside') {
            return (
                <Item type={showing.listing_data.outside_company_compensation_type} cash={showing.listing_data.outside_company_compensation_cash} percent={showing.listing_data.outside_company_compensation_percent} agent_type={type}/>
            )
        } else if (type === 'within') {
            return (
                <Item type={showing.listing_data.within_company_compensation_type} cash={showing.listing_data.within_company_compensation_cash} percent={showing.listing_data.within_company_compensation_percent} agent_type={type}/>
            )
        } else {
            return (
                <Item type={showing.listing_data.listing_agent_compensation_type} cash={showing.listing_data.listing_agent_compensation_cash} percent={showing.listing_data.listing_agent_compensation_percent} agent_type={type}/>
            )
        }
    }

    const CompensationBlock = () => {
        if (user.id) {
            if (user.agent_profile?.id === showing.listing_data.agent) {
                return <ItemBlock type={'listing_agent'} />
            } else {
                if (user.agent_profile?.company_name) {
                    if (user.agent_profile?.company_name === showing.listing_data.agent_data.company_name) {
                        return <ItemBlock type={'within'} />
                    } else {
                        return <ItemBlock type={'outside'} />
                    }
                } else {
                    return <ItemBlock type={'outside'} />
                }
            } 
        } else {
            return <ItemBlock type={'outside'} />
        }
    }

    const onSubmit = useCallback(async(event) => {
        const { email, first_name, last_name, phone_number, company, buyer_email, buyer_first_name, buyer_last_name, buyer_phone_number } = form

        event.preventDefault();
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
            isValid = true
        }

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
            isValid = true
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
            isValid = true
        }
        if(phone_number.value) {
            if (!validatePhone(phone_number.value)) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
                isValid = true
            }
        }

        if (addBuyer) {
            if (!validateEmail(buyer_email.value)) {
                inputChange({ setForm: setForm, key: 'buyer_email', value: 'error', data: 'Entered email address is invalid' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'buyer_email', value: 'error', data: ''})
                isValid = true
            }
    
            if (buyer_first_name.value === '') {
                inputChange({ setForm: setForm, key: 'buyer_first_name', value: 'error', data: 'First name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'buyer_first_name', value: 'error', data: ''})
                isValid = true
            }
    
            if (buyer_last_name.value === '') {
                inputChange({ setForm: setForm, key: 'buyer_last_name', value: 'error', data: 'Last name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'buyer_last_name', value: 'error', data: '' })
                isValid = true
            }
            if (buyer_phone_number.value) {
                if (!validatePhone(buyer_phone_number.value)) {
                    inputChange({ setForm: setForm, key: 'buyer_phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                    isValid = false
                } else {
                    inputChange({ setForm: setForm, key: 'buyer_phone_number', value: 'error', data: ''})
                    isValid = true
                }
            }
        }

        if (isValid) {
            setLoading(true);
            try {
                let data = {
                    listing: showing.listing,
                    mls_source: showing.listing_data.mls_source,
                    mls_number: showing.listing_data.mls_number,
                    buyer_agent_data: {
                        first_name: first_name.value,
                        last_name: last_name.value,
                        email: email.value,
                        company: company.value,
                        phone_number: phone_number.value,
                    },
                    start: moment(currentShowing.start).tz(showing.listing_data.timezone, true),
                    finish: moment(currentShowing.end).tz(showing.listing_data.timezone, true),
                }
                if (showing.buyers.length > 0) {
                    data.buyer_data = {
                        first_name: buyer_first_name.value,
                        last_name: buyer_last_name.value,
                        email: buyer_email.value,
                        phone_number: buyer_phone_number.value
                    }
                }
                await dispatch(actions.showing.showingFormRequest(data)).then((data) => {
                    localStorage.setItem('showingCreated',  JSON.stringify(data));
                    setLoading(false);
                    toast.success("Showing successfully requested.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/manage-showings');
                });
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [form, currentShowing, addBuyer])

    return (
        <div className='form-header-container'>
            <div className='contents'>
                <img className='image' src={ checkImageUrl(showing.listing_first_image_url, house1)} alt="" />
                <Seprator width={25} />
                <div className='details'>
                    <div>
                        <p className='id'>#{showing.listing_data.mls_number ? showing.listing_data.mls_number : showing.listing_data.id}</p>
                        <Seprator height={10} />
                        <p className='name'>{showing.listing_data.property_type} in {showing.listing_data.city}</p>
                        <Seprator height={10} />
                        <p className='address'>
                        {`${showing.listing_data.address}, ${showing.listing_data.city} ${showing.listing_data.state} ${showing.listing_data.zip_code}`}
                        </p>
                    </div>
                    <p className='price'>{formatCurrency(showing.listing_data.price)}</p>
                </div>
            </div>
            <div className='button-container'>
                <div>
                    <button 
                        className='button small primary no-glow save' 
                        onClick={onSubmit} 
                        disabled={
                            !currentShowing.start || 
                            !form.email.value || 
                            !form.first_name.value || 
                            !form.last_name.value || 
                            loading
                        }
                    >
                        <p>
                            <Icons.BeeIconButton width={20}/>
                            {
                                loading 
                                ? <Icons.Loading height={40} width={40} background={'none'} />
                                : 'Send Request!'
                            }
                        </p>
                    </button>
                    <button className='button small no-glow cancel' onClick={() => navigate(-1)}>Cancel</button>
                </div>
                {/* <div className='compensation-concession'>
                    <CompensationBlock />
                    <span className="tooltip" data-text="Seller concession to buyers.">
                        <div className='item'>
                                <Icons.Concession color={'#FAC91F'} />
                        {
                            ((showing.listing_data.offers_concession === 'yes' && (showing.listing_data.concession_amount)) || (showing.listing_data.offers_concession === 'percent' && (showing.listing_data.concession_percent)) || (showing.listing_data.offers_concession === 'other' && showing.listing_data.other_concession))
                            ? <p>{showing.listing_data.offers_concession === 'yes' ? formatCurrency(showing.listing_data.concession_amount) : showing.listing_data.offers_concession === 'percent' ? `${showing.listing_data.concession_percent}%` : 'Other concessions'}</p>
                            : <p className='no-concession'>No Concession Indicated</p>
                        }
                        </div>
                    </span>
                </div> */}
            </div>
        </div>
    )
}


export default FormHeader;