import * as React from "react";

const BuyerAgentBadge = (props) => (
  <svg
    className="verified-buyer-badge"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <defs>
      <clipPath className="verified-buyer-badge-clip-path">
        <rect
          className="Rectangle_1803"
          data-name="Rectangle 1803"
          width={20}
          height={20}
          fill="#fac91f"
        />
      </clipPath>
    </defs>
    <g className="Group_2335" data-name="Group 2335" clipPath="url(.verified-buyer-badge-clip-path)">
      <path
        className="Path_892"
        data-name="Path 892"
        d="M11.312.519h0a1.714,1.714,0,0,0,1.7.381,1.9,1.9,0,0,1,2.365,1.116,1.683,1.683,0,0,0,1.366,1.067,1.86,1.86,0,0,1,1.637,2.01,1.643,1.643,0,0,0,.758,1.543h0a1.828,1.828,0,0,1,.584,2.507,1.626,1.626,0,0,0,0,1.712,1.828,1.828,0,0,1-.584,2.507h0a1.643,1.643,0,0,0-.758,1.543,1.86,1.86,0,0,1-1.637,2.01,1.682,1.682,0,0,0-1.366,1.067A1.9,1.9,0,0,1,13.016,19.1a1.714,1.714,0,0,0-1.7.381,1.917,1.917,0,0,1-2.625,0,1.714,1.714,0,0,0-1.7-.381,1.9,1.9,0,0,1-2.365-1.116,1.682,1.682,0,0,0-1.366-1.067,1.86,1.86,0,0,1-1.637-2.01h0a1.643,1.643,0,0,0-.758-1.543,1.828,1.828,0,0,1-.584-2.507,1.625,1.625,0,0,0,0-1.712A1.828,1.828,0,0,1,.856,6.637a1.643,1.643,0,0,0,.758-1.543h0a1.86,1.86,0,0,1,1.637-2.01A1.682,1.682,0,0,0,4.618,2.016,1.9,1.9,0,0,1,6.983.9a1.714,1.714,0,0,0,1.7-.381,1.917,1.917,0,0,1,2.625,0"
        transform="translate(0.001 0)"
        fill="#9e3645"
      />
      <path
        className="Path_893"
        data-name="Path 893"
        d="M82.9,120.77v-2.637a1.435,1.435,0,0,1,.75-1.255l1.211-.667-1.239-.683a1.5,1.5,0,0,0-1.442,0l-2.451,1.35a1.436,1.436,0,0,0-.75,1.255v2.637a1.436,1.436,0,0,0,.75,1.255l2.45,1.35a1.5,1.5,0,0,0,1.442,0l1.24-.683-1.211-.667a1.435,1.435,0,0,1-.75-1.255"
        transform="translate(-74.863 -109.452)"
        fill="#fff"
      />
      <path
        className="Path_894"
        data-name="Path 894"
        d="M196.929,116.878l-2.45-1.35a1.5,1.5,0,0,0-1.442,0l-1.24.683,1.211.667a1.435,1.435,0,0,1,.75,1.255v2.637a1.435,1.435,0,0,1-.75,1.255l-1.211.667,1.239.683a1.5,1.5,0,0,0,1.442,0l2.451-1.35a1.435,1.435,0,0,0,.75-1.255v-2.637a1.435,1.435,0,0,0-.75-1.255"
        transform="translate(-181.797 -109.452)"
        fill="#fff"
      />
    </g>
  </svg>
);


export default BuyerAgentBadge;
