import * as React from "react";

const MoreUser = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <g id="keitch-icon" transform="translate(-620 -356)">
      <rect
        id="Rectangle_1562"
        data-name="Rectangle 1562"
        width={30}
        height={30}
        rx={15}
        transform="translate(620 356)"
        fill="#c3c3c3"
      />
      <g
        id="Icon_feather-more-vertical"
        data-name="Icon feather-more-vertical"
        transform="translate(647.953 353) rotate(90)"
      >
        <path
          id="Path_453"
          data-name="Path 453"
          d="M19.5,18A1.5,1.5,0,1,1,18,16.5,1.5,1.5,0,0,1,19.5,18Z"
          transform="translate(0 -5.024)"
          fill="#5e5e5e"
        />
        <path
          id="Path_454"
          data-name="Path 454"
          d="M19.5,7.5A1.5,1.5,0,1,1,18,6,1.5,1.5,0,0,1,19.5,7.5Z"
          fill="#5e5e5e"
        />
        <path
          id="Path_455"
          data-name="Path 455"
          d="M19.5,28.5A1.5,1.5,0,1,1,18,27,1.5,1.5,0,0,1,19.5,28.5Z"
          transform="translate(0 -10.047)"
          fill="#5e5e5e"
        />
      </g>
    </g>
  </svg>
);


export default MoreUser;
