import { useNavigate } from "react-router-dom";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './confirm.scss'
import Icons from "components/Icons";

const Confirm = () => {
    const navigate = useNavigate();

    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="confirm-container">
                <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} />
                <Seprator height={50} />
                <p className="header">Your account has been setup!</p>
                <Seprator height={26} />
                <p className="subheader">Thank you for joining the hive.</p>
                <Seprator height={100} />
                <button className="button primary medium no-glow browse" onClick={() => navigate('/login')}>Login Now</button>
            </div>
        </Section>
    )
}


export default Confirm;