import * as React from "react";

const Archived = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33325 3.18095C1.33325 2.60266 1.79992 2.13333 2.37492 2.13333H13.6249C14.1999 2.13333 14.6666 2.60266 14.6666 3.18095V4.43809C14.6666 5.01638 14.1999 5.48571 13.6249 5.48571H2.37492C1.79992 5.48571 1.33325 5.01638 1.33325 4.43809V3.18095ZM2.16659 6.32381H13.8333V11.3524C13.8333 12.0192 13.5699 12.6587 13.101 13.1302C12.6322 13.6018 11.9963 13.8667 11.3333 13.8667H4.66659C4.00354 13.8667 3.36766 13.6018 2.89882 13.1302C2.42998 12.6587 2.16659 12.0192 2.16659 11.3524V6.32381ZM6.74992 8C6.63941 8 6.53343 8.04415 6.45529 8.12273C6.37715 8.20132 6.33325 8.30791 6.33325 8.41905C6.33325 8.53018 6.37715 8.63677 6.45529 8.71536C6.53343 8.79394 6.63941 8.83809 6.74992 8.83809H9.24992C9.36043 8.83809 9.46641 8.79394 9.54455 8.71536C9.62269 8.63677 9.66659 8.53018 9.66659 8.41905C9.66659 8.30791 9.62269 8.20132 9.54455 8.12273C9.46641 8.04415 9.36043 8 9.24992 8H6.74992Z"
      fill={props.color || "#5F5F5F"}
    />
  </svg>
);


export default Archived;
