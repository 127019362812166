import * as React from "react"

const Youtube = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35.556}
    height={25}
    viewBox="0 0 35.556 25"
    {...props}
  >
    <defs>
      <style>
        {
          ".youtube-a{fill:#fff;stroke:#707070;}.youtube-b{fill:red;}.youtube-c{stroke:none;}.youtube-d{fill:none;}"
        }
      </style>
    </defs>
    <g transform="translate(-418.444 -20)">
      <g className="youtube-a" transform="translate(427 25)">
        <rect className="youtube-c" width={20} height={16} />
        <rect className="youtube-d" x={0.5} y={0.5} width={19} height={15} />
      </g>
      <path
        className="youtube-b"
        d="M35.863,8.412a4.468,4.468,0,0,0-3.143-3.164C29.946,4.5,18.828,4.5,18.828,4.5s-11.118,0-13.891.748A4.468,4.468,0,0,0,1.793,8.412C1.05,11.2,1.05,17.025,1.05,17.025s0,5.823.743,8.614a4.4,4.4,0,0,0,3.143,3.113c2.773.748,13.891.748,13.891.748s11.118,0,13.891-.748a4.4,4.4,0,0,0,3.143-3.113c.743-2.791.743-8.614.743-8.614s0-5.823-.743-8.614Zm-20.671,13.9V11.739l9.293,5.287-9.293,5.287Z"
        transform="translate(417.394 15.5)"
      />
    </g>
  </svg>
)


export default Youtube
