import * as React from "react"

const BackArrow = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0625 8.5H1.9375"
      stroke={props.color || "#FAC91F"}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 15.0625L1.9375 8.5L8.5 1.9375"
      stroke={props.color || "#FAC91F"}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)


export default BackArrow
