import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from 'store/actions';
import './teamDetails.scss'
import FormNav from './modules/FormNav';
import Icons from 'components/Icons';
import Seprator from 'components/Separator';
import MemberList from './modules/MemberList';
import InviteMemberModal from './modules/InviteMemberModal';

const TeamDetails = () => {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState({})
    const [members, setMembers] = useState([])
    const [createModal, setCreateModal] = useState(false)

    useEffect(() => {
        const init = async() => {
            if (id) {
                try {
                    setLoading(true);
                    await dispatch(actions.team.retrieveTeam(id)).then((data) => {
                        setTeam(data);
                    });
                    fetchTeams();
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            }
        }

        init();
    }, [id])

    const fetchTeams = async() => {
        if (id) {
            try {
                setLoading(true);
                await dispatch(actions.team.fetchTeamMembers(id)).then((data) => {
                    setMembers(data);
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    }

    return (
        <div className="team-details-container">
            <InviteMemberModal modal={createModal} setModal={setCreateModal} refetch={fetchTeams} />
            {
                !loading
                ? <>
                    <div className='top-header'>
                        <div className="left">
                            <p className='title'>{team.name}</p>
                        </div>
                        <div className="right">
                            <div className='menu'>
                                <p className='title'>Members</p>
                            </div>
                            {
                                user.id === team.creator?.id &&
                                <button 
                                    className='button primary small no-glow' 
                                    style={{ width: 180}} 
                                    onClick={() => setCreateModal(prev => !prev)}
                                >
                                    <Icons.CirclePlus />
                                    <Seprator width={10} />
                                    Invite Members
                                </button>
                            }
                        </div>
                    </div>
                    <div className="body-container">
                        <div className="nav">
                            <FormNav data={team} />
                        </div>
                        <div className="form">
                            <MemberList members={members} />
                        </div>
                    </div>
                </> 
                : <div className='loading'>
                    <Icons.Loading />
                </div>
            }
        </div>
    )
}


export default TeamDetails;