import './tableHeader.scss';

const TableHeader = ({ title, children }) => {
  return (
    <div className="table-header-component">
      <h1 className="header">{title}</h1>
      {children}
    </div>
  )
}


export default TableHeader;