import * as React from "react";

const ListingFeedbackIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#F9F9F9" />
    <path
      d="M11.333 10C10.7142 10 10.1208 10.2431 9.68331 10.6759C9.24579 11.1086 9 11.6956 9 12.3076V16.9229C9 17.2259 9.06034 17.526 9.17759 17.8059C9.29483 18.0859 9.46668 18.3403 9.68331 18.5546C10.1208 18.9874 10.7142 19.2305 11.333 19.2305H11.7996V21.023C11.7995 21.1968 11.8501 21.3669 11.9452 21.513C12.0403 21.6591 12.176 21.775 12.3359 21.8468C12.4959 21.9187 12.6734 21.9434 12.8472 21.9182C13.021 21.8929 13.1837 21.8186 13.316 21.7042L13.6772 21.3913C13.6519 21.1789 13.6661 20.9637 13.7191 20.7563L14.0691 19.3735C14.2002 18.8549 14.4711 18.3811 14.853 18.0028L19.3603 13.5445C19.7075 13.2013 20.1434 12.9591 20.6203 12.8444C21.0972 12.7296 21.5969 12.7467 22.0647 12.8938V12.3076C22.0647 11.6956 21.8189 11.1086 21.3814 10.6759C20.9438 10.2431 20.3504 10 19.7317 10H11.333ZM15.5137 18.6554L20.021 14.1971C20.1831 14.0368 20.3756 13.9098 20.5874 13.8231C20.7991 13.7364 21.0261 13.6918 21.2553 13.6919C21.4845 13.692 21.7115 13.7367 21.9232 13.8236C22.1349 13.9104 22.3273 14.0377 22.4893 14.198C22.6513 14.3584 22.7798 14.5488 22.8674 14.7582C22.955 14.9677 23.0001 15.1922 23 15.4189C22.9999 15.6457 22.9547 15.8701 22.8669 16.0795C22.7791 16.289 22.6505 16.4792 22.4883 16.6395L17.981 21.0969C17.7184 21.3566 17.3894 21.541 17.0292 21.6304L15.6313 21.9756C15.4922 22.0099 15.3467 22.008 15.2086 21.9702C15.0706 21.9323 14.9447 21.8598 14.8434 21.7597C14.7421 21.6595 14.6686 21.5351 14.6302 21.3986C14.5919 21.2621 14.5898 21.1181 14.6243 20.9806L14.9743 19.5978C15.0647 19.2415 15.2511 18.9152 15.5137 18.6554Z"
      fill="#949494"
    />
  </svg>
);


export default ListingFeedbackIcon;
