const ToastMessage = ({messages}) => {
    return(
        <div>
            <ul>
                {
                    messages.map((message, index) => {
                        return (<li key={index}>{message}</li>)
                    })
                }
            </ul>
        </div>
    )
}


export default ToastMessage