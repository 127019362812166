import { useNavigate } from "react-router-dom";
// Components
import Icons from "components/Icons";
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './verify.scss'
import VerifiedIllus from 'assets/images/verified-illus.svg'

const Verify = () => {
    const navigate = useNavigate();

    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="verify-container">
                {/* <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} /> */}
                <img src={VerifiedIllus} alt="verified" />
                <Seprator height={50} />
                <p className="header">Your account has been verified!</p>
                <Seprator height={26} />
                <p className="subheader">Thank you for joining the hive.</p>
                <Seprator height={100} />
                <button className="button primary medium no-glow browse" onClick={() => navigate('/')}>Go to Homepage</button>
            </div>
        </Section>
    )
}


export default Verify;