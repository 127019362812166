import Seprator from 'components/Separator'
import ToastMessage from 'components/ToastMessage';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
import { getErrorMessage } from 'utils/modules/Handlers';
import './questionnaireForm.scss'


const QuestionnaireForm = ({ modal, setModal, selectedData }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({})

    useEffect(() => {
        const init = async() => {
            await setData(selectedData)
        }
        init();
    }, [selectedData])

    const checkDisabled = () => {
        if ((data.ai_approval || data.vetted_finanace_buyer) && data.question_ten) {
            return false
        } else {
            if (data.question_one &&  data.question_two && 
                data.question_three &&  data.question_four &&
                data.question_five && data.question_six &&
                data.question_seven && data.question_eight &&
                data.question_ten) {
                    return false
            } else { return true }
        }
    }

    const onSubmit = useCallback(async() => {
        const formData = {
            question_one: data.question_one,
            question_two: data.question_two,
            question_three: data.question_three,
            question_four: data.question_four,
            question_five: data.question_five,
            question_six: data.question_six,
            question_seven: data.question_seven,
            question_eight: data.question_eight,
            question_nine: data.question_nine,
            question_ten: data.question_ten,
            ai_approval: data.ai_approval,
            vetted_finanace_buyer: data.vetted_finanace_buyer,
            questionnaire_filled_out: true
        }
        try {
            await dispatch(actions.buyer.patchBuyerMortgageConnection({id: data.id, data: formData}));
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            setModal(prev => !prev);
            toast.success('Questionnaire submitted!', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [data])

    return (
        <div className={`questionnaire-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Mortgage Questionnaire
                </p>
                <span className='section-subtitle'>Fill out the following questions to proceed.</span>
                <Seprator height={30} />
                <p className='approval-pill'>Automatic Queen Bee Approval</p>
                <Seprator height={20} />
                <span className='sublabel'>
                    (If either one of the following are checked off by MLO, the only step is to confirm the Pre-approval letter has been uploaded and then MLO must certify through electronic signature):
                </span>
                <Seprator height={30} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        onChange={(event) => setData({...data, ai_approval: event.target.checked})} 
                    /> AI Approval
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        onChange={(event) => setData({...data, vetted_finanace_buyer: event.target.checked})} 
                    /> Vetted Finance Buyer (Underwriting has already approved)
                </div>
                <span className='sublabel option'>Must Upload an underwriting-issued Buyer Ready Letter</span>
                <Seprator height={50} />
                <p className='approval-pill'>Manual Queen Bee Approval</p>
                <Seprator height={30} />
                <span className='sublabel'>
                    If none of the two above, the MLO must check <mark>ALL</mark> the following:
                </span>
                <Seprator height={30} />

                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_one: event.target.checked})} 
                    /> The borrower's DTI ratio lower than 45%.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_two: event.target.checked})} 
                    /> Income Docs (tax returns, W-2, Paystubs).
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_three: event.target.checked})} 
                    /> Credit Pull has been performed.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_four: event.target.checked})} 
                    /> Affirmative response from "Findings" (DU/LP).
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_five: event.target.checked})} 
                    /> Underwriting has reviewed the loan docs for this buyer.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_six: event.target.checked})} 
                    /> Credit has been pulled in the last 60 days.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_seven: event.target.checked})} 
                    /> If borrower is self-employed an underwriter or a qualified loan person in your organization has reviewed the borrower's tax returns.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={data.ai_approval || data.vetted_finanace_buyer}
                        className='small'
                        onChange={(event) => setData({...data, question_eight: event.target.checked})} 
                    /> Borrower has 6-month cash reserves
                </div>

                <Seprator height={50} />
                <span className='sublabel'>
                    Does the borrower have a student loan? If <mark className='colored'>yes</mark>:
                </span>
                <Seprator height={30} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        onChange={(event) => setData({...data, question_nine: event.target.checked})} 
                    /> If borrower has student loan, the buyer's loan balance and DTI ratio has been carefully evaluated, adjusting for the exact requirements of the loan type.
                </div>

                <Seprator height={50} />
                <span className='sublabel'>
                    <mark className='colored'>Pre-Approval</mark> Letter must be uploaded (either it's already there and MLO verifies or MLO can upload themselves
                </span>
                <Seprator height={30} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        onChange={(event) => setData({...data, question_ten: event.target.checked})} 
                    /> I certify that I have confirmed and verified the above criteria and deem this buyer as being highly likely to close this loan based on my findings.
                </div>
                <Seprator height={50} />
                <div className='fotenote'>
                    <p>{data.mortgage_broker?.first_name} {data.mortgage_broker?.last_name}</p>
                    <span>Mortgage Broker</span>
                </div>
                <Seprator height={50} />
                <button className='button primary small no-glow' onClick={onSubmit} disabled={checkDisabled()}>
                    Submit Questionnaire
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default QuestionnaireForm;