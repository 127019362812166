import * as React from "react"

const MortgageConnection = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <g transform="translate(-99 -638)">
      <rect
        fill={props.color || "#2a2a2a"}
        width={22}
        height={22}
        rx={2}
        transform="translate(99 638)"
      />
      <path
        fill="#fff"
        d="M7.726,4.328l1.5.429a.252.252,0,0,1-.069.495H8.173a.772.772,0,0,1-.309-.066.372.372,0,0,0-.423.06l-.525.525a.36.36,0,0,0,.045.552,2.192,2.192,0,0,0,.954.345V7.2a.481.481,0,0,0,.48.48h.48a.481.481,0,0,0,.48-.48V6.67a1.687,1.687,0,0,0,1.479-1.89A1.76,1.76,0,0,0,9.546,3.35l-1.5-.429a.252.252,0,0,1,.069-.495H9.1a.772.772,0,0,1,.309.066.372.372,0,0,0,.423-.06l.525-.525a.36.36,0,0,0-.045-.552,2.191,2.191,0,0,0-.954-.345V.48A.481.481,0,0,0,8.878,0H8.4a.481.481,0,0,0-.48.48v.528A1.687,1.687,0,0,0,6.439,2.9,1.76,1.76,0,0,0,7.726,4.328ZM16.013,9.84a.9.9,0,0,0-1.209,0l-2.615,2.216a1.744,1.744,0,0,1-1.134.42H7.7a.481.481,0,0,1,0-.96H9.921A.96.96,0,0,0,9.969,9.6H5.438a3.207,3.207,0,0,0-2.1.789L2.024,11.517H.453A.468.468,0,0,0,0,12v2.879a.468.468,0,0,0,.453.48h10.1a1.756,1.756,0,0,0,1.134-.42l4.283-3.629a1,1,0,0,0,.039-1.467Z"
        transform="translate(101.842 641.321)"
      />
    </g>
  </svg>
)


export default MortgageConnection
