import * as React from "react"

const Draft = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8333 0.583344C14.0433 0.583344 14.2447 0.666751 14.3931 0.815217C14.5416 0.963684 14.625 1.16505 14.625 1.37501V4.3493L7.50079 11.4743L7.49604 14.8294L10.8575 14.8341L14.625 11.0666V15.625C14.625 15.835 14.5416 16.0363 14.3931 16.1848C14.2447 16.3333 14.0433 16.4167 13.8333 16.4167H1.16667C0.956704 16.4167 0.75534 16.3333 0.606874 16.1848C0.458407 16.0363 0.375 15.835 0.375 15.625V1.37501C0.375 1.16505 0.458407 0.963684 0.606874 0.815217C0.75534 0.666751 0.956704 0.583344 1.16667 0.583344H13.8333ZM15.2409 5.97301L16.3603 7.09243L10.2027 13.25L9.08175 13.2484L9.08333 12.1306L15.2409 5.97301ZM7.5 8.50001H3.54167V10.0833H7.5V8.50001ZM9.875 5.33334H3.54167V6.91668H9.875V5.33334Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default Draft
