import { Instance, InstanceMultipart } from "utils";

const login = ({ email, password }) => {
    return Instance.post('rest-auth/login/', { email, password });
}

const retrieveUser = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('me/')
}

const updateUser = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.patch(`users/${id}/`, data)
}

const updateProfilePhoto = (data) => {
    const token = localStorage.getItem('token')
    InstanceMultipart.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`users/upload-profile-photo/`, data)
}

const signup = (data) => Instance.post('rest-auth/registration/', data);

const logout = () => {
    delete Instance.defaults.headers.common["Authorization"];
    return Instance.post('/rest-auth/logout/');
}

const validatePhone = (data) => {
    return Instance.post('validate-phone/', data);
}

const validateEmail = (data) => {
    return Instance.post('validate-email/', data);
}

const setPassword = (data) => {
    return Instance.post('invite-set-password/', data);
}

const setAccount = (data) => {
    return Instance.post('invite-set-account/', data);
}

const fetchMainCalendarEvents = ({ start, end }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`main-calendar/?start=${start}&end=${end}`)
}

const fetchLanguages = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`language/`)
}

const fetchRealEstateSpecialization = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`real-estate-specialization/`)
}

const fetchCertificate = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`certificate/`)
}

const resetPassword = (data) => {
    return Instance.post('reset-password/', data);
}

const confirmResetPassword = (data) => {
    return Instance.post('confirm-reset-password/', data);
}

const deleteAccount = () => {
    return Instance.delete('delete-account/');
}

const deactivateAccount = () => {
    return Instance.post('deactivate-account/');
}

const activateAccount = () => {
    return Instance.post('activate-account/');
}

const signupAgentInvite = (data) => {
    return Instance.post('signup-agent-invite/', data);
}

const investorRequest = (data) => {
    return Instance.post('investor-request/', data);
}

const demoRequest = (data) => {
    return Instance.post('demo-request/', data);
}


const accountDeletionRequest = (data) => {
    return Instance.post('request-account-deletion/', data);
}


const auth = {
    login, 
    retrieveUser,
    updateUser,
    updateProfilePhoto,
    signup,
    logout,
    validatePhone,
    validateEmail,
    setPassword,
    setAccount,
    fetchMainCalendarEvents,
    fetchLanguages,
    fetchRealEstateSpecialization,
    fetchCertificate,
    resetPassword,
    confirmResetPassword,
    deleteAccount,
    deactivateAccount,
    activateAccount,
    signupAgentInvite,
    investorRequest,
    demoRequest,
    accountDeletionRequest
}


export default auth;
