import './sellerBlock.scss'
import NoUser from 'assets/images/no_user.jpg'
import UserPlaceholder from 'components/UserPlaceholder';
import { checkImageUrl } from 'utils/modules/Handlers';

const SellerBlock = ({ data, setModal, setSelectedData }) => {
    const onClick = () => {
        setSelectedData(data);
        setModal(prev => !prev);
    }
    return (
        <div className='manage-listing seller-block-container'>
            {
                data.sellers.length > 0 
                ? <>
                    {
                        data.sellers.map((seller, index) => {
                            return (
                                <div onClick={onClick} key={index}>
                                    {
                                        seller.profile_photo 
                                        ? <img className="seller-image" src={checkImageUrl(seller.profile_photo, NoUser)} alt="" />
                                        : <UserPlaceholder user={seller} type={'seller'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                                    }
                                </div>
                            )
                        })
                    }
                </>
                : <span style={{ fontSize: 10, fontWeight: 700, color: '#949494' }}> No Homeowners </span>
            }
        </div>
    )
}


export default SellerBlock;