import { Link, useLocation, useNavigate } from 'react-router-dom';
// Assets
import './header.scss'
import ShowingBeeLogoWhite from 'assets/images/ShowingBeeLogoWhite.png'
import SBDarkLogo from 'assets/images/SBDarkLogo.png'
import { useState } from 'react';
import CustomButton from 'components/CustomButton';
import Logo from 'components/Logo';
import { useSelector } from 'react-redux';
import PageWrapper from 'components/PageWrapper';
import HeaderUserBlock from 'components/HeaderUserBlock';

const Header = ({ mode='light' }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const { user } = useSelector((state) => state.auth)
    const [menuOpen, setMenuOpen] = useState(false)
    const [modal, setModal] = useState(false)

    return (
        <PageWrapper style={{ background: mode === 'dark' ? '#2A2A2A' : '#fff' }}>
            <header className={`navik-header ${mode}`}>
                {/* <!-- Header --> */}
                <div className="container">

                    {/* <!-- Navik header --> */}
                    <div className="navik-header-container">
                        {/* <!-- Burger menu --> */}
                        <div className='burger-menu-container'>
                            <div className={`burger-menu ${menuOpen && 'menu-open'}`} onClick={() => setMenuOpen(prev => !prev)}>
                                <div className="line-menu line-half first-line"></div>
                                <div className="line-menu"></div>
                                <div className="line-menu line-half last-line"></div>
                            </div>
                        </div>

                        {/* <!--Logo--> */}
                        <div className="logo">
                            <Link to="/"> <Logo color={`${mode === 'dark' ? 'light' : 'default'}`} /></Link>
                        </div>
                        {/* <!--Navigation menu--> */}
                        <nav className={`navik-menu ${menuOpen && 'show'}`}>
                            <ul className={`${menuOpen && 'show'}`}>
                                {/* <li><Link to="/">Support</Link></li> */}
                                {/* <li><Link to="/">Hire Agents</Link></li> */}
                                <li><Link className={`${pathname.includes('/listings') && 'active'}`} to="/listings/">Browse Listings</Link></li>
                                <li><Link className={`${pathname.includes('/bee-tier') && 'active'}`} to="/bee-tier/">Member Tiers</Link></li>
                                <div className='desktop-nav'>
                                    {
                                        !user.id 
                                        ? <li className='sign-in'>
                                            <CustomButton text={'Sign In'} action={() => navigate('/login')} />
                                        </li>
                                        : <HeaderUserBlock modal={modal} setModal={setModal} simple={true} />
                                    }
                                </div>
                            </ul>
                        </nav>
                        <div className='mobile-nav'>
                            {
                                !user.id ?
                                <p className='mobile-signin-link'>
                                    <Link to="/login/">Sign In</Link>
                                </p>
                                : <HeaderUserBlock modal={modal} setModal={setModal} simple={true} />
                            }
                        </div>

                        
                    </div>
                </div>
            </header>
        </PageWrapper>
    );
};

export default Header;