import Icons from 'components/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './messageReplyModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';
import Talk from 'talkjs';
import NoUser from 'assets/images/no_user.jpg'
import { Config } from 'utils';

const MessageReplyModal = ({ modal, setModal, selectedData, fetchData, type }) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [talkLoaded, markTalkLoaded] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [currentUser, setCurrentUser] = useState(null);
    const [connId, setConnId] = useState(null);

    useEffect(() => {
        const init = async() => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (user.id && talkLoaded && selectedData && type==='chat') {
                const currentUserData = new Talk.User({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    photoUrl: checkImageUrl(user.profile_photo, NoUser),
                    role: user.user_type,
                });

                const otherUserData  = new Talk.User({
                    id: selectedData?.inquirer?.id,
                    name: `${selectedData?.inquirer?.first_name} ${selectedData?.inquirer?.last_name}`,
                    email: selectedData?.inquirer?.email,
                    photoUrl: checkImageUrl(selectedData?.inquirer?.profile_photo, NoUser),
                    role: selectedData?.inquirer?.user_type,
                });

                const session = new Talk.Session({
                    appId: Config.TalkJS_APP_ID,
                    me: currentUserData,
                });

                let conversationId = Talk.oneOnOneId(currentUserData, otherUserData);
                const conversation = session.getOrCreateConversation(conversationId)
                conversation.setParticipant(currentUserData);
                conversation.setParticipant(otherUserData);
                try {
                    const otherUser = {
                        name: `${selectedData?.inquirer.first_name} ${selectedData?.inquirer.last_name}`,
                        email: [selectedData?.inquirer.email],
                        photoUrl: checkImageUrl(selectedData?.inquirer.profile_photo, NoUser),
                        role: selectedData?.inquirer.user_type,
                    }
                    const currentUser = {
                        name: `${user.first_name} ${user.last_name}`,
                        email: [user.email],
                        photoUrl: checkImageUrl(user.profile_photo, NoUser),
                        role: user.user_type,
                    }
                    await dispatch(actions.message.updateCreateUser({ id: otherUserData.id, data: otherUser }));
                    await dispatch(actions.message.updateCreateUser({ id: currentUser.id, data: currentUser }));
                    await dispatch(actions.message.createConversation({id: conversation.id, data: { participants: [currentUserData.id.toString(), otherUserData.id.toString()] }}));
                } catch (error) {
                    console.log(error);
                }
                setConnId(conversation.id)
                setCurrentUser(currentUserData);
            }
        }

        init();
    }, [user, talkLoaded, selectedData, type])

    const onSubmit = useCallback(async() => {
        setLoading(true);
        try {
            const data = [
                {
                    text: `Hi there ${selectedData?.inquirer.first_name}, this is a generated notice regarding your inquiry on Listing #${selectedData?.listing.mls_number || selectedData?.listing.id}, the listing agent has replied to your inquiry.`,
                    sender: currentUser.id.toString(),
                    type: "SystemMessage",
                },
                {
                    text: `${message}`,
                    sender: currentUser.id.toString(),
                    type: "UserMessage",
                }
            ]
            await dispatch(actions.message.sendMessage({id: connId, data: data})).then(async() => {
                const inquiryData = {
                    inquiry: selectedData.id,
                }
                await dispatch(actions.agent.resolvedBuyerInquiry(inquiryData))
                await fetchData();
                setLoading(false);
                setModal(prev => !prev);
                toast.success('Inquiry reply has been sent by message.', {
                    position: toast.POSITION.TOP_CENTER
                });
            })
        } catch (error) {
            console.log(error);
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }, [selectedData, message, currentUser])

    return (
        <div className={`message-reply-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Chat Message
                </p>
                <p className='section-title message'>
                    Send a chat reply to <span style={{ color: '#FAC91F', fontWeight: 700 }}>{(selectedData && selectedData.inquirer) ? `${selectedData.inquirer?.first_name} ${selectedData.inquirer?.last_name}` : `${selectedData?.first_name} ${selectedData?.last_name}`}</span>.
                </p>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Message</label>
                    <textarea
                        onChange={(event) => setMessage(event.target.value)}
                        style={{ height: 250, padding: 15 }}
                        placeholder='Message' 
                        className='small' 
                    ></textarea>
                </div>
                <Seprator height={30} />
                <button className='button primary small no-glow' onClick={onSubmit} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Send'
                }
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default MessageReplyModal;