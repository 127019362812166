import * as React from "react";

const Briefcase = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={20}
    viewBox="0 0 25 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1933_1380)">
      <path
        d="M1.92385 20.0013H23.0763C23.329 20.0017 23.5794 19.9588 23.8129 19.8751C24.0465 19.7915 24.2588 19.6687 24.4375 19.5138C24.6162 19.3589 24.7579 19.1749 24.8544 18.9725C24.951 18.77 25.0005 18.553 25.0001 18.334V8.33398H-0.0046361V18.334C-0.00505016 18.5534 0.0445833 18.7707 0.14141 18.9734C0.238237 19.1761 0.380347 19.3603 0.559567 19.5152C0.738788 19.6702 0.951584 19.7929 1.18572 19.8763C1.41985 19.9597 1.6707 20.0022 1.92385 20.0013Z"
        fill={props.color || "#6C767C"}
      />
      <path
        d="M23.0762 3.33333H22.5949V2.91616C22.5945 2.80563 22.5436 2.69972 22.4534 2.62157C22.3632 2.54341 22.2411 2.49934 22.1135 2.49898H20.1991C20.0716 2.49934 19.9494 2.54341 19.8592 2.62157C19.769 2.69972 19.7182 2.80563 19.7178 2.91616V3.33333H18.0284V1.66734C18.0271 1.22547 17.8241 0.801995 17.4635 0.489539C17.103 0.177084 16.6144 0.00107296 16.1046 0H8.89386C8.38429 0.00143029 7.89608 0.177598 7.5359 0.490015C7.17573 0.802432 6.97286 1.2257 6.97162 1.66734V3.33333H5.28849V2.91616C5.28808 2.80563 5.23724 2.69972 5.14706 2.62157C5.05688 2.54341 4.93469 2.49934 4.80715 2.49898H2.88492C2.75739 2.49934 2.63519 2.54341 2.54501 2.62157C2.45484 2.69972 2.40399 2.80563 2.40358 2.91616V3.33333H1.9238C1.67105 3.33298 1.4207 3.37586 1.18711 3.45953C0.953517 3.54319 0.741275 3.66599 0.562552 3.82089C0.383829 3.97579 0.242139 4.15974 0.145605 4.36219C0.0490704 4.56464 -0.000409102 4.78162 2.54758e-06 5.00068V7.08384H25.0047V5.00068C25.0051 4.78128 24.9555 4.56397 24.8586 4.36125C24.7618 4.15853 24.6197 3.9744 24.4405 3.81945C24.2613 3.6645 24.0485 3.54178 23.8143 3.45835C23.5802 3.37493 23.3294 3.33244 23.0762 3.33333ZM16.3453 3.33333H8.65319V1.87458C8.6536 1.76404 8.70444 1.65814 8.79462 1.57998C8.8848 1.50183 9.007 1.45776 9.13453 1.4574H15.8655C15.993 1.45776 16.1152 1.50183 16.2054 1.57998C16.2956 1.65814 16.3464 1.76404 16.3468 1.87458L16.3453 3.33333Z"
        fill={props.color || "#6C767C"}
      />
    </g>
    <defs>
      <clipPath id="clip0_1933_1380">
        <rect width={25} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
)


export default Briefcase;
