import * as React from "react"

const CircleClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill={props.color || '#FFF'}
      d="M13.375,3.375a10,10,0,1,0,10,10A10,10,0,0,0,13.375,3.375ZM15.909,17l-2.534-2.534L10.841,17a.768.768,0,1,1-1.087-1.087l2.534-2.534L9.755,10.841a.768.768,0,0,1,1.087-1.087l2.534,2.534,2.534-2.534A.768.768,0,1,1,17,10.841l-2.534,2.534L17,15.909A.772.772,0,0,1,17,17,.763.763,0,0,1,15.909,17Z"
      transform="translate(-3.375 -3.375)"
    />
  </svg>
)


export default CircleClose
