import * as React from "react"

const Info = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill={props.color || "#2a2a2a"}
      d="M20.563,10.563a10,10,0,1,1-10-10A10,10,0,0,1,20.563,10.563Zm-10,2.016a1.855,1.855,0,1,0,1.855,1.855A1.855,1.855,0,0,0,10.563,12.579ZM8.8,5.911,9.1,11.4a.484.484,0,0,0,.483.457h1.958a.484.484,0,0,0,.483-.457l.3-5.484a.484.484,0,0,0-.483-.51H9.285A.484.484,0,0,0,8.8,5.911Z"
      transform="translate(-0.563 -0.563)"
    />
  </svg>
)


export default Info
