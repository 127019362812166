import { Instance } from "utils";

const fetchBuyerAgents = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('/contacts/my-buyer-agents/');
}

const fetchFeaturedAgents = ({ filters }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/featured-buyer-agents/search/?${filters}`);
}


const fetchAllAgents = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('/contacts/all-agents/');
}

const fetchBuyerAgentConnection = ({ offset, limit=20  }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/buyer-agent-connection/?limit=${limit}&offset=${offset}`);
}

const fetchBuyers = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('contacts/my-buyers/')
}

const fetchInquiries = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('contact-request/')
}

const inviteBuyer = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('buyer-agent-connection/buyer-invite/', data)
}

const listingDashboardData = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('listing/dashboard/')
}

const showingDashboardData = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('showing/dashboard/?duration=current&exclude_draft=true')
}

const showingsToday = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('showing/today-showings/')
}

const approvedShowings = ({filters}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    let params = '?limit=5&duration=current&exclude_draft=true'
    if (filters) {
        params += `&${filters}`
    }
    return Instance.get(`showing/approved-showings/${params}`)
}

const enabledListings = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('listing/enabled-listings/?limit=6')
}

const inquiryDashboardData = ({agent, type}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/buyer-inquiry/dashboard/?listing_agent=${agent}&inquiry_type=${type}`);
}

const replyToBuyerInquiry = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('/buyer-inquiry/reply-to-inquiry/', data);
}

const resolvedBuyerInquiry = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('/buyer-inquiry/inquiry-resolved/', data);
}

const listingSellers = ({ offset }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`listing/listing-sellers/?limit=20&offset=${offset}`)
}

const searchListingSellers = ({ offset, limit=20, filters }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/listing/listing-sellers/?limit=${limit}&offset=${offset}&${filters}`);
}

const searchBuyerAgentConnection = ({ offset, limit=20, filters }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/buyer-agent-connection/search/?limit=${limit}&offset=${offset}&${filters}`);
}


const agent = {
    fetchBuyerAgents,
    fetchFeaturedAgents,
    fetchAllAgents,
    fetchBuyerAgentConnection,
    fetchBuyers,
    fetchInquiries,
    inviteBuyer,
    listingDashboardData,
    showingDashboardData,
    showingsToday,
    approvedShowings,
    enabledListings,
    inquiryDashboardData,
    replyToBuyerInquiry,
    resolvedBuyerInquiry,
    listingSellers,
    searchListingSellers,
    searchBuyerAgentConnection
    
}


export default agent;
