// Packages
import Icons from 'components/Icons';
import Seprator from 'components/Separator'
import './copyRightModal.scss'


const CopyRightModal = ({ modal, setModal }) => {
    const onClick = (link) => {
        window.open(link, '_blank', 'noreferrer');
    }
    return (
        <div className={`copy-right-modal ${modal ? 'open' : 'close'}`}>
            <div className='modal-container'>
                <div className='modal-title'>
                    <div className='close' onClick={() => setModal(prev => !prev)}>
                        <Icons.Close height={13} width={13} />
                    </div>
                </div>
                <Seprator height={40} />
                <div className='content'>
                    <section>
                        <p className='header'>Submit a copyright removal request</p>
                        <Seprator height={30} />
                        <p className='body'>
                            If your copyright-protected work was posted on SHOWING BEE, LLC.  without your authorization, you can submit a copyright removal request to request that the content be removed. Submitting a copyright removal request is a legal process. 
                        </p>
                        <br /><br />
                        <p className='body'>
                            <span className='header'>What to know before you submit a removal request</span><br/>
                            Copyright exceptions: You need to consider whether <span className='link' onClick={() => onClick('https://www.copyright.gov/help/faq/faq-fairuse.html')}>fair use</span>, 
                            fair dealing, or a similar <span className='link' onClick={() => onClick('https://copyrightalliance.org/education/copyright-law-explained/limitations-on-a-copyright-owners-rights/fair-use-exceptions-copyright/')}>copyright exception</span> applies. Showing Bee, LLC.  may ask you to confirm you have made this consideration. 
                            If you do not adequately respond, the content identified in your removal request will not be removed from SHOWING BEE, LLC. 
                            If a copyright exception applies, then the removal request you submit is considered invalid.  
                        </p>
                    </section>
                    <Seprator height={70} />
                    <section>
                        <p className='header'>Personal info: </p>
                        <br />
                        <p className='body'>
                            If you provide a valid legal alternative, such as the name of a company or authorized representative, we will review and apply it if appropriate. 
                        </p>
                        <br />
                        <p className='body'>
                            The copyright owner's name you enter will become part of the public record of your request.  
                        </p>
                        <br />
                        <p className='body'>
                            Your full legal name is required to complete a copyright removal request. It may be shared with the uploader of the video removed for copyright infringement. 
                        </p>
                        <br />
                        <p className='body'>
                            The primary email address from your removal request may be shared with the uploader of the video removed for copyright infringement. The uploader might contact you to further resolve their copyright takedown. 
                        </p>
                        <br />
                        <p className='body'>
                            Your physical address and phone number will remain confidential unless requested as part of a lawsuit. If Showing Bee is required to share any information, we will notify you before doing so. 
                        </p>
                        <br /><br />
                        <p className='body'>
                            <span className='header'>How to submit a copyright removal request </span><br />
                            The fastest and simplest way to submit a copyright removal request is to fill out our webform (below) on a computer. The copyright owner or agent authorized to act on the owner’s behalf should submit the request.  
                        </p>
                        <br />
                        <p className='body'>
                            Do not make false claims. Misuse of the removal request webform, such as submitting false information, may result in the suspension of your account or other legal consequences. 
                        </p>
                    </section>
                    <Seprator height={70} />
                    <section>
                        <p className='header'>Submit a copyright removal request </p>
                        <Seprator height={30} />
                        <p className='body'>
                        We can also accept copyright removal requests by email, fax, and mail. 
                        <br />
                        Notices of copyright infringement:  
                        </p>
                        <br /><br />
                        <div className='numerals'>
                            <ul className='list'>
                                <li>
                                    <p className='body'>
                                        By Mail: SHOWING BEE, LLC.  801 49th Street North, St. Petersburg, Florida 33710 
                                    </p>
                                </li>
                                <li>
                                    <p className='body'>
                                        By Email: <span className='link'>dmca_notice_email@showingbee.com</span>
                                    </p>
                                </li>
                                <li>
                                    <p className='body'>
                                        Note: all correspondence with respect to copyright infringement must be accompanied by your signed statement, either by mail or email attachment, before we are required to take action.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <p className='body numerals' data-list='1.'>
                            Identification of the copyrighted work (s) that you claim has been infringed.
                        </p>
                        <p className='body numerals' data-list='2.'>
                            Identification of the copyrighted work (s) that you claim have been infringed, location of such works, a URL where such works where found, a screen shot of such, the time and date found, supporting documents to claim works – so Services can locate such work.
                        </p>
                        <p className='body numerals' data-list='3.'>
                            Your address, phone number, and email (if possible), so the appropriate copyright representative at Services may contact you about your complaint. 
                        </p>
                        <p className='body numerals' data-list='4.'>
                            Please delineate your claim of infringement stating the full nature of the claim, URL, identifying the works and its authors as unauthorized use by the copyright owner or such authorization to act on behalf of the copyright owner’s behalf. 
                        </p>
                    </section>
                    <Seprator height={70} />
                </div>
            </div>
        </div>
    )
}

export default CopyRightModal;