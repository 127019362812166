// Packages
import Icons from 'components/Icons';
import Seprator from 'components/Separator'
import ToastMessage from 'components/ToastMessage';
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import './inviteMemberModal.scss'
import { useParams } from 'react-router-dom';


const InviteMemberModal = ({ modal, setModal, refetch }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    const [form, setForm] = useState({
        email: {
            value: '',
            error: '',
        },
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
    });

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async() => {
        const { email, first_name, last_name, phone_number } = form
        let isValid = true
        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (isNewUser) {
            if (first_name.value === '') {
                inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
            }
    
            if (last_name.value === '') {
                inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
            }

            if (first_name.value && last_name.value) {
                isValid = true
            }

            if (phone_number.value) {
                if (!validatePhone(phone_number.value)) {
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                    isValid = false
                } else {
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
                    isValid = true
                }
            }
        }

        if (isValid) {
            const memberData = {
                email: email.value,
                team: id,
            }
            if (isNewUser) {
                memberData.first_name = first_name.value
                memberData.last_name = last_name.value
                memberData.phone_number = phone_number.value ? `+1${phone_number.value}` : ''
                memberData.user_type = 'agent'
            }
            try {
                setIsLoading(true);
                await dispatch(actions.team.addMember(memberData)).then(async(data) => {
                    const memberApplicationData = {
                        user: data.member,
                        team: data.team
                    }
                    await dispatch(actions.team.addMemberApplication(memberApplicationData))
                    await refetch();
                    onClose();
                    setIsLoading(false);
                    toast.success(`Invite sent to member!`, {
                        position: toast.POSITION.TOP_CENTER
                    });
                })
            } catch (error) {
                setIsLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }


    }, [form, isNewUser])

    const onClose = () => {
        setModal(prev => !prev);
        setIsNewUser(false);
        setForm({
            email: {
                value: '',
                error: '',
            },
            first_name: {
                value: '',
                error: '',
            },
            last_name: {
                value: '',
                error: '',
            },
            phone_number: {
                value: '',
                error: '',
            },
        });
        document.getElementById('email').value = ''
        document.getElementById('first_name').value = ''
        document.getElementById('last_name').value = ''
        document.getElementById('phone_number').value = ''
    }

    return (
        <div className={`invite-member-modal ${modal ? 'open' : 'close'}`}>
            <div className='modal-container'>
                <div className='modal-title'>
                    Invite to Team
                    <div className='close' onClick={onClose}>
                        <Icons.Close height={13} width={13} />
                    </div>
                </div>
                <Seprator height={40} />
                <div className="content">
                    <div>
                        <div className='form-input'>
                            <label>Email</label>
                            <input
                                type="text"
                                className='small'
                                id="email"
                                onChange={(event) => onChangeInput({event, type: "email"})} 
                            />
                            <span className='error-message'>{form.email.error}</span>
                        </div>
                        <Seprator height={20} />
                        <div className='form-input'>
                            <div className='options'>
                                <input 
                                    type='checkbox' 
                                    className='small'
                                    checked={isNewUser}
                                    id='add-buyer'
                                    onChange={(event) => setIsNewUser(event.target.checked)} 
                                /> <label htmlFor='add-buyer'>Not a Showing Bee user?</label>
                            </div>
                        </div>
                        {
                            isNewUser &&
                            <div className='new-user-form'>
                                <Seprator height={10} />
                                <p>Please add the required information to invite users outside of Showing Bee.</p>
                                <div className='name'>
                                    <div className='form-input'>
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            className='small'
                                            id="first_name"
                                            onChange={(event) => onChangeInput({event, type: "first_name"})} 
                                        />
                                        <span className='error-message'>{form.first_name.error}</span>
                                    </div>
                                    <Seprator width={20} />
                                    <div className='form-input'>
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            className='small'
                                            id="last_name"
                                            onChange={(event) => onChangeInput({event, type: "last_name"})} 
                                        />
                                        <span className='error-message'>{form.last_name.error}</span>
                                    </div>
                                </div>
                                <Seprator height={20} />
                                <div className='form-input'>
                                    <label>Phone Number (Optional)</label>
                                    <input
                                        type="text"
                                        className='small'
                                        id="phone_number"
                                        onChange={(event) => onChangeInput({event, type: "phone_number"})} 
                                    />
                                    <span className='error-message'>{form.phone_number.error}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <Seprator height={30} />
                    <button disabled={isLoading} className='button primary small no-glow' onClick={onSubmit}>
                        {
                            isLoading 
                            ? <Icons.Loading height={40} width={40} background={'none'} />
                            : 'Invite'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default InviteMemberModal;