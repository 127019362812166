import * as React from "react"

const Price = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13.563}
    viewBox="0 0 13 13.563"
    {...props}
  >
    <path
      fill="#fac91f"
      d="M0,10.736v1.131c0,.935,2.184,1.7,4.875,1.7s4.875-.76,4.875-1.7V10.736a8.923,8.923,0,0,1-4.875,1.131A8.923,8.923,0,0,1,0,10.736ZM8.125,3.391C10.816,3.391,13,2.63,13,1.7S10.816,0,8.125,0,3.25.76,3.25,1.7,5.434,3.391,8.125,3.391ZM0,7.957V9.324c0,.935,2.184,1.7,4.875,1.7s4.875-.76,4.875-1.7V7.957A7.927,7.927,0,0,1,4.875,9.324,7.927,7.927,0,0,1,0,7.957Zm10.563.291C12.017,7.955,13,7.409,13,6.781V5.65a6.075,6.075,0,0,1-2.437.914ZM4.875,4.238C2.184,4.238,0,5.187,0,6.357S2.184,8.477,4.875,8.477,9.75,7.528,9.75,6.357,7.566,4.238,4.875,4.238ZM10.443,5.73C11.967,5.444,13,4.882,13,4.238V3.107A8,8,0,0,1,8.92,4.214,2.9,2.9,0,0,1,10.443,5.73Z"
    />
  </svg>
)


export default Price
