import * as React from "react"

const Swap = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.857}
    height={10}
    viewBox="0 0 12.857 10"
    {...props}
  >
    <defs>
      <style>{".swap{fill:#fac91f;}"}</style>
    </defs>
    <path
      className="swap"
      d="M7.35,11.786,4.5,14.643,7.35,17.5V15.357h5.007V13.929H7.35Zm10.007-1.429L14.507,7.5V9.643H9.5v1.429h5.007v2.143Z"
      transform="translate(-4.5 -7.5)"
    />
  </svg>
)


export default Swap
