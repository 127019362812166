import * as React from "react"

const Marker = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={37}
    viewBox="0 0 28 37"
    {...props}
  >
    <defs>
      <style>{".marker-a{fill:#fac91f;stroke:#d3a918;}.marker-b{fill:#2b2b2b;}"}</style>
    </defs>
    <g transform="translate(-3040.5 2050.501)">
      <path
        className="marker-a"
        d="M12.113,35.274C1.9,20.463,0,18.943,0,13.5a13.5,13.5,0,0,1,27,0c0,5.443-1.9,6.963-12.113,21.774a1.688,1.688,0,0,1-2.775,0ZM13.5,19.125A5.625,5.625,0,1,0,7.875,13.5,5.625,5.625,0,0,0,13.5,19.125Z"
        transform="translate(3041 -2050.001)"
      />
      <circle
        className="marker-b"
        cx={6.5}
        cy={6.5}
        r={6.5}
        transform="translate(3048 -2043)"
      />
    </g>
  </svg>
)


export default Marker
