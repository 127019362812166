// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card-component {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-card-component > div {
  display: flex;
  color: white;
}
.user-card-component > div img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border: 3px solid white;
  border-radius: 10px;
  margin-right: 15px;
}
.user-card-component > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-card-component > div > div > p {
  margin: 0;
  font-size: 14px;
  color: var(--secondary-color-1);
  font-weight: 700;
}
.user-card-component > div > div > span {
  font-size: 12px;
  color: var(--secondary-color-5);
}
.user-card-component .phone-number {
  font-size: 12px;
  color: var(--secondary-color-5);
}`, "",{"version":3,"sources":["webpack://./src/components/UserCard/userCard.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;AACJ;AAAI;EACI,aAAA;EACA,YAAA;AAER;AADQ;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAGZ;AADQ;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;AAGZ;AAFY;EACI,SAAA;EACA,eAAA;EACA,+BAAA;EACA,gBAAA;AAIhB;AAFY;EACI,eAAA;EACA,+BAAA;AAIhB;AAAI;EACI,eAAA;EACA,+BAAA;AAER","sourcesContent":[".user-card-component {\n    padding: 15px 0;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    > div {\n        display: flex;\n        color: white;\n        img {\n            width: 55px;\n            height: 55px;\n            object-fit: cover;\n            border: 3px solid white;\n            border-radius: 10px;\n            margin-right: 15px;\n        }\n        > div {\n            display: flex;\n            flex-direction: column;\n            justify-content: center;\n            > p {\n                margin: 0;\n                font-size: 14px;\n                color: var(--secondary-color-1);\n                font-weight: 700;\n            }\n            > span {\n                font-size: 12px;\n                color: var(--secondary-color-5);\n            }\n        }\n    }\n    .phone-number {\n        font-size: 12px;\n        color: var(--secondary-color-5);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
