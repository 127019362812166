import './customTextInput.scss'

/* ACCEPTABLE PROPS VALUE
 * inputProps: object
 * leftSlot: HTML
 * rightSlot: HTML
 * label: String
 * error: String
 * success: boolean
 * size: small, normal(default), large
 */

const CustomTextInput = ({ inputProps, leftSlot, rightSlot, label, error, success, size, style }) => {
    return (
        <div style={style} className={`custom-text-input ${error && 'error'} ${success && 'success'} ${inputProps?.disabled && 'disabled'}`}>
            {
                label &&
                <label>{label}</label>
            }
            <div className={`input-wrapper ${size}`}>
                <div style={{ display: 'flex', columnGap: 8, width: '100%' }}>
                    <>
                        {
                            leftSlot &&
                            <div className='left-slot'>
                                {leftSlot}
                            </div>
                        }
                        <input {...inputProps} />
                    </>
                </div>
                {
                    rightSlot &&
                    <div className='right-slot'>
                        {rightSlot}
                    </div>
                }
            </div>
            {
                error &&
                <span className='error-text'>{error}</span>
            }
            {
                success &&
                <span className='success-text'>{success}</span>
            }
        </div>
    )
}

export default CustomTextInput;