// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-container {
  padding: 0 80px;
  height: -moz-fit-content;
  height: fit-content;
  animation: fadein 0.5s;
  width: 100%;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 768px) and (max-width: 1024px), (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .custom-container {
    padding: 0 40px;
  }
}
@media (min-width: 481px) and (max-width: 767px), (min-width: 320px) and (max-width: 480px) {
  .custom-container {
    padding: 0 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CustomContainer/customContainer.scss","webpack://./src/assets/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,wBAAA;EAAA,mBAAA;EACA,sBAAA;EACA,WAAA;AADJ;AAEI;EACI;IAAO,UAAA;EACb;EAAM;IAAO,UAAA;EAGb;AACF;;ACHY;EDIR;IACI,eAAA;EAGN;AACF;AChBY;EDiBR;IACI,eAAA;EAEN;AACF","sourcesContent":["@import 'assets/styles/_mixins.scss';\n\n.custom-container {\n    padding: 0 80px;\n    height: fit-content;\n    animation: fadein .5s; \n    width: 100%;\n    @keyframes fadein {\n        from { opacity: 0; }\n        to   { opacity: 1; }\n    }\n}\n\n@include media(tablet) {\n    .custom-container {\n        padding: 0 40px;\n    }\n}\n\n@include media(mobile) {\n    .custom-container {\n        padding: 0 24px;\n    }\n}","@mixin media($keys...) {\r\n    @each $key in $keys {\r\n        @if ($key==mobile) {\r\n            @media (min-width: 481px) and (max-width: 767px),\r\n            (min-width: 320px) and (max-width: 480px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==tablet) {\r\n            @media (min-width: 768px) and (max-width: 1024px),\r\n            (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==nitty-gritt) {\r\n            @media (width: 1024px) and (max-height: 833px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==small-desktop) {\r\n            @media (max-width: 1440px) and (max-height: 833px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==desktop) {\r\n            @media (min-width: 1024px) and (max-width: 1440px) {\r\n                @content;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
