import * as React from "react"

const SellerBage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96.992}
    height={125}
    viewBox="0 0 96.992 125"
    {...props}
  >
    <g transform="translate(-728.727 -659)">
      <path
        fill={props.color || "#fac91f"}
        className="seller-badge-a"
        d="M140.785,1582.084a110.712,110.712,0,0,0,48.661-10.245c6.768,4.9,34.01,11.431,48.331,10.153-.1,10.989,0,60.458,0,60.458s-10.5,38.066-48.115,54.389c-22.572-8.225-41.318-30.161-48.841-54.389C141.075,1620.519,140.785,1582.084,140.785,1582.084Z"
        transform="translate(587.941 -912.84)"
      />
      <g transform="translate(751.425 688.519)">
        <path
          fill={props.subcolor || '#fff'}
          className="seller-badge-b"
          d="M1425.863,70.8l25.9,18.977v34.565h-17.828V98.815H1417.9v25.529h-17.83V89.778Z"
          transform="translate(-1400.071 -70.801)"
        />
      </g>
    </g>
  </svg>
)


export default SellerBage
