import { checkImageUrl } from 'utils/modules/Handlers';
import './userCard.scss';
import NoUser from 'assets/images/no_user.jpg'
import UserPlaceholder from 'components/UserPlaceholder';

const UserCard = ({ data, hide=false, type }) => {
  return (
    <div className='user-card-component'>
        <div>
            {
              !hide
              ? <img className="user-image" src={checkImageUrl(data?.profile_photo, NoUser)} alt="" />
              : <UserPlaceholder type={type} placeholder={type === 'buyer' ? 'B' : 'S'} customStyle={{ border: '3px solid white', height: 55, width: 55, borderRadius: 10, marginRight: 15 }} />
            }
            
            {
              !hide &&
              <div>
                  <p>{data?.first_name} {data?.last_name}</p>
                  <span>{data?.email}</span>
              </div>
            }
        </div>
        {
          !hide &&
          <p className='phone-number'>{data?.phone_number}</p>
        }
    </div>
  );
}


export default UserCard;