// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listing-list-component {
  font-family: "Proxima Nova", sans-serif;
  display: flex;
  column-gap: 16px;
  background: #FFF;
}
.listing-list-component img {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  object-fit: cover;
}
.listing-list-component .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.listing-list-component .content p {
  line-height: normal;
  margin: 0;
}
.listing-list-component .content .id {
  color: #949494;
  font-size: 8px;
  font-weight: 400;
}
.listing-list-component .content .name {
  font-size: 14px;
  font-weight: 600;
}
.listing-list-component .content .address {
  color: #949494;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ListingList/listingList.scss"],"names":[],"mappings":"AAAA;EACI,uCAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AAER;AAAI;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;AAER;AADQ;EACI,mBAAA;EACA,SAAA;AAGZ;AADQ;EACI,cAAA;EACA,cAAA;EACA,gBAAA;AAGZ;AADQ;EACI,eAAA;EACA,gBAAA;AAGZ;AADQ;EACI,cAAA;EACA,eAAA;AAGZ","sourcesContent":[".listing-list-component {\n    font-family: 'Proxima Nova', sans-serif;\n    display: flex;\n    column-gap: 16px;\n    background: #FFF;\n    img {\n        height: 48px;\n        width: 48px;\n        border-radius: 8px;\n        object-fit: cover;\n    }\n    .content {\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        p {\n            line-height: normal;\n            margin: 0;\n        }\n        .id {\n            color: #949494;\n            font-size: 8px;\n            font-weight: 400;\n        }\n        .name {\n            font-size: 14px;\n            font-weight: 600;\n        }\n        .address {\n            color: #949494;\n            font-size: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
