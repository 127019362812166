import { useNavigate } from "react-router-dom";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './deletedAccount.scss'
import { useEffect } from "react";
import DeleteIllus from 'assets/images/delete-acc.svg'
import Icons from "components/Icons";

const AccountDeleted = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const init = async() => {
            await setTimeout(() => {
                navigate("/");
            }, 5000);
        }
        init();
    }, [])

    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="deleted-account-container">
                <img src={DeleteIllus} alt="" />
                <Seprator height={50} />
                <p className="header">I guess this is goodbye, your account has been deleted.</p>
                <Seprator height={26} />
                <p className="subheader">Thank you for being part of Showing Bee. <br />If you have any concerns please contact the support team.</p>
                <Seprator height={80} />
                <p className="wait">Please wait while we redirect back to our homepage</p>
                <Icons.Loading height={100} width={100} background={'none'} />
            </div>
        </Section>
    )
}


export default AccountDeleted;