import Separator from 'components/Separator';
import './chooseAgent.scss'
import NoUser from 'assets/images/no_user.jpg'
import Icons from 'components/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import RequestStepper from 'components/RequestStepper';
import Footer from 'components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import actions from 'store/actions';
import { toast } from 'react-toastify'

const ChooseAgent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { id: listingID, mls } = useParams();
    const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.agent.fetchBuyerAgents());
        }
        init();
    }, [dispatch, listingID])

    const onGoBack = () => {
        if (urlParams.get('prev') && urlParams.get('prev') !== 'confirm') {
            navigate(`/request/${mls}/${listingID}/${urlParams.get('prev')}/?prev=${urlParams.get('next')}&next=${urlParams.get('next')}${urlParams.get('back') && '&back='+urlParams.get('back')}`)
        } else {
            navigate(`/listings/${mls}/${listingID}`);
            toast.info('Showing request progress saved as draft.', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }

    const noAgent = useCallback(async() => {
        try {
            if (myAgents.length > 0) {
                const body = {
                    showing: localStorage.getItem('showingId'),
                    buyer_agent: myAgents[0].id,
                }
                await dispatch(actions.showing.showingRequestStep2RemoveAgent(body))
                navigate(`/request/${mls}/${listingID}/${urlParams.get('next')}`)
            } else {
                navigate(`/request/${mls}/${listingID}/${urlParams.get('next')}`)
            }
        } catch (error) {
            console.log(error)
        }

    }, [myAgents])

    return (
        <section className="choose-agent-container">
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p 
                        className='back-button' 
                        onClick={onGoBack}>
                            Back
                    </p>
                    <h1 className="title">Choose your Agent</h1>
                    {/* <button 
                        className="button medium primary next-button" 
                        style={{ width: 120 }}
                        onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent`)}
                    >
                        Next
                    </button> */}
                </div>
                <p className='subtitle'>Select an option for your agent.</p>
            </div>
            <div className="agent-choice">

                {
                    myAgents.length > 0
                    ? <div className="invite">
                        <div className="selectbox" onClick={() => navigate(`/request/${mls}/${listingID}/select-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`)}>
                            <Icons.More />
                            <Separator height={15} />
                            <h1 className="title">Request showing with my own agent</h1>
                            <Separator height={10} />
                            <p className="subtitle">If you already work with an agent before, you can choose them to represent you again.</p>
                        </div>
                    </div>
                    : <div className="invite">
                        <div className="selectbox" onClick={() => navigate(`/request/${mls}/${listingID}/invite-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}&from=choose-agent`)}>
                            <Icons.Add />
                            <Separator height={15} />
                            <h1 className="title">I'd like to invite my agent</h1>
                            <Separator height={10} />
                            <p className="subtitle">If you already have an agent that we can work with, please fill out the agent's contact details and we'll get things ready for you.</p>
                        </div>
                    </div>
                }
                <div className="showingbee">
                    <div className="selectbox selected" onClick={() => navigate(`/request/${mls}/${listingID}/showingbee-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`)}>
                        <div className="agent-profile--container">
                            <div className="agent-profile--image">
                                <div className="agent-profile--avatar" style={{ backgroundImage: `url(${NoUser})`}}>
                                    <Icons.BuyerAgentBadge width={30} height={30} />
                                </div>
                            </div>
                            <div className="agent-profile--image">
                                <div className="agent-profile--avatar" style={{ backgroundImage: `url(${NoUser})`}}>
                                    <Icons.BuyerAgentBadge width={35} height={35} />
                                </div>
                            </div>
                            <div className="agent-profile--image">
                                <div className="agent-profile--avatar" style={{ backgroundImage: `url(${NoUser})`}}>
                                    <Icons.BuyerAgentBadge width={30} height={30} />
                                </div>
                            </div>
                        </div>
                        <Separator height={30} />
                        <h1 className="title">Showing Bee Agents</h1>
                        <Separator height={10} />
                        <p className="subtitle">You may choose from our expertly trained agents to assist you all the way on achieving your dream home.</p>
                        <Separator height={20} />
                        <span className='recommended'>Recommended</span>
                    </div>
                </div>
                <div className="without">
                    <div className="selectbox w-450 h-400" onClick={noAgent}>
                        <Icons.Minus />
                        <Separator height={15} />
                        <h1 className="title">I'd like to request without an agent.</h1>
                        <Separator height={10} />
                        <p className="subtitle">Choose this option if you want to book directly with the Listing Agent without the help of a Buyer's Agent.</p>
                    </div>
                </div>
            </div>
            <Separator height={90} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Separator height={90} />
            <Footer subfooter />
        </section>
    )
}


export default ChooseAgent;