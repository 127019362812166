import { useNavigate } from "react-router-dom";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Packages
import Talk from 'talkjs';
import moment from 'moment';
import html2canvas from 'html2canvas';
// Assets
import './showingRequestSubmitted.scss'
import ShowingRequestIllus from 'assets/images/ShowingRequestIllus.svg'
import { useEffect, useState } from "react";
import Icons from "components/Icons";
import { useDispatch } from "react-redux";
import actions from "store/actions";
import { Config } from 'utils';
import { toast } from "react-toastify";
import ToastMessage from "components/ToastMessage";
import { checkImageUrl, getErrorMessage } from "utils/modules/Handlers";
import NoUser from 'assets/images/no_user.jpg';

const ShowingRequestSubmitted = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showing, setShowing] = useState(null);
    const [talkLoaded, markTalkLoaded] = useState(false);
    const [relatedUsers, setRelatedUsers] = useState([])
    const [attachment, setAttachment] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        const initSBBot = async() => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (talkLoaded) {
                const showingBeeBot = {
                    name: 'Showing Bee Showings Bot',
                    email: ['showings@showingbee.com'],
                    photoUrl: 'https://i.ibb.co/SdMNTMM/sb-bot.png',
                    role: 'admin',
                }
                await dispatch(actions.message.updateCreateUser({ id: 'showingbee_showings_bot', data: showingBeeBot }));
            }
        }

        initSBBot();
    }, [talkLoaded])

    useEffect(() => {
        const init = async() => {
            const data = localStorage.getItem('showingCreated');
            if (data) {
                console.log(JSON.parse(data))
                setShowing(JSON.parse(data))
            } else {
                navigate('/listings/');
            }
        }

        init();
    }, [])

    useEffect(() => {
        const initRelatedUsers = async() => {
            if (showing && showing.id) {
                let users = [];
                if (showing.buyer_agent_data) {
                    users.push(showing.buyer_agent_data?.agent_data.user_data)
                }
                users.push(showing.listing_data?.agent_data.user_data);
                if (showing.agent_members_data && showing.agent_members_data.length > 0) {
                    showing.agent_members_data.map(item => {
                        users.push(item.agent_data.user_data);
                    })
                }
                if (showing.buyers_data.length > 0) {
                    showing.buyers_data.map(item => {
                        users.push(item);
                    })
                }
                console.log(users)
                setRelatedUsers(users)
            }
        }

        initRelatedUsers();
    }, [showing])

    useEffect(() => {
        const getAttachment = () => {
            if (showing && showing.id) {
                let preview = document.getElementById('showing-preview')
                html2canvas(preview, {useCORS: true, scale: 2}).then(async(canvas) => {
                    console.log(canvas.toDataURL());
                    canvas.toBlob(async(blob) => {
                        const formData = new FormData()
                        const file = new File([blob], `showing_request_${showing.id}__${moment(new Date(showing.date)).format('MM-DD-YY')}.png`,{ type: "image/png" })
                        formData.append('file', file);
                        formData.append('filename ', `showing_request_${showing.id}__${moment(new Date(showing.date)).format('MM-DD-YY')}.png`);
                        const response = await dispatch(actions.message.uploadFiles({ data: formData }));
                        setAttachment(response.attachmentToken)
                    }, 'image/png')
                });
            }
            
        }
        getAttachment();
    }, [showing])

    useEffect(() => {
        const onSendScreenShot = async() => {
            try {
                if (attachment && relatedUsers.length > 0 && (showing && showing.id)) {
                    const messageData = [
                        {
                            text: `Showing Request for Listing #${showing.listing_data?.mls_number} on ${showing.date} around ${showing.start_time} to ${showing.finish_time}`,
                            sender: 'showingbee_showings_bot',
                            type: "SystemMessage",
                        },
                        {
                            attachmentToken: attachment,
                            type: 'UserMessage',
                            sender: 'showingbee_showings_bot',
                        },
                        {
                            text: `Listing Details: ${Config.CLIENT_URL}/listings/${showing.listing_data.mls_source}/${showing.listing_data.mls_number || showing.listing_data.id}/`,
                            type: 'UserMessage',
                            sender: 'showingbee_showings_bot',
                        },
                    ]
        
                    const showingBeeBot  = new Talk.User({
                        id: 'showingbee_showings_bot',
                        name: 'Showing Bee Showings Bot',
                        email: 'showings@showingbee.com',
                        photoUrl: 'https://i.ibb.co/SdMNTMM/sb-bot.png',
                        role: 'admin',
                    });
        
                    await Promise.all(
                        relatedUsers.map(async (item) => {
                            const userData  = new Talk.User({
                                id: item.id,
                                name: `${item.first_name} ${item.last_name}`,
                                email: item.email,
                                photoUrl: checkImageUrl(item.profile_photo, NoUser),
                                role: item.user_type,
                            });
                            
                            const session = new Talk.Session({
                                appId: Config.TalkJS_APP_ID,
                                me: userData,
                            });
                            let conversationId = Talk.oneOnOneId(userData, showingBeeBot);
                            const conversation = session.getOrCreateConversation(conversationId)
                            conversation.setParticipant(userData, {access: "Read", notify: true});
                            conversation.setParticipant(showingBeeBot, {access: "Read", notify: true});
            
                            try {
                                await dispatch(actions.message.updateCreateUser({ 
                                    id: userData.id, 
                                    data: {
                                        name: userData.name,
                                        email: [userData.email],
                                        photoUrl: userData.photoUrl,
                                        role: userData.role,
                                    } 
                                }));
                                await dispatch(actions.message.createConversation({id: conversation.id, data: { participants: [userData.id.toString(), showingBeeBot.id.toString()] }}));    
                            } catch (error) {
                                console.log(error);
                            }
                            await dispatch(actions.message.sendMessage({id: conversation.id, data: messageData})).then(() => {
                                setLoading(false);
                            })
                        })
                    )
        
                }
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
        onSendScreenShot();
    }, [relatedUsers, attachment, showing])

    const goToListing = () => {
        navigate('/listings/');
        localStorage.removeItem('showingCreated');
    }

    return (
        <Section style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="showing-request-submitted-container">
                <img className='illus' src={ShowingRequestIllus} alt="showing-schedule" />
                <Seprator height={50} />
                {
                    loading
                    ? <>
                        <p className="header">Finalizing Showing Request</p>
                        <Seprator height={26} />
                        <div className='loading'>
                            <Icons.Loading />
                        </div>
                    </>
                    : 
                    <>
                        <p className="header">Showing Request Submitted!</p>
                        <Seprator height={26} />
                        <p className="subheader">Thank you ~ your request has been submitted. Please allow time for your showing request to be approved.</p>
                        <Seprator height={35} />
                        <span className="invite">Want to search for more available listings? Go to Showing Bee now!</span>
                        <Seprator height={30} />
                        <div className="browse" onClick={goToListing}>Click here to view more</div>
                    </>
            }
                <div id='showing-preview' className='contents'>
                    <div className='preview'>
                        <Icons.SBHeader />
                    </div>
                    <div className='content-details'>
                        <div className='listing-details'>
                            <div className='listing-info'>
                                <p className="listing">Listing #{showing?.listing_data?.mls_number}</p>
                                <Seprator height={15} />
                                <p className="title">{showing?.listing_data?.property_type} in {showing?.listing_data?.city}</p>
                                <Seprator height={20} />
                                <h1 className="address">
                                    {showing?.listing_data?.address}
                                </h1>
                                <Seprator height={20} />
                            </div>
                            <div className='listing-agent'>
                                {
                                    showing?.listing_data?.list_agent_full_name 
                                    ? <>
                                        <img src={checkImageUrl(showing?.listing_data?.agent_data.user_data?.profile_photo, NoUser)} alt="" />
                                        <span>{showing?.listing_data?.list_agent_full_name}</span>
                                    </>
                                    : <span>No Listing Agent</span>
                                }
                                
                            </div>
                        </div>
                        <Seprator height={20} />
                        <div className="info-container">
                            <div className="details">
                                {
                                    showing?.buyers_data?.length > 0 
                                    ? showing?.buyers_data.map((item, index) => {
                                        return(
                                            <div key={index}>
                                                <div className='content'>
                                                    <p className="buyer">{item.first_name} {item.last_name}</p>
                                                    <span className="email">{item.email}</span>
                                                </div>
                                            </div>
                                    )
                                    })
                                    : <div>
                                        <div className='content'>
                                            <p className="buyer">Invited Buyer</p>
                                        </div>
                                    </div>
                                }
                                <Seprator height={20} />
                                <div className='showing'>
                                    <p className="date">{showing?.date}</p>
                                    <p className='address'>{showing?.listing_data?.address}</p>
                                </div>
                            </div>
                            <div className="time">
                                <p className="duration">{showing?.duration}</p>
                                <p className="specific">{`${showing?.start_time} - ${showing?.finish_time}`}</p>
                            </div>
                        </div>
                        {
                            showing?.buyer_agent_data &&
                            <>
                                <Seprator height={50} />
                                <div style={{ displat: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    
                                            <div style={{ display: 'flex' }}>
                                                
                                                <div>
                                                    <p className="agent">
                                                        {
                                                            showing?.buyer_agent_data?.id 
                                                            && `${showing?.buyer_agent_data?.first_name} ${showing?.buyer_agent_data?.last_name}`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                    <Seprator height={10} />
                                    <div className="buyer-agent-info">
                                        <div><Icons.EmailSmall height={20} width={25} color={'#6C767C'} /> {showing?.buyer_agent_data?.agent_data?.user_data?.email || 'Not Indicated'}</div>
                                        <div><Icons.Telephone height={20} width={25} /> {showing?.buyer_agent_data?.agent_data?.user_data?.phone_number || 'Not Indicated' }</div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

        </Section>
    )
}


export default ShowingRequestSubmitted;