import Section from "components/Section";
import Seprator from "components/Separator";
import ToastMessage from "components/ToastMessage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import actions from "store/actions";
import { Config } from "utils";
import { checkImageUrl, getErrorMessage, inputChange } from "utils/modules/Handlers";
import NoUser from 'assets/images/no_user.jpg'
import './contacts.scss'
import Icons from "components/Icons";
import debouce from 'lodash.debounce'
import DashboardFiltersContainer from "components/DashboardFiltersContainer";
import CustomTextInput from "components/CustomTextInput";
import CustomButton from "components/CustomButton";
import ListingStatus from "components/ListingStatus";
import { useLocation, useNavigate } from "react-router-dom";
import house1 from 'assets/images/house1.jpg'
import ModalContainer from "components/ModalContainer";
import ListingList from "components/ListingList";
import ShowingStatus from "components/ShowingStatus";
import ShowingTime from "components/ShowingTime";

const Contacts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation(); 
    const [modalVisible, setModalVisible] = useState(false);
    const [showingsModal, setShowingsModal] = useState(false);
    const { connections: { data, previous, next, total }, listingSellers: { data: sellersData, previous: sellersPrevious, next: sellersNext, total: sellersTotal } } = useSelector((state) => state.agent)
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [sellerPages, setSellerPages] = useState([]);
    const [sellerPage, setSellerPage] = useState(1);
    const [filters, setFilters] = useState('')
    const [tab, setTab] = useState('buyer')
    const [selectedConnection, setSelectedConnection] = useState(null)
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
    });

    useEffect(() => {
        if (location.pathname.includes('sellers')) {
            setTab('seller')
        } else {
            setTab('buyer')
        }
    }, [location.pathname])

    useEffect(() => {
        const init = async () => {
            await dispatch(actions.agent.fetchBuyerAgentConnection());
            await dispatch(actions.agent.listingSellers());
            
        }
        init();
    }, [])

    useEffect(() => {
        const init = async () => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total / 20) || 1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    value: filters ? `${Config.API_URL}/buyer-agent-connection/search/?limit=20&offset=${offset}&${filters}` : `${Config.API_URL}/buyer-agent-connection/?limit=20&offset=${offset}`,
                    name: `${offset + 1} - ${i * 20 >= total ? total : i * 20}`,
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [total])

    useEffect(() => {
        const init = async () => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(sellersTotal / 20) || 1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    value: `${Config.API_URL}/listing-sellers/?limit=20&offset=${offset}&${filters}`,
                    name: `${offset + 1} - ${i * 20 >= sellersTotal ? sellersTotal : i * 20}`,
                })
                offset = i * 20
            }
            setSellerPages(pageLinks)
        }
        init();
    }, [sellersTotal])

    const onSearch = useCallback(async () => {
        try {
            await dispatch(actions.agent.searchBuyerAgentConnection({ offset: 0, filters }))
            await dispatch(actions.agent.searchListingSellers({ offset: 0, filters }))
        } catch (error) {
            console.log(error)
        }
    }, [filters])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [filters])

    const onChangeSearch = (event) => {
        setFilters(`search=${event.target.value}`)
    }

    const handleInput = useMemo(() => {
        return debouce(onChangeSearch, 300)
    }, [])

    const loadPageData = async ({ page, pageNumber }) => {
        await dispatch(actions.agent.fetchBuyerAgentConnectionPagination(page));
        setPage(pageNumber);
    }

    const loadSellerPageData = async ({ page, pageNumber }) => {
        await dispatch(actions.agent.fetchListingSellersPagination(page));
        setSellerPage(pageNumber);
    }

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onInvite = useCallback(async () => {
        const { email, first_name, last_name, phone_number } = form
        let isValid = true

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }
        if (phone_number.value) {
            if (!validatePhone(phone_number.value)) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: '' })
            }
        }

        if (isValid) {
            const data = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                phone_number: phone_number.value ? `1${phone_number.value}` : '',
            }
            try {
                await dispatch(actions.agent.inviteBuyer(data));
                await dispatch(actions.agent.fetchBuyerAgentConnection());
                setModalVisible(prev => !prev);
                toast.success('Contact invitation sent!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [form])

    const onShowings = (connection) => {
        setSelectedConnection(connection)
        setShowingsModal(prev => !prev)
    }

    return (
        <div className="contacts-container" style={{ minHeight: '91.5vh' }}>
            <div className={`add-buyer-modal ${modalVisible && 'visible'}`}>
                <div className='form-section'>
                    <p className='section-title'>
                        Invite Connection (Buyer)
                    </p>
                    <Seprator height={30} />
                    <div className='form-input'>
                        <label>First Name</label>
                        <input
                            id='first_name'
                            placeholder='Enter First Name'
                            type='text'
                            className='small'
                            value={form.first_name.value}
                            onChange={(event) => onChangeInput({ event, type: "first_name" })}
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.first_name.error}</span>
                    </div>
                    <Seprator height={20} />
                    <div className='form-input'>
                        <label>Last Name</label>
                        <input
                            id='last_name'
                            placeholder='Enter Last Name'
                            type='text'
                            className='small'
                            value={form.last_name.value}
                            onChange={(event) => onChangeInput({ event, type: "last_name" })}
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.last_name.error}</span>
                    </div>
                    <Seprator height={20} />
                    <div className='form-input'>
                        <label>Email Address</label>
                        <input
                            id='email'
                            placeholder='Enter Email Address'
                            type='text'
                            className='small'
                            value={form.email.value}
                            onChange={(event) => onChangeInput({ event, type: "email" })}
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.email.error}</span>
                    </div>
                    <Seprator height={20} />
                    <div className='form-input'>
                        <label>Phone Number</label>
                        <input
                            id='phone_number'
                            placeholder='Enter Phone Number (Optional)'
                            type='text'
                            className='small'
                            maxLength={10}
                            value={form.phone_number.value}
                            onChange={(event) => onChangeInput({ event, type: "phone_number" })}
                        />
                        <span className='error-message' style={{ alignSelf: 'flex-start' }}>{form.phone_number.error}</span>
                    </div>
                    <Seprator height={35} />
                    <button className='button primary small no-glow' onClick={onInvite}>
                        Invite
                    </button>
                    <Seprator height={15} />
                    <button className='button cancel small no-glow' onClick={() => setModalVisible(prev => !prev)}>Cancel</button>
                </div>
            </div>
            <ModalContainer modal={showingsModal} setModal={setShowingsModal} title={`${selectedConnection?.buyer?.first_name} ${selectedConnection?.buyer?.last_name}'s Showings`}>
                <div className="showings-modal">
                    {
                        selectedConnection?.showings.map((showing, index) => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={index}>
                                    <ListingList data={showing} />
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', rowGap: 8 }}>
                                        <p style={{ fontSize: 12, fontWeight: 500, color: '#5F5F5F', margin: 0 }}>{showing.start_time} - {showing.finish_time}</p>
                                        <ShowingStatus showing={showing} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </ModalContainer>
            <DashboardFiltersContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', columnGap: 8 }}>

                    <div className="table-filters">
                        <CustomTextInput
                            inputProps={{
                                type: "text",
                                placeholder: 'Search Connection',
                                id: 'email',
                                onChange: handleInput,
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="buttons" style={{ display: 'flex', alignItems: 'center', columnGap: 32 }}>
                        {
                            tab === 'buyer' &&
                            <CustomButton text={"Invite Connection"} type="secondary" action={() => setModalVisible(prev => !prev)} />
                        }
                        {/* <ul className="tab-choice">
                            <li className={`${tab === 'buyer' && 'active'}`} onClick={() => setTab('buyer')}>
                                <Icons.BuyerIndicator width={15} height={15} />
                                <p>Buyers</p>
                            </li>
                            <li className={`${tab === 'seller' && 'active'}`} onClick={() => setTab('seller')}>
                                <Icons.SellerIndicator width={15} height={15} />
                                <p>Sellers</p>
                            </li>
                        </ul> */}
                        {
                            (data.length > 0 && tab === 'buyer') &&
                            <div className="pagination">
                                <p>{pages[page - 1]?.name} of {total}</p>
                                <p onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                            fill={previous ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                                <p onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.95964 3.95955C6.35017 3.56903 6.98333 3.56903 7.37385 3.95955L10.7072 7.29288C11.0977 7.68341 11.0977 8.31657 10.7072 8.7071L7.37385 12.0404C6.98333 12.431 6.35017 12.431 5.95964 12.0404C5.56912 11.6499 5.56912 11.0167 5.95964 10.6262L8.58587 7.99999L5.95964 5.37376C5.56912 4.98324 5.56912 4.35007 5.95964 3.95955Z"
                                            fill={next ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                            </div>
                        }
                        {
                            (sellersData.length > 0 && tab === 'seller') &&
                            <div className="pagination">
                                <p>{sellerPages[sellerPage - 1]?.name} of {sellersTotal}</p>
                                <p onClick={() => loadSellerPageData({ page: sellersPrevious, pageNumber: sellerPage - 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                            fill={sellersPrevious ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                                <p onClick={() => loadSellerPageData({ page: sellersNext, pageNumber: sellerPage + 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.95964 3.95955C6.35017 3.56903 6.98333 3.56903 7.37385 3.95955L10.7072 7.29288C11.0977 7.68341 11.0977 8.31657 10.7072 8.7071L7.37385 12.0404C6.98333 12.431 6.35017 12.431 5.95964 12.0404C5.56912 11.6499 5.56912 11.0167 5.95964 10.6262L8.58587 7.99999L5.95964 5.37376C5.56912 4.98324 5.56912 4.35007 5.95964 3.95955Z"
                                            fill={sellersNext ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </DashboardFiltersContainer>
            <div className="contents">
                <div className="table-container">
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th width="300px"><div>Name</div></th>
                                {
                                    tab === 'buyer' &&
                                    <th width="220px"><div>Type</div></th>
                                }
                                {
                                    tab === 'seller' &&
                                    <th width="300px"><div>Listing</div></th>
                                }
                                <th width="354px"><div>Email</div></th>
                                <th width="220px"><div>Contact</div></th>
                                {
                                    tab === 'buyer' &&
                                    <>
                                        <th width="220px"><div style={{ textAlign: 'center' }}>Showings</div></th>
                                        <th width="146px"><div style={{ textAlign: 'center' }}>Action</div></th>
                                    </>
                                }
                            </tr>
                        </thead>
                        {
                            tab === 'buyer' &&
                            <tbody id="showings-table">
                                {
                                    data.length > 0 ?
                                        data.map((connection, index) => {
                                            return (
                                                <tr className="body" key={index}>
                                                    <td data-label="Name" align="center">
                                                        <div className="user-container">
                                                            <img className="user-image" src={checkImageUrl(connection.buyer?.profile_photo, NoUser)} alt="" />
                                                            <p className="user-info">{connection.buyer?.first_name} {connection.buyer?.last_name}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div data-label="Type" className="type-container">
                                                            {
                                                                connection.buyer?.buyer_tier === 'F'
                                                                    ? <>
                                                                        <Icons.ForagerBeeTier width={20} height={20} />
                                                                        <p>Forager Bee Buyer</p>
                                                                    </>
                                                                    : connection.buyer?.buyer_tier === 'H'
                                                                        ? <>
                                                                            <Icons.HouseBeeTier width={20} height={20} />
                                                                            <p>House Bee Buyer</p>
                                                                        </>
                                                                        : <>
                                                                            <Icons.QueenBeeTier width={20} height={20} />
                                                                            <p>Queen Bee Buyer</p>
                                                                        </>
                                                            }

                                                        </div>
                                                    </td>
                                                    <td data-label="Email">
                                                        <p style={{ padding: '0 16px', fontSize: 12, color: '#949494', fontWeight: 400 }}>{connection.buyer?.email}</p>
                                                    </td>
                                                    <td data-label="Phone">
                                                        <p style={{ padding: '0 16px', fontSize: 12, color: '#949494', fontWeight: 400 }}>{connection.buyer?.phone_number || 'No Contact'}</p>
                                                    </td>
                                                    <td align="center">
                                                        <div className="showing-container">
                                                            {
                                                                connection.showings?.length > 0
                                                                    ? <div className="showings-block-container">
                                                                        {
                                                                            connection.showings.slice(0, 3).map((showing, index) => {
                                                                                return (
                                                                                    <div key={index} onClick={() => onShowings(connection)}>
                                                                                        <img className="listing-image" src={checkImageUrl(showing?.primary_photo_url, house1)} alt="" />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            connection.showings.length > 3 &&
                                                                            <Icons.MoreUser className="listing-image" width={24} heigth={24}/>
                                                                        }
                                                                    </div>
                                                                    : <p style={{ fontSize: 12, color: '#949494', fontWeight: 400 }}>No Showings</p>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td align="center">
                                                        <div className="action-container">
                                                            <svg
                                                                onClick={() => navigate(`/showings-map/${connection.buyer?.id}`)}
                                                                width={32}
                                                                height={32}
                                                                viewBox="0 0 32 32"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <circle cx={16} cy={16} r={16} fill="#F9F9F9" />
                                                                <g clipPath="url(#clip0_2157_5737)">
                                                                    <path
                                                                        d="M18.8068 9.33331H13.193C11.0611 9.33331 9.33325 11.0612 9.33325 13.1931V21.4619C9.33325 22.1274 9.87277 22.6666 10.538 22.6666H18.8068C20.9384 22.6666 22.6666 20.9388 22.6666 18.8069V13.1931C22.6666 11.0612 20.9384 9.33331 18.8068 9.33331ZM17.3364 17.3471C17.3364 17.7145 17.0384 18.0124 16.6711 18.0124H12.6675C12.3002 18.0124 12.0022 17.7145 12.0022 17.3471C12.0022 16.9798 12.3002 16.6819 12.6675 16.6819H16.6711C17.0384 16.6819 17.3364 16.9798 17.3364 17.3471ZM19.3324 15.3255H12.6675C12.3002 15.3255 12.0022 15.0276 12.0022 14.6603C12.0022 14.2929 12.3002 13.995 12.6675 13.995H19.3324C19.6997 13.995 19.9976 14.2929 19.9976 14.6603C19.9976 15.0276 19.6997 15.3255 19.3324 15.3255Z"
                                                                        fill="#949494"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2157_5737">
                                                                        <rect
                                                                            width={13.3333}
                                                                            height={13.3333}
                                                                            fill="white"
                                                                            transform="translate(9.33325 9.33331)"
                                                                        />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="6">
                                                <div className="empty-table">No Available Connection</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        }
                        {
                            tab === 'seller' &&
                            <tbody id="showings-table">
                                {
                                    sellersData.length > 0 ?
                                        sellersData.map((connection, index) => {
                                            return (
                                                <tr className="body" key={index}>
                                                    <td data-label="Name" align="center">
                                                        <div className="user-container">
                                                            <img className="user-image" src={checkImageUrl(connection.profile_photo, NoUser)} alt="" />
                                                            <p className="user-info">{connection.first_name} {connection.last_name}</p>
                                                        </div>
                                                    </td>
                                                    <td data-label="Listing">
                                                        <ul className="listing-container" style={{ display: 'flex', columnGap: 4, padding: '0 16px' }}>
                                                            {
                                                                connection.listings.map((item, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <ListingStatus color={item.listing_status_color} text={item.mls_number || item.id} style={{ fontSize: 10 }} />
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td data-label="Email">
                                                        <p style={{ padding: '0 16px', fontSize: 12, color: '#949494', fontWeight: 400 }}>{connection.email}</p>
                                                    </td>
                                                    <td data-label="Phone">
                                                        <p style={{ padding: '0 16px', fontSize: 12, color: '#949494', fontWeight: 400 }}>{connection.phone_number || 'No Contact'}</p>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="4">
                                                <div className="empty-table">No Available Connection</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}


export default Contacts;