import './logo.scss';
import Default from 'assets/images/ShowingBeeLogoBlack.png';
import Light from 'assets/images/ShowingBeeLogoWhite.png';
import Dark from 'assets/images/ShowingBeeLogoDark.png';

import DefaultMark from 'assets/images/SBMark.png';
import LightMark from 'assets/images/SBMarkLight.png';
import DarkMark from 'assets/images/SBMarkDark.png';

import DefaultMini from 'assets/images/SBLogo.png';
import LightMini from 'assets/images/SBMarkLight.png';
import DarkMini from 'assets/images/ShowingBeeLogoGray.png';


/* ACCEPTABLE PROPS VALUE
 * type: default, mark, mini
 * color: default, dark, light
 */

const Logo = ({ type = 'default', color = 'default', style, action = () => {} }) => {
    return (
        <div className={`showing-bee-logo ${type} ${color}`} onClick={action}>
            {
                type === 'default' &&
                <>
                    {
                        color === 'dark'
                            ? <img style={style} src={Dark} alt='ShowingBee' />
                            : color === 'light'
                                ? <img style={style} src={Light} alt='ShowingBee' />
                                : <img style={style} src={Default} alt='ShowingBee' />
                    }
                </>
            }
            {
                type === 'mark' &&
                <>
                    {
                        color === 'dark'
                            ? <img style={style} src={DarkMark} alt='ShowingBee' />
                            : color === 'light'
                                ? <img style={style} src={LightMark} alt='ShowingBee' />
                                : <img style={style} src={DefaultMark} alt='ShowingBee' />
                    }
                </>
            }
            {
                type === 'mini' &&
                <>
                    {
                        color === 'dark'
                            ? <img style={style} src={DarkMini} alt='ShowingBee' />
                            : color === 'light'
                                ? <img style={style} src={LightMini} alt='ShowingBee' />
                                : <img style={style} src={DefaultMini} alt='ShowingBee' />
                    }
                </>
            }
        </div>
    )
}


export default Logo;