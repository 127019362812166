import * as React from "react";
const ListingFormIcon = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={16} cy={16} r={16} fill={props.background || "#F9F9F9"} />
        <g clipPath="url(#clip0_1331_16571)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9999 20.6667C23.9999 22.5077 22.5076 24 20.6666 24C18.8256 24 17.3333 22.5077 17.3333 20.6667C17.3333 18.8257 18.8256 17.3333 20.6666 17.3333C22.5076 17.3333 23.9999 18.8257 23.9999 20.6667ZM20.9999 19.3333C20.9999 19.1492 20.8507 19 20.6666 19C20.4825 19 20.3333 19.1492 20.3333 19.3333V20.3333H19.3333C19.1492 20.3333 18.9999 20.4826 18.9999 20.6667C18.9999 20.8508 19.1492 21 19.3333 21H20.3333V22C20.3333 22.1841 20.4825 22.3333 20.6666 22.3333C20.8507 22.3333 20.9999 22.1841 20.9999 22V21H21.9999C22.184 21 22.3333 20.8508 22.3333 20.6667C22.3333 20.4826 22.184 20.3333 21.9999 20.3333H20.9999V19.3333Z"
                fill={props.color || "#949494"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8069 9.33333H13.193C11.0611 9.33333 9.33325 11.0612 9.33325 13.1931V21.4619C9.33325 22.1274 9.8728 22.6667 10.538 22.6667H17.0489C16.7206 22.0741 16.5338 21.3922 16.5338 20.6667C16.5338 18.3839 18.3844 16.5334 20.6671 16.5334C21.3923 16.5334 22.074 16.7201 22.6666 17.0483V13.1931C22.6666 11.0612 20.9384 9.33333 18.8069 9.33333ZM17.3363 17.3472C17.3363 17.7145 17.0385 18.0124 16.6711 18.0124H12.6674C12.3002 18.0124 12.0022 17.7145 12.0022 17.3472C12.0022 16.9798 12.3002 16.6819 12.6674 16.6819H16.6711C17.0385 16.6819 17.3363 16.9798 17.3363 17.3472ZM19.3324 15.3255H12.6674C12.3002 15.3255 12.0022 15.0276 12.0022 14.6603C12.0022 14.293 12.3002 13.995 12.6674 13.995H19.3324C19.6996 13.995 19.9976 14.293 19.9976 14.6603C19.9976 15.0276 19.6996 15.3255 19.3324 15.3255Z"
                fill={props.color || "#949494"}
            />
        </g>
        <defs>
            <clipPath id="clip0_1331_16571">
                <rect width={16} height={16} fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
);
export default ListingFormIcon;
