import * as React from "react"

const Media = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.571}
    height={16}
    viewBox="0 0 20.571 16"
    {...props}
  >
    <path
      d="M17.143,15.964v.571a1.714,1.714,0,0,1-1.714,1.714H1.714A1.714,1.714,0,0,1,0,16.536V7.393A1.714,1.714,0,0,1,1.714,5.679h.571v7.429a2.86,2.86,0,0,0,2.857,2.857Zm3.429-2.857V3.964A1.714,1.714,0,0,0,18.857,2.25H5.143A1.714,1.714,0,0,0,3.429,3.964v9.143a1.714,1.714,0,0,0,1.714,1.714H18.857A1.714,1.714,0,0,0,20.571,13.107ZM9.143,5.679A1.714,1.714,0,1,1,7.429,3.964,1.714,1.714,0,0,1,9.143,5.679ZM5.714,10.821,7.7,8.839a.429.429,0,0,1,.606,0L9.714,10.25l4.84-4.84a.429.429,0,0,1,.606,0l3.125,3.126v4H5.714Z"
      transform="translate(0 -2.25)"
      fill={props.color || '#2a2a2a'}
    />
  </svg>
)


export default Media
