import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Routes
import NotFound from 'routes/NotFound';
// Components
import Separator from 'components/Separator';
import Footer from 'components/Footer';
import Icons from 'components/Icons';
// Assets
import house1 from 'assets/images/house1.jpg'
import './loginToRequest.scss'
import { checkImageUrl } from 'utils/modules/Handlers';

const LoginToRequest = () => {
    const { id: listingID, mls } = useParams();
    const {public: { listing: data } } = useSelector((state) => state.listing)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.listing.retrievePublicListing({ mls, id: listingID }))
        }
        init();
    }, [dispatch, listingID])

    return (
        <>
            {
                data.id ? 
                <>
                    <Separator height={60} />
                    <section className="request-container">
                        <div className="sign-in-login">
                            <h1 className="header">Sign in to Showing Bee</h1>
                            <Separator height={15} />
                            <p className="subheader">To continue on your showing request, please <br/> sign in to your account.</p>
                            <Separator height={70} />
                            <div className="button-container">
                                <button 
                                    className='button primary' 
                                    onClick={() => navigate(`/login`, { state: { from: `/request/${mls}/${data.mls_number || data.id}` }})}
                                >
                                    Sign In
                                </button>
                                <p className="or">or</p>
                                <button 
                                    className='button primary outline no-glow' 
                                    onClick={() => navigate(`/signup`, { from: '/request/:mls/:id' } )}
                                >
                                    Create an account
                                </button>
                            </div>
                        </div>
                        <div className="listing-detail">
                            <Separator height={50} />
                            <div className="detail-container">
                                <p className="address">
                                {
                                    data.mls_number 
                                    ? <>{data.full_address}</>
                                    : <>{`${data.address}, ${data.city} ${data.state} ${data.zip_code}`}</>
                                }
                                </p>
                                <Separator height={15} />
                                <p className="listing">Listing #{data.mls_number || data.id}</p>
                                <Separator height={20} />
                                <img className="preview" src={checkImageUrl(data.primary_photo_url, house1)} alt="" />
                                <Separator height={30} />
                                {
                                    data.agent.full_name &&
                                    <div className="badge">
                                        <span>{data.agent.full_name}</span>
                                        <Separator width={5} />
                                        <Icons.ListingAgentBadge height={14} width={14} />
                                    </div>  
                                }
                            </div>
                        </div> 
                    </section>
                    <Separator height={100} />
                    <Footer subfooter />
                </>
                : <NotFound />
            }
        </>
    )
}


export default LoginToRequest;