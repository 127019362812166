import * as React from "react";

const SBHeader = (props) => (
  <svg
    width={740}
    height={150}
    viewBox="0 0 740 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={740} height={150} rx={20} fill="url(#paint0_linear_1952_44)" />
    <mask
      id="mask0_1952_44"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={740}
      height={150}
    >
      <rect
        width={740}
        height={150}
        rx={20}
        fill="url(#paint1_linear_1952_44)"
      />
    </mask>
    <g mask="url(#mask0_1952_44)">
      <g opacity={0.3}>
        <path
          d="M165.681 55.9428H151.051V70.5726H165.681V55.9428Z"
          fill="white"
        />
        <path
          d="M186.836 55.9428H172.206V70.5726H186.836V55.9428Z"
          fill="white"
        />
        <path
          d="M165.681 77.5429H151.051V92.1727H165.681V77.5429Z"
          fill="white"
        />
        <path
          d="M186.836 77.5429H172.206V92.1727H186.836V77.5429Z"
          fill="white"
        />
        <path
          d="M212.083 112.557L138.286 156.082L61.6917 112.557V34.8896L137.819 -9.26332L148.478 -3.28576L160.048 -11.1072L137.738 -23.4271L50 25.9739V121.23L137.11 170.712L226.409 121.068V57.6448H258.303V45.9531L192.246 7.5549L180.169 15.5182L232.062 47.4323L212.002 46.0342L212.083 112.557Z"
          fill="white"
        />
        <path
          d="M159.805 131.989L145.641 140.905L113.585 121.149V57.7259H81.7518V46.2773L201.85 -23.4271L290.055 25.832V121.311L202.012 173.427L180.554 158.777L193.421 151.28L201.931 157.399L278.444 112.557V34.5654L202.093 -9.10121L107.992 45.8721H127.951L128.053 112.557L159.805 131.989Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1952_44"
        x1={-88.9028}
        y1={-9.53757}
        x2={372.57}
        y2={489.662}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAC91F" />
        <stop offset={0.523958} stopColor="#FAC91F" />
        <stop offset={1} stopColor="#F8A728" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1952_44"
        x1={-88.9028}
        y1={-9.53757}
        x2={372.57}
        y2={489.662}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAC91F" />
        <stop offset={0.523958} stopColor="#FAC91F" />
        <stop offset={1} stopColor="#F8A728" />
      </linearGradient>
    </defs>
  </svg>
)


export default SBHeader;
