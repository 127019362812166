import * as React from "react"

const CalendarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.57292 1.66666C5.78012 1.66666 5.97883 1.74897 6.12534 1.89548C6.27186 2.04199 6.35417 2.24071 6.35417 2.44791V3.74999H13.6458V2.44791C13.6458 2.24071 13.7281 2.04199 13.8747 1.89548C14.0212 1.74897 14.2199 1.66666 14.4271 1.66666C14.6343 1.66666 14.833 1.74897 14.9795 1.89548C15.126 2.04199 15.2083 2.24071 15.2083 2.44791V3.74999H15.4688C16.2285 3.74999 16.9571 4.05179 17.4943 4.58901C18.0315 5.12622 18.3333 5.85484 18.3333 6.61457V15.4687C18.3333 16.2285 18.0315 16.9571 17.4943 17.4943C16.9571 18.0315 16.2285 18.3333 15.4688 18.3333H4.53125C3.77152 18.3333 3.0429 18.0315 2.50568 17.4943C1.96847 16.9571 1.66667 16.2285 1.66667 15.4687V6.61457C1.66667 5.85484 1.96847 5.12622 2.50568 4.58901C3.0429 4.05179 3.77152 3.74999 4.53125 3.74999H4.79167V2.44791C4.79167 2.24071 4.87398 2.04199 5.02049 1.89548C5.167 1.74897 5.36572 1.66666 5.57292 1.66666ZM4.53125 7.39582C3.8125 7.39582 3.22917 7.97916 3.22917 8.69791V15.4687C3.22917 16.1875 3.8125 16.7708 4.53125 16.7708H15.4688C16.1875 16.7708 16.7708 16.1875 16.7708 15.4687V8.69791C16.7708 7.97916 16.1875 7.39582 15.4688 7.39582H4.53125Z"
      fill={props.color || "#949494"}
    />
  </svg>
)

export default CalendarIcon
