import * as React from "react";

const BeeIconButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={323.532}
    height={266.988}
    viewBox="0 0 323.532 266.988"
    {...props}
  >
    <defs>
      <style>
        {
          ".a,.g{fill:none;}.b{clip-path:url(#a);}.c{fill:#4d4d4d;}.d{fill:#424242;}.e{fill:#fac91f;}.f{fill:#fff;}.g{stroke:#424242;stroke-linecap:round;stroke-miterlimit:10;stroke-width:15px;}"
        }
      </style>
      <clipPath id="a">
        <rect className="a" width={266.988} height={323.532} />
      </clipPath>
    </defs>
    <g transform="translate(323.532) rotate(90)">
      <g className="b">
        <path
          className="c"
          d="M133.594,317.032a10.58,10.58,0,0,1-9.256-5.344l-13.531-23.436a10.689,10.689,0,0,1,9.256-16.033h27.062a10.689,10.689,0,0,1,9.256,16.033L142.85,311.688a10.58,10.58,0,0,1-9.256,5.344"
        />
        <path
          className="d"
          d="M147.125,278.72A4.188,4.188,0,0,1,150.752,285l-13.531,23.436a4.188,4.188,0,0,1-7.254,0L116.436,285a4.188,4.188,0,0,1,3.627-6.282Zm0-13H120.063A17.188,17.188,0,0,0,105.178,291.5l13.531,23.436a17.188,17.188,0,0,0,29.77,0L162.01,291.5a17.188,17.188,0,0,0-14.885-25.782"
        />
        <path
          className="c"
          d="M133.594,289.989c-38.811,0-70.385-34.716-70.385-77.388s31.574-77.387,70.385-77.387,70.385,34.715,70.385,77.387-31.574,77.388-70.385,77.388"
        />
        <path
          className="d"
          d="M206.792,186.867c-9.95-33.74-38.97-58.15-73.2-58.15s-63.24,24.41-73.19,58.15a90.564,90.564,0,0,0-3.69,25.73q0,4.545.44,8.97c4.1,42.11,36.76,74.92,76.44,74.92s72.35-32.81,76.45-74.92q.435-4.425.44-8.97a90.564,90.564,0,0,0-3.69-25.73m-73.2,96.62c-28.41,0-52.55-20.69-60.82-49.21a78.462,78.462,0,0,1-1.35-37.99c6.65-31.25,32.01-54.57,62.17-54.57,30.18,0,55.53,23.33,62.18,54.58a78.421,78.421,0,0,1-1.35,37.98c-8.27,28.52-32.41,49.21-60.83,49.21"
        />
        <path
          className="e"
          d="M210.482,212.6q0,4.545-.44,8.97a96.822,96.822,0,0,1-15.62,12.71c-13.87,9.06-33.79,17.11-60.83,17.11s-46.96-8.05-60.82-17.11a96.382,96.382,0,0,1-15.62-12.71q-.435-4.425-.44-8.97a90.564,90.564,0,0,1,3.69-25.73,74.163,74.163,0,0,0,11.02,9.42c13.18,9.26,32.96,17.1,62.17,17.1s49-7.84,62.18-17.09a74.785,74.785,0,0,0,11.02-9.43,90.564,90.564,0,0,1,3.69,25.73"
        />
        <path
          className="f"
          d="M50.225,238.778c-17.531,0-32.22-7.772-39.293-20.791-11.769-21.663.679-51.251,27.749-65.958,23.278-12.647,40.81-18.794,53.6-18.794,9.1,0,15.045,3.135,18.7,9.868,5.532,10.182,5.458,28.338-.187,46.254-6.012,19.082-17.013,34.5-29.428,41.245a65.612,65.612,0,0,1-31.143,8.176"
        />
        <path
          className="d"
          d="M92.283,126.735v13c8.325,0,11.162,3.11,12.988,6.471,4.658,8.575,4.387,25.131-.675,41.2-5.518,17.513-15.361,31.528-26.331,37.487a59.107,59.107,0,0,1-28.041,7.388c-15.11,0-27.664-6.5-33.581-17.4-4.638-8.536-4.855-18.993-.613-29.443,4.6-11.342,13.751-21.18,25.753-27.7,21.99-11.947,38.98-18.005,50.5-18.006Zm0,0c-16.779,0-38.082,9.467-56.7,19.583C5.31,162.762-8.282,196.238,5.22,221.09c8.523,15.689,25.8,24.189,45,24.189a72.084,72.084,0,0,0,34.246-8.965C114.738,219.871,130.2,164.852,116.694,140c-5.2-9.563-13.919-13.266-24.415-13.265"
        />
        <path
          className="f"
          d="M216.763,238.778a65.613,65.613,0,0,1-31.144-8.176c-12.415-6.744-23.416-22.163-29.428-41.245-5.645-17.916-5.718-36.072-.186-46.254,3.657-6.733,9.6-9.868,18.7-9.868,12.788,0,30.321,6.147,53.6,18.794,27.069,14.707,39.517,44.295,27.748,65.958-7.073,13.019-21.761,20.791-39.29,20.791Z"
        />
        <path
          className="d"
          d="M174.708,139.735c11.515,0,28.5,6.058,50.5,18.006,12,6.52,21.148,16.358,25.753,27.7,4.242,10.45,4.025,20.907-.613,29.443-5.917,10.892-18.471,17.4-33.581,17.4a59.107,59.107,0,0,1-28.041-7.388c-10.97-5.959-20.813-19.974-26.331-37.487-5.062-16.067-5.333-32.623-.675-41.2,1.826-3.361,4.664-6.471,12.992-6.471m0-13c-10.5,0-19.219,3.7-24.415,13.265-13.5,24.852,1.956,79.871,32.224,96.314a72.081,72.081,0,0,0,34.246,8.965c19.2,0,36.481-8.5,45-24.189,13.5-24.852-.09-58.328-30.357-74.772-18.62-10.116-39.923-19.583-56.7-19.583"
        />
        <path className="g" d="M116.705,77.045s-5.5-45.166-51.363-61.064" />
        <path className="g" d="M150.483,77.045s5.5-45.166,51.363-61.064" />
        <path
          className="c"
          d="M58.246,20.71a7.1,7.1,0,1,1,7.1-7.1,7.113,7.113,0,0,1-7.1,7.1"
        />
        <path
          className="d"
          d="M58.246,13a.605.605,0,1,1-.6.6.605.605,0,0,1,.6-.6m0-13a13.6,13.6,0,1,0,13.6,13.6A13.6,13.6,0,0,0,58.246,0"
        />
        <path
          className="c"
          d="M208.942,20.71a7.1,7.1,0,1,1,7.1-7.1,7.113,7.113,0,0,1-7.1,7.1"
        />
        <path
          className="d"
          d="M208.942,13a.605.605,0,1,1-.6.6.605.605,0,0,1,.6-.6m0-13a13.6,13.6,0,1,0,13.6,13.6A13.6,13.6,0,0,0,208.942,0"
        />
        <path
          className="d"
          d="M133.594,163.488c-30.807,0-55.87-20.057-55.87-44.71s25.063-44.71,55.87-44.71,55.87,20.056,55.87,44.71-25.063,44.71-55.87,44.71"
        />
        <path
          className="d"
          d="M133.594,80.567c13.659,0,26.391,4.278,35.852,12.046,8.717,7.157,13.517,16.449,13.517,26.164s-4.8,19.007-13.517,26.164c-9.461,7.768-22.193,12.046-35.852,12.046s-26.391-4.278-35.852-12.046c-8.717-7.157-13.517-16.449-13.517-26.164s4.8-19.007,13.517-26.164c9.461-7.768,22.193-12.046,35.852-12.046m0-13c-34.446,0-62.369,22.928-62.369,51.21s27.923,51.21,62.369,51.21,62.369-22.927,62.369-51.21-27.923-51.21-62.369-51.21"
        />
      </g>
    </g>
  </svg>
)

export default BeeIconButton;
