import { createSlice } from '@reduxjs/toolkit'

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    data: {},
    member: {
      data: [],
      next: null,
      previous: null,
      total: 0,
    },
  },
  reducers: {

  },
});


export default teamSlice