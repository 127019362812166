import { useCallback, useEffect, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
// Assets
import './confirmTemporaryPassword.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import ForgotPasswordIllus from 'assets/images/forgot-pass-page-illus.svg'
import Icons from 'components/Icons';
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import ToastMessage from 'components/ToastMessage';
import { toast } from 'react-toastify';
import Logo from 'components/Logo';
import CustomTextInput from 'components/CustomTextInput';
import CustomButton from 'components/CustomButton';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';

const ConfirmTemporaryPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [form, setForm] = useState({
        password: {
            value: '',
            error: '',
        },
        confirm_password: {
            value: '',
            error: '',
        },
    });


    useEffect(() => {
        const init = async() => {
            if (urlParams.get('uidb64') && urlParams.get('tmp') && urlParams.get('token')) {
                setLoading(prev => !prev);
            } else {
                navigate('/confirm-temporary-password/invalid/');

            }
        }
        init();
    }, [])

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async (event) => {
        event.preventDefault();

        const { password, confirm_password } = form
        let isValid = true

        if (password.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: '' })
        }

        if (confirm_password.value !== password.value) {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: 'Password does not match' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: '' })
        }

        if ((password.value && confirm_password.value) && confirm_password.value === password.value) {
            isValid = true
        }

        if (isValid) {
            setLoading(true);
            try {
                const data = {
                    uidb64: urlParams.get('uidb64'),
                    token: urlParams.get('token'),
                    temporary_code: urlParams.get('tmp'),
                    password: password.value,
                }
                await dispatch(actions.auth.confirmResetPassword(data)).then(() => {
                    setSubmitted(true);
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }

    }, [dispatch, form, navigate])

    return (
        <PageWrapper>
            <CustomContainer>
                <div className='conform-temp-password-container'>
                    <div className='conform-temp-password-form'>
                        <Logo style={{ height: 40 }} action={() => navigate('/')} />
                        <Seprator height={80} />
                        {
                            loading
                                ? <div className='loading'>
                                    <Icons.Loading />
                                </div>
                                : <>
                                    {
                                        !submitted
                                            ? <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24, alignItems: 'center' }}>
                                                <p className="header">Reset Password Setup</p>
                                                <p className="subheader">Please setup your password to complete the reset.</p>
                                                <img className='illus'
                                                    src={ForgotPasswordIllus}
                                                    alt=""
                                                />
                                                <CustomTextInput
                                                    inputProps={{
                                                        type: viewPassword ? 'text' : 'password',
                                                        placeholder: 'Password',
                                                        id: 'email',
                                                        autoComplete: 'new-password',
                                                        onChange: (event) => onChangeInput({ event, type: "password" }),
                                                    }}
                                                    size={'large'}
                                                    style={{ width: 300 }}
                                                    error={form.password.error}
                                                    rightSlot={<Icons.Eye style={{ cursor: 'pointer' }} color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />}
                                                />
                                                <CustomTextInput
                                                    inputProps={{
                                                        type: viewPassword2 ? 'text' : 'password',
                                                        placeholder: 'Confirm Password',
                                                        id: 'email',
                                                        autoComplete: 'new-password',
                                                        onChange: (event) => onChangeInput({ event, type: "confirm_password" }),
                                                    }}
                                                    size={'large'}
                                                    style={{ width: 300 }}
                                                    error={form.confirm_password.error}
                                                    rightSlot={<Icons.Eye style={{ cursor: 'pointer' }} color={viewPassword2 ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword2(prev => !prev)} />}
                                                />
                                                <CustomButton text={'Submit'} loading={loading} style={{ width: 300 }} size={'large'} action={onSubmit} />
                                            </div>
                                            : <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24, alignItems: 'center' }}>
                                                <h1 className='header'>Password has been reset!</h1>
                                                <h2 className='subheader' style={{ lineHeight: '35px' }}>
                                                    Please login into your account.
                                                </h2>
                                                <CustomButton text={'Back to Login'} loading={loading} style={{ width: 300 }} size={'large'} action={() => navigate('/login')} />
                                            </div>
                                    }
                                </>

                        }
                    </div>
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default ConfirmTemporaryPassword;