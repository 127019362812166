import { useMapsLibrary, useMap, Marker } from "@vis.gl/react-google-maps";
import { useEffect, useState, useRef } from "react";
import Separator from "../../../components/Separator";
import LocationAlt from "../../../assets/images/Forager.png";
import './directions.scss';
import ListingList from "components/ListingList";
import CustomButton from "components/CustomButton";
import Icons from "components/Icons";
import ShowingTime from "components/ShowingTime";
import { checkImageUrl } from "utils/modules/Handlers";
import house1 from "assets/images/house1.jpg";
import ListingStatus from "components/ListingStatus";
import NoUser from 'assets/images/no_user.jpg'
import CustomTextInput from "components/CustomTextInput";
import { useNavigate } from "react-router-dom";

const Directions = ({ showings, center, userLocation }) => {
    const map = useMap();
    const navigate = useNavigate();
    const routesLibrary = useMapsLibrary('routes');
    const [directionsService, setDirectionsService] = useState();
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);
    const [routeIndex, setRouteIndex] = useState(0);
    const selected = routes[routeIndex];
    const leg = selected?.legs[0];
    const placesLibrary = useMapsLibrary('places');
    const [autocompleteService, setAutocompleteService] = useState();
    const [predictions, setPredictions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef();
    const [selectedShowing, setSelectedShowing] = useState(null);
    const [showList, setShowList] = useState(false);
    const [showRoute, setShowRoute] = useState(true);
    const [wazeOrigin, setWazeOrigin] = useState(null);
    useEffect(() => {
        if (showings.length > 0) {
            setSelectedShowing(showings[0]);
        }
    }, [showings]);

    // Initialize autocomplete service
    useEffect(() => {
        if (!placesLibrary) return;
        setAutocompleteService(new placesLibrary.AutocompleteService());
    }, [placesLibrary]);

    // Handle input changes and fetch predictions
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.length > 0 && autocompleteService) {
            autocompleteService.getPlacePredictions({ input: value }, (results, status) => {
                const google = window.google;
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setPredictions(results);
                }
            });
        } else {
            setPredictions([]);
        }
    };

    // Handle prediction selection
    const handlePredictionSelect = (prediction) => {
        const google = window.google;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: prediction.description }, (results, status) => {
            console.log(results[0].geometry.location.lat());
            console.log(results[0].geometry.location.lng());
            map.setCenter({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
        });
        setInputValue(prediction.description);
        setPredictions([]);
    };

    // Initialize directions service and renderer
    useEffect(() => {
        if (!routesLibrary || !map) return;
        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({
            map,
            suppressMarkers: false, // This ensures markers are shown
            markerOptions: {
                visible: true
            }
        }));
    }, [routesLibrary, map]);

    // Use directions service
    useEffect(() => {
        if (!directionsService || !directionsRenderer) return;
        const google = window.google;
        // Clear previous markers and directions
        directionsRenderer.setMap(null);
        directionsRenderer.setMap(map);

        let waypoints = [];

        // Only attempt to create route if we have a valid input or showings
        if (!inputValue && !showings.length) {
            directionsRenderer.setMap(null);
            return;
        }

        const createRoute = (originLocation, destinationLocation) => {
            directionsService
                .route({
                    origin: originLocation,
                    destination: destinationLocation,
                    waypoints: waypoints,
                    travelMode: google.maps.TravelMode.DRIVING,
                    provideRouteAlternatives: true
                })
                .then(response => {
                    directionsRenderer.setDirections(response);
                    setRoutes(response.routes);
                    setRouteIndex(0); // Reset to first route when selecting new showing
                })
                .catch(error => {
                    console.error('Directions request failed:', error);
                    directionsRenderer.setMap(null);
                    setRoutes([]);
                });
        };

        if (showings.length > 0) {
            // Ensure we're passing numbers for coordinates
            const origin = inputValue;
            const destination = {
                lat: Number(selectedShowing.listing_data.lat),
                lng: Number(selectedShowing.listing_data.lng)
            };

            // if (showings.length > 2) {
            //     waypoints = showings.slice(1, -1).map(showing => ({
            //         location: {
            //             lat: Number(showing.listing_data.lat),
            //             lng: Number(showing.listing_data.lng)
            //         },
            //         stopover: true
            //     }));
            // }

            if (typeof origin === 'string') {
                // Geocode the address first
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: origin }, (results, status) => {
                    if (status === 'OK') {
                        const originLocation = results[0].geometry.location;
                        createRoute(originLocation, destination);
                    } else {
                        console.error('Geocode was not successful:', status);
                        directionsRenderer.setMap(null);
                        setRoutes([]);
                    }
                });
            } else {
                createRoute(origin, destination);
            }
        }

        return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer, center, showings, inputValue, selectedShowing, map]);

    // Update direction route
    useEffect(() => {
        if (!directionsRenderer) return;
        directionsRenderer.setRouteIndex(routeIndex);
    }, [routeIndex, directionsRenderer]);

    // Add this useEffect to handle geocoding of inputValue
    useEffect(() => {
        if (inputValue) {
            const google = window.google;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: inputValue }, (results, status) => {
                if (status === 'OK') {
                    setWazeOrigin({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    });
                }
            });
        } else {
            setWazeOrigin(null);
        }
    }, [inputValue]);

    // Add this useEffect to set initial input value based on userLocation
    useEffect(() => {
        if (userLocation) {
            const google = window.google;
            const geocoder = new google.maps.Geocoder();
            const latlng = {
                lat: parseFloat(userLocation.latitude),
                lng: parseFloat(userLocation.longitude)
            };

            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    setInputValue(results[0].formatted_address);
                }
            });
        }
    }, [userLocation]);

    console.log(routes, '===routes');

    return (
        <>
            {userLocation && (
                <Marker
                    position={{
                        lat: Number(userLocation.latitude),
                        lng: Number(userLocation.longitude)
                    }}
                    title="Your Location"
                    icon={{ url: LocationAlt, scaledSize: { width: 45, height: 60 } }}
                />
            )}
            {selectedShowing && (
                <div className="directions">
                    <div className="current-showing">
                        <div className="current-showing-content">
                            <ListingList data={selectedShowing.listing_data} />
                            <CustomButton text="Show Routes" size="small" action={() => setShowRoute(true)} />
                        </div>
                        <div className="show-more">
                            <CustomButton type="secondary" iconOnly={<Icons.List color="#5F5F5F" />} style={{ padding: 8 }} action={() => setShowList(true)} />
                        </div>
                    </div>
                    <div className={`showings-list ${showList ? 'show' : ''}`}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ margin: 0, fontSize: 12, fontWeight: 700, color: '#2A2A2A' }}>Showings</p>
                            <p style={{ margin: 0, cursor: 'pointer', color: '#2A2A2A', fontSize: 8, fontWeight: 700 }} onClick={() => setShowList(false)}>Close</p>
                        </div>
                        <Separator height={16} />
                        <ul style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                            {
                                showings.map((showing, index) => (
                                    <li key={index} className="showing-item">
                                        <ListingList data={showing.listing_data} />
                                        <CustomButton
                                            text={selectedShowing.id === showing.id ? "Selected" : "Select"}
                                            size="small"
                                            disabled={selectedShowing.id === showing.id}
                                            action={() => setSelectedShowing(showing)}
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className={`direction-details ${showRoute ? 'show' : ''}`}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
                                <p style={{ margin: 0, fontSize: 12, fontWeight: 700, color: '#2A2A2A' }}>Showing Details</p>
                                <svg
                                    onClick={() => setShowRoute(false)}
                                    style={{ cursor: 'pointer' }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M3.95958 3.95955C4.3501 3.56903 4.98327 3.56903 5.37379 3.95955L8.00002 6.58578L10.6262 3.95955C11.0168 3.56903 11.6499 3.56903 12.0405 3.95955C12.431 4.35007 12.431 4.98324 12.0405 5.37376L9.41423 7.99999L12.0405 10.6262C12.431 11.0167 12.431 11.6499 12.0405 12.0404C11.6499 12.431 11.0168 12.431 10.6262 12.0404L8.00002 9.4142L5.37379 12.0404C4.98327 12.431 4.3501 12.431 3.95958 12.0404C3.56906 11.6499 3.56906 11.0167 3.95958 10.6262L6.58581 7.99999L3.95958 5.37376C3.56906 4.98324 3.56906 4.35007 3.95958 3.95955Z"
                                        fill="#5F5F5F"
                                    />
                                </svg>
                            </div>
                            <Separator height={16} />
                            <div className="showing-details">
                                <img src={checkImageUrl(selectedShowing.listing_data.featured_image?.image_url, house1)} alt="" />
                                <ListingStatus color={selectedShowing.listing_data.listing_status_color} text={selectedShowing.listing_data.listing_status} />
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                                    <p style={{ margin: 0, fontSize: 10, fontWeight: 500, color: '#949494' }}>Listing #{selectedShowing.listing_data.mls_number || selectedShowing.listing_data.id}</p>
                                    <p style={{ margin: 0, fontSize: 16, fontWeight: 700, color: '#2A2A2A' }}>{selectedShowing.listing_data.property_type} in {selectedShowing.listing_data.city}</p>
                                    <p style={{ margin: 0, fontSize: 10, fontWeight: 500, color: '#949494' }}>{selectedShowing.listing_data.address}, {selectedShowing.listing_data.city} {selectedShowing.listing_data.state} {selectedShowing.listing_data.zip_code}</p>
                                </div>
                                <ShowingTime style={{ width: '100%' }} date={selectedShowing.date} start={selectedShowing.start_time} end={selectedShowing.finish_time} status={selectedShowing.status} />
                                <div className="participants">
                                    <p style={{ margin: 0, fontSize: 12, fontWeight: 600, color: '#2A2A2A' }}>Participants</p>
                                    <Separator height={8} />
                                    <ul>
                                        <li>
                                            <img src={checkImageUrl(selectedShowing.listing_data?.agent_data.user_data.profile_photo, NoUser)} alt="" />
                                            <p>{selectedShowing.listing_data?.agent_data.user_data.first_name} {selectedShowing.listing_data?.agent_data.user_data.last_name}</p>
                                        </li>
                                        {
                                            selectedShowing.buyer_agent ?
                                                <li>
                                                    <img src={checkImageUrl(selectedShowing.buyer_agent_data.agent_data.user_data.profile_photo, NoUser)} alt="" />
                                                    <p>{selectedShowing.buyer_agent_data.agent_data.user_data.first_name} {selectedShowing.buyer_agent_data.agent_data.user_data.last_name}</p>
                                                </li>
                                                : <>
                                                    {
                                                        selectedShowing.buyers_data.map((buyer, index) => (
                                                            <li key={index}>
                                                                <img src={checkImageUrl(buyer.profile_photo, NoUser)} alt="" />
                                                                <p>{buyer.first_name} {buyer.last_name}</p>
                                                            </li>
                                                        ))
                                                    }
                                                </>
                                        }
                                        {
                                            selectedShowing.sellers.map((seller, index) => (
                                                <li key={index}>
                                                    <img src={checkImageUrl(seller.profile_photo, NoUser)} alt="" />
                                                    <p>{seller.first_name} {seller.last_name}</p>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <Separator height={16} />
                            <div className="direction-routes">
                                <div className="location-input-wrapper">
                                    <svg
                                        width={12}
                                        height={55}
                                        viewBox="0 0 12 55"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx={6} cy={5} r={4} fill="white" stroke="#5F5F5F" />
                                        <circle cx={6} cy={14} r={1} fill="#5F5F5F" />
                                        <circle cx={6} cy={20} r={1} fill="#5F5F5F" />
                                        <circle cx={6} cy={26} r={1} fill="#5F5F5F" />
                                        <circle cx={6} cy={32} r={1} fill="#5F5F5F" />
                                        <circle cx={6} cy={38} r={1} fill="#5F5F5F" />
                                        <path
                                            d="M6.82866 53.6848C8.07781 52.5879 10.3002 50.3469 10.3002 48.2904C10.3002 47.1525 9.84716 46.0612 9.04075 45.2566C8.23435 44.452 7.14063 44 6.0002 44C4.85976 44 3.76604 44.452 2.95964 45.2566C2.15323 46.0612 1.7002 47.1525 1.7002 48.2904C1.7002 50.3469 3.92186 52.5879 5.17173 53.6848C5.39971 53.8878 5.69461 54 6.0002 54C6.30578 54 6.60068 53.8878 6.82866 53.6848ZM4.56686 48.2904C4.56686 47.9111 4.71787 47.5473 4.98668 47.2791C5.25548 47.0109 5.62005 46.8602 6.0002 46.8602C6.38034 46.8602 6.74491 47.0109 7.01371 47.2791C7.28252 47.5473 7.43353 47.9111 7.43353 48.2904C7.43353 48.6696 7.28252 49.0334 7.01371 49.3016C6.74491 49.5698 6.38034 49.7205 6.0002 49.7205C5.62005 49.7205 5.25548 49.5698 4.98668 49.3016C4.71787 49.0334 4.56686 48.6696 4.56686 48.2904Z"
                                            fill="#FAC91F"
                                        />
                                    </svg>
                                    <div className="location-input">
                                        <div>
                                            <CustomTextInput
                                                inputProps={{
                                                    ref: inputRef,
                                                    type: 'text',
                                                    value: inputValue,
                                                    onChange: handleInputChange,
                                                    placeholder: 'Your location here'
                                                }}
                                                rightSlot={<svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={12}
                                                    height={12}
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M6 4.18182C4.99545 4.18182 4.18182 4.99545 4.18182 6C4.18182 7.00455 4.99545 7.81818 6 7.81818C7.00455 7.81818 7.81818 7.00455 7.81818 6C7.81818 4.99545 7.00455 4.18182 6 4.18182ZM10.0636 5.54545C9.96068 4.62369 9.54734 3.76434 8.8915 3.1085C8.23567 2.45266 7.37631 2.03932 6.45455 1.93636V1.45455C6.45455 1.20455 6.25 1 6 1C5.75 1 5.54545 1.20455 5.54545 1.45455V1.93636C4.62369 2.03932 3.76434 2.45266 3.1085 3.1085C2.45266 3.76434 2.03932 4.62369 1.93636 5.54545H1.45455C1.20455 5.54545 1 5.75 1 6C1 6.25 1.20455 6.45455 1.45455 6.45455H1.93636C2.03932 7.37631 2.45266 8.23567 3.1085 8.8915C3.76434 9.54734 4.62369 9.96068 5.54545 10.0636V10.5455C5.54545 10.7955 5.75 11 6 11C6.25 11 6.45455 10.7955 6.45455 10.5455V10.0636C7.37631 9.96068 8.23567 9.54734 8.8915 8.8915C9.54734 8.23567 9.96068 7.37631 10.0636 6.45455H10.5455C10.7955 6.45455 11 6.25 11 6C11 5.75 10.7955 5.54545 10.5455 5.54545H10.0636ZM6 9.18182C4.24091 9.18182 2.81818 7.75909 2.81818 6C2.81818 4.24091 4.24091 2.81818 6 2.81818C7.75909 2.81818 9.18182 4.24091 9.18182 6C9.18182 7.75909 7.75909 9.18182 6 9.18182Z"
                                                        fill="#5F5F5F"
                                                    />
                                                </svg>}
                                            />
                                            {predictions.length > 0 && (
                                                <ul className="predictions">
                                                    {predictions.map((prediction) => (
                                                        <li
                                                            key={prediction.place_id}
                                                            onClick={() => handlePredictionSelect(prediction)}
                                                        >
                                                            {prediction.description}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <Separator height={8} />
                                        <CustomTextInput
                                            disabled
                                            inputProps={{
                                                type: 'text',
                                                value: `${selectedShowing.listing_data.address} ${selectedShowing.listing_data.city}, ${selectedShowing.listing_data.state} ${selectedShowing.listing_data.zip_code}`,
                                                disabled: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <Separator height={16} />
                                <div className="routes-list">
                                    <div style={{ padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p style={{ margin: 0, fontSize: 12, fontWeight: 700, color: '#2A2A2A' }}>Routes</p>
                                        <div className="route-options" style={{ display: 'flex', gap: '10px' }}>
                                            <a
                                                href={`https://waze.com/ul?ll=${selectedShowing.listing_data.lat},${selectedShowing.listing_data.lng}&navigate=yes${wazeOrigin ? `&from=${wazeOrigin.lat},${wazeOrigin.lng}` : ''}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={20} width={20}>
                                                    <path d="M502.2 201.7C516.7 287.5 471.2 369.6 389 409.8c13 34.1-12.4 70.2-48.3 70.2a51.7 51.7 0 0 1 -51.6-49c-6.4 .2-64.2 0-76.3-.6A51.7 51.7 0 0 1 159 479.9c-33.9-1.4-58-34.8-47-67.9-37.2-13.1-72.5-34.9-99.6-70.8-13-17.3-.5-41.8 20.8-41.8 46.3 0 32.2-54.2 43.2-110.3C94.8 95.2 193.1 32 288.1 32c102.5 0 197.2 70.7 214.1 169.7zM373.5 388.3c42-19.2 81.3-56.7 96.3-102.1 40.5-123.1-64.2-228-181.7-228-83.5 0-170.3 55.4-186.1 136-9.5 48.9 5 131.4-68.8 131.4C58.2 358.6 91.6 378.1 127 389.5c24.7-21.8 63.9-15.5 79.8 14.3 14.2 1 79.2 1.2 87.9 .8a51.7 51.7 0 0 1 78.8-16.4zM205.1 187.1c0-34.7 50.8-34.8 50.8 0s-50.8 34.7-50.8 0zm116.6 0c0-34.7 50.9-34.8 50.9 0s-50.9 34.8-50.9 0zm-122.6 70.7c-3.4-16.9 22.2-22.2 25.6-5.2l.1 .3c4.1 21.4 29.9 44 64.1 43.1 35.7-.9 59.3-22.2 64.1-42.8 4.5-16.1 28.6-10.4 25.5 6-5.2 22.2-31.2 62-91.5 62.9-42.6 0-80.9-27.8-87.9-64.3z" />
                                                </svg>
                                            </a>
                                            <a
                                                href={`https://www.google.com/maps/dir/${wazeOrigin ? `${wazeOrigin.lat},${wazeOrigin.lng}` : ''}/${selectedShowing.listing_data.lat},${selectedShowing.listing_data.lng}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3" height={20} width={20}>
                                                    <path
                                                        fill="#1a73e8"
                                                        d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"
                                                    />
                                                    <path
                                                        fill="#ea4335"
                                                        d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"
                                                    />
                                                    <path
                                                        fill="#4285f4"
                                                        d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"
                                                    />
                                                    <path
                                                        fill="#fbbc04"
                                                        d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"
                                                    />
                                                    <path
                                                        fill="#34a853"
                                                        d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <Separator height={12} />
                                    {
                                        (selected && leg) &&
                                        <>
                                            {
                                                routes.length > 0 ?
                                                    <ul>
                                                        {routes.map((route, index) => (
                                                            <>
                                                                {
                                                                    route.summary ?
                                                                        <li key={index} onClick={() => setRouteIndex(index)} className={routeIndex === index ? 'selected' : ''}>
                                                                            <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={16}
                                                                                    height={16}
                                                                                    viewBox="0 0 16 16"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M6 14C5.26667 14 4.63889 13.7389 4.11667 13.2167C3.59444 12.6944 3.33333 12.0667 3.33333 11.3333V5.88333C2.94444 5.73889 2.62511 5.49733 2.37533 5.15867C2.12556 4.82 2.00044 4.43378 2 4C2 3.44444 2.19444 2.97222 2.58333 2.58333C2.97222 2.19444 3.44444 2 4 2C4.55556 2 5.02778 2.19444 5.41667 2.58333C5.80556 2.97222 6 3.44444 6 4C6 4.43333 5.87511 4.81956 5.62533 5.15867C5.37556 5.49778 5.056 5.73933 4.66667 5.88333V11.3333C4.66667 11.7 4.79733 12.014 5.05867 12.2753C5.32 12.5367 5.63378 12.6671 6 12.6667C6.36622 12.6662 6.68022 12.5358 6.942 12.2753C7.20378 12.0149 7.33422 11.7009 7.33333 11.3333V4.66667C7.33333 3.93333 7.59444 3.30556 8.11667 2.78333C8.63889 2.26111 9.26667 2 10 2C10.7333 2 11.3611 2.26111 11.8833 2.78333C12.4056 3.30556 12.6667 3.93333 12.6667 4.66667V10.1167C13.0556 10.2611 13.3751 10.5029 13.6253 10.842C13.8756 11.1811 14.0004 11.5671 14 12C14 12.5556 13.8056 13.0278 13.4167 13.4167C13.0278 13.8056 12.5556 14 12 14C11.4444 14 10.9722 13.8056 10.5833 13.4167C10.1944 13.0278 10 12.5556 10 12C10 11.5667 10.1251 11.1778 10.3753 10.8333C10.6256 10.4889 10.9449 10.25 11.3333 10.1167V4.66667C11.3333 4.3 11.2029 3.98622 10.942 3.72533C10.6811 3.46444 10.3671 3.33378 10 3.33333C9.63289 3.33289 9.31911 3.46356 9.05867 3.72533C8.79822 3.98711 8.66756 4.30089 8.66667 4.66667V11.3333C8.66667 12.0667 8.40556 12.6944 7.88333 13.2167C7.36111 13.7389 6.73333 14 6 14Z"
                                                                                        fill="#2A2A2A"
                                                                                    />
                                                                                </svg>
                                                                                <p style={{ color: '#2A2A2A', fontSize: 14, fontWeight: 700 }}>via {route.summary} </p>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, textAlign: 'right' }}>
                                                                                <p style={{ color: '#10B980', fontSize: 10, fontWeight: 700 }}>
                                                                                    {route.legs[0].duration?.text}
                                                                                </p>
                                                                                <p style={{ color: '#5F5F5F', fontSize: 10, fontWeight: 500 }}>
                                                                                    {route.legs[0].distance?.text}
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        : <li key={index}>
                                                                            <p>
                                                                                No other routes found
                                                                            </p>
                                                                        </li>
                                                                }
                                                            </>
                                                        ))}
                                                    </ul>
                                                    : <p>No other routes found</p>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="back-button">
                            <CustomButton text="Go Back" type="secondary" size="small" action={() => navigate('/connections/buyers')} />
                        </div>
                    </div>
                </div>
            )}
            {
                (showings.length === 0 && !selectedShowing) &&
                <div className="not-available">
                    <div className="not-available-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={30}
                            height={30}
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M6 14C5.26667 14 4.63889 13.7389 4.11667 13.2167C3.59444 12.6944 3.33333 12.0667 3.33333 11.3333V5.88333C2.94444 5.73889 2.62511 5.49733 2.37533 5.15867C2.12556 4.82 2.00044 4.43378 2 4C2 3.44444 2.19444 2.97222 2.58333 2.58333C2.97222 2.19444 3.44444 2 4 2C4.55556 2 5.02778 2.19444 5.41667 2.58333C5.80556 2.97222 6 3.44444 6 4C6 4.43333 5.87511 4.81956 5.62533 5.15867C5.37556 5.49778 5.056 5.73933 4.66667 5.88333V11.3333C4.66667 11.7 4.79733 12.014 5.05867 12.2753C5.32 12.5367 5.63378 12.6671 6 12.6667C6.36622 12.6662 6.68022 12.5358 6.942 12.2753C7.20378 12.0149 7.33422 11.7009 7.33333 11.3333V4.66667C7.33333 3.93333 7.59444 3.30556 8.11667 2.78333C8.63889 2.26111 9.26667 2 10 2C10.7333 2 11.3611 2.26111 11.8833 2.78333C12.4056 3.30556 12.6667 3.93333 12.6667 4.66667V10.1167C13.0556 10.2611 13.3751 10.5029 13.6253 10.842C13.8756 11.1811 14.0004 11.5671 14 12C14 12.5556 13.8056 13.0278 13.4167 13.4167C13.0278 13.8056 12.5556 14 12 14C11.4444 14 10.9722 13.8056 10.5833 13.4167C10.1944 13.0278 10 12.5556 10 12C10 11.5667 10.1251 11.1778 10.3753 10.8333C10.6256 10.4889 10.9449 10.25 11.3333 10.1167V4.66667C11.3333 4.3 11.2029 3.98622 10.942 3.72533C10.6811 3.46444 10.3671 3.33378 10 3.33333C9.63289 3.33289 9.31911 3.46356 9.05867 3.72533C8.79822 3.98711 8.66756 4.30089 8.66667 4.66667V11.3333C8.66667 12.0667 8.40556 12.6944 7.88333 13.2167C7.36111 13.7389 6.73333 14 6 14Z"
                                fill="#fff"
                            />
                        </svg>
                    </div>
                    <p>Directions are not available because there are no showings for this buyer</p>
                    <CustomButton text="Go Back" type="secondary" action={() => navigate('/connections/buyers')} />
                </div>
            }
        </>
    );
}


export default Directions;