import { useCallback, useEffect, useRef, useState } from 'react'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import actions from 'store/actions'
// Packages
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import moment from 'moment'
import 'moment-timezone';
// Components
import Seprator from 'components/Separator'
// Assets
import './calendarView.scss'
import Icons from 'components/Icons'


const CalendarView = ({ showing, currentShowing, setCurrentShowing }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const calendarRef = useRef();
    const [startTime, setStartTime] = useState(moment().startOf('day').format('HH:mm:ss'))
    const [endTime, setEndTime] = useState(moment().endOf('day').format('HH:mm:ss'))

    useEffect(() => {
        const start = moment().startOf('day').add(showing.listing_data?.availability_start_minutes, 'minute').format('HH:mm:ss');
        const end = moment().startOf('day').add(showing.listing_data?.availability_end_minutes, 'minute').format('HH:mm:ss');

        setStartTime(start)
        setEndTime(end)
    }, [showing]);
    
    const getTimezone = (timezone) => {
        if (timezone) {
            return timezone
        } else {
            return 'America/New_York'
        }
    }
    
    const dayCellClassNames  = (date) => {
        if (date.isPast) {
            console.log(date)
            return ['past-cell'];
        }
    }

    const handleSelect = useCallback((event) => {
        setCurrentShowing({
            id: '1',
            title: 'Showing (Current)',
            start: moment.parseZone(event.start).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment.parseZone(event.end).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            classNames: ['event', 'showing', 'current'],
            editable: true,
        });
    }, []);

    const handleSelectAllow = useCallback((selectInfo) => {
        const check = moment(selectInfo.start).format('yyyy-MM-DD');
        const today = moment().subtract(1, 'days').format('yyyy-MM-DD');
        return !(
            showing.listing_data?.max_showing_window_minutes !== null &&
            (selectInfo.end - selectInfo.start) > (showing.listing_data?.max_showing_window_minutes * 60 * 1000)
        ) && (check > today);
    }, [showing]);

    const convertEvent = (sysEvent) => {
        // if (sysEvent.event_type !== 'showing' && !sysEvent.event_id && !sysEvent.id) return {};
        const mainEvent = sysEvent.listing_event || sysEvent;
        let title = mainEvent.title || mainEvent.event_type.replace('_', ' ') + ` (${mainEvent.status})`
        if (sysEvent.buyer_agent !== user.id) {
            title = 'Blackout'
        }
        const event = {
            id: sysEvent.event_id,
            title: title,
            start: moment.parseZone(sysEvent.start).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment.parseZone(sysEvent.end).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            overlap: false,
            classNames: ['event'],
            editable: false,
            extendedProps: {
                event_type: sysEvent.event_type,
                listing_event: sysEvent.listing_event,
                linked_to_parent: sysEvent.linked_to_parent,
                url: sysEvent.url,
                title: mainEvent.title,
                description: mainEvent.description,
            }
        };
        if (!!sysEvent['listing_event']) {
            event.classNames.push('occurrence');
        }
        if (sysEvent.event_type === 'showing') {
            event.editable = false;
        }

        if (sysEvent.event_type === 'showing' && sysEvent.state !== 'approved' && sysEvent.buyer_agent !== user.id) {
            event.classNames.push('blackout');
        } else {
            event.classNames.push(sysEvent.event_type);
        }
        event.classNames.push(sysEvent.status);
        return event;
    };

    const fetchListingEvents = useCallback(async (info, success, failure) => {
        const data = await dispatch(actions.listing.fetchListingEventsStartEnd({ listing: showing.listing_data?.id, start: info.startStr, end: info.endStr }));
        const events = data
            .filter(event => !event.occurrences_url) // exclude the original event in favor of first occurrence
            .filter(event => event.id || !data.find(other => other.id && other.url === event.url)) // exclude the default occurrence in favor of the custom recurrence
            .map(event => convertEvent(event));
        success([...events, ...[currentShowing].filter(x => x)]);
    }, [showing, currentShowing]);
    
    return (
        <section className="showing-form-calendar-container">
            <div className='header'>
                <Icons.ShowingForm height={15} width={15} color={'#2A2A2A'} />
                <p>Re-Showing Request Form</p>
            </div>
            <div className='info'>
                <p>
                   Set a <span className='showing'>Showing Time</span> on the available slot in the live calendar, click and drag in the calendar to set the time.
                </p>
            </div>
            <Seprator height={30} />
            <div className="calendars">
                <div className="sb-fc">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[ interactionPlugin, dayGridPlugin, timeGridPlugin]}
                        height="auto"
                        initialView="timeGridWeek"
                        headerToolbar={{
                            left: 'dayGridMonth,timeGridWeek,timeGridDay',
                            center: 'title',
                            right: 'prev,next',
                        }}
                        dayCellClassNames={dayCellClassNames}
                        selectable={true}
                        allDaySlot={false}
                        firstDay={1}
                        slotDuration="00:15:00"
                        slotLabelInterval="01:00"
                        slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZSeroMinute: false, meridiem: 'lowercase' }}
                        eventTimeFormat={{ hour: 'numeric', minute: '2-digit', omitZSeroMinute: false, meridiem: 'lowercase' }}
                        slotMinTime={startTime}
                        slotMaxTime={endTime}
                        nowIndicator={true}
                        now={moment().tz(getTimezone(showing.listing_data?.timezone)).format('YYYY-MM-DDTHH:mm:ss')}
                        longPressDelay={500}
                        initialDate={moment().tz(getTimezone(showing.listing_data?.timezone)).format('YYYY-MM-DD')}
                        businessHours={[
                            {
                                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                                startTime: startTime,
                                endTime: endTime,
                            },
                        ]}
                        events={fetchListingEvents}
                        selectOverlap={false}
                        // selectOverlap={(event) => event.classNames.includes('showing-pending')}
                        select={handleSelect}
                        selectAllow={handleSelectAllow}
                        eventAllow={handleSelectAllow}
                        eventDrop={(info) => handleSelect(info.event)}
                        eventResize={(info) => handleSelect(info.event)}
                    />
                </div>
            </div>
        </section>
    );
}


export default CalendarView;