import axios from 'axios';
import Config from './Config';

const Instance = axios.create({
    withCredentials: false,
    baseURL: Config.API_URL,
    headers: {
      "Content-Type": "application/json",
    },
});


export default Instance;