import Icons from 'components/Icons';
import Seprator from 'components/Separator';
import './formNav.scss'
import TeamPlaceholder from 'assets/images/teams-img-placeholder.svg'
import { checkImageUrl } from 'utils/modules/Handlers';
import NoUser from 'assets/images/no_user.jpg'

const FormNav = ({ data }) => {
    return (
        <div className="form-nav-container">
            <img className='team-image' src={TeamPlaceholder} alt="" />
            <Seprator height={30} />
            <div className='team-info'>
                <p>About</p>
                <Seprator height={20} />
                <span>{data.description ? data.description : 'No information about this team.'}</span>
                <Seprator height={30} />
                <p>Creator</p>
                <Seprator height={35} />
                <div className='creator-info'>
                    <img src={checkImageUrl(data.creator?.profile_photo, NoUser)} alt='' />
                    <Seprator width={6} />
                    <div>
                        <p>{data.creator?.first_name} {data.creator?.last_name}</p>
                        <Seprator height={6} />
                        <span>{data.creator?.email}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FormNav;