import debouce from 'lodash.debounce'
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Packages
import Splide from '@splidejs/splide';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
// Utils
import { checkImageUrl, formatCurrency, inputChange } from 'utils/modules/Handlers';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import RequestStepper from 'components/RequestStepper';
import Footer from 'components/Footer';
// Assets
import './showingBeeAgent.scss';
import NoUser from 'assets/images/no_user.jpg'

const ShowingBeeAgent = () => {
    const { id: listingID, mls } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const {featuredAgents: myAgents } = useSelector((state) => state.agent)
    const { showing } = useSelector((state) => state.showing)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const compensation_type = ['All', 'Cash', 'Percent']
    const [filters, setFilters] = useState({
        compensation_type: {
            value: 'All',
        },
        minimum_amount: {
            value: '',
        },
        maximum_amount: {
            value: '',
        },
    })

    const onSearch = useCallback(async() => {
        const { compensation_type, minimum_amount, maximum_amount } = filters
        let filterString = []
        if (compensation_type.value !== 'All') {
            if (compensation_type.value) filterString.push(`compensation_type=${compensation_type.value.toLowerCase()}`)
            if (minimum_amount.value) filterString.push(`minimum_amount=${minimum_amount.value}`)
            if (maximum_amount.value) filterString.push(`maximum_amount=${maximum_amount.value}`)
        }
        setLoad(true);
        try {
            await dispatch(actions.agent.fetchFeaturedAgents({ filters: filterString.join('&') })).then(() => {
                setLoad(false);
            });
        } catch (error) {
            setLoad(false);
            console.log(error)
        }
    }, [filters])

    useEffect(() => {
        onSearch();
    }, [filters])

    useEffect(() => {
        const init = async() => {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id)) 
        }
        init();
    }, [dispatch])

    const onSelectAgent = async(agent, showing) => {
        const body = {
            showing: showing.id,
            buyer_agent: agent.id,
        }
        await dispatch(actions.showing.showingRequestStep2Agent(body))
        navigate(`/request/${mls}/${listingID}/${urlParams.get('next')}`)
    }

    const onTypeChange = (item) => {
        if (item === 'All') {
            document.getElementById('minimum_amount').value = ''
            document.getElementById('maximum_amount').value = ''
            setFilters({
                compensation_type: {
                    value: 'All',
                },
                minimum_amount: {
                    value: '',
                },
                maximum_amount: {
                    value: '',
                },
            })
        } else {
            inputChange({ setForm: setFilters, key: 'compensation_type', value: 'value', data: item })
        }
    }
    const onChangeInput = (event) => {
        inputChange({ setForm: setFilters, key: event.target.name, value: 'value', data: event.target.value })
    }

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 100)
    }, [filters])

    return (
        <section className="featured-agents-container">
            {/* Back & Next */}
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p className='back-button' onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`)}>Back</p>
                    <h1 className="title">Showing Bee Agents</h1>
                </div>
                <p className='subtitle'>Select an agent for you.</p>
            </div>
            <div className="grid-container">
                <div className="filters">
                    <div className='contents'>
                        <div className='filter-block'>
                            <div className='header'>
                                <Icons.Compensation color={'#FAC91F'} />
                                <p>Compensation Type</p>
                            </div>
                            <div className='type-choices'>
                                {
                                    compensation_type.map((item, index) => {
                                        return ( 
                                        <div 
                                            key={index}
                                            className={`choice ${filters.compensation_type.value === item && 'selected'}`}
                                            onClick={(event) => onTypeChange(item)}
                                        >
                                        <p>{item}</p>
                                        </div> )
                                    })
                                }
                            </div>
                        </div>
                        <Separator width={20} />
                        <div className='filter-block'>
                            <div className='header'>
                                <Icons.CompensationAmount color={'#FAC91F'} />
                                <p>Compensation Amount</p>
                            </div>
                            <Separator height={10} />
                            <div className='max-min-input'>
                                <input id="minimum_amount" name='minimum_amount' type="text" onChange={handleInput} placeholder="Min" disabled={filters.compensation_type.value === 'All'} />
                                <p className='separator'>to</p>
                                <input id="maximum_amount" name='maximum_amount' type="text" onChange={handleInput} placeholder="Max" disabled={filters.compensation_type.value === 'All'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="agents">
                    {
                        !load
                        ? <div className='contents'>
                        {
                            myAgents.length > 0 
                            ? <ResponsiveMasonry
                                columnsCountBreakPoints={{350: 1, 750: 2, 900: myAgents.length > 3 ? 4 : 3}}
                            >
                                <Masonry gutter={'40px'}>
                                { 
                                    myAgents.map(agent => (
                                        <div key={agent.id} className="agent_card">
                                            <div className="selectbox agent" onClick={() => onSelectAgent(agent, showing)}>
                                                <div className="agent-company">
                                                    <img src={agent.company_logo} alt="" />
                                                </div>
                                                <div className="agent-profile--image solo">
                                                    <div className="agent-profile--avatar" style={{backgroundImage: `url(${checkImageUrl(agent.profile_photo, NoUser)})`}}>
                                                        {agent.verified_buyer_agent && <Icons.MiniVerified width={24} height={24} />}
                                                    </div>
                                                </div>
                                                <Separator height={10} />
                                                <div className="agent-profile--info">
                                                    <p className="name">{agent.first_name} {agent.last_name}</p>
                                                    <Separator height={5} />
                                                    <span className="company-name">{agent.company_name ? agent.company_name : 'Showing Bee'}</span>
                                                    <Separator height={10} />
                                                    {
                                                        (agent.agent_data?.buyer_agent_compensation_type === 'cash' && agent.agent_data?.buyer_agent_compensation_cash)
                                                        && <>
                                                            <div className='compensation'>
                                                                <Icons.Compensation width={12} color={'#FAC91F'} />
                                                                <Separator width={5} />
                                                                <span className="company-name">{formatCurrency(agent.agent_data?.buyer_agent_compensation_cash)}</span>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        (agent.agent_data?.buyer_agent_compensation_type === 'percent' && agent.agent_data?.buyer_agent_compensation_percent)
                                                        && <>
                                                            <div className='compensation'>
                                                                <Icons.Compensation width={12} color={'#FAC91F'} />
                                                                <Separator width={5} />
                                                                <span className="company-name">{`${agent.agent_data?.buyer_agent_compensation_percent}%`}</span>
                                                            </div>
                                                        </>
                                                    }
                                                    <Separator height={20} />
                                                    <div>
                                                        <div className="email">
                                                        <Icons.EmailSmall height={13} color={'#6C767C'}/>
                                                            <Separator width={10} />
                                                            <span>{agent.email || 'Not Available'}</span>
                                                        </div>
                                                        <Separator height={10} />
                                                        <div className="phone">
                                                            <Icons.PhoneSmall height={13} color={'#6C767C'} />
                                                            <Separator width={10} />
                                                            <span>{agent.phone_number || 'Not Available'}</span>
                                                        </div>
                                                    </div>
                                                    <Separator height={20} />
                                                </div>                         
                                            </div>
                                        </div>
                                    ))
                                }
                                </Masonry>
                            </ResponsiveMasonry>
                            : <p className='no-result'>No Featured Agent Available</p>
                        }
                        </div>
                        : <div className='contents'>
                            <div className="loader">
                                <Icons.Loading background={'none'} height={100} width={100} />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Separator height={100} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Separator height={90} />
            <Footer subfooter />
        </section>
    )
}


export default ShowingBeeAgent;