import Seprator from 'components/Separator'
import './questionnairePreview.scss'

const QuestionnairePreview = ({ modal, setModal, selectedData }) => {
    return (
        <div className={`questionnaire-preview-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Mortgage Questionnaire
                </p>
                <span className='section-subtitle'>Fill out the following questions to proceed.</span>
                <Seprator height={30} />
                <p className='approval-pill'>Automatic Queen Bee Approval</p>
                <Seprator height={20} />
                <span className='sublabel'>
                    (If either one of the following are checked off by MLO, the only step is to confirm the Pre-approval letter has been uploaded and then MLO must certify through electronic signature):
                </span>
                <Seprator height={30} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        disabled={true}
                        defaultChecked={selectedData.ai_approval}
                    /> AI Approval
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        disabled={true}
                        defaultChecked={selectedData.vetted_finanace_buyer}
                    /> Vetted Finance Buyer (Underwriting has already approved)
                </div>
                <span className='sublabel option'>Must Upload an underwriting-issued Buyer Ready Letter</span>
                <Seprator height={50} />
                <p className='approval-pill'>Manual Queen Bee Approval</p>
                <Seprator height={30} />
                <span className='sublabel'>
                    If none of the two above, the MLO must check <mark>ALL</mark> the following:
                </span>
                <Seprator height={30} />

                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_one}
                    /> The borrower's DTI ratio lower than 45%.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_two}
                    /> Income Docs (tax returns, W-2, Paystubs).
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_three}
                    /> Credit Pull has been performed.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_four}
                    /> Affirmative response from "Findings" (DU/LP).
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_five}
                    /> Underwriting has reviewed the loan docs for this buyer.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_six}
                    /> Credit has been pulled in the last 60 days.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_seven}
                    /> If borrower is self-employed an underwriter or a qualified loan person in your organization has reviewed the borrower's tax returns.
                </div>
                <Seprator height={15} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        disabled={true}
                        className='small'
                        defaultChecked={selectedData.question_eight}
                    /> Borrower has 6-month cash reserves
                </div>

                <Seprator height={50} />
                <span className='sublabel'>
                    Does the borrower have a student loan? If <mark className='colored'>yes</mark>:
                </span>
                <Seprator height={30} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        disabled={true}
                        defaultChecked={selectedData.question_nine}
                    /> If borrower has student loan, the buyer's loan balance and DTI ratio has been carefully evaluated, adjusting for the exact requirements of the loan type.
                </div>

                <Seprator height={50} />
                <span className='sublabel'>
                    <mark className='colored'>Pre-Approval</mark> Letter must be uploaded (either it's already there and MLO verifies or MLO can upload themselves
                </span>
                <Seprator height={30} />
                <div className='options'>
                    <input 
                        type='checkbox' 
                        className='small'
                        disabled={true}
                        defaultChecked={selectedData.question_ten}
                    /> I certify that I have confirmed and verified the above criteria and deem this buyer as being highly likely to close this loan based on my findings.
                </div>
                <Seprator height={50} />
                <div className='fotenote'>
                    <p>{selectedData.mortgage_broker?.first_name} {selectedData.mortgage_broker?.last_name}</p>
                    <span>Mortgage Broker</span>
                </div>
                <Seprator height={50} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Close</button>
            </div>
        </div>
    )
}


export default QuestionnairePreview;