import * as React from "react"

const RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M13.125 5.625L19.875 12.375M19.875 12.375L13.125 19.125M19.875 12.375L4.125 12.375"
      stroke={props.color || "white"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RightArrow
