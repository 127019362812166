// Components
import CustomButton from 'components/CustomButton';
import CustomTextInput from 'components/CustomTextInput';
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import ToastMessage from 'components/ToastMessage';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
// Utils
import { getErrorMessageSignup, inputChange, validateEmail } from 'utils/modules/Handlers';

const InformationForm = ({ onChangeInput, form, setForm, setActive }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const onNext = useCallback(async (event) => {
        const { first_name, last_name, email } = form

        event.preventDefault();
        let isValid = true

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }

        if (isValid) {
            setIsLoading(true);
            try {
                await dispatch(actions.auth.validateEmail({ email: email.value })).then(() => {
                    setIsLoading(false);
                    setActive('account')
                })
            } catch (error) {
                inputChange({ setForm: setForm, key: 'email', value: 'error', data: getErrorMessageSignup(error.response.data) })
                setIsLoading(false);
            }
        }
    }, [form])

    return (
        <form className='info-container' onSubmit={(event) => onNext(event)}>
            <p className='header'>Let’s create your account</p>
            <span className='subheader'>Fill in your details and we'll set you up quickly</span>
            <Separator height={40} />
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, alignItems: 'center' }}>
                <CustomTextInput
                    inputProps={{
                        type: "text",
                        placeholder: 'First Name',
                        id: 'first_name',
                        value: form.first_name.value,
                        onChange: (event) => onChangeInput({ event, type: "first_name" }),
                    }}
                    size={'large'}
                    style={{ width: 300 }}
                    error={form.first_name.error}
                />
                <CustomTextInput
                    inputProps={{
                        type: "text",
                        placeholder: 'Last Name',
                        id: 'last_name',
                        value: form.last_name.value,
                        onChange: (event) => onChangeInput({ event, type: "last_name" }),
                    }}
                    size={'large'}
                    style={{ width: 300 }}
                    error={form.last_name.error}
                />
                <CustomTextInput
                    inputProps={{
                        type: "wmail",
                        placeholder: 'Email Address',
                        id: 'email',
                        value: form.email.value,
                        onChange: (event) => onChangeInput({ event, type: "email" }),
                    }}
                    size={'large'}
                    style={{ width: 300 }}
                    error={form.email.error}
                />
                <CustomButton text={'Next'} style={{ width: 300 }} size={'large'} loading={isLoading} />
            </div>
        </form>
    )
}


export default InformationForm;