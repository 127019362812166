import * as React from "react"

const BuyerBadge = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 103.154 125"
    {...props}
  >
    <defs>
      <style>
        {
          ".buyer-badge-b{clip-path:url(#buyer-badge-a);}"
        }
      </style>
      <clipPath id="buyer-badge-a">
        <rect 
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          width={103.154}
          height={125}
        />
      </clipPath>
    </defs>
    <g transform="translate(227.564 -279.424)">
      <g className="buyer-badge-b" transform="translate(-227.565 279.424)">
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M118.722,289.533a4.087,4.087,0,0,1-3.576-2.065l-5.228-9.055a4.13,4.13,0,0,1,3.576-6.195H123.95a4.13,4.13,0,0,1,3.576,6.195l-5.228,9.055a4.088,4.088,0,0,1-3.576,2.065"
          transform="translate(-67.106 -167.044)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M119.956,270.743a1.618,1.618,0,0,1,1.4,2.427l-5.228,9.055a1.618,1.618,0,0,1-2.8,0L108.1,273.17a1.618,1.618,0,0,1,1.4-2.427Zm0-5.023H109.5a6.641,6.641,0,0,0-5.751,9.961l5.228,9.055a6.641,6.641,0,0,0,11.5,0l5.228-9.055a6.641,6.641,0,0,0-5.751-9.961"
          transform="translate(-63.112 -163.056)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M90.4,195.013c-15,0-27.194-13.413-27.194-29.9s12.2-29.9,27.194-29.9,27.194,13.413,27.194,29.9-12.2,29.9-27.194,29.9"
          transform="translate(-38.787 -82.972)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M114.7,151.184c-3.844-13.036-15.057-22.467-28.282-22.467s-24.434,9.431-28.278,22.467a34.989,34.989,0,0,0-1.426,9.941q0,1.756.17,3.466c1.584,16.27,14.2,28.946,29.533,28.946s27.953-12.676,29.537-28.946q.168-1.709.17-3.466a34.991,34.991,0,0,0-1.426-9.941m-28.282,37.33c-10.976,0-20.3-7.994-23.5-19.013a30.316,30.316,0,0,1-.522-14.678c2.569-12.074,12.367-21.084,24.02-21.084s21.455,9.014,24.024,21.088a30.3,30.3,0,0,1-.522,14.674c-3.2,11.019-12.522,19.013-23.5,19.013"
          transform="translate(-34.801 -78.986)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M116.123,196.808q0,1.756-.17,3.466a37.407,37.407,0,0,1-6.035,4.911,42.151,42.151,0,0,1-23.5,6.611,42.127,42.127,0,0,1-23.5-6.611,37.236,37.236,0,0,1-6.035-4.911q-.168-1.71-.17-3.466a34.988,34.988,0,0,1,1.426-9.941,28.657,28.657,0,0,0,4.258,3.64c5.092,3.578,12.735,6.607,24.02,6.607s18.932-3.029,24.024-6.6a28.894,28.894,0,0,0,4.258-3.643,34.989,34.989,0,0,1,1.426,9.941"
          transform="translate(-34.801 -114.669)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M23.39,174.013c-6.773,0-12.449-3-15.181-8.033-4.547-8.37.262-19.8,10.721-25.484,8.994-4.886,15.767-7.261,20.709-7.261,3.517,0,5.813,1.211,7.226,3.813C49,140.982,48.973,148,46.792,154.918c-2.323,7.372-6.573,13.33-11.37,15.936a25.351,25.351,0,0,1-12.032,3.159"
          transform="translate(-3.985 -81.758)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M35.654,126.735v5.023c3.216,0,4.313,1.2,5.018,2.5,1.8,3.313,1.695,9.71-.261,15.917-2.132,6.766-5.935,12.181-10.173,14.484A22.838,22.838,0,0,1,19.4,167.513c-5.838,0-10.688-2.512-12.974-6.721a13.149,13.149,0,0,1-.237-11.376,21.786,21.786,0,0,1,9.95-10.7c8.5-4.616,15.06-6.957,19.51-6.957Zm0,0c-6.483,0-14.713,3.658-21.907,7.566C2.051,140.654-3.2,153.588,2.017,163.19c3.293,6.062,9.969,9.346,17.388,9.346a27.851,27.851,0,0,0,13.231-3.464c11.694-6.353,17.666-27.61,12.45-37.212-2.008-3.695-5.378-5.125-9.433-5.125"
          transform="translate(0 -77.769)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M176.964,174.013a25.35,25.35,0,0,1-12.033-3.159c-4.8-2.606-9.047-8.563-11.37-15.936-2.181-6.922-2.209-13.937-.072-17.871,1.413-2.6,3.709-3.813,7.226-3.813,4.941,0,11.715,2.375,20.709,7.261,10.458,5.682,15.268,17.114,10.721,25.484-2.733,5.03-8.408,8.033-15.18,8.033Z"
          transform="translate(-93.215 -81.758)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M156.708,131.758c4.449,0,11.013,2.341,19.51,6.957a21.786,21.786,0,0,1,9.95,10.7,13.149,13.149,0,0,1-.237,11.376c-2.286,4.208-7.136,6.721-12.974,6.721a22.838,22.838,0,0,1-10.834-2.854c-4.238-2.3-8.041-7.717-10.173-14.484-1.956-6.208-2.06-12.6-.261-15.917.705-1.3,1.8-2.5,5.02-2.5m0-5.023c-4.055,0-7.425,1.43-9.433,5.125-5.216,9.6.756,30.859,12.45,37.212a27.851,27.851,0,0,0,13.231,3.464c7.419,0,14.095-3.284,17.388-9.346,5.217-9.6-.035-22.536-11.729-28.889-7.194-3.908-15.425-7.566-21.908-7.566"
          transform="translate(-89.208 -77.769)"
        />
        <path
          fill="none"
          stroke={props.color || "#fac91f"}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5px"
          d="M85.187,39.574s-2.126-17.45-19.845-23.593"
          transform="translate(-40.096 -9.807)"
        />
        <path
          fill="none"
          stroke={props.color || "#fac91f"}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5px"
          d="M150.483,39.574s2.126-17.45,19.845-23.593"
          transform="translate(-92.342 -9.807)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M53.886,11.99a2.745,2.745,0,1,1,2.745-2.745,2.748,2.748,0,0,1-2.745,2.745"
          transform="translate(-31.382 -3.989)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M49.9,5.023a.234.234,0,1,1-.234.234.234.234,0,0,1,.234-.234M49.9,0a5.256,5.256,0,1,0,5.256,5.256A5.256,5.256,0,0,0,49.9,0"
          transform="translate(-27.393 0)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M204.582,11.99a2.745,2.745,0,1,1,2.745-2.745,2.748,2.748,0,0,1-2.745,2.745"
          transform="translate(-123.855 -3.989)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M200.593,5.023a.234.234,0,1,1-.234.234.234.234,0,0,1,.234-.234m0-5.023a5.256,5.256,0,1,0,5.257,5.256A5.256,5.256,0,0,0,200.593,0"
          transform="translate(-119.866 0)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M99.31,108.616c-11.9,0-21.586-7.749-21.586-17.274S87.407,74.068,99.31,74.068,120.9,81.817,120.9,91.342s-9.683,17.274-21.586,17.274"
          transform="translate(-47.694 -45.451)"
        />
        <path
          className="buyer-badge-a"
          fill={props.color || "#fac91f"}
          d="M95.322,72.59a21.787,21.787,0,0,1,13.852,4.654,12.395,12.395,0,0,1,0,20.217,22.94,22.94,0,0,1-27.7,0,12.395,12.395,0,0,1,0-20.217A21.787,21.787,0,0,1,95.322,72.59m0-5.023c-13.309,0-24.1,8.858-24.1,19.786s10.788,19.786,24.1,19.786,24.1-8.858,24.1-19.786-10.788-19.786-24.1-19.786"
          transform="translate(-43.706 -41.462)"
        />
      </g>
    </g>
  </svg>
)


export default BuyerBadge
