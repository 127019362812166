import NoUser from 'assets/images/no_user.jpg'
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import './brokerCard.scss';
import { checkImageUrl } from 'utils/modules/Handlers';

const BrokerCard = ({ data, children, style }) => {
    return (
        <div className='broker-card-component' style={style}>
            <div className='content'>
                <img src={checkImageUrl(data.profile_photo, NoUser)} alt={data.first_name + ' ' + data.last_name} />
                <Separator height={16} />
                <div className='badge'>
                    <Icons.BrokerBadge height={44} width={44} />
                    <div style={{display: 'flex', flexDirection: 'column', rowGap: 4}}>
                        <p style={{fontSize: 14, fontWeight: 700, color: '#2A2A2A'}}>{data.first_name} {data.last_name}</p>
                        <p style={{fontSize: 12, fontWeight: 400, color: '#949494'}}>Mortgage Broker</p>
                    </div>
                </div>
                <Separator height={24} />
                <p style={{fontSize: 12, fontWeight: 400, color: '#6C767C'}}>{data.email || 'No Email'}</p>
                <p style={{fontSize: 12, fontWeight: 400, color: '#6C767C'}}>{data.phone_number || 'No Phone Number'}</p>
            </div>
            {children}
        </div>
    )
}

export default BrokerCard;