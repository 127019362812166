import * as React from "react";

const CircleDelete = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#F9F9F9" />
    <g clipPath="url(#clip0_1085_5924)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.52 9.33333C17.7998 9.33341 18.0726 9.42152 18.2995 9.58521C18.5265 9.74889 18.6962 9.97983 18.7847 10.2453L19.1467 11.3333H21.3333C21.5101 11.3333 21.6797 11.4036 21.8047 11.5286C21.9298 11.6536 22 11.8232 22 12C22 12.1768 21.9298 12.3464 21.8047 12.4714C21.6797 12.5964 21.5101 12.6667 21.3333 12.6667L21.3313 12.714L20.7533 20.8093C20.7173 21.3137 20.4915 21.7858 20.1214 22.1304C19.7513 22.475 19.2644 22.6666 18.7587 22.6667H13.2413C12.7356 22.6666 12.2487 22.475 11.8786 22.1304C11.5085 21.7858 11.2827 21.3137 11.2467 20.8093L10.6687 12.7133C10.6675 12.6978 10.6668 12.6822 10.6667 12.6667C10.4899 12.6667 10.3203 12.5964 10.1953 12.4714C10.0702 12.3464 10 12.1768 10 12C10 11.8232 10.0702 11.6536 10.1953 11.5286C10.3203 11.4036 10.4899 11.3333 10.6667 11.3333H12.8533L13.2153 10.2453C13.3038 9.97973 13.4736 9.7487 13.7007 9.58501C13.9278 9.42132 14.2007 9.33327 14.4807 9.33333H17.52ZM14 14.6667C13.8367 14.6667 13.6791 14.7266 13.5571 14.8351C13.4351 14.9436 13.3571 15.0932 13.338 15.2553L13.3333 15.3333V19.3333C13.3335 19.5033 13.3986 19.6667 13.5152 19.7902C13.6319 19.9138 13.7913 19.9882 13.9609 19.9981C14.1306 20.0081 14.2976 19.9529 14.4279 19.8438C14.5582 19.7348 14.6419 19.5801 14.662 19.4113L14.6667 19.3333V15.3333C14.6667 15.1565 14.5964 14.987 14.4714 14.8619C14.3464 14.7369 14.1768 14.6667 14 14.6667ZM18 14.6667C17.8232 14.6667 17.6536 14.7369 17.5286 14.8619C17.4036 14.987 17.3333 15.1565 17.3333 15.3333V19.3333C17.3333 19.5101 17.4036 19.6797 17.5286 19.8047C17.6536 19.9298 17.8232 20 18 20C18.1768 20 18.3464 19.9298 18.4714 19.8047C18.5964 19.6797 18.6667 19.5101 18.6667 19.3333V15.3333C18.6667 15.1565 18.5964 14.987 18.4714 14.8619C18.3464 14.7369 18.1768 14.6667 18 14.6667ZM17.52 10.6667H14.48L14.258 11.3333H17.742L17.52 10.6667Z"
        fill="#5F5F5F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1085_5924">
        <rect width={16} height={16} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);


export default CircleDelete;
