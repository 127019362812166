import * as React from "react";


const Bee = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.751}
    height={18.44}
    viewBox="0 0 14.751 18.44"
    {...props}
  >
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M6.658,31.151a4.857,4.857,0,0,1-1.991,3.412,3.893,3.893,0,0,1-1.892.511A2.721,2.721,0,0,1,.288,33.7a3.326,3.326,0,0,1,1.677-4.261,12.4,12.4,0,0,1,1.66-.8A3.68,3.68,0,0,0,6.658,31.151Z"
      transform="translate(0 -21.095)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M37.095,33.709a2.721,2.721,0,0,1-2.486,1.379,3.883,3.883,0,0,1-1.892-.511,4.855,4.855,0,0,1-1.991-3.411,3.683,3.683,0,0,0,3.042-2.51,12.461,12.461,0,0,1,1.65.791,3.326,3.326,0,0,1,1.677,4.261Z"
      transform="translate(-22.632 -21.108)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M21.244,42.578a4.22,4.22,0,0,1-3.771,2.578,3.98,3.98,0,0,1-2.849-1.235,4.769,4.769,0,0,1-.923-1.346,4.3,4.3,0,0,0,1.256-.474,5.287,5.287,0,0,0,2.2-3.721l0-.018c.1.007.206.011.311.011s.2,0,.3-.01l0,.019a5.288,5.288,0,0,0,2.2,3.718,4.329,4.329,0,0,0,1.267.477Z"
      transform="translate(-10.092 -28.257)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M25.716,65.016l-.461.824a.968.968,0,0,1-.348.358.934.934,0,0,1-1.3-.358l-.461-.824a4.227,4.227,0,0,0,2.566,0Z"
      transform="translate(-17.051 -47.889)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M18.384,20.456a3.215,3.215,0,0,0,3.446-2.919,3.215,3.215,0,0,0-3.446-2.919,3.215,3.215,0,0,0-3.446,2.919A3.215,3.215,0,0,0,18.384,20.456Z"
      transform="translate(-11.003 -10.767)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M15.384,6.17a.419.419,0,0,1-.411-.375,4,4,0,0,0-2.563-3.129.431.431,0,0,1-.256-.544.411.411,0,0,1,.527-.264A4.856,4.856,0,0,1,15.8,5.691a.425.425,0,0,1-.361.476.379.379,0,0,1-.051,0Z"
      transform="translate(-8.936 -1.352)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M30.4,6.167a.406.406,0,0,1-.052,0,.425.425,0,0,1-.36-.475,4.856,4.856,0,0,1,3.113-3.832.411.411,0,0,1,.527.264.431.431,0,0,1-.256.544,4,4,0,0,0-2.563,3.13.421.421,0,0,1-.411.374Z"
      transform="translate(-22.089 -1.349)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M10.117,0a.764.764,0,0,0-.751.775.752.752,0,1,0,1.5,0A.764.764,0,0,0,10.117,0Z"
      transform="translate(-6.898)"
    />
    <path
      className="a"
      fill={props.color || "#6c767c"}
      d="M41.722,0a.764.764,0,0,0-.751.775.752.752,0,1,0,1.5,0A.764.764,0,0,0,41.722,0Z"
      transform="translate(-30.178)"
    />
  </svg>
)

export default Bee;
