import { useCallback, useEffect, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
// Assets
import './accountDeletionForm.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import DeleteAccountIllus from 'assets/images/delete-acc.svg'
import Icons from 'components/Icons';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
import ConfirmRequest from 'components/ConfirmRequest';
import Footer from 'components/Footer';
import Logo from 'components/Logo';
import CustomTextInput from 'components/CustomTextInput';
import CustomButton from 'components/CustomButton';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';

const AccountDeletionForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async (event) => {
        let isValid = true

        const { first_name, last_name, email } = form;
        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }
        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }
        if (isValid) {
            setLoading(true);
            try {
                const data = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                }
                await dispatch(actions.auth.accountDeletionRequest(data));
                setLoading(false);
                setSubmit(true);
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }

    }, [form])

    return (
        <>
            {
                !submit ?
                    <>
                        <PageWrapper>
                            <CustomContainer>
                                <div className='account-deletion-container'>
                                    <div className='account-deletion-form'>
                                        <Logo style={{ height: 40 }} action={() => navigate('/')} />
                                        <Seprator height={80} />
                                        {
                                            loading
                                                ? <div className='loading'>
                                                    <Icons.Loading />
                                                </div>
                                                : <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24, alignItems: 'center' }}>
                                                    <h1 className='header'>Account Deletion Request</h1>
                                                    <h2 className='subheader' style={{ lineHeight: '35px' }}>Please filled out the form with the information associated with your Showing Bee Account.</h2>
                                                    <img
                                                        className='illus'
                                                        src={DeleteAccountIllus}
                                                        alt=""
                                                    />
                                                    <Seprator height={40} />
                                                    <CustomTextInput
                                                        inputProps={{
                                                            type: "text",
                                                            placeholder: 'First Name',
                                                            id: 'first_name',
                                                            onChange: (event) => onChangeInput({ event, type: "first_name" }),
                                                        }}
                                                        size={'large'}
                                                        style={{ width: 300 }}
                                                        error={form.first_name.error}
                                                    />

                                                    <CustomTextInput
                                                        inputProps={{
                                                            type: "text",
                                                            placeholder: 'Last Name',
                                                            id: 'last_name',
                                                            onChange: (event) => onChangeInput({ event, type: "last_name" }),
                                                        }}
                                                        size={'large'}
                                                        style={{ width: 300 }}
                                                        error={form.last_name.error}
                                                    />

                                                    <CustomTextInput
                                                        inputProps={{
                                                            type: "email",
                                                            placeholder: 'Email Address',
                                                            id: 'email',
                                                            onChange: (event) => onChangeInput({ event, type: "email" }),
                                                        }}
                                                        size={'large'}
                                                        style={{ width: 300 }}
                                                        error={form.email.error}
                                                    />

                                                    <CustomButton text={'Submit'} loading={loading} style={{ width: 300 }} size={'large'} action={onSubmit} />
                                                </div>
                                        }

                                        <Seprator height={50} />
                                    </div>
                                </div>
                            </CustomContainer>
                        </PageWrapper>

                        <Footer />
                    </>
                    : <ConfirmRequest header={'We received your Request.'} subheader={'Thank you for using Showing Bee.'} />
            }
        </>
    )
}


export default AccountDeletionForm;