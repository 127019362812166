import axios from "axios";
import { Config } from "utils";


const Instance = axios.create({
    // withCredentials: false,
    baseURL: 'https://api.talkjs.com',
    headers: {
      "Authorization": `Bearer ${Config.TalkJS_KEY}`,
      "Content-Type": "application/json",
    },
});

const InstanceMultipart = axios.create({
    // withCredentials: false,
    baseURL: 'https://api.talkjs.com',
    headers: {
      "Authorization": `Bearer ${Config.TalkJS_KEY}`,
      "Content-Type": "multipart/form-data",
    },
});


const fetchConversations = (id) => {
    return Instance.get(`/v1/${Config.TalkJS_APP_ID}/users/${id}/conversations`);
}

const sendMessage = ({ id, data }) => {
    return Instance.post(`/v1/${Config.TalkJS_APP_ID}/conversations/${id}/messages`, data);
}

const createConversation = ({ id, data }) => {
    return Instance.put(`/v1/${Config.TalkJS_APP_ID}/conversations/${id}`, data);
}

const updateCreateUser = ({id, data}) => {
    return Instance.put(`/v1/${Config.TalkJS_APP_ID}/users/${id}`, data);
}

const uploadFiles = ({data}) => {
    return InstanceMultipart.post(`/v1/${Config.TalkJS_APP_ID}/files/`, data);
}


const message = {
    fetchConversations,
    sendMessage,
    createConversation,
    updateCreateUser,
    uploadFiles,
}


export default message;
