import * as React from "react"

const Add = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <g
      id="Add_Icon"
      data-name="Add Icon"
      transform="translate(-1235.5 -365.027)"
    >
      <path
        id="Icon_material-do-not-disturb-on"
        data-name="Icon material-do-not-disturb-on"
        d="M28,3A25,25,0,1,0,53,28,25.009,25.009,0,0,0,28,3ZM40.5,30.5h-25v-5h25Z"
        transform="translate(1232.5 362.027)"
        fill="#fac91f"
      />
      <rect
        id="Rectangle_659"
        data-name="Rectangle 659"
        width={25}
        height={5}
        transform="translate(1263 378.008) rotate(90)"
        fill="#fff"
      />
    </g>
  </svg>
)


export default Add;
