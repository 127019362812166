// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './confirm.scss'
import Icons from "components/Icons";

const ConfirmFeedback = () => {
    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="confirm-container">
                <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} />
                <Seprator height={50} />
                <p className="header">We received your feedback!</p>
                <Seprator height={26} />
                <p className="subheader">Thank you for using Showing Bee.</p>
            </div>
        </Section>
    )
}


export default ConfirmFeedback;