import Separator from 'components/Separator'
import './dashboard.scss'
import { useEffect } from 'react'
import { CountUp } from 'countup.js'
import actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import DashboardFiltersContainer from 'components/DashboardFiltersContainer'
import { useNavigate } from 'react-router-dom'
import { checkImageUrl } from 'utils/modules/Handlers'
import NoUser from 'assets/images/no_user.jpg'
import moment from 'moment'


const MortgageDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth)
    const { mortgageConnections: { data } } = useSelector((state) => state.buyer)

    useEffect(() => {
        const init = async () => {
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
        }
        init();
    }, [])

    useEffect(() => {
        const init = async () => {
            await dispatch(actions.broker.brokerDashboardData()).then((data) => {
                const totalEl = document.getElementById('total')
                const pendingEl = document.getElementById('pending')
                const approvedEl = document.getElementById('approved')
                const amountEl = document.getElementById('amount')
                const queenEl = document.getElementById('queen')
                const missingDocumentEl = document.getElementById('missing-document')
                const expiredDocumentEl = document.getElementById('expired-document')

                const total = new CountUp(totalEl, data.total);
                const pending = new CountUp(pendingEl, data.pending);
                const approved = new CountUp(approvedEl, data.approved);
                const amount = new CountUp(amountEl, data.missingAmount);
                const queen = new CountUp(queenEl, data.queenBees);
                const missingDocument = new CountUp(missingDocumentEl, data.missingAmount);
                const expiredDocument = new CountUp(expiredDocumentEl, data.expiringDocs);

                pending.start();
                total.start();
                approved.start();
                amount.start();
                queen.start();
                missingDocument.start();
                expiredDocument.start();
            })

        }

        init();
    })

    const checkStatus = (item) => {
        if (item.approval_document) {
            if (item.date_approved) {
                return <span style={{ color: '#74A3EC' }}>Approved</span>
            } else if (item.date_cancelled) {
                return <span style={{ color: '#949494' }}>Cancelled</span>
            } else if (item.date_rejected) {
                return <span style={{ color: '#EF4546' }}>Rejected</span>
            } else { return <span style={{ color: '#70D6EC' }}>Waiting for Approval</span> }
        } else {
            return <span style={{ color: '#70D6EC' }}>Upload Pre-Approval Letter</span>
        }
    }

    return (
        <div className='mortgage-dashboard-container'>
            <DashboardFiltersContainer>

            </DashboardFiltersContainer>
            <div className='contents'>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className='block border-rb' style={{ maxWidth: 655 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>My Connections</p>
                            <p className='view-all' onClick={() => navigate('/connections')}>View All</p>
                        </div>
                        <Separator height={16} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Total<br /> Connections</p>
                                <Separator height={16} />
                                <span id='total'>0</span>
                            </div>
                            <div>
                                <p className='title'>Pending<br /> Request</p>
                                <Separator height={16} />
                                <span id='pending'>0</span>
                            </div>
                            <div>
                                <p className='title'>Approved</p>
                                <Separator height={16} />
                                <span id='approved'>0</span>
                            </div>
                            <div>
                                <p className='title'>Missing<br /> Amount</p>
                                <Separator height={16} />
                                <span id='amount'>0</span>
                            </div>
                            <div>
                                <p className='title'>Queen<br /> Bees</p>
                                <Separator height={16} />
                                <span id='queen'>0</span>
                            </div>
                        </div>
                    </div>
                    <div className='block border-b ' style={{ maxWidth: 485 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>Pre-Approval Documents</p>
                            <p className='view-all' onClick={() => navigate('/connections')}>View All</p>
                        </div>
                        <Separator height={27} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Missing<br /> Documents</p>
                                <Separator height={16} />
                                <span id='missing-document'>0</span>
                            </div>
                            <div>
                                <p className='title'>Expiring<br /> Documents</p>
                                <Separator height={16} />
                                <span id='expired-document'>0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='connection-list' style={{ display: 'flex', width: '100%' }}>
                    <div className='block border-r' style={{ maxWidth: 655 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>Connections List</p>
                            <p className='view-all' onClick={() => navigate('/connections')}>View All</p>
                        </div>
                        <Separator height={24} />
                        <div>
                            {
                                (data.length > 0 && data.filter(item => item.buyer?.buyer_profile?.default_mortgage_broker?.id === user.id).length > 0)
                                    ? data.map((item, key) => {
                                        return (
                                            item.buyer?.buyer_profile?.default_mortgage_broker?.id === user.id &&
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='profile'>
                                                    <img src={checkImageUrl(item.buyer?.profile_photo, NoUser)} alt="" />
                                                    <p>{item.buyer?.first_name} {item.buyer?.last_name}</p>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    {
                                                        item?.approval_document_expiry
                                                            ? <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                                                                <p style={{ fontSize: 10, fontWeight: 600 }}>{moment(item?.approval_document_expiry).format('MMMM DD, YYYY')}</p>
                                                                {
                                                                    parseInt(moment.duration(moment(item?.approval_document_expiry).startOf('day').diff(moment().startOf('day'))).asDays()) < 7 &&
                                                                    <>
                                                                        {
                                                                            (Math.sign(parseInt(moment.duration(moment(item.approval_document_expiry).diff(moment().startOf('day'))).asDays())) === -1 ||
                                                                                Math.sign(parseInt(moment.duration(moment(item.approval_document_expiry).diff(moment().startOf('day'))).asDays())) === 0)
                                                                                ? <p style={{ fontSize: 12, color: '#EF4546' }}>Document is expired</p>
                                                                                : <p style={{ fontSize: 12, color: '#EF4546' }}>Document is about to Expire!</p>
                                                                        }
                                                                    </>
                                                                }

                                                            </div>
                                                            : <p style={{ fontSize: 12, fontWeight: 500, color: '#949494' }}>No Document</p>
                                                    }
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <p style={{ fontSize: 12, fontWeight: 600 }}>{checkStatus(item)}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <p>No Default Connections</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MortgageDashboard;