import { checkImageUrl } from 'utils/modules/Handlers';
import './listingList.scss';
import house1 from 'assets/images/house1.jpg'

const ListingList = ({ data }) => {
    return (
        <div className="listing-list-component">
            <img src={checkImageUrl(data?.primary_photo_url || data?.featured_image?.image_url, house1)} alt="" />
            <div className="content">
                <p className='id'>Listing #{data?.mls_number || data?.id}</p>
                <p className='name'>{data?.property_type} in {data?.city}</p>
                <p className='address'>
                    {
                        data?.full_address ||
                        <>{`${data?.address}, ${data?.city} ${data?.state} ${data?.zip_code}`}</>
                    }
                </p>
            </div>
        </div>
    )
}

export default ListingList;