import Config from "./Config"

export const inputChange = ({ setForm, key, value, data }) => {
    const values = (prevValue) => { return({ ...prevValue, [value]: data }) }

    return setForm(prev => ({
        ...prev, 
        [key]: values(prev[key])
    }))
}

export const getErrorMessage = (data) => {
    let errors = []
    if ('non_field_errors' in data) {
        data['non_field_errors'].map(error => {
            return errors.push(error)
        })
    } else {
        Object.keys(data).map((key, index) => {
            if (data[key].map) {
                return data[key].map(error => {
                    return errors.push(`${key.toUpperCase().replaceAll('_', " ")}: ${error}`)
                })
            } else {
                return errors.push(data[key])
            }
        })
    }
    return errors
}

export const getErrorMessageSignup = (data) => {
    let errors = []
    if ('non_field_errors' in data) {
        data['non_field_errors'].map(error => {
            return errors.push(error)
        })
    } else {
        Object.keys(data).map((key, index) => {
            if (data[key].map) {
                return data[key].map(error => {
                    return errors.push(error)
                })
            } else {
                return errors.push(data[key])
            }
        })
    }
    return errors
}


export const checkImageUrl = (url, empty) => {
    if (url) {
        if (url.includes('https://') || url.includes('http://')) {
            return url
        } else {
            return `${Config.SERVER_URL}${url}`
        }
    } else return empty
}


export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2) )||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
  
    return true;
  }
  
  const isObject = (object) => {
    return object != null && typeof object === 'object';
  }


export const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (phone_number) => {
    let phone = `+1${phone_number}`
    return phone.match(/(^\+\d{11}(\d{2})?$)/);
};


export const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
}