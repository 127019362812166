import * as React from "react"

const CircleCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6666 8C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 8C1.33325 4.318 4.31792 1.33334 7.99992 1.33334C11.6819 1.33334 14.6666 4.318 14.6666 8ZM10.6866 5.98C10.7802 6.07375 10.8328 6.20084 10.8328 6.33334C10.8328 6.46584 10.7802 6.59292 10.6866 6.68667L7.35325 10.02C7.2595 10.1136 7.13242 10.1662 6.99992 10.1662C6.86742 10.1662 6.74034 10.1136 6.64659 10.02L5.31325 8.68667C5.26413 8.64089 5.22473 8.58569 5.1974 8.52436C5.17007 8.46303 5.15538 8.39682 5.15419 8.32968C5.15301 8.26255 5.16536 8.19586 5.1905 8.1336C5.21565 8.07135 5.25308 8.01479 5.30056 7.96731C5.34804 7.91983 5.40459 7.8824 5.46685 7.85725C5.52911 7.83211 5.5958 7.81976 5.66293 7.82094C5.73007 7.82213 5.79628 7.83682 5.85761 7.86415C5.91894 7.89148 5.97414 7.93088 6.01992 7.98L6.99992 8.96L8.48992 7.47L9.97992 5.98C10.0737 5.88637 10.2008 5.83378 10.3333 5.83378C10.4658 5.83378 10.5928 5.88637 10.6866 5.98Z"
      fill={props.color || "#DFDFDF"}
    />
  </svg>
)


export default CircleCheck
