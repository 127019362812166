import Icons from 'components/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './amountEdit.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';

const AmountEdit = ({ modal, setModal, selectedData }) => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const init = () => {
            if (selectedData.approval_amount) {
                setAmount(selectedData.approval_amount);
                document.getElementById('amount').value = selectedData.approval_amount;
            } else {
                setAmount('');
                document.getElementById('amount').value = '';
            }

        }

        init();
    }, [selectedData])

    const onSubmit = useCallback(async() => {
        if (amount) {
            setLoading(true);
            const data = {
                approval_amount: amount
            }

            try {
                await dispatch(actions.buyer.patchBuyerMortgageConnection({ id: selectedData.id, data: data }));
                await dispatch(actions.buyer.fetchBuyerMortgageConnection());
                setLoading(false);
                setModal(prev => !prev);
                toast.success('Pre-Approval Amount updated!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                setLoading(false);
                // console.log(error)
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [selectedData, amount])

    return (
        <div className={`edit-amount-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Edit Pre-Approval Amount
                </p>
                <Seprator height={30} />
                <div className='form-input'>
                    <label>Pre-Approval Amount</label>
                    <input 
                        id='amount' 
                        placeholder='Enter Pre-Approval Amount' 
                        type='text' 
                        className='small' 
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)} 
                    />
                </div>
                <Seprator height={30} />
                <button className='button primary small no-glow' onClick={onSubmit}>
                    {
                        loading 
                        ? <Icons.Loading height={40} width={40} background={'none'} disabled={!amount || loading} />
                        : 'Confirm'
                    }
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default AmountEdit;