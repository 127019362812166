import * as React from "react"

const Check = (props) => (
  <svg
    width={11}
    height={9}
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.62509 7.10627L1.45634 4.93752C1.33948 4.82066 1.18098 4.755 1.01572 4.755C0.85045 4.755 0.691952 4.82066 0.575091 4.93752C0.45823 5.05438 0.392578 5.21288 0.392578 5.37814C0.392578 5.45997 0.408696 5.541 0.440012 5.61661C0.471327 5.69221 0.517227 5.7609 0.575091 5.81877L3.18759 8.43127C3.43134 8.67502 3.82509 8.67502 4.06884 8.43127L10.6813 1.81877C10.7982 1.70191 10.8639 1.54341 10.8639 1.37814C10.8639 1.21288 10.7982 1.05438 10.6813 0.937518C10.5645 0.820657 10.406 0.755005 10.2407 0.755005C10.0754 0.755005 9.91695 0.820657 9.80009 0.937518L3.62509 7.10627Z"
      fill="white"
    />
  </svg>
)


export default Check
