import './listingStatus.scss'

const ListingStatus = ({ color, text, fit=false, style }) => {
    return (
        <div className={`listing-status-component ${fit && 'fit-content'}`}>
            <div className={`status-label ${color}`} style={style}>{text}</div>
        </div>
    )
}


export default ListingStatus;