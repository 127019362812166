import * as React from "react"

const Globe3D = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <defs>
      <style>
        {
          ".globe-3d-a,.globe-3d-b,.globe-3d-e{fill:none;}.globe-3d-a,.globe-3d-b{stroke:#facb1f;}.globe-3d-a{stroke-width:1.5px;}.globe-3d-b{opacity:0.4;}.globe-3d-c{fill:#facb1f;font-size:5px;font-family:ProximaNova-Bold, Proxima Nova;font-weight:700;letter-spacing:0.02em;}.globe-3d-d{stroke:none;}"
        }
      </style>
    </defs>
    <g transform="translate(-1026.507 -3019.506)">
      <g transform="translate(1026.484 3019.484)">
        <g className="globe-3d-a" transform="translate(0.023 0.023)">
          <rect className="globe-3d-d" width={22} height={22} rx={11} />
          <rect
            className="globe-3d-e"
            x={0.75}
            y={0.75}
            width={20.5}
            height={20.5}
            rx={10.25}
          />
        </g>
        <g className="globe-3d-b" transform="translate(5.023 1.023)">
          <ellipse className="globe-3d-d" cx={6} cy={10} rx={6} ry={10} />
          <ellipse className="globe-3d-e" cx={6} cy={10} rx={5.5} ry={9.5} />
        </g>
        <g className="globe-3d-b" transform="translate(21.023 5.023) rotate(90)">
          <ellipse className="globe-3d-d" cx={6} cy={10} rx={6} ry={10} />
          <ellipse className="globe-3d-e" cx={6} cy={10} rx={5.5} ry={9.5} />
        </g>
      </g>
      <text className="globe-3d-c" transform="translate(1032.507 3032.507)">
        <tspan x={0} y={0}>
          {"360\xB0"}
        </tspan>
      </text>
    </g>
  </svg>
)


export default Globe3D
