import * as React from "react";

const Lot = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 3.5C0.5 1.84315 1.84315 0.5 3.5 0.5L12.5 0.5C14.1569 0.5 15.5 1.84315 15.5 3.5L15.5 12.5C15.5 14.1569 14.1569 15.5 12.5 15.5L3.5 15.5C1.84315 15.5 0.5 14.1569 0.5 12.5L0.5 3.5ZM3.88871 12.6999C3.39256 12.6999 2.99035 12.2977 2.99034 11.8016L2.99035 7.56663C2.99035 7.07048 3.39256 6.66827 3.88871 6.66826C4.38487 6.66827 4.78708 7.07048 4.78708 7.56663L4.78708 10.9032L8.12366 10.9032C8.61981 10.9032 9.02203 11.3054 9.02203 11.8016C9.02203 12.2977 8.61981 12.6999 8.12366 12.6999L3.88871 12.6999ZM12.7346 3.88796C12.7346 3.3918 12.3324 2.98959 11.8362 2.98959L7.60128 2.98959C7.10512 2.98959 6.70291 3.3918 6.70291 3.88796C6.70291 4.38411 7.10512 4.78632 7.60128 4.78632L10.9379 4.78632L10.9379 8.1229C10.9379 8.61906 11.3401 9.02127 11.8362 9.02127C12.3324 9.02127 12.7346 8.61906 12.7346 8.1229L12.7346 3.88796Z"
            fill={props.color || "#2A2A2A"}
        />
    </svg>
);


export default Lot;