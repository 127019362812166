import { createSlice } from '@reduxjs/toolkit'

const buyerSlice = createSlice({
  name: 'buyer',
  initialState: {
    inquiries: {
      data: [],
      next: null,
      previous: null,
      total: 0,
    },
    mortgageConnections: {
      data: [],
      total: 0,
      next: null,
      previous: null,
      offset: 0,
    },
  },
  reducers: {
    fetchBuyerInquiriesSuccess: (state, action) => {
      state.inquiries.data = action.payload.results;
      state.inquiries.next = action.payload.next;
      state.inquiries.previous = action.payload.previous;
      state.inquiries.total = action.payload.count;
    },
    fetchBuyerMortgageConnectionSuccess: (state, action) => {
      state.mortgageConnections.data = action.payload.results;
      state.mortgageConnections.next = action.payload.next;
      state.mortgageConnections.previous = action.payload.previous;
      state.mortgageConnections.total = action.payload.count;
      state.mortgageConnections.offset = 0;
    }
  },
});


export default buyerSlice