// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-agent-container .form-container {
  display: flex;
  flex-direction: column;
  padding: 0 140px;
  width: 100%;
  align-items: center;
}
.invite-agent-container .form-container .phone-icon-container {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/routes/ShowingRequest/modules/InviteAgent/inviteAgent.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAAR;AACQ;EACI,WAAA;AACZ","sourcesContent":[".invite-agent-container {\n    .form-container {\n        display: flex;\n        flex-direction: column;\n        padding: 0 140px;\n        width: 100%;\n        align-items: center;\n        .phone-icon-container {\n            width: auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
