import { useEffect } from 'react';
// Redux
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Packages
import Splide from '@splidejs/splide';
// Utils
import { checkImageUrl } from 'utils/modules/Handlers';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import RequestStepper from 'components/RequestStepper';
import Footer from 'components/Footer';
// Assets
import './selectBuyer.scss';
import NoUser from 'assets/images/no_user.jpg'

const SelectBuyer = () => {
    const { id: listingID, mls } = useParams();
    const { buyers } = useSelector((state) => state.agent)
    const { showing } = useSelector((state) => state.showing)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        new Splide( '#select-buyer', {
            type: 'loop',
            rewind: true,
            pagination: false,
            arrows: true,
            perPage: 3,
            focus: 'left',
            fixedWidth: 320,
        }).mount();
    })

    useEffect(() => {
        const init = async() => {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id)) 
            await dispatch(actions.agent.fetchBuyers())            
        }
        init();
    }, [dispatch])

    const onSelectBuyer = async(buyer, showing) => {
        const body = {
            showing: showing.id,
            buyer: buyer.id,
        }
        await dispatch(actions.showing.showingRequestStep2Buyer(body))
        navigate(`/request/${mls}/${listingID}/calendar`)
    }

    return (
        <section className="select-buyer-container">
            {/* Back & Next */}
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p className='back-button' onClick={() => navigate(`/request/${mls}/${listingID}`)}>Back</p>
                    <h1 className="title">Select from my Buyers</h1>
                    {/* <button 
                        className="button medium primary next-button" 
                        style={{ width: 120 }}
                        onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent`)}
                    >
                        Next
                    </button> */}
                </div>
                <p className='subtitle'>Here are the buyers you have in your contact.</p>
            </div>
            <div className='contents'>
                <div id="select-buyer" className="splide agent-slider">
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                            <Icons.BoxArrowLeft color={'#808080'} />
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                            <Icons.BoxArrowRight color={'#808080'} />
                        </button>
                    </div>
                    <div className="splide__track">
                        <ul className="splide__list">
                            { 
                                buyers.map(buyer => (
                                    <li key={buyer.id} className="splide__slide">
                                        <div className="selectbox agent" onClick={() => onSelectBuyer(buyer, showing)}>
                                            <div className="agent-company">
                                                <img src={buyer.company_logo} alt="" />
                                            </div>
                                            <div className="agent-profile--image solo">
                                                <div className="agent-profile--avatar" style={{backgroundImage: `url(${checkImageUrl(buyer.profile_photo, NoUser)})`}}>
                                                    {buyer.verified_buyer_agent && <Icons.MiniVerified width={24} height={24} />}
                                                </div>
                                            </div>
                                            <Separator height={10} />
                                            <div className="agent-profile--info">
                                                <p className="name">{buyer.first_name} {buyer.last_name}</p>
                                                <Separator height={10} />
                                                <div>
                                                    <div className="email">
                                                        <Icons.EmailSmall />
                                                        <Separator width={10} />
                                                        <span>{buyer.email || 'Not Available'}</span>
                                                    </div>
                                                    <Separator height={10} />
                                                    <div className="phone">
                                                        <Icons.PhoneSmall />
                                                        <Separator width={10} />
                                                        <span>{buyer.phone_number || 'Not Available'}</span>
                                                    </div>
                                                </div>
                                                <Separator height={20} />
                                            </div>                         
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="more-options-container">
                    <div className="selectbox more-options" onClick={() => navigate(`/request/${mls}/${listingID}/invite-buyer`)}>
                        <Icons.Add />
                        <Separator height={15} />
                        <h1 className="header">I'd like to invite my buyer</h1>
                        <Separator height={10} />
                        <p className="subheader">If you like to invite a new buyer, please fill out the buyer's contact details and we'll get things ready for you.</p>
                    </div>
                </div>
            </div>
            <Separator height={100} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Separator height={90} />
            <Footer subfooter />
        </section>
    )
}


export default SelectBuyer;