import Seprator from 'components/Separator';
import './inbox.scss'
import { checkImageUrl } from 'utils/modules/Handlers';
import NoUser from 'assets/images/no_user.jpg'
import Icons from 'components/Icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const InBox = ({ 
    other, 
    setOther, 
    selectedConvo, 
    setSelectedConvo, 
    agents, 
    brokers, 
    buyers, 
    teamMembers 
}) => {
    const { user } = useSelector((state) => state.auth);

    const onClick = useCallback((item) => {
            setSelectedConvo(null);
            setOther(item);
    }, [])

    return (
        <div className="form-nav-container">
            <div className='navigation'>
                <div className='header'>
                    <Icons.MenuMessage />
                    <Seprator width={10} />
                    <p>Messaging</p>
                </div>
                <Seprator height={50} />
                <p className='subheader'>Conversations</p>
                <div id="inbox-container" />
                {
                    (user.user_type === 'buyer' || user.user_type === 'seller') &&
                    <>
                        {
                            agents.length > 0 &&
                            <>
                                <Seprator height={30} />
                                <p className='subheader'>Agents</p>
                                <ul className='items'>
                                    {
                                        agents.map((item, index) => {
                                            return (
                                                <li key={index} className={`${((other && other.id === item.id) || (selectedConvo && parseInt(selectedConvo?.others[0].id) === item.id)) && 'active'}`} onClick={() => onClick(item)}>
                                                    <div>
                                                        <img src={checkImageUrl(item.profile_photo, NoUser)} alt="" />
                                                        <p>{item.first_name} {item.last_name}</p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        }
                        {
                            brokers.length > 0 &&
                            <>
                                <Seprator height={30} />
                                <p className='subheader'>Mortgage Brokers</p>
                                <ul className='items'>
                                    {
                                        brokers.map((item, index) => {
                                            return (
                                                <li key={index} className={`${((other && other.id === item.id) || (selectedConvo && parseInt(selectedConvo?.others[0].id) === item.id)) && 'active'}`} onClick={() => onClick(item)}>
                                                    <div>
                                                        <img src={checkImageUrl(item.profile_photo, NoUser)} alt="" />
                                                        <p>{item.first_name} {item.last_name}</p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        }
                    </>
                }
                {
                    (user.user_type === 'agent' || user.user_type === 'mortgage_broker') &&
                    <>
                        {
                            buyers.length > 0 &&
                            <>
                                <Seprator height={30} />
                                <p className='subheader'>Buyers</p>
                                <ul className='items'>
                                    {
                                        buyers.map((item, index) => {
                                            return (
                                                <li key={index} className={`${((other && other.id === item.id) || (selectedConvo && parseInt(selectedConvo?.others[0].id) === item.id)) && 'active'}`} onClick={() => onClick(item)}>
                                                    <div>
                                                        <img src={checkImageUrl(item.profile_photo, NoUser)} alt="" />
                                                        <p>{item.first_name} {item.last_name}</p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        }
                    </>
                }
                {
                    (user.user_type === 'agent') &&
                    <>
                        {
                            teamMembers.length > 0 &&
                            <>
                                <Seprator height={30} />
                                <p className='subheader'>Team Members</p>
                                <ul className='items'>
                                    {
                                        teamMembers.map((item, index) => {
                                            return (
                                                <li key={index} className={`${((other && other.id === item.id) || (selectedConvo && parseInt(selectedConvo?.others[0].id) === item.id)) && 'active'}`} onClick={() => onClick(item)}>
                                                    <div>
                                                        <img src={checkImageUrl(item.profile_photo, NoUser)} alt="" />
                                                        <p>{item.first_name} {item.last_name}</p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}


export default InBox;