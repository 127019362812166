import * as React from "react";

const Inquiry = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="majesticons:question-circle">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12 9C11.7348 9 11.4804 9.10536 11.2929 9.29289C11.1054 9.48043 11 9.73478 11 10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11C9.73478 11 9.48043 10.8946 9.29289 10.7071C9.10536 10.5196 9 10.2652 9 10C9.00021 9.47052 9.14055 8.95053 9.40676 8.49285C9.67297 8.03516 10.0556 7.65608 10.5157 7.39411C10.9758 7.13215 11.4971 6.99662 12.0266 7.00131C12.556 7.006 13.0748 7.15073 13.5302 7.4208C13.9856 7.69087 14.3615 8.07667 14.6195 8.53899C14.8776 9.00132 15.0087 9.52372 14.9996 10.0531C14.9904 10.5825 14.8413 11.1001 14.5674 11.5532C14.2934 12.0063 13.9045 12.3789 13.44 12.633C13.2968 12.7063 13.1672 12.8038 13.057 12.921C13.0333 12.9459 13.014 12.9746 13 13.006V13.5C13 13.7652 12.8946 14.0196 12.7071 14.2071C12.5196 14.3946 12.2652 14.5 12 14.5C11.7348 14.5 11.4804 14.3946 11.2929 14.2071C11.1054 14.0196 11 13.7652 11 13.5V13C11 12.42 11.253 11.953 11.539 11.62C11.82 11.29 12.169 11.048 12.479 10.878C12.6741 10.7716 12.8283 10.6032 12.9171 10.3995C13.0059 10.1957 13.0242 9.96822 12.9693 9.75285C12.9144 9.53749 12.7893 9.34656 12.6137 9.21023C12.4382 9.07389 12.2223 8.99992 12 9ZM12.999 13.011V13.007V13.012V13.011ZM12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17H12.01C12.2752 17 12.5296 16.8946 12.7171 16.7071C12.9046 16.5196 13.01 16.2652 13.01 16C13.01 15.7348 12.9046 15.4804 12.7171 15.2929C12.5296 15.1054 12.2752 15 12.01 15H12Z"
        fill="#FAC91F"
      />
    </g>
  </svg>
)


export default Inquiry
