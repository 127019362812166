import Icons from 'components/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './defaultBrokerModal.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import Separator from 'components/Separator';
import API from 'API';
import NoUser from 'assets/images/no_user.jpg'
import ModalContainer from 'components/ModalContainer';
import CustomButton from 'components/CustomButton';

const DefaultBrokerModal = ({ modal, setModal, profile, refetch }) => {
    const dispatch = useDispatch();
    const [brokers, setBrokers] = useState([]);


    const fetchData = async () => {
        const brokerData = await API.buyer.fetchBuyerMortgageConnection({ offset: 0, limit: 6 });
        setBrokers(brokerData.data.results)
    }


    useEffect(() => {
        const init = async () => {
            await fetchData();
        }
        init();
    }, [])


    const onSubmit = useCallback(async (item) => {
        try {
            const data = { id: item.id }
            await dispatch(actions.buyer.setDefaultBroker(data)).then(() => {
                refetch();
                setModal(prev => !prev);
                toast.success('New default Mortgage Broker has been set', {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } catch (error) {
            toast.error(<ToastMessage messages={'Something went wrong.'} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [profile])

    return (
        <ModalContainer modal={modal} setModal={setModal} title='Change Default Mortgage Broker'>
            <div className='default-broker-form-section'>
                <p style={{ fontSize: 14, fontWeight: 600, color: '#2a2a2a', margin: 0, lineHeight: '24px' }}><Icons.Info height={12} width={12} color='#FAC91F ' /> If you already have an upgraded Bee Tier, changing your default Mortgage Broker will reset your tier status.</p>
                <Separator height={24} />
                <div className='items'>
                    {
                        brokers.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className='details'>
                                        <div>
                                            <img src={checkImageUrl(item?.mortgage_broker?.profile_photo, NoUser)} alt="" />
                                            <div className='info'>
                                                <p className='name'>{item?.mortgage_broker?.first_name} {item?.mortgage_broker?.last_name}</p>
                                                <Separator height={5} />
                                                <p className='email'>{item?.mortgage_broker?.email}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                profile?.default_mortgage_broker?.id !== item?.mortgage_broker?.id
                                                    ? <p className='set' onClick={() => onSubmit(item?.mortgage_broker)}>Set Default</p>
                                                    : <p className='current'>Default</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Separator height={30} />
                <CustomButton text='Cancel' type='secondary' action={() => setModal(prev => !prev)} style={{ width: 150, alignSelf: 'flex-end' }} />
            </div>
        </ModalContainer>
    )
}


export default DefaultBrokerModal;