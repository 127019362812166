import * as React from "react"

const Contact = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.5}
    height={15}
    viewBox="0 0 12.5 15"
    {...props}
  >
    <path
      fill={props.color || "#2a2a2a"}
      d="M14.25,0h-10V1.25h10Zm-10,15h10V13.75h-10Zm10-12.5h-10A1.254,1.254,0,0,0,3,3.75v7.5A1.254,1.254,0,0,0,4.25,12.5h10a1.254,1.254,0,0,0,1.25-1.25V3.75A1.254,1.254,0,0,0,14.25,2.5Zm-5,1.719A1.406,1.406,0,1,1,7.844,5.625,1.408,1.408,0,0,1,9.25,4.219Zm3.125,6.406H6.125V9.688c0-1.044,2.081-1.563,3.125-1.563s3.125.519,3.125,1.563Z"
      transform="translate(-3)"
    />
  </svg>
)


export default Contact
