import { useNavigate } from "react-router-dom";
// Components
import Icons from "components/Icons";
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './requestSubmitted.scss'
import ShowingRequestIllus from 'assets/images/ShowingRequestIllus.svg'

const RequestSubmitted = () => {
    const navigate = useNavigate();

    return (
        <Section style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="submit-container">
                <img className='illus' src={ShowingRequestIllus} alt="showing-schedule" />
                <Seprator height={50} />
                <p className="header">Showing Request Submitted!</p>
                <Seprator height={26} />
                <p className="subheader">Thank you ~ your request has been submitted. Please allow time for your showing request to be approved.</p>
                <Seprator height={50} />
                <div className="browse" onClick={() => navigate('/listings/')}>Browse more Listings</div>
            </div>
        </Section>
    )
}


export default RequestSubmitted;