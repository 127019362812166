import './dashboardFiltersContainer.scss'

const DashboardFiltersContainer = (props) => {
    return (
        <div className="dashboard-filters-container">
            {props.children}
        </div>
    )
}


export default DashboardFiltersContainer;