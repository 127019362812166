import * as React from "react"

const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.425}
    height={9.284}
    viewBox="0 0 16.425 9.284"
    {...props}
  >
    <path
      fill="#2a2a2a"
      d="M14.4,17.732l6.211-6.144a1.178,1.178,0,0,1,1.658,0,1.161,1.161,0,0,1,0,1.644l-7.037,6.961a1.181,1.181,0,0,1-1.619.034L6.53,13.236a1.158,1.158,0,0,1,0-1.644,1.178,1.178,0,0,1,1.658,0Z"
      transform="translate(-6.188 -11.247)"
    />
  </svg>
)


export default ArrowDown
