import Separator from 'components/Separator'
import './investorDetailPage.scss'
import { useSelector } from 'react-redux';
import Footer from 'components/Footer';
import placeholder1 from 'assets/images/placeholder1.png'
import hero_banner from 'assets/images/hero_banner.png'
import Fred from 'assets/images/Fred.png'
import Erin from 'assets/images/Erin.png'
import Keith from 'assets/images/Keith.png'
import Mik from 'assets/images/Mik.png'
import Marco from 'assets/images/Marco.png'
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import Teaser from 'assets/teaser.mp4';
import PlaceholderTeaser from 'assets/images/placeholder-teaser.png';

const InvestorDetailPage = () => {
    const { user } = useSelector((state) => state.auth);

    const highlights = [
        {
            title: 'Approval & Launch',
            desc: 'Our app has secured approval from both Apple and Android, with the final version scheduled for release in January 2025'
        },
        {
            title: 'User Experience',
            desc: 'Showing Bee distinguishes itself with an intuitive interface, providing an enhanced showing experience for real estate professionals, buyers, and sellers.'
        },
        {
            title: 'Market Potential',
            desc: 'In a market ripe for disruption, Showing Bee aims to be the go-to platform for efficient and seamless property showings. We are poised to challenge the dominance of ShowingTime, offering a superior alternative to agents and MLSs.'
        }
    ]

    const team_members = [
        {
            image: Fred,
            name: 'John Frederick Buenafe',
            position: 'Front End Developer / UI',
        },
        {
            image: Erin,
            name: 'Erin Knorr',
            position: 'Creator / Shareholder',
        },
        {
            image: Keith,
            name: 'Keith Gordon',
            position: 'Creator / Shareholder / Real Estate Broker',
        },
        {
            image: Mik,
            name: 'Mik Goldwyn Medina',
            position: 'Back End Software Engineer',
        },
        {
            image: Marco,
            name: 'Marco Pregonero',
            position: 'UI / UX Designer',
        },
    ]
    return (
        <>
            <Separator height={60} />
            <PageWrapper>
                <CustomContainer>
                    <div className="investor-detail-page-container">
                        <div className='content'>
                            <img src={hero_banner} className='hero-banner' alt='Welcome Investors'/>
                            <div className='about'>
                                <div><p>ABOUT<br /> SHOWING BEE</p></div>
                                <div>
                                    <span>
                                        Far more than just a showing app, Showing Bee is set to redefine how real estate showings are managed and experienced. Notably, our platform not only competes with the current industry leader, ShowingTime (owned by Zillow), but surpasses it in both user interface and functionality.
                                    </span>
                                </div>
                            </div>
                            <Separator height={100} />
                            <div className='highlights'>
                                <p className='top-header'>KEY HIGHLIGHTS</p>
                                <Separator height={30} />
                                <h2 className='header'>Charting a Path to Success</h2>
                                <Separator height={50} />
                                <div className='highlight'>
                                    {
                                        highlights.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <p className='number'>{index + 1}</p>
                                                    <p className='title'>{item.title}</p>
                                                    <span className='desc'>{item.desc}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Separator height={120} />
                            <div className='market'>
                                <img src={placeholder1} className='image' alt='Market landscape' />
                                <div className='details'>
                                    <p className='header'>Market<br /> Landscape</p>
                                    <span className='body'>Unlike previous attempts to challenge ShowingTime, Showing Bee stands out with its comprehensive approach. We are a listing aggregator, a showing service, a platform for sellers to promote their property's details through a unique social media component called "Waggle," named after the dance bees do to attract other bees to the hive. Moreover, we leverage AI to assist buyers in various aspects, including mortgage applications, positioning us as a multifaceted solution in the real estate tech landscape.</span>
                                </div>
                            </div>
                            <Separator height={120} />
                            <div className='highlights'>
                                <p className='top-header'>A MUST WATCH VIDEO</p>
                                <Separator height={30} />
                                <h2 className='header'>Intro to ShowingBee</h2>
                                <Separator height={50} />
                                <div>
                                    <video 
                                        width="100%" 
                                        height="100%"
                                        controls 
                                        style={{borderRadius: 40, maxWidth: 1281, maxHeight: 722}} 
                                        poster={PlaceholderTeaser}
                                    >
                                        <source src={Teaser} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                            <Separator height={120} />
                            <div className='team-container'>
                                <p className='header'>The Team</p>
                                <Separator height={50} />
                                <span className='subheader'>Our success is not just attributed to our cutting-edge product but also to our experienced team. Erin and I, with a background in real estate software development since 2005, have a proven track record of navigating complex challenges. Complemented by our core developers, the Showing Bee team, currently consisting of 5 members, is set to evolve into an agile and proficient development team of 10. This expansion ensures the delivery of a first-class service and product, commencing in the first quarter of 2025</span>
                                <Separator height={120} />
                                <div className='team'>
                                    {
                                        team_members.map((member, index) => {
                                            return (
                                                <div className='member' key={index}>
                                                    <img className='member-profile' src={member.image} alt={member.name} />
                                                    <div className='member-detail'>
                                                        <p>{member.name}</p>
                                                        <span>{member.position}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <Separator height={80} />
                                <div className='why-invest'>
                                    <p className='header'>Why Invest?</p>
                                    <Separator height={50} />
                                    <span className='subheader'>Choosing to invest in Showing Bee is more than supporting groundbreaking technology; it's embracing a transformative journey that extends beyond the showing landscape. It signifies a commitment to revolutionize the overall interaction and dynamics among all parties involved in a real estate transaction. We are confident that Showing Bee will lead the industry in innovation and efficiency.</span>
                                </div>
                                <Separator height={150} />
                                <div className='get-intouch'>
                                    <p className='header'>Get in Touch</p>
                                    <Separator height={50} />
                                    <span className='subheader'>
                                    We are actively seeking strategic partners and investors who share our vision for the future of real estate technology. Reach out to engage with us and explore how you can be part of the Showing Bee success story. Please contact me directly at <a href="mailto:keith@showingbee.com">keith@showingbee.com</a> to schedule a conversation.
                                    <br/>
                                    <br/>
                                    Thank you for considering Showing Bee. We look forward to the opportunity of collaborating with you and sharing more about our exciting venture.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </PageWrapper>
            <Separator height={100} />
            <Footer />
        </>
    )
}


export default InvestorDetailPage;