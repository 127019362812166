import Icons from 'components/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './notifyBuyerModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';
import Talk from 'talkjs';
import NoUser from 'assets/images/no_user.jpg'
import { Config } from 'utils';

const NotifyBuyerModal = ({ modal, setModal, selectedData }) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [talkLoaded, markTalkLoaded] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [currentUser, setCurrentUser] = useState(null);
    const [connId, setConnId] = useState(null);

    useEffect(() => {
        const init = async() => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (user.id && talkLoaded && selectedData.id) {
                const currentUserData = new Talk.User({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    photoUrl: checkImageUrl(user.profile_photo, NoUser),
                    role: user.user_type,
                });

                const otherUserData  = new Talk.User({
                    id: selectedData?.buyer.id,
                    name: `${selectedData?.buyer.first_name} ${selectedData?.buyer.last_name}`,
                    email: selectedData?.buyer.email,
                    photoUrl: checkImageUrl(selectedData?.buyer.profile_photo, NoUser),
                    role: selectedData?.buyer.user_type,
                });

                const session = new Talk.Session({
                    appId: Config.TalkJS_APP_ID,
                    me: currentUserData,
                });

                let conversationId = Talk.oneOnOneId(currentUserData, otherUserData);
                const conversation = session.getOrCreateConversation(conversationId)
                conversation.setParticipant(currentUserData);
                conversation.setParticipant(otherUserData);
                try {
                    const otherUser = {
                        name: `${selectedData?.buyer.first_name} ${selectedData?.buyer.last_name}`,
                        email: [selectedData?.buyer.email],
                        photoUrl: checkImageUrl(selectedData?.buyer.profile_photo, NoUser),
                        role: selectedData?.buyer.user_type,
                    }
                    const currentUser = {
                        name: `${user.first_name} ${user.last_name}`,
                        email: [user.email],
                        photoUrl: checkImageUrl(user.profile_photo, NoUser),
                        role: user.user_type,
                    }
                    await dispatch(actions.message.updateCreateUser({ id: otherUserData.id, data: otherUser }));
                    await dispatch(actions.message.updateCreateUser({ id: currentUserData.id, data: currentUser }));
                    await dispatch(actions.message.createConversation({id: conversation.id, data: { participants: [currentUserData.id.toString(), otherUserData.id.toString()] }}));
                } catch (error) {
                    console.log(error);
                }
                setConnId(conversation.id)
                setCurrentUser(currentUserData);
            }
        }

        init();
    }, [user, talkLoaded, selectedData])


    const messageBuyer = useCallback(async() => {
        const data = [
            {
                text: `Hi there ${selectedData?.buyer.first_name}, this is to notify you that you're pre-approval document is expiring/expired. Your mortgage broker wants to know if "May I send you another preapproval? How is the home search going? What is your current need for a pre-approval?" More information regarding this notice below.`,
                sender: currentUser.id.toString(),
                type: "SystemMessage",
            },
            {
                text: `${message}`,
                sender: currentUser.id.toString(),
                type: "UserMessage",
            }
        ]
        
        await dispatch(actions.message.sendMessage({id: connId, data: data}));


    }, [selectedData, message, currentUser, connId])

    const emailBuyer = useCallback(async() => {
        const data = {
            id: selectedData.id,
            message: message,
        }
        await dispatch(actions.broker.notifyBuyer(data))

    }, [message, selectedData])

    const onSubmitEmailOnly = useCallback(async() => {
        setLoading(true);
        try {
            await emailBuyer();
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            setLoading(false);
            setModal(prev => !prev);
            toast.success('An email notification was successfully sent.', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }, [emailBuyer])

    const onSubmitMessageOnly = useCallback(async() => {
        setLoading(true);
        try {
            await messageBuyer();
            await dispatch(actions.broker.notifyAddDate({
                id: selectedData.id,
            }));
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            setLoading(false);
            setModal(prev => !prev);
            toast.success('A chat notification was successfully sent.', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }, [messageBuyer, selectedData])

    const onSubmitBoth = useCallback(async() => {
        setLoading(true);
        try {
            await messageBuyer();
            await emailBuyer();
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            setLoading(false);
            setModal(prev => !prev);
            toast.success('An email and chat notification was successfully sent.', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }, [messageBuyer, emailBuyer])

    return (
        <div className={`notify-buyer-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Notify Buyer
                </p>
                <p>
                    Send a message to <span style={{ color: '#FAC91F', fontWeight: 700 }}>{`${selectedData.buyer?.first_name} ${selectedData.buyer?.last_name}`}</span> about their Approval Document.
                </p>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Message</label>
                    <textarea
                        onChange={(event) => setMessage(event.target.value)}
                        style={{ height: 250, padding: 15 }}
                        placeholder='Message' 
                        className='small' 
                    ></textarea>
                </div>
                <Seprator height={30} />
                <button className='button chat small no-glow' onClick={onSubmitMessageOnly} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Send Chat'
                }
                </button>
                <Seprator height={15} />
                <button className='button email small no-glow' onClick={onSubmitEmailOnly} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Send Email'
                }
                </button>
                <Seprator height={15} />
                <button className='button primary small no-glow' onClick={onSubmitBoth} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Send Both'
                }
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default NotifyBuyerModal;