import * as React from "react"

const Car = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M3.4375 18.3334C3.17187 18.3334 2.94938 18.2419 2.77 18.0591C2.59063 17.8762 2.50062 17.6502 2.5 17.381L2.5 7.85716C2.5 7.55558 2.56656 7.26986 2.69969 7.00002C2.83281 6.73018 3.01625 6.50796 3.25 6.33335L8.875 2.04764C9.04687 1.92066 9.22656 1.82542 9.41406 1.76193C9.60156 1.69843 9.79687 1.66669 10 1.66669C10.2031 1.66669 10.3984 1.69843 10.5859 1.76193C10.7734 1.82542 10.9531 1.92066 11.125 2.04764L16.75 6.33335C16.9844 6.50796 17.1681 6.73018 17.3012 7.00002C17.4344 7.26986 17.5006 7.55558 17.5 7.85716V17.381C17.5 17.6508 17.41 17.8772 17.23 18.06C17.05 18.2429 16.8275 18.334 16.5625 18.3334H15.625C15.3594 18.3334 15.1369 18.2419 14.9575 18.0591C14.7781 17.8762 14.6881 17.6502 14.6875 17.381V9.76192C14.6875 9.49208 14.5975 9.26605 14.4175 9.08383C14.2375 8.90161 14.015 8.81018 13.75 8.80954L6.25 8.80954C5.98437 8.80954 5.76187 8.90097 5.5825 9.08383C5.40312 9.26669 5.31312 9.49272 5.3125 9.76192L5.3125 17.381C5.3125 17.6508 5.2225 17.8772 5.0425 18.06C4.8625 18.2429 4.64 18.334 4.375 18.3334H3.4375ZM8.125 16.4286C7.85937 16.4286 7.63687 16.3372 7.4575 16.1543C7.27812 15.9714 7.18812 15.7454 7.1875 15.4762C7.18687 15.207 7.27687 14.981 7.4575 14.7981C7.63812 14.6153 7.86062 14.5238 8.125 14.5238H11.875C12.1406 14.5238 12.3634 14.6153 12.5434 14.7981C12.7234 14.981 12.8131 15.207 12.8125 15.4762C12.8119 15.7454 12.7219 15.9718 12.5425 16.1553C12.3631 16.3387 12.1406 16.4299 11.875 16.4286H8.125ZM8.125 12.6191C7.85937 12.6191 7.63687 12.5276 7.4575 12.3448C7.27812 12.1619 7.18812 11.9359 7.1875 11.6667C7.18687 11.3975 7.27687 11.1714 7.4575 10.9886C7.63812 10.8057 7.86062 10.7143 8.125 10.7143H11.875C12.1406 10.7143 12.3634 10.8057 12.5434 10.9886C12.7234 11.1714 12.8131 11.3975 12.8125 11.6667C12.8119 11.9359 12.7219 12.1622 12.5425 12.3457C12.3631 12.5292 12.1406 12.6203 11.875 12.6191H8.125Z"
            fill={props.color || "#2A2A2A"}
        />
    </svg>
)


export default Car
