import './section.scss'

const Section = (props) => {
    return (
        <section className={`section ${props.auto && 'section--auto'} ${props.className}`} style={{ ...props.style }}>
            {props.children}
        </section>
    );
}


export default Section;