import './suspenseLoader.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import Seprator from 'components/Separator';

const SuspenseLoader = () => {
    return (
        <section className="suspense-loader-container">
            <img className='logo' src={ShowingBeeLogoBlack} alt='showingbee logo' />
            <Seprator height={30} />
            <svg
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1284.642 476"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
            >
                <g id="loader-1-u-dots" transform="matrix(1.002234 0 0 1 2.132-161.173)">
                <g
                    id="loader-1-s-circle1_ts"
                    transform="translate(272.23,440.214782) scale(0.035635,0.035635)"
                >
                    <circle
                    id="loader-1-s-circle1"
                    r={8.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle2_ts"
                    transform="translate(1128.334792,181.854) scale(0.058455,0.058455)"
                >
                    <circle
                    id="loader-1-s-circle2"
                    r={8.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle3_ts"
                    transform="translate(1034.957,490.76) scale(-0.054759,-0.054759)"
                >
                    <circle
                    id="loader-1-s-circle3"
                    r={8.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle4_ts"
                    transform="translate(739.227358,499.267) scale(0.086686,0.086686)"
                >
                    <circle
                    id="loader-1-s-circle4"
                    r={8.507}
                    transform="translate(-0.267358,-0.015)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle5_ts"
                    transform="translate(807.507,250.356) scale(0.037088,0.037088)"
                >
                    <circle
                    id="loader-1-s-circle5"
                    r={8.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle6_ts"
                    transform="translate(543.96,198.868) scale(-0.152288,-0.152288)"
                >
                    <circle
                    id="loader-1-s-circle6"
                    r={8.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle7_ts"
                    transform="translate(362,301.259) scale(0.063979,0.063979)"
                >
                    <circle
                    id="loader-1-s-circle7"
                    r={5.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle8_ts"
                    transform="translate(597.467,387.802) scale(0.097074,0.097074)"
                >
                    <circle
                    id="loader-1-s-circle8"
                    r={5.507}
                    transform="translate(0,-0.008)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle9_ts"
                    transform="translate(116.452,205.808) scale(0.021473,0.021473)"
                >
                    <circle
                    id="loader-1-s-circle9"
                    r={4.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                <g
                    id="loader-1-s-circle10_ts"
                    transform="translate(859.452,383.295) scale(0.052105,0.052105)"
                >
                    <circle
                    id="loader-1-s-circle10"
                    r={4.507}
                    transform="translate(0,0)"
                    fill="#1a1b1d"
                    />
                </g>
                </g>
                <path
                id="loader-1-u-daan"
                d="M3680,2435.268c0,0,176.127,127.985,300.628,7.669s132.348-225.2,280.912-237.755s237.493,261.557,337.931,194.6s51.265-216.569,183.089-224.939s93.115,151.7,5.231,120.316s61.2-246.909,172.627-89.976"
                transform="translate(-3680-2083.642822)"
                fill="none"
                stroke="#fbbf1c"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="10,15"
                />
                <g
                id="loader-1-u-bee_to"
                style={{
                    offsetPath:
                    "path('M-49.801658,316.139178C-49.801658,316.139178,157.659549,493.732877,300.628,359.294178C443.596451,224.855479,432.976,134.094178,581.54,121.539178C730.104,108.984178,819.033,383.096178,919.471,316.139178C1019.909,249.182178,970.736,99.570178,1102.56,91.200178C1234.384,82.830178,1195.675,242.900178,1107.791,211.516178C1019.907,180.132178,1169.844436,-56.549049,1306.824788,151.547321')",
                    offsetRotate: "0deg",
                }}
                >
                <g id="loader-1-u-bee_tr" transform="rotate(118.65774)">
                    <g
                    id="loader-1-u-bee"
                    transform="translate(-25.33932,-49.344462)"
                    clipPath="url(#loader-1-u-a)"
                    >
                    <path
                        id="loader-1-s-path1"
                        d="M4.665,8.626c-.735753.005113-1.417024-.387133-1.782-1.026L0.279,3.086c-.371835-.635255-.371835-1.421745,0-2.057C0.643184,0.389445,1.324035,-0.003927,2.06,0h5.209c.736322-.004289,1.417641.389132,1.782,1.029.371835.635255.371835,1.421745,0,2.057L6.447,7.6c-.364976.638867-1.046247,1.031113-1.782,1.026"
                        transform="translate(21.049 52.396)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="loader-1-s-path2"
                        d="M8.522,2.5c.28831-.00071.555043.152632.699505.40214s.144651.557176.000495.80686L6.622,8.22c-.143449.251323-.41062.406455-.7.406455s-.556551-.155132-.7-.406455l-2.6-4.511c-.144156-.249684-.143968-.557353.000495-.80686s.411195-.40285.699505-.40214Zm0-2.5h-5.209c-1.181868-.000067-2.27399.630408-2.864953,1.653919s-.590981,2.284553-.000047,3.308081l2.6,4.511c.590868,1.023682,1.683031,1.654326,2.865,1.654326s2.274132-.630644,2.865-1.654326l2.6-4.511c.590136-1.022136.590978-2.281267.002209-3.304192s-1.677949-1.654666-2.858209-1.657808"
                        transform="translate(19.796 51.145)"
                        fill="#424242"
                    />
                    <path
                        id="loader-1-s-path3"
                        d="M13.548,29.791C6.077,29.791,0,23.109,0,14.9s6.077-14.9,13.548-14.9s13.552,6.682,13.552,14.9-6.077,14.9-13.548,14.9"
                        transform="translate(12.166 26.026)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="loader-1-s-path4"
                        d="M28.887,11.193C26.972,4.7,21.386,0,14.8,0s-12.175,4.7-14.09,11.193c-.473522,1.607946-.71265,3.275783-.71,4.952q0,.875.085,1.727c.789,8.105,7.075,14.42,14.715,14.42s13.926-6.315,14.715-14.421q.084-.852.085-1.727c.00265-1.676217-.236478-3.344054-.71-4.952M14.8,29.79c-5.468,0-10.115-3.982-11.707-9.472-.684783-2.379164-.774071-4.890207-.26-7.312C4.111,6.991,8.993,2.5,14.8,2.5s10.686,4.493,11.966,10.508c.513984,2.421129.424696,4.931505-.26,7.31-1.592,5.49-6.238,9.472-11.708,9.472"
                        transform="translate(10.916 24.775)"
                        fill="#424242"
                    />
                    <path
                        id="loader-1-s-path5"
                        d="M29.6,4.952q0,.875-.085,1.727c-.91459.917635-1.922373,1.737401-3.007,2.446-3.492301,2.228856-7.565849,3.374933-11.708,3.294-4.14259.08191-8.216735-1.064237-11.709-3.294-1.084645-.708154-2.092141-1.527959-3.006-2.446Q0,5.827,0,4.952c-.00265-1.676217.236478-3.344054.71-4.952.646099.672146,1.356491,1.27938,2.121,1.813C5.368,3.6,9.175,5.1,14.8,5.1s9.429-1.5,11.966-3.285c.764136-.534851,1.474475-1.142709,2.121-1.815.473522,1.607946.71265,3.275783.71,4.952"
                        transform="translate(10.916 35.968)"
                        fill="#fac91f"
                    />
                    <path
                        id="loader-1-s-path6"
                        d="M8.417,20.315c-3.374,0-6.2-1.5-7.563-4-2.265-4.17.131-9.865,5.341-12.7C10.676,1.183,14.05,0,16.512,0c1.473252-.150069,2.892579.599021,3.6,1.9c1.065,1.96,1.051,5.454-.036,8.9-1.157,3.673-3.275,6.641-5.664,7.939-1.836261,1.014355-3.896282,1.555308-5.994,1.574"
                        transform="translate(1.25 25.645)"
                        fill="#fff"
                    />
                    <path
                        id="loader-1-s-path7"
                        d="M17.763,0v2.5c1.6,0,2.148.6,2.5,1.246.9,1.651.844,4.837-.13,7.93-1.062,3.371-2.957,6.068-5.068,7.215-1.653918.91524-3.509819,1.40396-5.4,1.422-2.607217.162566-5.092169-1.123536-6.465-3.346-.895689-1.773902-.937624-3.858511-.114-5.667.988964-2.294842,2.739472-4.178157,4.956-5.332c4.233-2.3,7.5-3.466,9.72-3.466Zm0,0c-3.23,0-7.33,1.822-10.914,3.769C1.022,6.934,-1.594,13.378,1,18.161c1.64,3.02,4.967,4.656,8.662,4.656c2.306645-.018429,4.572302-.611651,6.592-1.726c5.826-3.165,8.8-13.755,6.2-18.538-.926604-1.698901-2.762255-2.697711-4.692-2.553"
                        transform="translate(0 24.394)"
                        fill="#424242"
                    />
                    <path
                        id="loader-1-s-path8"
                        d="M12.484,20.315c-2.098057-.018526-4.158442-.559485-5.995-1.574C4.1,17.443,1.982,14.475,0.825,10.8c-1.087-3.446-1.101-6.941-.036-8.9.707421-1.300979,2.126748-2.050069,3.6-1.9C6.85,0,10.225,1.183,14.706,3.617c5.21,2.831,7.606,8.526,5.341,12.7-1.361,2.506-4.189,4-7.562,4Z"
                        transform="translate(29.239 25.645)"
                        fill="#fff"
                    />
                    <path
                        id="loader-1-s-path9"
                        d="M5.646,2.5c2.216,0,5.486,1.166,9.719,3.466c2.217243,1.154163,3.968165,3.038251,4.957,5.334.822373,1.809093.778967,3.893708-.118,5.667-1.372149,2.2229-3.85672,3.509773-6.464,3.348-1.890181-.01804-3.746082-.50676-5.4-1.422-2.111-1.147-4.006-3.845-5.068-7.215C2.3,8.585,2.249,5.4,3.145,3.748C3.5,3.1,4.043,2.5,5.646,2.5m0-2.5c-1.932539-.147961-3.772174.851313-4.7,2.553-2.6,4.783.376,15.373,6.2,18.538c2.019682,1.114388,4.285349,1.707613,6.592,1.726c3.7,0,7.022-1.636,8.662-4.656c2.6-4.783-.014-11.227-5.84-14.392C12.976,1.822,8.875,0,5.646,0"
                        transform="translate(27.982 24.394)"
                        fill="#424242"
                    />
                    <path
                        id="loader-1-s-path10"
                        d="M9.886,11.754C8.976497,6.328288,5.189593,1.825833,0,0"
                        transform="translate(12.577 3.076)"
                        fill="none"
                        stroke="#424242"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                    />
                    <path
                        id="loader-1-s-path11"
                        d="M0,11.754C0.909503,6.328288,4.696407,1.825833,9.886,0"
                        transform="translate(28.965 3.076)"
                        fill="none"
                        stroke="#424242"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                    />
                    <path
                        id="loader-1-s-path12"
                        d="M1.368,2.735c-.553486.000404-1.052679-.33275-1.264675-.844027s-.095021-1.099921.296353-1.491295.980018-.50835,1.491295-.296353.844431.711189.844027,1.264675c-.001101.754517-.612483,1.365899-1.367,1.367"
                        transform="translate(9.844 1.251)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="loader-1-s-path13"
                        d="M2.619,2.5c.064065,0,.116.051935.116.116s-.051935.116-.116.116-.116-.051935-.116-.116c0-.030765.012221-.06027.033976-.082024s.051259-.033976.082024-.033976m0-2.5c-1.059378-.000404-2.014667.637438-2.420353,1.616059s-.181863,2.105264.567087,2.8545s1.875507.97349,2.854283.568177s1.616983-1.360358,1.616983-2.419736C5.237,1.172957,4.065043,0.000552,2.619,0"
                        transform="translate(8.592 0)"
                        fill="#424242"
                    />
                    <path
                        id="loader-1-s-path14"
                        d="M1.368,2.735c-.553486.000404-1.052679-.33275-1.264675-.844027s-.095021-1.099921.296353-1.491295.980018-.50835,1.491295-.296353.844431.711189.844027,1.264675c-.001101.754517-.612483,1.365899-1.367,1.367"
                        transform="translate(38.849 1.251)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="loader-1-s-path15"
                        d="M2.619,2.5c.064065,0,.116.051935.116.116s-.051935.116-.116.116-.116-.051935-.116-.116c0-.030765.012221-.06027.033976-.082024s.051259-.033976.082024-.033976m0-2.5c-1.059378-.000404-2.014667.637438-2.420353,1.616059s-.181863,2.105264.567087,2.8545s1.875507.97349,2.854283.568177s1.616983-1.360358,1.616983-2.419736C5.237,1.172957,4.065043,0.000552,2.619,0"
                        transform="translate(37.598 0)"
                        fill="#424242"
                    />
                    <path
                        id="loader-1-s-path16"
                        d="M10.754,17.211C4.824,17.211,0,13.351,0,8.606C0,3.861,4.824,0,10.754,0s10.754,3.86,10.754,8.606-4.824,8.606-10.754,8.606"
                        transform="translate(14.96 14.257)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="loader-1-s-path17"
                        d="M12,2.5c2.498367-.044288,4.935503.774801,6.9,2.319c1.585654,1.19741,2.542045,3.049865,2.6,5.036-.057955,1.986135-1.014346,3.83859-2.6,5.036-1.964363,1.544557-4.401512,2.364004-6.9,2.32-2.498367.044288-4.935503-.774801-6.9-2.319-1.585386-1.197193-2.541745-3.04922-2.6-5.035.057955-1.986135,1.014346-3.83859,2.6-5.036C7.06423,3.276085,9.50139,2.45628,12,2.5M12,0C5.375,0,0,4.413,0,9.857s5.375,9.857,12,9.857s12-4.413,12-9.857-5.365-9.857-12-9.857"
                        transform="translate(13.709 13.005)"
                        fill="#424242"
                    />
                    <clipPath id="loader-1-u-a">
                        <rect
                        id="loader-1-s-rect1"
                        width={51.39}
                        height={62.273}
                        rx={0}
                        ry={0}
                        fill="none"
                        />
                    </clipPath>
                    </g>
                </g>
                </g>
            </svg>
            <p className='footnote'>Please wait, while we prepare things for you.</p>
        </section>
    )
}

export default SuspenseLoader;