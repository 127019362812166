import * as React from "react"

const Increase = (props) => (
  <svg
    width={8}
    height={8}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.79998 5.38984e-06C3.86664 5.39567e-06 3.92914 0.0103389 3.98748 0.0310055C4.04581 0.0516722 4.09998 0.0871728 4.14998 0.137506L7.44998 3.43751C7.54998 3.53751 7.59998 3.65617 7.59998 3.79351C7.59998 3.93084 7.54998 4.04967 7.44998 4.15001C7.34998 4.25001 7.23331 4.30001 7.09998 4.30001C6.96664 4.30001 6.84998 4.25001 6.74998 4.15001L4.29998 1.70001L4.29998 7.30001C4.29998 7.44167 4.25198 7.55834 4.15598 7.65001C4.05998 7.74167 3.94131 7.78751 3.79998 7.78751C3.65831 7.78751 3.53948 7.73951 3.44348 7.64351C3.34748 7.54751 3.29964 7.42884 3.29998 7.28751L3.29998 1.70001L0.849976 4.15001C0.749976 4.25001 0.633309 4.30001 0.499976 4.30001C0.366642 4.30001 0.249976 4.25001 0.149976 4.15001C0.0499761 4.05001 -2.44584e-05 3.93117 -2.44464e-05 3.79351C-2.44343e-05 3.65584 0.0499761 3.53717 0.149976 3.43751L3.44998 0.137506C3.49998 0.0875061 3.55414 0.0520055 3.61248 0.0310055C3.67081 0.0100055 3.73331 -0.000327949 3.79998 5.38984e-06Z"
      fill="#E70909"
    />
  </svg>
)


export default Increase
