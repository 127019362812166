import * as React from "react"

const Group = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99166 0.666504C4.39166 0.666504 0.658325 4.39984 0.658325 8.99984C0.658325 13.5998 4.39166 17.3332 8.99166 17.3332C13.5917 17.3332 17.325 13.5998 17.325 8.99984C17.325 4.39984 13.5917 0.666504 8.99166 0.666504ZM12 5.94984C12.8917 5.94984 13.6083 6.6665 13.6083 7.55817C13.6083 8.44984 12.8917 9.1665 12 9.1665C11.1083 9.1665 10.3917 8.44984 10.3917 7.55817C10.3833 6.6665 11.1083 5.94984 12 5.94984ZM6.99999 4.63317C8.08333 4.63317 8.96666 5.5165 8.96666 6.59984C8.96666 7.68317 8.08333 8.5665 6.99999 8.5665C5.91666 8.5665 5.03333 7.68317 5.03333 6.59984C5.03333 5.50817 5.90833 4.63317 6.99999 4.63317ZM6.99999 12.2415V15.3665C4.99999 14.7415 3.41666 13.1998 2.71666 11.2332C3.59166 10.2998 5.77499 9.82484 6.99999 9.82484C7.44166 9.82484 7.99999 9.8915 8.58333 10.0082C7.21666 10.7332 6.99999 11.6915 6.99999 12.2415ZM8.99166 15.6665C8.76666 15.6665 8.54999 15.6582 8.33332 15.6332V12.2415C8.33332 11.0582 10.7833 10.4665 12 10.4665C12.8917 10.4665 14.4333 10.7915 15.2 11.4248C14.225 13.8998 11.8167 15.6665 8.99166 15.6665Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default Group
