import * as React from "react"

const LocationMark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={13}
    viewBox="0 0 14 13"
    {...props}
  >
    <path
        fill="#fac91f"
        className="a"
        d="M7,0A3.133,3.133,0,0,0,3.937,3.2c0,1.428,2,4.032,2.768,4.977a.373.373,0,0,0,.588,0c.767-.945,2.768-3.549,2.768-4.977A3.133,3.133,0,0,0,7,0ZM7,4.266A1.044,1.044,0,0,1,5.979,3.2a1.022,1.022,0,1,1,2.042,0A1.044,1.044,0,0,1,7,4.266ZM.489,5.483A.813.813,0,0,0,0,6.237v6.356a.391.391,0,0,0,.533.377l3.356-1.6V5.457a7.859,7.859,0,0,1-.516-1.179ZM7,9.132A1.142,1.142,0,0,1,6.113,8.7c-.478-.589-.986-1.26-1.446-1.948v4.621L9.333,13V6.754c-.46.688-.968,1.359-1.446,1.948A1.142,1.142,0,0,1,7,9.132Zm6.467-5.04-3.356,1.6V13l3.4-1.421A.813.813,0,0,0,14,10.825V4.469A.391.391,0,0,0,13.467,4.092Z"
    />
  </svg>
)


export default LocationMark
