import Footer from "components/Footer";
import Header from "components/Header";
import { Outlet, useLocation } from "react-router-dom";

const HomeContainer = () => {
    const { pathname } = useLocation();
    return (
        <>
            <Header mode={`${pathname === "/" || pathname.includes('/home') ? 'dark' : 'light'}`}/>
            <Outlet />
            <Footer />
        </>
    )
}


export default HomeContainer