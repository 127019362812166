import * as React from "react";

const ListingEditIcon = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={16} cy={16} r={16} fill="#F9F9F9" />
        <g clipPath="url(#clip0_1331_16492)">
            <path
                d="M21.2284 13.7288C21.487 13.4641 21.8265 13.2971 22.1908 13.2555H22.193C22.5274 13.2164 22.8654 13.2842 23.1603 13.4497C23.4552 13.6152 23.6922 13.8701 23.8385 14.179C23.9847 14.4879 24.033 14.8355 23.9766 15.1736C23.9201 15.5117 23.7616 15.8235 23.5233 16.0658L19.0663 20.6062C18.6957 20.9819 18.2324 21.2489 17.7254 21.3792L16.4677 21.7C16.3999 21.7173 16.3302 21.7231 16.2611 21.7172C16.2218 21.7138 16.1828 21.7068 16.1445 21.696C16.039 21.6663 15.9427 21.6091 15.8653 21.5302C15.7877 21.4513 15.7316 21.3533 15.7025 21.2457C15.6733 21.1381 15.6719 21.0247 15.6985 20.9165L16.0145 19.6352C16.1424 19.1187 16.4045 18.6469 16.7734 18.2693L21.2284 13.7288Z"
                fill="#949494"
            />
            <path
                d="M12.4964 9.76999C13.4864 9.18776 14.7064 9.18776 15.6964 9.76999L18.5933 11.4736C19.3853 11.9394 19.9342 12.7214 20.122 13.6119L16.9134 16.7918V15.5954C16.9134 15.5513 16.9081 15.5075 16.8976 15.4648C16.8889 15.4296 16.8767 15.3951 16.8611 15.3619C16.8267 15.2884 16.7764 15.2226 16.7134 15.1688L14.5009 13.2783C14.3901 13.1837 14.2465 13.1314 14.0976 13.1314C14.025 13.1314 13.9536 13.1438 13.8865 13.1676C13.8162 13.1925 13.7509 13.2299 13.6944 13.2783L11.4817 15.1688C11.4187 15.2226 11.3683 15.2883 11.3339 15.3619C11.2994 15.4353 11.2814 15.5149 11.2814 15.5954V18.2942C11.2814 18.4465 11.345 18.5924 11.4581 18.7C11.5712 18.8076 11.7248 18.8681 11.8848 18.8681H12.6894C12.8494 18.8681 13.0029 18.8076 13.1161 18.7C13.2292 18.5924 13.2928 18.4465 13.2928 18.2942V16.764C13.2928 16.7133 13.314 16.6647 13.3517 16.6288C13.3895 16.5929 13.4406 16.5728 13.4939 16.5728H14.7008C14.7542 16.5728 14.8053 16.5929 14.8431 16.6288C14.8808 16.6647 14.902 16.7133 14.902 16.764V18.2942C14.902 18.4465 14.9656 18.5924 15.0787 18.7C15.1658 18.7828 15.2769 18.8378 15.3964 18.8586C15.327 19.0345 15.2678 19.2151 15.2195 19.3994L14.6895 21.4283C14.6448 21.5997 14.6445 21.78 14.6886 21.9516C14.7092 22.0322 14.7394 22.1094 14.7781 22.182C14.8219 22.2641 14.8767 22.34 14.9411 22.4075C14.9784 22.4466 15.0187 22.4826 15.0614 22.515C14.215 22.7877 13.2831 22.6927 12.4964 22.2299L9.59951 20.5263C8.60951 19.9441 7.99951 18.8681 7.99951 17.7035V14.2964C7.99951 13.1319 8.60951 12.0558 9.59951 11.4736L12.4964 9.76999Z"
                fill="#949494"
            />
        </g>
        <defs>
            <clipPath id="clip0_1331_16492">
                <rect width={16} height={16} fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
);


export default ListingEditIcon;
