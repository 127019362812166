import * as React from "react"

const List = (props) => (
    <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="foundation:list">
      <path
        id="Vector"
        d="M2.81008 4.84431H2.812C2.81204 4.93992 2.8498 5.03165 2.91709 5.09957C2.98438 5.16749 3.07576 5.2061 3.17136 5.20703V5.20735H12.8288V5.20719C12.9248 5.20677 13.0167 5.16836 13.0844 5.10035C13.1521 5.03235 13.1902 4.94029 13.1902 4.84431H13.1918V3.17087C13.1917 3.0747 13.1533 2.98252 13.0853 2.91458C13.0172 2.84663 12.925 2.80847 12.8288 2.80847H3.17136C3.07509 2.80851 2.98277 2.84678 2.9147 2.91485C2.84662 2.98292 2.80836 3.07524 2.80832 3.17151C2.80832 3.17775 2.80992 3.18367 2.81024 3.18991L2.81008 4.84431ZM12.8286 6.80063H3.17136C3.07509 6.80067 2.98277 6.83894 2.9147 6.90701C2.84662 6.97508 2.80836 7.0674 2.80832 7.16367C2.80832 7.16991 2.80992 7.17583 2.81024 7.18207V8.83647H2.81216C2.8122 8.93208 2.84996 9.02381 2.91725 9.09173C2.98454 9.15965 3.07592 9.19826 3.17152 9.19919V9.19951H12.829V9.19935C12.9249 9.19893 13.0168 9.16052 13.0846 9.09251C13.1523 9.02451 13.1904 8.93245 13.1904 8.83647H13.192V7.16303C13.1917 7.06684 13.1533 6.97469 13.0852 6.90676C13.0171 6.83884 12.9248 6.80067 12.8286 6.80063ZM12.8286 10.7926H3.17136C3.07509 10.7927 2.98277 10.8309 2.9147 10.899C2.84662 10.9671 2.80836 11.0594 2.80832 11.1557C2.80832 11.1619 2.80992 11.1678 2.81024 11.1741V12.8285H2.81216C2.8122 12.9241 2.84996 13.0158 2.91725 13.0837C2.98454 13.1516 3.07592 13.1903 3.17152 13.1912V13.1915H12.829C12.925 13.191 13.0169 13.1524 13.0846 13.0844C13.1523 13.0164 13.1904 12.9243 13.1904 12.8283H13.192V11.1549C13.1917 11.0587 13.1532 10.9666 13.0851 10.8987C13.017 10.8308 12.9248 10.7927 12.8286 10.7926Z"
        fill={props.color || "#C9C9C9"}
      />
    </g>
  </svg>
)


export default List
