import * as React from "react"

const LeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12.828}
    viewBox="0 0 12 12.828"
    {...props}
  >
    <defs>
      <style>
        {
          ".left-arrow-a{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}"
        }
      </style>
    </defs>
    <g transform="translate(-6.5 -6.086)">
      <path className="left-arrow-a" d="M17.5,18H7.5" transform="translate(0 -5.5)" />
      <path className="left-arrow-a" d="M12.5,17.5l-5-5,5-5" />
    </g>
  </svg>
)

export default LeftArrow
