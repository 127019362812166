import * as React from "react";

const Compensation = (props) => (
  <svg
    width={17}
    height={15}
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.26 14.388H2C1.057 14.388 0.586 14.388 0.293 14.095C2.08616e-07 13.803 0 13.332 0 12.389V11.277C0 10.759 -1.04308e-07 10.5 0.133 10.268C0.266 10.037 0.467 9.91997 0.869 9.68597C3.515 8.14697 7.271 7.28097 9.779 8.77597C9.947 8.87697 10.099 8.99897 10.229 9.14397C10.3633 9.29116 10.4668 9.46376 10.5333 9.65158C10.5998 9.8394 10.628 10.0386 10.6163 10.2376C10.6045 10.4365 10.553 10.631 10.4649 10.8097C10.3767 10.9884 10.2537 11.1476 10.103 11.278C9.98281 11.3922 9.83606 11.4747 9.676 11.518C9.796 11.504 9.911 11.488 10.021 11.471C10.932 11.326 11.697 10.838 12.397 10.309L14.205 8.94397C14.5275 8.70935 14.9161 8.58297 15.315 8.58297C15.7139 8.58297 16.1025 8.70935 16.425 8.94397C16.998 9.37697 17.174 10.09 16.811 10.672C16.388 11.35 15.792 12.217 15.22 12.747C14.647 13.277 13.794 13.751 13.098 14.087C12.326 14.46 11.474 14.674 10.607 14.815C8.849 15.099 7.017 15.055 5.277 14.697C4.28429 14.4929 3.27348 14.3887 2.26 14.388Z"
      fill={props.color || "#2A2A2A"}
    />
    <path
      d="M4 1.02083C4 0.457333 4.45733 0 5.02083 0H12.8958C13.4593 0 13.9167 0.457333 13.9167 1.02083V5.97917C13.9167 6.24991 13.8091 6.50956 13.6177 6.701C13.4262 6.89245 13.1666 7 12.8958 7H5.02083C4.75009 7 4.49044 6.89245 4.299 6.701C4.10755 6.50956 4 6.24991 4 5.97917V1.02083ZM5.75 0.729167V1.3125C5.75 1.42853 5.70391 1.53981 5.62186 1.62186C5.53981 1.70391 5.42853 1.75 5.3125 1.75H4.72917V2.625H5.3125C5.48486 2.625 5.65553 2.59105 5.81477 2.52509C5.97401 2.45913 6.1187 2.36245 6.24058 2.24058C6.36245 2.1187 6.45913 1.97401 6.52509 1.81477C6.59105 1.65553 6.625 1.48486 6.625 1.3125V0.729167H5.75ZM8.95833 4.95833C9.30643 4.95833 9.64027 4.82005 9.88641 4.57391C10.1326 4.32777 10.2708 3.99393 10.2708 3.64583C10.2708 3.29774 10.1326 2.9639 9.88641 2.71776C9.64027 2.47161 9.30643 2.33333 8.95833 2.33333C8.61024 2.33333 8.2764 2.47161 8.03026 2.71776C7.78411 2.9639 7.64583 3.29774 7.64583 3.64583C7.64583 3.99393 7.78411 4.32777 8.03026 4.57391C8.2764 4.82005 8.61024 4.95833 8.95833 4.95833ZM4.72917 5.25H5.3125C5.42853 5.25 5.53981 5.29609 5.62186 5.37814C5.70391 5.46019 5.75 5.57147 5.75 5.6875V6.27083H6.625V5.6875C6.625 5.3394 6.48672 5.00556 6.24058 4.75942C5.99444 4.51328 5.6606 4.375 5.3125 4.375H4.72917V5.25ZM12.1667 5.6875C12.1667 5.57147 12.2128 5.46019 12.2948 5.37814C12.3769 5.29609 12.4881 5.25 12.6042 5.25H13.1875V4.375H12.6042C12.2561 4.375 11.9222 4.51328 11.6761 4.75942C11.4299 5.00556 11.2917 5.3394 11.2917 5.6875V6.27083H12.1667V5.6875ZM12.1667 1.3125V0.729167H11.2917V1.3125C11.2917 1.48486 11.3256 1.65553 11.3916 1.81477C11.4575 1.97401 11.5542 2.1187 11.6761 2.24058C11.9222 2.48672 12.2561 2.625 12.6042 2.625H13.1875V1.75H12.6042C12.4881 1.75 12.3769 1.70391 12.2948 1.62186C12.2128 1.53981 12.1667 1.42853 12.1667 1.3125Z"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
)


export default Compensation;
