import * as React from "react"

const Property = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={13}
    viewBox="0 0 14 13"
    {...props}
  >
    <path
      fill="#fac91f"
      d="M16.571,10.69l-7-5.561-7,5.561V8.423l7-5.561,7,5.561Zm-1.75-.2v5.373h-3.5V12.279h-3.5v3.582h-3.5V10.488l5.25-4.03Z"
      transform="translate(-2.571 -2.861)"
    />
  </svg>
)


export default Property
