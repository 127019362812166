// Assets
import house1 from 'assets/images/house1.jpg'
import Seprator from 'components/Separator';
import { checkImageUrl } from 'utils/modules/Handlers';
import './listingBlock.scss'
// Redux
import { useSelector } from "react-redux";

const ListingBlock = ({ data }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="manage-showing-listing-block-container">
            <div className={`item-details ${data.listing_data?.agent_members.includes(user?.id) && 'team-member'} ${data.buyer_agent === user?.id && 'buyer-agent'} ${data.listing_agent === user?.agent_profile?.id && 'listing-agent'}`}>
                <p className='name'>{data.listing_data.property_type} in {data.listing_data.city}</p>
                <Seprator height={8} />
                <p className='address'>
                    {`${data.listing_data.address}, ${data.listing_data.city} ${data.listing_data.state} ${data.listing_data.zip_code}`}
                </p>
                <Seprator height={8} />
                <p className='id'>{data.listing_data?.mls_number ? data.listing_data?.mls_number : data.listing_data?.id}</p>
            </div>
        </div>
    )
}


export default ListingBlock;