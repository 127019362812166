import ToastMessage from "components/ToastMessage"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import actions from "store/actions"
import { getErrorMessage } from "utils/modules/Handlers"
import './actionBlock.scss'
import CustomButton from "components/CustomButton"

const ActionBlock = ({ data, setSelectedData, setModal, setQuestionnaireModal, setPreviewModal, setDeleteModal }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onDeleteEntry = (item) => {
        setSelectedData(item)
        setDeleteModal(prev => !prev)
    }

    const onSelectForm = (item) => {
        setSelectedData(item)
        setQuestionnaireModal(prev => !prev)
    }

    const onSelectPreview = (item) => {
        setSelectedData(item)
        setPreviewModal(prev => !prev)
    }

    const approveRejectRequest = async(id, type, tier) => {
        const requestData = { buyer_tier: tier}
        try {
            await dispatch(actions.broker.approveRejectRequest({id, type, data: requestData}));
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            toast.success(`Buyer tier upgrade has been ${type === 'reject' ? 'rejected' : 'approved' }`, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <div className="connection-action-block-container">
            {
                (data.buyer_tier === 'Q' && !data.questionnaire_filled_out && data.approval_document) &&
                <CustomButton text={"Fill out Questionnaire"} action={() => onSelectForm(data)} />
            }
            {
                data.date_approved || data.date_rejected
                ? <>
                    {
                        (data.buyer_tier === 'Q' &&  data.date_approved && data.questionnaire_filled_out) &&
                        <CustomButton text={"Questionnaire Preview"} action={() => onSelectPreview(data)} type="tertiary" />
                    }
                </>
                : <>
                    {/* {
                        !data.questionnaire_filled_out &&
                        <p className="action-button draft" onClick={() => onSelectEntry(data)}>
                            {
                                data.approval_document ? 'Re-Upload Document' : 'Upload Document'
                            }
                        </p>
                    } */}
                    {
                        data.questionnaire_filled_out &&
                        <p style={{ fontSize: 10, fontWeight: 700, color: '#949494' }}>
                            Questionnaire Submitted
                        </p>
                    }
                    {
                        data.request_upgrade &&
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                            {
                                (data.approval_document && (data.buyer_tier !== 'Q' || (data.buyer_tier === 'Q' && data.questionnaire_filled_out))) &&
                                <CustomButton text={"Approve"} action={() => approveRejectRequest(data.id, 'approve', data.buyer_tier)} type="success" size={'small'} />
                            }
                            {
                                (data.buyer_tier !== 'Q' || (data.buyer_tier === 'Q' && !data.questionnaire_filled_out)) &&
                                <CustomButton text={"Reject"} action={() => approveRejectRequest(data.id, 'reject', data.buyer_tier)} type="reject" size={'small'} />
                            }
                        </div>
                    }
                </>
            }
            {/* <p
                    className='action-button delete'
                    onClick={() => onDeleteEntry(data)}
                >
                    Delete
            </p> */}
        </div>
    )
}


export default ActionBlock;