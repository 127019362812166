import * as React from "react"


const Listing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.24924 0.55509C8.47573 0.108508 7.52274 0.108509 6.74923 0.555091L1.31067 3.69505C0.537166 4.14163 0.0606689 4.96695 0.0606689 5.86011V12.14C0.0606689 13.0332 0.537168 13.8585 1.31067 14.3051L6.74924 17.445C7.52274 17.8916 8.47573 17.8916 9.24924 17.445L14.6878 14.3051C15.4613 13.8585 15.9378 13.0332 15.9378 12.14V5.86011C15.9378 4.96695 15.4613 4.14163 14.6878 3.69505L9.24924 0.55509ZM7.99917 5.33328C8.19305 5.33328 8.38008 5.4002 8.52429 5.52116L11.4052 7.93786C11.4872 8.00663 11.5527 8.09071 11.5975 8.18464C11.6424 8.27858 11.6656 8.38027 11.6656 8.48311V11.9331C11.6656 12.1276 11.5828 12.3142 11.4354 12.4518C11.2881 12.5893 11.0882 12.6666 10.8799 12.6666H9.83224C9.62386 12.6666 9.42401 12.5893 9.27666 12.4518C9.12931 12.3142 9.04653 12.1276 9.04653 11.9331V9.97705C9.04653 9.9122 9.01894 9.85001 8.96982 9.80415C8.9207 9.7583 8.85409 9.73254 8.78463 9.73254H7.2132C7.14373 9.73254 7.07712 9.7583 7.028 9.80415C6.97888 9.85001 6.95129 9.9122 6.95129 9.97705V11.9331C6.95129 12.1276 6.86851 12.3142 6.72116 12.4518C6.57381 12.5893 6.37396 12.6666 6.16558 12.6666H5.11796C4.90957 12.6666 4.70972 12.5893 4.56237 12.4518C4.41502 12.3142 4.33224 12.1276 4.33224 11.9331V8.48311C4.33233 8.38023 4.35561 8.27852 4.40055 8.18458C4.44549 8.09065 4.51109 8.00659 4.5931 7.93786L7.47405 5.52116C7.61827 5.4002 7.8053 5.33328 7.99917 5.33328Z"
      fill={props.color || "#949494"}
    />
  </svg>
)

export default Listing
