// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-header-container {
  display: flex;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 0.5px solid var(--secondary-color-2);
}
.form-header-container .header-title {
  flex: 1 1;
  font-family: "Proxima Nova", sans-serif;
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  color: var(--secondary-color-5);
  margin: 0;
}
.form-header-container img {
  height: 140px;
  width: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.form-header-container .details {
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova", sans-serif;
  justify-content: space-between;
}
.form-header-container .details > p, .form-header-container .details div > p {
  margin: 0;
}
.form-header-container .details .id, .form-header-container .details .address {
  font-weight: 500;
}
.form-header-container .details .name, .form-header-container .details .price {
  font-weight: 700;
}
.form-header-container .details .name, .form-header-container .details .address {
  color: var(--secondary-color-1);
}
.form-header-container .details .id {
  color: var(--secondary-color-5);
  font-size: 20px;
}
.form-header-container .details .name {
  font-size: 24px;
}
.form-header-container .details .address {
  font-size: 16px;
}
.form-header-container .details .price {
  font-size: 22px;
  color: var(--primary-color-1);
}`, "",{"version":3,"sources":["webpack://./src/routes/ShowingFeeback/modules/FormHeader/formHeader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,mDAAA;AACJ;AAAI;EACI,SAAA;EACA,uCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,SAAA;AAER;AAAI;EACI,aAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,sBAAA;EACA,uCAAA;EACA,8BAAA;AAER;AADQ;EAAe,SAAA;AAIvB;AAHQ;EACI,gBAAA;AAKZ;AAHQ;EACI,gBAAA;AAKZ;AAHQ;EACI,+BAAA;AAKZ;AAHQ;EACI,+BAAA;EACA,eAAA;AAKZ;AAHQ;EACI,eAAA;AAKZ;AAHQ;EACI,eAAA;AAKZ;AAHQ;EACI,eAAA;EACA,6BAAA;AAKZ","sourcesContent":[".form-header-container {\r\n    display: flex;\r\n    padding-bottom: 50px;\r\n    margin-bottom: 50px;\r\n    border-bottom: 0.5px solid var(--secondary-color-2);\r\n    .header-title {\r\n        flex: 1;\r\n        font-family: 'Proxima Nova', sans-serif;\r\n        text-align: center;\r\n        font-size: 34px;\r\n        font-weight: 700;\r\n        color: var(--secondary-color-5);\r\n        margin: 0;\r\n    }\r\n    img {\r\n        height: 140px;\r\n        width: 200px;\r\n        object-fit: cover;\r\n        border-radius: 10px;\r\n    }\r\n    .details {\r\n        display: flex;\r\n        flex-direction: column;\r\n        font-family: 'Proxima Nova', sans-serif;\r\n        justify-content: space-between;\r\n        > p, div > p { margin: 0; }\r\n        .id, .address {\r\n            font-weight: 500;\r\n        }\r\n        .name, .price {\r\n            font-weight: 700;\r\n        }\r\n        .name, .address {\r\n            color: var(--secondary-color-1);\r\n        }\r\n        .id {\r\n            color: var(--secondary-color-5);\r\n            font-size: 20px;\r\n        }\r\n        .name {\r\n            font-size: 24px;\r\n        }\r\n        .address {\r\n            font-size: 16px;\r\n        }\r\n        .price {\r\n            font-size: 22px;\r\n            color: var(--primary-color-1);\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
