import API from 'API'
import brokerSlice from '../reducers/broker'

const { fetchFeaturedMortgageBrokersSuccess } = brokerSlice.actions

const fetchFeaturedMortgageBrokers = () => async dispatch => {
  const response = await API.broker.fetchFeaturedMortgageBrokers();
  const payload = response.data;
  dispatch(fetchFeaturedMortgageBrokersSuccess(payload));
}

const approveRejectRequest = ({ id, type, data }) => async dispatch => {
  const response = await API.broker.approveRejectRequest({ id, type, data });
  const payload = response.data;
  return payload
}

const brokerBuyerInvite = (data) => async dispatch => {
  const response = await API.broker.brokerBuyerInvite(data);
  const payload = response.data;
  return payload
}

const subscribeFeaturedBroker = () => async dispatch => {
  const response = await API.broker.subscribeFeaturedBroker();
  const payload = response.data;
  return payload
}

const unsubscribeFeaturedBroker = () => async dispatch => {
  const response = await API.broker.unsubscribeFeaturedBroker();
  const payload = response.data;
  return payload
}

const updateBuyerTier = (data) => async dispatch => {
  const response = await API.broker.updateBuyerTier(data);
  const payload = response.data;
  return payload
}

const brokerDashboardData = () => async dispatch => {
  const response = await API.broker.brokerDashboardData();
  const payload = response.data;
  return payload
}

const notifyBuyer = (data) => async dispatch => {
  const response = await API.broker.notifyBuyer(data);
  const payload = response.data;
  return payload
}

const notifyAddDate = (data) => async dispatch => {
  const response = await API.broker.notifyAddDate(data);
  const payload = response.data;
  return payload
}


const brokerActions = {
    fetchFeaturedMortgageBrokers,
    approveRejectRequest,
    brokerBuyerInvite,
    subscribeFeaturedBroker,
    unsubscribeFeaturedBroker,
    updateBuyerTier,
    brokerDashboardData,
    notifyBuyer,
    notifyAddDate
}


export default brokerActions;