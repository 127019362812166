import * as React from "react";

const QueenBeeIndicator = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <g transform="translate(-265 -193)">
      <circle
        cx={10}
        cy={10}
        r={10}
        transform="translate(265 193)"
        fill="#fef4d2"
      />
      <g transform="translate(259 189.5)">
        <path
          d="M3,6.246l.652.364a2.677,2.677,0,0,0,1.636.28A2.1,2.1,0,0,0,6.649,6.2L8,4.5,9.35,6.2a2.1,2.1,0,0,0,1.362.692,2.677,2.677,0,0,0,1.636-.28L13,6.246l-.877,3.062a1.018,1.018,0,0,1-.684.625h0a10.715,10.715,0,0,1-6.879,0h0a1.018,1.018,0,0,1-.684-.625Z"
          transform="translate(8 5)"
          fill="#fac91f"
        />
        <path
          d="M8,3.5a1,1,0,0,1,.783.377l1.351,1.7a1.14,1.14,0,0,0,.715.324,1.712,1.712,0,0,0,1.012-.162l.652-.364a1,1,0,0,1,1.449,1.149l-.877,3.062a1.99,1.99,0,0,1-1.315,1.294A11.564,11.564,0,0,1,8,11.5a11.564,11.564,0,0,1-3.769-.623A1.99,1.99,0,0,1,2.916,9.583L2.039,6.521A1,1,0,0,1,3.488,5.373l.652.364A1.713,1.713,0,0,0,5.152,5.9a1.14,1.14,0,0,0,.715-.324l1.35-1.7A1,1,0,0,1,8,3.5Zm3.1,4.416a3.849,3.849,0,0,1-.522-.036A3.1,3.1,0,0,1,8.568,6.82L8,6.106l-.568.714A3.1,3.1,0,0,1,5.424,7.88a3.9,3.9,0,0,1-.911.016l.3,1.059a.434.434,0,0,0,.074.033A9.542,9.542,0,0,0,8,9.5a9.542,9.542,0,0,0,3.11-.511.433.433,0,0,0,.074-.033l.3-1.059Q11.3,7.916,11.1,7.916Z"
          transform="translate(8 5)"
          fill="#fac91f"
        />
        <path
          d="M16.5,24.25a11.521,11.521,0,0,1-4.811-1.068.75.75,0,0,1,.623-1.365,9.818,9.818,0,0,0,8.377,0,.75.75,0,0,1,.623,1.365A11.521,11.521,0,0,1,16.5,24.25Z"
          transform="translate(-0.5 -5)"
          fill="#fac91f"
        />
      </g>
    </g>
  </svg>
);


export default QueenBeeIndicator;
