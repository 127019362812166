import ModalContainer from 'components/ModalContainer';
import UserCard from 'components/UserCard';
import { useSelector } from 'react-redux';

const ConnectedUserModal = ({ data, modal, setModal }) => {
  const { user } = useSelector((state) => state.auth)

  return (
    <ModalContainer modal={modal} setModal={setModal} title={'Connected Users'}>
      <>
        <div>
          <p style={{ fontSize: 13, fontWeight: 700 }}>Listing Agent</p>
          <UserCard data={data?.listing_data?.agent_data?.user_data} type={'la'} />
        </div>

        {
          data?.buyer_agent_data &&
          <div>
            <p style={{ fontSize: 13, fontWeight: 700 }}>Buyer's Agent</p>
            <UserCard data={data?.buyer_agent_data} type={'ba'} />
          </div>
        }

        {
          data?.buyers?.length > 0 &&
          <div>
            <p style={{ fontSize: 13, fontWeight: 700 }}>Buyer</p>
            {
              data?.buyers.map((buyer, index) => {
                return (<UserCard data={buyer} key={index} type={'buyer'} hide={(data?.buyer_agent_data?.id === user.id || !data?.buyer_agent_data) ? false : true} />)
              })
            }
          </div>
        }

        {
          data?.sellers?.length > 0 &&
          <div>
            <p style={{ fontSize: 13, fontWeight: 700 }}>Homeowner</p>
            {
              data?.sellers.map((seller, index) => {
                return (<UserCard data={seller} type={'seller'} key={index} hide={data?.listing_data?.agent_data?.user_data.id === user.id ? false : true} />)
              })
            }
          </div>
        }
      </>
    </ModalContainer>
  )
}


export default ConnectedUserModal;