import SBLogo from 'assets/images/SBLogo.png'
import Seprator from 'components/Separator';
import { useCallback, useEffect, useState } from 'react';
import CopyRightModal from '../CopyRightModal';
import { useNavigate } from 'react-router-dom';

const PrivacyCenter = () => {
    const [active, setActive] = useState('section-1');
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const container = document.getElementById('container');
        container.addEventListener('scroll', checkPosition);
    }, [])


    const scrollToHash = useCallback(async (hash) => {
        const el = document.getElementById(hash);
        const container = document.getElementById('container');
        container.removeEventListener('scroll', checkPosition);
        el.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
        setActive(hash)
        setTimeout(() => {
            container.addEventListener('scroll', checkPosition);
        }, 1500);
    }, [])

    const checkPosition = (event) => {
        const scrollableHeight = event.currentTarget.scrollHeight - event.currentTarget.clientHeight
        if (event.currentTarget.scrollTop === 0) { setActive('section-1'); }
        if (event.currentTarget.scrollTop >= scrollableHeight-2) { setActive('section-2'); }
    }

    return (
        <div className="disclosure-page">
            <CopyRightModal modal={modal} setModal={setModal} />
            <div className="nav">
                <div>
                    <img className='cursor' src={SBLogo} alt='logo' onClick={() => navigate('/')} />
                    <Seprator height={70} />
                    <ul>
                        <li className={`${active === 'section-1' && 'active'}`} onClick={() => scrollToHash('section-1')}>How to manage your cookie setting on Showing Bee</li>
                        <li className={`${active === 'section-2' && 'active'}`} onClick={() => scrollToHash('section-2')}>How to Disable Cookies</li>
                    </ul>
                </div>
            </div>
            <div className="info">
                <div id='container' className={`${modal && 'fixed'}`}>
                    {/* =================================================== SECTION 1 ===================================================*/}
                    <section id='section-1'>
                        <h1>Privacy Center</h1>
                        <Seprator height={70} />
                        <p className='header'>How to manage your cookie setting on Showing Bee</p>
                        <Seprator height={30} />
                        <p className='body'>
                            Cookies and pixels are both small pieces of data that are used by websites and advertisers to collect information about user behavior online.  
                        </p>
                        <br />
                        <p className='body'>
                            Cookies are small text files that are stored on a user's computer or device when they visit a website. These files contain information about the user's browsing history, preferences, and other data that can be used to personalize their experience on the website or track their behavior across multiple sites. Cookies can be either "first-party" cookies, which are created by the website itself, or "third-party" cookies, which are created by external domains (e.g. advertisers). 
                        </p>
                        <br />
                        <p className='body'>
                            Pixels, also known as "tracking pixels" or "web beacons," are small images that are embedded in web pages or emails. When a user loads a web page or opens an email, the pixel sends a request to a server, which can be used to track user behavior, such as whether they opened an email or clicked on a particular link. 
                        </p>
                        <br />
                        <p className='body'>
                            Both cookies and pixels can be used for a variety of purposes, including targeted advertising, analytics, and personalization. However, they can also raise privacy concerns, as they allow websites and advertisers to collect and store information about users without their explicit consent or knowledge. 
                        </p>
                    </section>
                    {/* =================================================== SECTION 2 ===================================================*/}
                    <section id='section-2'>
                        <p className='header'>How to Disable Cookies</p>
                        <Seprator height={30} />
                        <p className='body'>
                            The method for disabling cookies can vary depending on the web browser you're using. Here are some general steps that should work across most popular browsers: 
                        </p>
                        <p className='body numerals' data-list='1.'>
                            Open your browser and click on the menu button (usually located in the top-right corner).
                        </p>
                        <p className='body numerals' data-list='2.'>
                            Click on "Settings" or "Preferences" in the menu.
                        </p>
                        <p className='body numerals' data-list='3.'>
                            Look for the "Privacy & Security" section.
                        </p>
                        <p className='body numerals' data-list='4.'>
                            Locate the option for managing cookies. This may be labeled as "Cookies," "Site Settings," or "Content Settings." 
                        </p>
                        <br /><br />
                        <p className='body'>
                            Choose the option to block cookies or disable them entirely.
                            <br />
                            Save your changes and restart your browser for the changes to take effect. 
                            <br />
                            Note that disabling cookies may affect the functionality of some websites, as they rely on cookies to remember user preferences and login information. Additionally, some websites may not allow you to access their content if you have cookies disabled. 
                        </p>
                    </section>
                    <section className='bottom'>
                        <p className='header cursor' onClick={() => navigate('/terms-of-use/')}>Terms of Use</p> | <p className='header link cursor'>Privacy Center</p> | <p className='header cursor' onClick={() => navigate('/cookie-policy/')}>Cookie Policy</p> 
                    </section>
                </div>
            </div>
        </div>
    )
}


export default PrivacyCenter;