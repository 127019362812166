import * as React from "react"

const Image = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 12.9309V1.61636C14.9987 1.18807 14.8227 0.777686 14.5104 0.474835C14.1981 0.171985 13.775 0.00127913 13.3333 0H1.66667C1.22504 0.00127913 0.801888 0.171985 0.489612 0.474835C0.177337 0.777686 0.00131894 1.18807 0 1.61636V12.9309C0.00131894 13.3592 0.177337 13.7696 0.489612 14.0724C0.801888 14.3753 1.22504 14.546 1.66667 14.5473H13.3333C13.775 14.546 14.1981 14.3753 14.5104 14.0724C14.8227 13.7696 14.9987 13.3592 15 12.9309ZM4.58333 8.48592L6.66667 10.9185L9.58333 7.27364L13.3333 12.1227H1.66667L4.58333 8.48592Z"
      fill="#2A2A2A"
    />
  </svg>
)


export default Image;
