// Components
import Section from "components/Section";
// Assets
import './pageLoading.scss'
import SuspenseLoader from "components/SuspenseLoader";

const PageLoading = () => {

    return (
        <Section className="page-loading-container" style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <SuspenseLoader />
        </Section>
    )
}


export default PageLoading;