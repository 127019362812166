import * as React from "react"

const Instruction = (props) => (
  <svg
    width={15}
    height={19}
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 2.27273C0 1.66996 0.239447 1.09189 0.665666 0.665666C1.09189 0.239447 1.66996 0 2.27273 0H12.7273C13.0257 0 13.3213 0.0587858 13.597 0.173001C13.8727 0.287216 14.1233 0.454624 14.3343 0.665666C14.5454 0.876709 14.7128 1.12725 14.827 1.40299C14.9412 1.67873 15 1.97427 15 2.27273V15.2273C15 15.4081 14.9282 15.5815 14.8003 15.7094C14.6724 15.8373 14.499 15.9091 14.3182 15.9091H1.36364C1.36364 16.1502 1.45942 16.3814 1.6299 16.5519C1.80039 16.7224 2.03162 16.8182 2.27273 16.8182H14.3182C14.499 16.8182 14.6724 16.89 14.8003 17.0179C14.9282 17.1457 15 17.3192 15 17.5C15 17.6808 14.9282 17.8543 14.8003 17.9821C14.6724 18.11 14.499 18.1818 14.3182 18.1818H2.27273C1.66996 18.1818 1.09189 17.9424 0.665666 17.5162C0.239447 17.0899 0 16.5119 0 15.9091V2.27273ZM7.5 5.45455C7.74111 5.45455 7.97234 5.35877 8.14282 5.18828C8.31331 5.01779 8.40909 4.78656 8.40909 4.54545C8.40909 4.30435 8.31331 4.07312 8.14282 3.90263C7.97234 3.73214 7.74111 3.63636 7.5 3.63636C7.25889 3.63636 7.02766 3.73214 6.85718 3.90263C6.68669 4.07312 6.59091 4.30435 6.59091 4.54545C6.59091 4.78656 6.68669 5.01779 6.85718 5.18828C7.02766 5.35877 7.25889 5.45455 7.5 5.45455V5.45455ZM6.81818 7.04545V11.5909C6.81818 11.7717 6.89002 11.9452 7.01788 12.073C7.14575 12.2009 7.31917 12.2727 7.5 12.2727C7.68083 12.2727 7.85425 12.2009 7.98212 12.073C8.10998 11.9452 8.18182 11.7717 8.18182 11.5909V7.04545C8.18182 6.86462 8.10998 6.6912 7.98212 6.56334C7.85425 6.43547 7.68083 6.36364 7.5 6.36364C7.31917 6.36364 7.14575 6.43547 7.01788 6.56334C6.89002 6.6912 6.81818 6.86462 6.81818 7.04545Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default Instruction
