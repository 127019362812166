import * as React from "react"

const Minus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      id="Icon_material-do-not-disturb-on"
      data-name="Icon material-do-not-disturb-on"
      d="M28,3A25,25,0,1,0,53,28,25.009,25.009,0,0,0,28,3ZM40.5,30.5h-25v-5h25Z"
      transform="translate(-3 -3)"
      fill="#fac91f"
    />
  </svg>
)


export default Minus
