import * as React from "react";

const TeamMemberIndicator = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <g transform="translate(-260 -429)">
      <circle
        cx={10}
        cy={10}
        r={10}
        transform="translate(260 429)"
        fill="#527BA3"
      />
      <text
        transform="translate(270 442.5)"
        fill="#fff"
        fontSize={10}
        fontFamily="ProximaNova-Bold, Proxima Nova"
        fontWeight={700}
      >
        <tspan x={-7.185} y={0}>
          {"TM"}
        </tspan>
      </text>
    </g>
  </svg>
);


export default TeamMemberIndicator;
