import * as React from "react"

const Security = (props) => (
  <svg
    width={16}
    height={20}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0.833008L0.5 4.16634V9.16634C0.5 13.7913 3.7 18.1163 8 19.1663C12.3 18.1163 15.5 13.7913 15.5 9.16634V4.16634L8 0.833008ZM8 9.99134H13.8333C13.3917 13.4247 11.1 16.483 8 17.4413V9.99967H2.16667V5.24967L8 2.65801V9.99134Z"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
)


export default Security
