import * as React from "react"

const Decrease = (props) => (
  <svg
    width={8}
    height={8}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.00001 7.7875C3.93335 7.7875 3.87085 7.77717 3.81251 7.7565C3.75418 7.73583 3.70001 7.70033 3.65001 7.65L0.350012 4.35C0.250012 4.25 0.200012 4.13133 0.200012 3.994C0.200012 3.85667 0.250012 3.73783 0.350012 3.6375C0.450012 3.5375 0.566679 3.4875 0.700012 3.4875C0.833346 3.4875 0.950012 3.5375 1.05001 3.6375L3.50001 6.0875V0.4875C3.50001 0.345833 3.54801 0.229167 3.64401 0.1375C3.74001 0.0458334 3.85868 0 4.00001 0C4.14168 0 4.26051 0.0480001 4.35651 0.144C4.45251 0.24 4.50035 0.358667 4.50001 0.5V6.0875L6.95001 3.6375C7.05001 3.5375 7.16668 3.4875 7.30001 3.4875C7.43335 3.4875 7.55001 3.5375 7.65001 3.6375C7.75001 3.7375 7.80001 3.85633 7.80001 3.994C7.80001 4.13167 7.75001 4.25033 7.65001 4.35L4.35001 7.65C4.30001 7.7 4.24585 7.7355 4.18751 7.7565C4.12918 7.7775 4.06668 7.78783 4.00001 7.7875Z"
      fill="#02B946"
    />
  </svg>
)


export default Decrease;
