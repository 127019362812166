import Separator from "components/Separator";
import SellersBanner from 'assets/images/SellersBanner.png'
import SellersAsset from 'assets/images/SellersAsset.png'
import CustomButton from "components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SellersInfoPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="sellers-details">
            <p className="header">Sellers</p>
            <Separator height={8} />
            <p className="subheader">Revolutionizing your Real Estate.</p>
            <Separator height={40} />
            <img src={SellersBanner} alt="Buyers Banner" style={{ width: '100%', height: 'auto'}} />
            <Separator height={120} />
            <p className="title">At Showing Bee, <br />we prioritize your needs.</p>
            <Separator height={40} />
            <div className="blocks">
                <div style={{ flexDirection: 'column', flex: 1 }}>
                    <div style={{ height: '100%', flex: 1 }}>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Participation.</p>
                            <span className="content">When a property you own is listed on Showing Bee, you'll have access to set blackouts on the live interactive calendar, manage showing appointments, and if applicable, set any compensation or concession amounts.</span>
                        </div>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Debut Listings on Showing Bee.</p>
                            <span className="content">Before you list on the MLS, introduce your listings to the market via Showing Bee.</span>
                        </div>
                    </div>
                    <div style={{ height: '100%', flex: 1 }}>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Customize Your Marketing & Target Your Buyer Audience.</p>
                            <span className="content">Personalize the marketing of your listing with Waggle. Upload videos, make relevant property posts - it's Social Media, just for Real Estate!</span>
                        </div>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Connect & Communicate.</p>
                            <span className="content">Connect with buyers and their agents.</span>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ flex: 1, maxWidth: 416 }}>
                    <p className="title">Advertise Open Houses.</p>
                    <span className="content">Get the word out effortlessly. Real-time calendar allows you to schedule Open Houses with a click, including recurring events!</span>
                    <img className="image-resize" src={SellersAsset} alt="" style={{ maxWidth: 352 }} />
                </div>
            </div>
            <Separator height={80} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <span className="content">Let Showing Bee streamline your real estate process.</span>
                {
                    user.id &&
                    <>
                        <Separator height={24} />
                        <CustomButton text={'Join Showing Bee'} size={'large'} action={() => navigate('/signup')} />
                    </>
                }
            </div>
        </div>
    );
}


export default SellersInfoPage;