import './footer.scss'
import MainFooter from './modules/MainFooter';
import SubFooter from './modules/SubFooter';

const Footer = ({ subfooter }) => {
    const currentUser = {};

    return (
        <>
            {
                subfooter ?
                <SubFooter /> :
                <MainFooter currentUser={currentUser} />
            }
        </>
    );
}


export default Footer;