import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import './reShowingFormCalendar.scss'
import CalendarView from './modules/CalendarView';
import FormHeader from './modules/FormHeader';
import { inputChange } from 'utils/modules/Handlers'

const ReShowingFormCalendar = () => {
    const { showing } = useSelector((state) => state.showing)
    const urlParams = new URLSearchParams(window.location.search);
    const [currentShowing, setCurrentShowing] = useState({});
    const dispatch = useDispatch();
    const [addBuyer, setAddBuyer] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
        company: {
            value: '',
        },
        buyer_first_name: {
            value: '',
        },
        buyer_last_name: {
            value: '',
        },
        buyer_email: {
            value: '',
        },
        buyer_phone_number: {
            value: '',
        },
    });

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.showing.retrieveShowing(urlParams.get('showing')));    
        }
        init()
    }, [])

    useEffect(() => {
        const init = async() => {
            await inputChange({ setForm: setForm, key: 'email', value: 'value', data: user.email })
            await inputChange({ setForm: setForm, key: 'first_name', value: 'value', data: user.first_name })
            await inputChange({ setForm: setForm, key: 'last_name', value: 'value', data: user.last_name })
            if (user.agent_profile?.company_name) {
                await inputChange({ setForm: setForm, key: 'company', value: 'value', data: user.company })
            }
            if (user.phone_number) {
                await inputChange({ setForm: setForm, key: 'phone_number', value: 'value', data: user.phone_number.replace("+1","") })
            }

            if (showing.buyers.length > 0) {
                setAddBuyer(true);
                const buyer = showing.buyers[0];
                await inputChange({ setForm: setForm, key: 'buyer_email', value: 'value', data: buyer.email })
                await inputChange({ setForm: setForm, key: 'buyer_first_name', value: 'value', data: buyer.first_name })
                await inputChange({ setForm: setForm, key: 'buyer_last_name', value: 'value', data: buyer.last_name })
                if (buyer.phone_number) {
                    await inputChange({ setForm: setForm, key: 'buyer_phone_number', value: 'value', data: buyer.phone_number.replace("+1","") })
                }
            }


        }
        init();
    }, [showing]);

    return (
        <div className="re-showing-calendar-container">
            {
                showing.id &&
                <div className="form">
                    <FormHeader 
                        showing={showing}
                        form={form}
                        setForm={setForm}
                        addBuyer={addBuyer}
                        currentShowing={currentShowing}
                    />
                    <CalendarView 
                        showing={showing} 
                        form={form} 
                        setForm={setForm} 
                        currentShowing={currentShowing} 
                        setCurrentShowing={setCurrentShowing}
                        addBuyer={addBuyer}
                        setAddBuyer={setAddBuyer}
                    />
                </div>
            }
        </div>
    )
}


export default ReShowingFormCalendar;