import * as React from "react"

const Target = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fill="#fff"
      d="M10,6.909A3.091,3.091,0,1,0,13.091,10,3.09,3.09,0,0,0,10,6.909Zm6.908,2.318a6.95,6.95,0,0,0-6.135-6.135V1.5H9.227V3.092A6.95,6.95,0,0,0,3.092,9.227H1.5v1.545H3.092a6.95,6.95,0,0,0,6.135,6.135V18.5h1.545V16.908a6.95,6.95,0,0,0,6.135-6.135H18.5V9.227ZM10,15.409A5.409,5.409,0,1,1,15.409,10,5.405,5.405,0,0,1,10,15.409Z"
      transform="translate(-1.5 -1.5)"
    />
  </svg>
)


export default Target
