import './customContainer.scss';


const CustomContainer = (props) => {
	return (
		<div className='custom-container' style={props.style}>
			{props.children}
		</div>
	)
}


export default CustomContainer;