import * as React from "react";
const Approver = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill={props.color || '#2a2a2a'} d="m8,16.77v-3.77h5.798l-.163-.916c-.097-.561-.083-1.059.037-1.488.42-1.546,1.771-2.596,3.357-2.596.477,0,.95.096,1.406.285.179.074.352.17.517.283C18.484,3.759,14.432,0,9.5,0,4.253,0,0,4.253,0,9.5c0,4.891,3.697,8.915,8.449,9.439l-.449-2.169Zm-3.175-7.831l1.4-1.429,2.385,2.338,4.193-4.066,1.393,1.436-4.455,4.319c-.295.295-.701.463-1.133.463s-.838-.168-1.144-.474l-2.64-2.587Zm15.175,15.061h-8.47l-1.53-7.23v-1.77h6.184l-.58-3.265c-.057-.334-.029-.519-.007-.599.188-.69.75-1.136,1.432-1.136.213,0,.428.044.64.132.254.105.503.367.682.719l1.649,3.119v10.03Zm4-9v9h-2v-9h2Z" />
  </svg>
);
export default Approver;
