import Icons from "components/Icons"
import Separator from "components/Separator"
import moment from "moment"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import actions from "store/actions"
import './actionBlock.scss'
import ModalContainer from "components/ModalContainer"
import CustomTextInput from "components/CustomTextInput"
import CustomButton from "components/CustomButton"

const ActionBlock = ({ data, refresh, selectedPage, isLoading, setIsLoading, duration }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState('');
    const [reason, setReason] = useState('');

    const reFetchShowings = async() => {
        if (selectedPage) {
            await dispatch(actions.showing.fetchShowingsPagination(selectedPage));
        } else {
            refresh();
        }
    }

    const approveShowingBA = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.approveShowingBA(data.id))
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }
    
    const approveShowingLA = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.approveShowingLA(data.id))
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }
    
    const approveShowingSeller = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.approveShowingSeller(data.id))
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }

    const rejectShowingLA = () => {
        setSelected('reject-la');
        setModal(prev => !prev)
    }
    
    const rejectShowingSeller = () => {
        setSelected('reject-seller');
        setModal(prev => !prev)
    }
    
    const approveShowingForSeller = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.approveShowingForSeller(data.id));
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }
    
    const cancelShowing = () => {
        setSelected('cancel-showing');
        setModal(prev => !prev)
    }
    
    const startShowing = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.startShowing(data.id))
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }
    
    const finishShowing = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.finishShowing(data.id))
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }
    
    const sendFeedbackRequest = async() => {
        setIsLoading(true)
        try {
            await dispatch(actions.showing.requestFeedbackShowing(data.id))
            reFetchShowings();
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong.', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false);
        }
    }

    // const continueShowing = async() => {
    //     localStorage.setItem('showingId', data.id);
    //     await window.scrollTo({ top: 0, behavior: 'smooth' });
    //     if(data.buyers.length === 0) {
    //         navigate(`/request/${data.listing_data.mls_source}/${data.listing}/select-buyer`);
    //     } else if (data.date.includes('not set')) {
    //         navigate(`/request/${data.listing_data.mls_source}/${data.listing}/calendar`);
    //     } else {
    //         navigate(`/request/${data.listing_data.mls_source}/${data.listing}/review`);
    //     }
    // }

    const onSendReject = async() => {
        setIsLoading(true)
        if (selected === 'reject-la') {
            try {
                await dispatch(actions.showing.rejectShowingLA({ id: data.id, data: { reason: reason }}));
                reFetchShowings();
                clearModal();
                setIsLoading(false);
            } catch (error) {
                toast.error('Something went wrong.', {
                    position: toast.POSITION.TOP_CENTER
                });
                setIsLoading(false);
            }
        } else if (selected === 'reject-seller') {
            try {
                await dispatch(actions.showing.rejectShowingSeller({ id: data.id, data: { reason: reason }}));
                reFetchShowings();
                clearModal();
                setIsLoading(false);
            } catch (error) {
                toast.error('Something went wrong.', {
                    position: toast.POSITION.TOP_CENTER
                });
                setIsLoading(false);
            }
        } else if (selected === 'cancel-showing') {
            try {
                await dispatch(actions.showing.cancelShowing({ id: data.id, data: { reason: reason }}));
                reFetchShowings();
                clearModal();
                setIsLoading(false);
            } catch (error) {
                toast.error('Something went wrong.', {
                    position: toast.POSITION.TOP_CENTER
                });
                setIsLoading(false);
            }
        } 
    }

    const clearModal = async() => {
        await setModal(prev => !prev);
        setReason('');
        setSelected('');
    }

    return (
        <div className="action-block-container">
            <ModalContainer modal={modal} setModal={setModal} title={'Reason for Rejection/Cancellation'}>
                <div className='form-section'>
                    <CustomTextInput
                        size={'large'}
                        inputProps={{
                            id: 'reason', 
                            placeholder: 'PLease state the reason for rejection/cancellation',
                            onChange: (event) => setReason(event.target.value)
                        }}
                    />
                    <Separator height={16} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: 8 }}>
                        <CustomButton
                            text={'Cancel'}
                            type="secondary"
                            action={clearModal}
                        />
                        <CustomButton
                            text={'Submit'}
                            action={onSendReject}
                            disabled={reason ? false : true}
                        />
                    </div>
                </div>
            </ModalContainer>
                {
                    <div className="actions">
                        {
                            duration === 'current'
                            ? <>
                                { 
                                    (data.status === 'pending' && 
                                    data.buyer_agent &&
                                    !data.buyer_agent_approved && 
                                data.buyer_agent_data?.id === user.id) && 
                                    <p className={`action-button approve ${isLoading && 'disabled'}`} onClick={approveShowingBA}>Approve Request</p>
                                }
                                {
                                    (data.status === 'pending' && (data.listing_data.agent_data.user === user.id || data.listing_data.agent_members.includes(user.id)) &&
                                    ((data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'auto' ) || data.listing_data.showing_approval_type === 'la_only') &&
                                    (data.state !== 'ShowingState.APPROVED' && data.state !== 'ShowingState.SELLER_AGENT_APPROVED')) &&
                                    <p className={`action-button approve ${isLoading && 'disabled'}`} onClick={approveShowingLA}>Approve</p>
                                }
                                {
                                    (data.status === 'pending' && (data.listing_data.agent_data.user === user.id || data.listing_data.agent_members.includes(user.id)) &&
                                    ((data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'auto' ) || data.listing_data.showing_approval_type === 'la_only') &&
                                    (data.state !== 'ShowingState.APPROVED' && data.state !== 'ShowingState.SELLER_AGENT_APPROVED')) &&
                                    <p className={`action-button reject ${isLoading && 'disabled'}`} onClick={rejectShowingLA}>Reject</p>
                                }
                                {
                                    (data.status === 'pending' && data.listing_data.sellers_data.some(seller => seller.id === user.id) &&
                                    ((data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'auto' ) || data.listing_data.showing_approval_type === 'owner_only') &&
                                    (data.state !== 'ShowingState.APPROVED' && data.state !== 'ShowingState.SELLER_CLIENT_APPROVED')) && 
                                    <p className={`action-button approve ${isLoading && 'disabled'}`} onClick={approveShowingSeller}>Approve</p>
                                }
                                {
                                    (data.status === 'pending' && data.listing_data.sellers_data.some(seller => seller.id === user.id) &&
                                    ((data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'auto' ) || data.listing_data.showing_approval_type === 'owner_only') &&
                                    (data.state !== 'ShowingState.APPROVED' && data.state !== 'ShowingState.SELLER_CLIENT_APPROVED')) && 
                                    <p className={`action-button reject ${isLoading && 'disabled'}`} onClick={rejectShowingSeller}>Reject</p>
                                }
                                {
                                    (data.status === 'pending' && (data.listing_data.agent_data.user === user.id || data.listing_data.agent_members.includes(user.id)) &&
                                    ((data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'auto' ) || data.listing_data.showing_approval_type === 'owner_only') &&
                                    (data.state !== 'ShowingState.APPROVED' && data.state !== 'ShowingState.SELLER_CLIENT_APPROVED')) && 
                                    <p className={`action-button approve ${isLoading && 'disabled'}`} onClick={approveShowingForSeller}>Approve for Seller</p>
                                }
                                {
                                    (data.status === 'upcoming' && (data.listing_data.agent_data.user === user.id || data.listing_data.agent_members.includes(user.id)) &&
                                    moment(data.start).startOf('day').isAfter()) && 
                                    <p className={`action-button approve ${isLoading && 'disabled'}`} onClick={startShowing}>Start Showing</p>
                                }
                                
                                {
                                    data.status === 'upcoming' &&
                                    <p className={`action-button reject ${isLoading && 'disabled'}`} onClick={cancelShowing}>Cancel</p>
                                }
                                {
                                    (data.status === 'ongoing' && (data.listing_data.agent_data.user === user.id || data.listing_data.agent_members.includes(user.id)) && moment(data.start).startOf('day').isBefore()) && 
                                    <p className={`action-button approve ${(isLoading) && 'disabled'}`} onClick={finishShowing}>Finish Showing</p>
                                }

                                {
                                    (data.status === 'finished' && !data.feedback_requested && data.listing_data.request_feedback && data.listing_data.agent_data.user === user.id) &&
                                    <p className="action-button approve" onClick={() => sendFeedbackRequest(data.id)}>Send Feedback Request</p>
                                }

                                {/* {
                                    data.status === 'draft' &&
                                    <p className="action-button draft" onClick={continueShowing}>Continue Request</p>
                                } */}
                                {
                                    ((data.buyer_agent && data.buyer_agent_approved === null) && (data.listing_data.agent_data.user === user.id || data.listing_data.agent_members.includes(user.id))) &&
                                    <div className="status-label">{data.status_data.message}</div>
                                }
                                {
                                    (data.status === 'finished' && data.buyer_agent === user.id) &&
                                    <p className="action-button reshwowing" onClick={() => navigate(`re-showing/?showing=${data.id}`)}>Re-Showing</p>
                                }
                            </>
                            : <>
                                {
                                    (data.status === 'finished' && !data.feedback_requested && data.listing_data.request_feedback && data.listing_data.agent_data.user === user.id) &&
                                    <p className="action-button approve" onClick={() => sendFeedbackRequest(data.id)}>Send Feedback Request</p>
                                }
                                {
                                    (data.status === 'finished' && data.buyer_agent === user.id) &&
                                    <p className="action-button reshwowing" onClick={() => navigate(`re-showing/?showing=${data.id}`)}>Re-Showing</p>
                                }
                            </>
                        }
                    </div>
                }
        </div>
    )
}


export default ActionBlock;