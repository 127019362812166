import Seprator from 'components/Separator';
import moment from 'moment';
import './showingTime.scss';

const ShowingTime = ({ status, date, start, end, style }) => {

  const formatTime = () => {
    const dateFormat = moment(date).format('ddd');
    return `${dateFormat} ${start} - ${dateFormat} ${end}`
}

  return (
    <div className={`showing-time-component ${status}`} style={style}>
        <div className="listing-time">
            <p>{`${moment(date).format('MMM')} ${moment(date).format('DD')}`}</p>
            <p className='time'>{formatTime()}</p>
        </div>
    </div>
  )
}


export default ShowingTime;