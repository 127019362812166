import Icons from 'components/Icons';
import './modalContainer.scss';

const ModalContainer = ({ modal, setModal, title, children}) => {

  return (
    <div className={`modal-container-component ${modal && 'visible'}`}>
      <div className='modal-container'>
        <div className='modal-header'>
          <div className='modal-title'>
            {title}
            <div className='close' onClick={() => setModal(prev => !prev)}>
                <Icons.CircleClose width={20} height={20} color={'#808080'} />
            </div>
          </div>
        </div>
        <div className='modal-body'>
          {children}
        </div>
      </div>
    </div>
  )
}


export default ModalContainer;