import Seprator from 'components/Separator'
import ToastMessage from 'components/ToastMessage';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import './inviteConnections.scss'
//FilePond
import { FilePond, registerPlugin } from 'react-filepond';
//FilePond Packages
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
//FilePond Styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import Icons from 'components/Icons';
// FilePond Register Plug-ins
registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode,
    FilePondPluginMediaPreview
)

const InviteConnections = ({ modal, setModal }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
        note: {
            value: '',
        },
        amount: {
            value: '',
        }
    });

    const onFileUpload = (files) => {
        let fileData = []
        try {
            files.map(file => {
                return fileData.push(file.getFileEncodeDataURL())
            })
            if (fileData.length > 0) setFiles(fileData);
            else setFiles([])
        } catch (error) {
            console.log(error)
        }
    }

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onInvite = useCallback(async() => {
        const { email, first_name, last_name, phone_number, note, amount } = form
        let isValid = true

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }
        if (phone_number.value) {
            if (!validatePhone(phone_number.value)) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
                isValid = true
            }
        }

        if (isValid) {
            setLoading(true);
            const data = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                phone_number: phone_number.value ? `1${phone_number.value}` : '',
                approval_document: files[0],
                note: note.value,
                approval_amount: amount.value
            }
            try {
                await dispatch(actions.broker.brokerBuyerInvite(data));
                await dispatch(actions.buyer.fetchBuyerMortgageConnection());
                setLoading(false);
                setModal(prev => !prev);
                toast.success('Connection invitation sent!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [form, files])

    return (
        <div className={`invite-connection-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Invite Connection (Buyer)
                </p>
                <Seprator height={30} />
                <div className='form-input'>
                    <label>First Name</label>
                    <input 
                        id='first_name' 
                        placeholder='Enter First Name' 
                        type='text' 
                        className='small' 
                        value={form.first_name.value}
                        onChange={(event) => onChangeInput({event, type: "first_name"})} 
                    />
                    <span className='error-message' style={{ width: 240 }}>{form.first_name.error}</span>
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Last Name</label>
                    <input 
                        id='last_name' 
                        placeholder='Enter Last Name' 
                        type='text' 
                        className='small' 
                        value={form.last_name.value}
                        onChange={(event) => onChangeInput({event, type: "last_name"})} 
                    />
                    <span className='error-message' style={{ width: 240 }}>{form.last_name.error}</span>
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Email Address</label>
                    <input 
                        id='email' 
                        placeholder='Enter Email Address' 
                        type='text' 
                        className='small' 
                        value={form.email.value}
                        onChange={(event) => onChangeInput({event, type: "email"})} 
                    />
                    <span className='error-message' style={{ width: 240 }}>{form.email.error}</span>
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Phone Number</label>
                    <input 
                        id='phone_number' 
                        placeholder='Enter Phone Number' 
                        type='text' 
                        className='small' 
                        maxLength={10} 
                        value={form.phone_number.value}
                        onChange={(event) => onChangeInput({event, type: "phone_number"})} 
                    />
                    <span className='error-message' style={{ alignSelf: 'flex-start' }}>{form.phone_number.error}</span>
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Approval Document</label>
                    <FilePond
                        files={files}
                        oninit={() => {}}
                        maxFileSize="5MB"
                        imagePreviewHeight={110}
                        allowMultiple={false}
                        allowFileTypeValidation={true}
                        acceptedFileTypes={['image/*', 'application/pdf']}
                        onupdatefiles={(fileItems) => {
                            onFileUpload(fileItems);
                        }}
                    />
                </div>
                <Seprator height={10} />
                <div className='form-input'>
                    <label>Pre-Approval Amount</label>
                    <input 
                        id='amount' 
                        placeholder='Enter Pre-Approval Amount' 
                        type='text' 
                        className='small' 
                        value={form.amount.value}
                        onChange={(event) => onChangeInput({event, type: "amount"})} 
                    />
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Note</label>
                    <textarea
                        onChange={(event) => onChangeInput({event, type: "note"})}
                        style={{ height: 150, padding: 15 }}
                        placeholder='Note' 
                        className='small' 
                    ></textarea>
                </div>
                <Seprator height={45} />
                <button className='button primary small no-glow' onClick={onInvite} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Invite'
                }
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default InviteConnections;