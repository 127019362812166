// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper .inner {
  max-width: 1440px;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/PageWrapper/pageWrapper.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,YAAA;AAAR","sourcesContent":[".page-wrapper {\n    .inner {\n        max-width: 1440px;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
