import * as React from "react"

const Bed = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M3.24115 8.0895H4.91169V7.094C4.91169 6.44766 5.25693 6.09881 5.87836 6.09881H8.32925C8.95068 6.09881 9.29592 6.44766 9.29592 7.094V8.08919H10.787V7.094C10.787 6.44766 11.1322 6.09881 11.7882 6.09881H14.0873C14.7432 6.09881 15.0885 6.44766 15.0885 7.094V8.08919H16.7593V6.10602C16.7593 4.82023 16.1105 4.16669 14.923 4.16669L5.07746 4.16669C3.89681 4.16669 3.24115 4.82023 3.24115 6.10602L3.24115 8.0895ZM1.66675 15.2521C1.66675 15.6082 1.88758 15.8334 2.23282 15.8334H2.61972C2.95782 15.8334 3.17865 15.6079 3.17865 15.2521V14.2206C3.25455 14.2426 3.47568 14.257 3.63431 14.257L16.3724 14.257C16.5313 14.257 16.7456 14.2422 16.8215 14.2206V15.2521C16.8215 15.6082 17.0423 15.8334 17.3807 15.8334H17.7742C18.1126 15.8334 18.3334 15.6079 18.3334 15.2521L18.3334 11.1186C18.3334 9.80368 17.6361 9.08469 16.3727 9.08469L3.62746 9.08469C2.36407 9.08469 1.66675 9.80368 1.66675 11.1186L1.66675 15.2521Z"
            fill={props.color || "#2A2A2A"}
        />
    </svg>
)


export default Bed
