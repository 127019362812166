import * as React from "react"

const Showing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1731_3935)">
      <path
        d="M12.5087 0.666672H5.49137C2.82655 0.666672 0.666687 2.82653 0.666687 5.49135V15.8274C0.666687 16.6593 1.34108 17.3333 2.17263 17.3333H12.5087C15.1731 17.3333 17.3334 15.1735 17.3334 12.5087V5.49135C17.3334 2.82653 15.1731 0.666672 12.5087 0.666672ZM10.6706 10.684C10.6706 11.1431 10.2982 11.5155 9.83902 11.5155H4.83447C4.37531 11.5155 4.00293 11.1431 4.00293 10.684C4.00293 10.2248 4.37531 9.85241 4.83447 9.85241H9.83902C10.2982 9.85241 10.6706 10.2248 10.6706 10.684ZM13.1656 8.15692H4.83447C4.37531 8.15692 4.00293 7.78453 4.00293 7.32537C4.00293 6.86621 4.37531 6.49382 4.83447 6.49382H13.1656C13.6247 6.49382 13.9971 6.86621 13.9971 7.32537C13.9971 7.78453 13.6247 8.15692 13.1656 8.15692Z"
        fill={props.color || "#949494"}
      />
    </g>
    <defs>
      <clipPath id="clip0_1731_3935">
        <rect
          width={16.6667}
          height={16.6667}
          fill="white"
          transform="translate(0.666687 0.666672)"
        />
      </clipPath>
    </defs>
  </svg>
)


export default Showing
