import './catalogueContainer.scss';

const CatalogueContainer = ({ children, type='light', style }) => {
    return (
        <div className={`catalogue-container ${type}`} style={style}>
            {children}
        </div>
    )
}

export default CatalogueContainer;  