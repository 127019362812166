import Seprator from 'components/Separator';
import './customButton.scss';
import Icons from 'components/Icons';

/* ACCEPTABLE PROPS VALUE
 * type: primary, secondary, tertiary, text, error, success
 * size: small, normal(default), large
 * iconOnly: button with icon only
 */

const CustomButton = ({ text, size, type = 'primary', iconLeft, iconRight, disabled, loading, iconOnly, style, action = () => { } }) => {
  return (
    <button className={`custom-button ${size} ${type} ${(!iconLeft && !iconRight) && 'center'}`} disabled={disabled || loading} onClick={action} style={style}>
      {
        !iconOnly
          ? <>
            {
              iconLeft &&
              <div className='icon-left'>
                {iconLeft}
              </div>
            }
            {
              !loading 
              ? <p className={`${iconLeft && 'has-left-icon'}`}> {text} </p>
              : <Icons.Loading height={16} width={16} background={'none'} />
            }
            {
              iconRight &&
              <div className='icon-right'>{iconRight}</div>
            }
          </>
          : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{iconOnly}</div>
      }
    </button>
  )
}

export default CustomButton;