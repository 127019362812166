import Icons from 'components/Icons';
import { checkImageUrl } from 'utils/modules/Handlers';
import './buyerInfoModal.scss'
import NoUser from 'assets/images/no_user.jpg'
import Separator from 'components/Separator';



const BuyerInfoModal = ({ modal, setModal, selectedData }) => {
    return (
        <div className={`buyer-modal ${modal && 'visible'}`}>
            <div className='modal-container'>
                <div className='modal-title'>
                    Buyer Information
                    <div className='close' onClick={() => setModal(prev => !prev)}>
                        <Icons.Close />
                    </div>
                </div>
                <Separator height={20} />
                <div className='buyer-details'>
                    <Separator height={20} />
                    <img className="buyer-image" src={checkImageUrl(selectedData.buyer?.profile_photo, NoUser)} alt="" />
                    <Separator height={40} />
                    <p className="name">{selectedData.buyer?.first_name} {selectedData.buyer?.last_name}</p>
                    <Separator height={10} />
                    <div className='content'>
                        <div>
                            <Icons.EmailSmall color={'#6C767C'} />
                            <Separator width={10} />
                            <span>{selectedData.buyer?.email || 'Not Available'}</span>
                        </div>
                        <Separator width={20} />
                        <div>
                            <Icons.PhoneSmall color={'#6C767C'} />
                            <Separator width={10} />
                            <span>{selectedData.buyer?.phone_number || 'Not Available'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyerInfoModal