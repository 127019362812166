import * as React from "react"

const More = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <g id="Group_270" data-name="Group 270" transform="translate(-254 -118)">
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx={25}
        cy={25}
        r={25}
        transform="translate(254 118)"
        fill="#fac91f"
      />
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx={3}
        cy={3}
        r={3}
        transform="translate(265 140)"
        fill="#fff"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx={3}
        cy={3}
        r={3}
        transform="translate(276 140)"
        fill="#fff"
      />
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx={3}
        cy={3}
        r={3}
        transform="translate(287 140)"
        fill="#fff"
      />
    </g>
  </svg>
)


export default More
