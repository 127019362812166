import * as React from "react"

const Approve = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.747}
    height={10.252}
    viewBox="0 0 13.747 10.252"
    {...props}
  >
    <path
      fill="#02b946"
      d="M4.669,14.627.2,10.16a.687.687,0,0,1,0-.972l.972-.972a.687.687,0,0,1,.972,0l3.01,3.01L11.6,4.779a.687.687,0,0,1,.972,0l.972.972a.687.687,0,0,1,0,.972l-7.9,7.9A.687.687,0,0,1,4.669,14.627Z"
      transform="translate(0 -4.577)"
    />
  </svg>
)


export default Approve
