import * as React from "react";


const Menu = (props) => (
    <svg
        width={18}
        height={14}
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            x={0.666687}
            y={5.51849}
            width={16.6667}
            height={2.96296}
            rx={1.48148}
            fill={props.color || "#949494"}
        />
        <rect
            x={4}
            y={0.333344}
            width={10}
            height={2.96296}
            rx={1.48148}
            fill={props.color || "#949494"}
        />
        <rect
            x={4}
            y={10.7038}
            width={10}
            height={2.96296}
            rx={1.48148}
            fill={props.color || "#949494"}
        />
    </svg>
);


export default Menu;
