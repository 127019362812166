import { Instance } from "utils";

const fetchFeaturedMortgageBrokers = (agent) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/contacts/featured-mortgage-brokers/`);
}

const approveRejectRequest = ({ id, type, data }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/buyer-broker-connection/${id}/${type}/`, data);
}

const brokerBuyerInvite = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/buyer-broker-connection/buyer-invite/`, data);
}

const subscribeFeaturedBroker = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/stripe/subscribe/featured-mortgage-broker/`);
}

const unsubscribeFeaturedBroker = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/stripe/unsubscribe/featured-mortgage-broker/`);
}

const updateBuyerTier = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/buyer-broker-connection/update-buyer-tier/`, data);
}

const brokerDashboardData = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('/buyer-broker-connection/dashboard/');
}

const notifyBuyer = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/buyer-broker-connection/notify-buyer/`, data);
}

const notifyAddDate = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/buyer-broker-connection/notify-add-date/`, data);
}

const broker = {
    fetchFeaturedMortgageBrokers,
    approveRejectRequest,
    brokerBuyerInvite,
    subscribeFeaturedBroker,
    unsubscribeFeaturedBroker,
    updateBuyerTier,
    brokerDashboardData,
    notifyBuyer,
    notifyAddDate,
 }


export default broker;
