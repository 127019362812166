import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from 'store/actions';
import './showingFeedback.scss'
import FormHeader from './modules/FormHeader';
import FormNav from './modules/FormNav';
import Feedbacks from './modules/Feedbacks';

const ShowingFeeback = () => {
    const { id } = useParams();
    const { listing } = useSelector((state) => state.listing);
    const dispatch = useDispatch();
    const [addModal, setAddModal] = useState(false);

    useEffect(() => {
      const init = async() => {
          await dispatch(actions.listing.retrieveListing(id));
      }
      init();
    }, [])

    return (
        <div className="showing-feedback-container">
            <div className="nav">
                <FormNav setModal={setAddModal} />
            </div>
            {
                listing.id &&
                <div className="form">
                    <FormHeader data={listing} />
                    <Feedbacks listing={listing} />
                </div>
            }
        </div>
    )
}


export default ShowingFeeback;