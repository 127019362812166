import SBLogo from 'assets/images/SBLogo.png'
import Icons from 'components/Icons';
import Seprator from 'components/Separator';
import { useCallback, useEffect, useState } from 'react';
import CopyRightModal from '../CopyRightModal';
import { useNavigate } from 'react-router-dom';

const CookiePolicy = () => {
    const navigate = useNavigate();
    const [active, setActive] = useState('intro');
    const [modal, setModal] = useState(false);

    useEffect(() => {
        const container = document.getElementById('container');
        container.addEventListener('scroll', checkPosition);
    }, [])


    const scrollToHash = useCallback(async (hash) => {
        const el = document.getElementById(hash);
        const container = document.getElementById('container');
        container.removeEventListener('scroll', checkPosition);
        el.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
        setActive(hash)
        setTimeout(() => {
            container.addEventListener('scroll', checkPosition);
        }, 1500);
    }, [])

    const checkPosition = (event) => {
        const y = (window.innerHeight / 2) - 480;
        const scrollableHeight = event.currentTarget.scrollHeight - event.currentTarget.clientHeight

        const section1 = event.currentTarget.querySelector('#section-1')
        const section2_1 = event.currentTarget.querySelector('#section-2_1')
        const section2_2 = event.currentTarget.querySelector('#section-2_2')
        const section2_3 = event.currentTarget.querySelector('#section-2_3')


        if (event.currentTarget.scrollTop === 0) { setActive('intro'); }
        if (y > section1.getBoundingClientRect().y - 20 && y < section1.getBoundingClientRect().y + 20) { setActive('section-1'); }
        if (y > section2_1.getBoundingClientRect().y - 20 && y < section2_1.getBoundingClientRect().y + 20) { setActive('section-2_1'); }
        if (y > section2_2.getBoundingClientRect().y - 20 && y < section2_2.getBoundingClientRect().y + 20) { setActive('section-2_2'); }
        if (y > section2_3.getBoundingClientRect().y - 20 && y < section2_3.getBoundingClientRect().y + 20) { setActive('section-2_3'); }
        if (event.currentTarget.scrollTop >= scrollableHeight) { setActive('section-3'); }
    }

    return (
        <div className="disclosure-page">
            <CopyRightModal modal={modal} setModal={setModal} />
            <div className="nav">
                <div>
                    <img className='cursor' src={SBLogo} alt='logo' onClick={() => navigate('/')} />
                    <Seprator height={70} />
                    <ul>
                        <li className={`${active === 'intro' && 'active'}`} onClick={() => scrollToHash('intro')}>SHOWING BEE’S Cookie Policy</li>
                        <li className={`${active === 'section-1' && 'active'}`} onClick={() => scrollToHash('section-1')}>1. What are cookies? </li>
                        <li className={`${(active === 'section-2_1' || active === 'section-2_2' || active === 'section-2_3') && 'active'}`} onClick={() => scrollToHash('section-2_1')}>2. The types of cookies that we use</li>
                        <li className={`${active === 'section-2_2' && 'active'} indent`} onClick={() => scrollToHash('section-2_2')}><Icons.ArrowRight height={10} color={`${active === 'section-2_2' ? '#2a2a2a' : '#6C767C'}`} /> Basic performance – 'necessary' or 'essential' cookies</li>
                        <li className={`${active === 'section-2_3' && 'active'} indent`} onClick={() => scrollToHash('section-2_3')}><Icons.ArrowRight height={10} color={`${active === 'section-2_3' ? '#2a2a2a' : '#6C767C'}`} /> Help us to improve – 'analytics' cookies</li>
                        <li className={`${active === 'section-3' && 'active'}`} onClick={() => scrollToHash('section-3')}>3. Cookie set by third parties</li>
                        <li className={`${(active === 'section-4' || active === 'section-3') && 'active'}`} onClick={() => scrollToHash('section-4')}>4. How to manage cookies using your browser</li>
                    </ul>
                </div>
            </div>
            <div className="info">
                <div id='container' className={`${modal && 'fixed'}`}>
                    {/* =================================================== Intro SECTION ===================================================*/}
                    <section id='intro'>
                        <h1>SHOWING BEE’S Cookie Policy</h1>
                        <Seprator height={70} />
                        <p className='body'>
                            Our website uses a number of cookies for a number of reasons. We explain these in this policy. Before we can use some - but not all - of these cookies, we need your consent.
                        </p>
                        <br />
                        <p className='body'>
                            You may alter your consent preferences - including to withdraw consent to non-essential cookies which you have previously given - at any time by moving the <span className='link'>on/off toggles below</span>, or by clicking '<span className='link'>Reject all non-essential cookies</span>' to reject all optional cookies. 
                            Any selections you make will be automatically saved and you may navigate away from the Cookie Policy.
                        </p>
                        <Seprator height={50} />
                        <p className='header'>Essential cookies </p>
                        <Seprator height={30} />
                        <p className='body'>
                            These are used to operate the core functions of our website, so that you may visit and move around it, and use its features. We do not require your consent to use these cookies.
                        </p>
                        <Seprator height={50} />
                        <p className='header'>Experience enhancing cookies</p>
                        <Seprator height={30} />
                        <p className='body'>
                            They make your online experience easier by saving browsing information. With cookies, sites can: Keep you signed in, remember your site preferences, give you locally relevant content. We use these types of cookies to improve our services.  
                        </p>
                        <Seprator height={50} />
                        <p className='header'>Analytics cookies </p>
                        <Seprator height={30} />
                        <p className='body'>
                        These help us to understand how visitors use and move around our website, which informs improvements and adaptations to our website. Rejecting these cookies prevents us from collecting such data. The performance of our site will not be affected.
                        </p>
                        <Seprator height={50} />
                        <p className='header'>Social media cookies </p>
                        <Seprator height={30} />
                        <p className='body'>
                            These provide us with anonymized demographics and browsing activity information and help us to tailor and measure the effectiveness of our advertising on social media sites and other data about the effectiveness of our Waggle. Rejecting these prevents us from relating your visits to our website to our social media marketing campaigns and prevents social media sites from setting cookies on your device via showingbee.com.
                        </p>
                        <Seprator height={50} />
                        <div>
                            <input 
                                type='checkbox' 
                                className='small'
                                id='experience'
                                onChange={(event) => {}} 
                            /> <label htmlFor='experience'>Experience enhancing cookies</label>
                        </div>
                        <br />
                        <div>
                            <input 
                                type='checkbox' 
                                className='small'
                                id='analytics'
                                onChange={(event) => {}} 
                            /> <label htmlFor='analytics'>Analytics cookies</label>
                        </div>
                        <br />
                        <div>
                            <input 
                                type='checkbox' 
                                className='small'
                                id='social'
                                onChange={(event) => {}} 
                            /> <label htmlFor='social'>Social media cookies</label>
                        </div>
                        <br />
                        <div>
                            <input 
                                type='checkbox' 
                                className='small'
                                id='reject_all'
                                onChange={(event) => {}} 
                            /> <label htmlFor='reject_all'>Reject all non-essential cookies </label>
                        </div>
                        <Seprator height={30} />
                        <p className='body'><span className='link'>*</span>Your selections will be automatically saved, and you may navigate away from the Cookie Policy. </p>
                        <br />
                        <p className='body'>
                            If you have any queries concerning our use of your personal information, please email <span className='link'>yourprivacy@showingbee.com</span>. Please also visit our <span className='link'>Privacy Policy</span>, which explains how we collect your personal information, what we do with it and your rights in respect of it. 
                        </p>
                    </section>
                    {/* =================================================== SECTION 1 ===================================================*/}
                    <section id='section-1'>
                        <p className='header'>1. What are cookies?</p>
                        <Seprator height={30} />
                        <p className='body'>
                        Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Software on your device, for example a web browser, stores the cookies and sends them back to a website next time you visit. Cookies allow websites to recognize your device and preferences and provide information to the owners of sites which can be used to improve your online experience.
                        </p>
                    </section>
                    {/* =================================================== SECTION 2.1 ===================================================*/}
                    <section id='section-2_1'>
                        <p className='header'>2. The types of cookies that we use</p>
                        <Seprator height={30} />
                        <p className='body'>
                            This section sets out information about the cookie technology that we use on our site for which we are responsible. Where 'personal data' is processed by these cookies, SHOWING BEE is the 'data controller' of that information, within the meaning of applicable data protection legislation. For an explanation of the meaning of these and other data protection legislation terms, and for comprehensive information about our responsibilities when handling personal data please see our <span className='link'>Privacy Policy</span>.
                        </p>
                        <p className='body'>
                            Third party providers of the cookie technology that we use may process data collected by the cookies on our site, in an aggregated form, for their own purposes. If this is the case in respect of any of the third-party cookie providers that we use, we have indicated as much below.
                        </p>
                    </section>
                    {/* =================================================== SECTION 2.2 ===================================================*/}
                    <section id='section-2_2'>
                        <p className='header'>Basic performance – 'necessary' or 'essential' cookies</p>
                        <Seprator height={30} />
                        <p className='body'>
                            We use essential cookies to operate the core functions of our website, so that you may visit and 	move around it and use its features. We do not require your consent to use these cookies, but 	you may be able to block these cookies yourself on your device/browser. However, without 	these cookies, our site is unlikely to work as you would expect and certain services that you 	may ask for, for example, signing into your online account, cannot be provided
                        </p>
                        <p className='body'>
                            Where we process 'personal data' using these essential cookies, we do so based on our 	legitimate interests to provide a website for visitors to use and to promote our business. 
                        </p>
                        <p className='body'>
                            The essential cookies that we use on our website are technically necessary for the site to 	function properly. For example, essential cookies are required so that you can log into 		your account, set your preferences and complete forms. They remember you and your settings 	and save you having to retype information when navigating between pages. They ensure our 	pages load quickly by distributing the workload. Essential cookies are also necessary for our 	site to distinguish between browsers, differentiate users behind a shared IP address so as 	to deliver correct content, and to recognize trusted traffic and apply security settings.
                        </p>
                        <Seprator height={30} />
                        <p className='body'>
                            <span className='header'>Data processed by essential cookies</span><br />
                            When you navigate to and around our website our essential cookies collect your session ID, a 	time stamp of your visit and log in, and session verification data. Our essential cookies may also 	collect information about your browser type and settings, device type and settings, operating 	system and mobile network.  
                        </p>
                        <Seprator height={30} />
                        <p className='body'>
                            <span className='header'>Recipients</span><br />
                            We process data collected by essential cookies on our own servers. Data collected by certain 	essential cookies may be collected in aggregate form only from which no one visitor to our site 	can be identified or distinguished from another, by our third-party essential cookie providers 	for their own purposes.  
                        </p>
                        <Seprator height={30} />
                        <p className='body'>
                            <span className='header'>Transfers</span><br />
                            Our global presence means that your personal information may be transferred across the 	business worldwide due, for example, to our shared IT systems and datacenters, and cross-	border working practices. For more information about such transfers across our business please <span className='link'>see section 3 of our Privacy Policy</span>.  
                        </p>
                        <Seprator height={30} />
                        <p className='body'>
                            <span className='header'>Duration</span><br />
                            Certain of our essential cookies are session cookies, meaning they expire once you exit your session and close your browser.
                            <br />
                            Some of our essential cookies may last for six months or longer, for example to remember your 	cookie preferences for this period and to recognize trusted traffic, or for as long as you retain an 	online account with us.   
                        </p>
                    </section>
                    {/* =================================================== SECTION 2.3 ===================================================*/}
                    <section id='section-2_3'>
                        <p className='header'>Help us to improve – 'analytics' cookies</p>
                        <Seprator height={30} />
                        <p className='body'>
                            Analytics cookies are used by us for statistical analysis purposes. This helps us to understand how visitors use and move around our website, which inform improvements and adaptions to our website to best meet our visitors' needs by, for example, making the most popular features the easiest to find and access or ensuring that our site works properly on the devices and technologies that our visitors use. 
                        </p>
                        <p className='body'>
                            These cookies work by using a distinctive sequence of characters from which your browser or device can be identified ('unique ID'), which, for example, can be used by us to recognize the date and time of your visit(s) to our site. Our analytics cookies may also collect information about your browser type and settings, device type and settings, operating system and mobile network. This information is used to distinguish you from other visitors to our site, but it cannot be used to identify you as a named individual.
                        </p>
                        <p className='body'>
                            We will only set these types of cookies where you have provided us with your consent to do so by either choosing 'Allow all cookies' or by adjusting your cookie settings via the '<span className='link'>Your cookies settings</span>', above. Where we process your personal information using these types of cookies, we do so on the basis of that consent.
                        </p>
                        <p className='bidy'>
                            Rejecting our analytics cookies will prevent us from collecting the data described above to improve our site for you and other visitors. The performance and functionality of our site will not be affected. 
                        </p>
                        <Seprator height={30} />
                        <p className='header'>The analytics cookies that we use </p>
                        <Seprator height={30} />
                        <p className='body'>
                            <span className='header'>Google Analytics</span><br />
                            Google Analytics is a widely used platform for measuring the performance of a website. It helps us to understand things like what our visitors are reading and what technology (e.g., browsers) we should prioritize for testing. This information is used to help us give you the best possible experience – more of what you want, and less of what you don’t.
                            <br />
                            We process the data collected by these Google Analytics cookies. Google Analytics may provide us with technical support. Data may be collected - in aggregate form only from which no one visitor to our site can be identified or distinguished from another - by Google Analytics to help improve Google Analytics' products and services.
                            <br />
                            Rejecting analytic cookies will prevent us from using Google Analytics cookies to collect data about your visit(s) to our site, which would be used by us in aggregation with other visitors' data to understand visitors' behavior and how many visits are made to our site. This will not affect any consent to the use of Google Analytics cookies that you may give to other websites. 
                        </p>
                        <Seprator height={30} />
                        <p className='body'>
                            <span className='header'>Social media cookies </span><br />
                            These provide us with anonymized demographics and browsing activity information and help us to tailor and measure the effectiveness of our advertising on social media sites. Rejecting these prevents us from relating your visits to our website to our social media marketing campaigns and prevents social media sites from setting cookies on your device. More information:
                            <br />
                            We have configured the social media plug-ins on our website to only set cookies on devices used 	 by logged-in members of those social media platforms. For example, we may advertise the 	Services that we provide on certain third-party websites. We use cookies from those third parties' sites on our own website to:
                        </p>
                        <div className='numerals'>
                            <ul className='list'>
                                <li>
                                    <p className='body'>
                                    provide us with anonymized demographics and browsing activity information of the logged-in visitors to our own website; 
                                    </p>
                                </li>
                                <li>
                                    <p className='body'>
                                    help us to tailor our advertising on the websites of those third parties to previous logged-in visitors to certain pages of our own site; and 
                                    </p>
                                </li>
                                <li>
                                    <p className='body'>
                                    help us measure the effectiveness of our advertising. 
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <Seprator height={30} />
                        <p className='body'>
                            These cookies work by using a distinctive sequence of characters from which your browser or device can be identified ('unique ID'). Social media cookies may also collect information about your browser type and settings, device type and settings, operating system and mobile network. This information is used to distinguish you from other visitors to our site, but it cannot be used to identify you as a named individual. 
                        </p>
                        <p className='body'>
                            We will only set these types of cookies where you have provided us with your consent to do so by either choosing '<span className='link'>Allow all cookies</span>' or by adjusting your cookie settings via the '<span className='link'>Your cookies settings</span>', above. Where we process your personal information using these types of cookies, we do so based on that consent.
                        </p>
                        <p className='body'>
                            Rejecting these cookies will prevent us from relating your visits to our website to our marketing campaigns. It will also prevent any cookies that you have given consent for to use to better understand your browsing behavior from being stored on your device.
                        </p>
                    </section>
                    {/* =================================================== SECTION 3 ===================================================*/}
                    <section id='section-3'>
                        <p className='header'>3. Cookie set by third parties</p>
                        <Seprator height={30} />
                        <p className='body'>
                            '<span className='link'>Third party cookies</span>' are cookies set by a domain other than the one that you are visiting. When you visit our website, third party organizations may place cookies on your browser, even if you are not a logged-in member of those organizations. This is because third party cookies do not require you to actively engage with the third party's content or visit their site, just load a page on which the third party's technology sits. For example, we use third party technologies such as video and audio hosting platforms to enable you to watch videos and listen to podcasts on our website. The providers of these technologies may deploy cookies to your browser when you visit the pages on our site where we are using their technologies.
                        </p>
                        <br />
                        <p className='body'>
                            These cookies may inform the third-party organizations about your visit to our site which may be used by those organizations to deliver targeted messages and advertising to you. In all cases, we do not set these cookies and we have no access to the information that they gather. Such cookies are subject to the third-party organizations' own cookie policies. 
                        </p>
                    </section>
                    {/* =================================================== SECTION 3 ===================================================*/}
                    <section id='section-4'>
                        <p className='header'>4. How to manage cookies using your browser </p>
                        <Seprator height={30} />
                        <p className='body'>
                        All recent versions of popular browsers give you a level of control over cookies. You can set your browser to accept or reject all, or certain, cookies.
                        </p>
                        <br />
                        <p className='body'>
                        If you use your browser settings to block all cookies, which would include blocking our 'basic performance'/ essential cookies, you may not be able to access all or parts of our site.
                        </p>
                    </section>
                    <section className='bottom'>
                        <p className='header cursor' onClick={() => navigate('/terms-of-use/')}>Terms of Use</p> | <p className='header cursor' onClick={() => navigate('/privacy-center/')}>Privacy Center</p> | <p className='header link cursor'>Cookie Policy</p> 
                    </section>
                </div>
            </div>
        </div>
    )
}


export default CookiePolicy;