import * as React from "react";

const ListingImageIcon = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={16} cy={16} r={16} fill="#F9F9F9" />
        <path
            d="M12.5 10C11.837 10 11.2011 10.2634 10.7322 10.7322C10.2634 11.2011 10 11.837 10 12.5V19.498C10 20.008 10.152 20.481 10.414 20.877L14.797 16.494C15.1158 16.1752 15.5482 15.9962 15.999 15.9962C16.4498 15.9962 16.8822 16.1752 17.201 16.494L17.541 16.834L19.629 14.747C19.9339 14.4419 20.3109 14.2187 20.725 14.0983C21.1392 13.9778 21.5771 13.964 21.998 14.058V12.5C21.998 11.837 21.7346 11.2011 21.2658 10.7322C20.7969 10.2634 20.161 10 19.498 10H12.5ZM19.498 13.501C19.5022 13.6352 19.4795 13.7688 19.4311 13.8941C19.3826 14.0193 19.3095 14.1335 19.2161 14.2299C19.1227 14.3263 19.0109 14.403 18.8872 14.4553C18.7636 14.5077 18.6307 14.5347 18.4965 14.5347C18.3623 14.5347 18.2294 14.5077 18.1058 14.4553C17.9821 14.403 17.8703 14.3263 17.7769 14.2299C17.6835 14.1335 17.6104 14.0193 17.5619 13.8941C17.5135 13.7688 17.4908 13.6352 17.495 13.501C17.5032 13.2409 17.6123 12.9941 17.7993 12.813C17.9862 12.6319 18.2362 12.5307 18.4965 12.5307C18.7568 12.5307 19.0068 12.6319 19.1937 12.813C19.3807 12.9941 19.4898 13.2409 19.498 13.501ZM21.262 15.007C20.9115 15.0463 20.5848 15.204 20.336 15.454L16.05 19.742C15.6952 20.0986 15.443 20.5442 15.32 21.032L15.016 22.242C14.9904 22.3442 14.9917 22.4514 15.0198 22.553C15.0479 22.6545 15.1018 22.7471 15.1764 22.8216C15.2509 22.8962 15.3435 22.9501 15.445 22.9782C15.5466 23.0063 15.6538 23.0076 15.756 22.982L16.966 22.679C17.4538 22.556 17.8994 22.3038 18.256 21.949L22.544 17.661C22.7732 17.4322 22.9256 17.1377 22.9799 16.8184C23.0342 16.4991 22.9878 16.1709 22.8471 15.8791C22.7064 15.5874 22.4784 15.3467 22.1947 15.1904C21.9111 15.0341 21.5858 14.97 21.264 15.007M16.835 17.541L15.342 19.034C14.8586 19.5188 14.5152 20.1251 14.348 20.789L14.046 21.998H12.5C11.99 21.998 11.516 21.845 11.121 21.584L15.504 17.2C15.569 17.135 15.6462 17.0834 15.7311 17.0482C15.816 17.0131 15.9071 16.9949 15.999 16.9949C16.0909 16.9949 16.182 17.0131 16.2669 17.0482C16.3518 17.0834 16.429 17.135 16.494 17.2L16.835 17.541Z"
            fill="#949494"
        />
    </svg>
);


export default ListingImageIcon;
