import * as React from "react"

const Email = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props} viewBox="0 0 30 30">
    <defs>
      <clipPath id="a">
        <path
          d="M26.806 6H5.645a2.642 2.642 0 0 0-2.632 2.645L3 24.516a2.653 2.653 0 0 0 2.645 2.645h21.161a2.653 2.653 0 0 0 2.645-2.645V8.645A2.653 2.653 0 0 0 26.806 6Zm0 5.29L16.225 17.9l-10.58-6.61V8.645l10.58 6.613 10.58-6.613Z"
          transform="translate(-13015.226 -6796.33)"
          fill={props.color || '#fff'}
        />
      </clipPath>
    </defs>
    <g transform="translate(13032 6794)">
      <rect
        width={3}
        height={19}
        rx={1}
        transform="translate(-13032 -6790)"
        fill={props.color || '#fff'}
      />
      <rect
        width={3}
        height={19}
        rx={1}
        transform="translate(-13005 -6790)"
        fill={props.color || '#fff'}
      />
      <g transform="translate(-18)" clipPath="url(#a)">
        <path fill={props.color || '#fff'} d="M-13012-6788h26v15h-26z" />
      </g>
      <path
        d="M-13029-6794h24a3 3 0 0 1 3 3v3h-30v-3a3 3 0 0 1 3-3ZM-13005-6764h-24a3 3 0 0 1-3-3v-6h30v6a3 3 0 0 1-3 3Z"
        fill={props.color || '#fff'}
      />
    </g>
  </svg>
)


export default Email
