import * as React from "react"

const Share = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.057}
    height={10}
    viewBox="0 0 9.057 10"
    {...props}
  >
    <path
      fill={props.color || '#2a2a2a'}
      d="M12.043,10.179a1.375,1.375,0,0,0-.984.377L7.452,8.471A1.69,1.69,0,0,0,7.5,8.119a1.689,1.689,0,0,0-.051-.352l3.557-2.06A1.51,1.51,0,1,0,10.529,4.6a1.68,1.68,0,0,0,.051.352L7.023,7.013a1.519,1.519,0,0,0-1.034-.4A1.494,1.494,0,0,0,4.5,8.119,1.519,1.519,0,0,0,7.048,9.225L10.63,11.31a1.26,1.26,0,0,0-.05.327,1.463,1.463,0,1,0,1.463-1.457Z"
      transform="translate(-4.5 -3.094)"
    />
  </svg>
)


export default Share
