import './customCheckbox.scss';

const CustomCheckbox = ({ title, inputProps, id, labelStyle }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type='checkbox'
                className='custom-checkbox'
                id={id}
                {...inputProps}
            /> <label htmlFor={id} style={labelStyle}>{title}</label>
        </div>
    )
}


export default CustomCheckbox;