import * as React from "react"

const ShowingForm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.707}
    height={25}
    viewBox="0 0 29.707 25"
    {...props}
  >
    <g transform="translate(-575 173.608)">
      <g transform="translate(575 -173.608)">
        <rect
          fill={props.color || "#2a2a2a"}
          width={4.206}
          height={2.608}
          rx={1.304}
          transform="translate(0 5.403)"
        />
        <rect
          fill={props.color || "#2a2a2a"}
          width={4.206}
          height={2.608}
          rx={1.304}
          transform="translate(0 11.375)"
        />
        <rect
          fill={props.color || "#2a2a2a"}
          width={4.206}
          height={2.608}
          rx={1.304}
          transform="translate(0 17.263)"
        />
        <path
          fill={props.color || "#2a2a2a"}
          d="M1191-166.87v-2.587s-.063-1.754,1.393-1.754h17.438s2.777-.416,2.645,2.782c-1.762,3.052-7.381,11.492-7.381,11.492a10.718,10.718,0,0,0-.6,2.3c-.23,1.435-.957,5.228-.957,5.228s-.23.851.6.532,5.228-2.534,5.228-2.534a2.784,2.784,0,0,0,1.311-1.063c.625-.864,1.728-2.654,1.728-2.654v6.353s.116,2.534-1.551,2.534l-18.421.009s-1.347-.069-1.347-1.808V-150.5s3.137.64,3.137-2.233-3.137-2.321-3.137-2.321v-1.258s3.137.536,3.137-2.419-3.223-2.251-3.223-2.251v-1.17s3.223.438,3.223-2.3S1191-166.87,1191-166.87Z"
          transform="translate(-1188.893 171.229)"
        />
        <path
          fill={props.color || "#2a2a2a"}
          d="M1377.335-107.962,1371-98.19l3.7,2.336,6.5-9.344Z"
          transform="translate(-1353.753 113.284)"
        />
        <path
          fill={props.color || "#2a2a2a"}
          d="M2.46,0h0A2.082,2.082,0,0,1,4.542,2.082v.862a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.46A2.46,2.46,0,0,1,2.46,0Z"
          transform="translate(25.941 2.113) rotate(34)"
        />
        <path
          fill={props.color || "#2a2a2a"}
          d="M1357.06,19.354l3.806,2.233-3.544,2.192s-1.132.5-.975-.587S1357.06,19.354,1357.06,19.354Z"
          transform="translate(-1340.323 -3.323)"
        />
      </g>
      <rect
        fill="#e4e4e4"
        width={10.43}
        height={2.271}
        rx={1.136}
        transform="translate(582.486 -169.098)"
      />
      <rect
        fill="#e4e4e4"
        width={7.486}
        height={2.271}
        rx={1.136}
        transform="translate(582.486 -163.715)"
      />
    </g>
  </svg>
)

export default ShowingForm
