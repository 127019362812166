import * as React from "react"

const Star = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.927}
    height={18}
    viewBox="0 0 18.927 18"
    {...props}
  >
    <path
      id="Icon_feather-star"
      data-name="Icon feather-star"
      d="M12.464,3l2.924,5.924,6.539.956L17.2,14.489,18.312,21l-5.849-3.076L6.615,21l1.117-6.511L3,9.88l6.539-.956Z"
      transform="translate(-3 -3)"
      fill={props.color || "#fac91f"}
    />
  </svg>
)


export default Star
