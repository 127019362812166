import * as React from "react"

const Inquiries = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66669 11.1741C1.66669 10.9451 1.69724 10.7197 1.75835 10.4981L3.16854 5.38611C3.31669 4.8488 3.63705 4.37495 4.08045 4.03726C4.52386 3.69956 5.06581 3.51668 5.62317 3.51666H14.3769C14.9342 3.51668 15.4762 3.69956 15.9196 4.03726C16.363 4.37495 16.6833 4.8488 16.8315 5.38611L18.2417 10.4972C18.3028 10.7194 18.3334 10.9454 18.3334 11.175V14.6278C18.3334 15.1189 18.1382 15.5899 17.791 15.9372C17.4437 16.2845 16.9726 16.4796 16.4815 16.4796H3.51854C3.0274 16.4796 2.55637 16.2845 2.20908 15.9372C1.86179 15.5899 1.66669 15.1189 1.66669 14.6278V11.1741ZM4.50743 5.75555C4.57472 5.51128 4.72032 5.29583 4.92187 5.14228C5.12342 4.98874 5.36979 4.90557 5.62317 4.90555H14.3769C14.6302 4.90557 14.8766 4.98874 15.0782 5.14228C15.2797 5.29583 15.4253 5.51128 15.4926 5.75555L16.9028 10.8667L16.9167 10.9241H13.7037C13.543 10.9241 13.3851 10.9659 13.2455 11.0455C13.1059 11.125 12.9894 11.2396 12.9074 11.3778L12.3463 12.3222C12.2644 12.4604 12.1479 12.5749 12.0082 12.6545C11.8686 12.734 11.7107 12.7759 11.55 12.7759H8.36669C8.19478 12.7758 8.02629 12.7279 7.88009 12.6374C7.73389 12.547 7.61575 12.4177 7.53891 12.2639L7.12409 11.4361C7.04725 11.2823 6.92911 11.153 6.78292 11.0625C6.63672 10.9721 6.46823 10.9242 6.29632 10.9241H3.08335L3.09724 10.8667L4.50743 5.75555Z"
      fill={props.color || "#949494"}
    />
  </svg>
)


export default Inquiries
