import * as React from "react";

const Feedback = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.49973 1.5C2.03567 1.5 1.59062 1.68234 1.26248 2.00691C0.934346 2.33149 0.75 2.7717 0.75 3.23071V6.69214C0.75 6.91942 0.795258 7.14448 0.88319 7.35446C0.971123 7.56444 1.10001 7.75523 1.26248 7.91594C1.59062 8.24051 2.03567 8.42286 2.49973 8.42286H2.84968V9.76728C2.84965 9.8976 2.88758 10.0252 2.95892 10.1347C3.03025 10.2443 3.13199 10.3312 3.25195 10.3851C3.37191 10.439 3.50503 10.4576 3.63538 10.4386C3.76574 10.4197 3.88781 10.364 3.987 10.2782L4.25786 10.0435C4.23891 9.88416 4.24958 9.72274 4.28936 9.56721L4.55182 8.53016C4.65012 8.14117 4.85331 7.78586 5.13973 7.50212L8.52021 4.15838C8.78062 3.90099 9.10752 3.71935 9.46523 3.63327C9.82293 3.5472 10.1977 3.56001 10.5485 3.67032V3.23071C10.5485 2.7717 10.3642 2.33149 10.036 2.00691C9.70788 1.68234 9.26282 1.5 8.79877 1.5H2.49973ZM5.63525 7.99156L9.01573 4.64782C9.13733 4.52764 9.28167 4.43232 9.44051 4.36731C9.59935 4.3023 9.76958 4.26887 9.94149 4.26894C10.1134 4.269 10.2836 4.30256 10.4424 4.36768C10.6012 4.43281 10.7454 4.52824 10.8669 4.64852C10.9885 4.76879 11.0848 4.91156 11.1505 5.06868C11.2163 5.22579 11.2501 5.39417 11.25 5.56421C11.2499 5.73424 11.216 5.90259 11.1502 6.05966C11.0843 6.21673 10.9878 6.35943 10.8663 6.47961L7.48577 9.82266C7.28878 10.0175 7.04205 10.1557 6.77188 10.2228L5.72344 10.4817C5.61919 10.5074 5.50999 10.506 5.40645 10.4776C5.30292 10.4492 5.20856 10.3949 5.13256 10.3197C5.05655 10.2446 5.00148 10.1514 4.97269 10.049C4.9439 9.94658 4.94237 9.83857 4.96825 9.73543L5.23071 8.69839C5.2985 8.43115 5.43831 8.18711 5.63525 7.99226"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
);


export default Feedback;
