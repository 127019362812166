import { Link } from "react-router-dom";

import fb from 'assets/images/fb.png'
import x from 'assets/images/x.png'
import mail from 'assets/images/mail.png'
import { useEffect } from 'react';
import CustomContainer from 'components/CustomContainer';
import Logo from 'components/Logo';
import CustomTextInput from 'components/CustomTextInput';
import CustomButton from 'components/CustomButton';
import PageWrapper from "components/PageWrapper";

const MainFooter = ({ currentUser }) => {
    useEffect(() => {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <PageWrapper style={{ backgroundColor: '#F9F9F9' }}>
            <CustomContainer>
                <div className='footer-wrapper'>
                    <footer>
                        <div className='content'>
                            <Logo />
                            <div className='footer-links'>
                                <ul>
                                    <li className='header'>About</li>
                                    <li>Home</li>
                                    <li>Company</li>
                                </ul>
                                <ul>
                                    <li className='header'>News and Information</li>
                                    <li>Press Kit</li>
                                    <li><Link to="/investors">Investors</Link></li>
                                    <li><Link to="/mls">MLS</Link></li>
                                    <li><Link to="/request-a-demo">Request a Demo</Link></li>
                                </ul>
                                <ul>
                                    <li className='header'>Tools</li>
                                    <li>Search for Agent</li>
                                </ul>
                                <ul>
                                    <li className='header'>Customers</li>
                                    <li><Link to="/terms-of-use/">Terms of Use</Link></li>
                                    <li><Link to="/privacy-center/">Privacy Center</Link></li>
                                    <li><Link to="/cookie-policy/">Cookie Policy</Link></li>
                                </ul>
                            </div>
                            <div className='subscribe'>
                                <CustomTextInput
                                    inputProps={{
                                        disabled: false,
                                        placeholder: 'Email Address'
                                    }}
                                    rightSlot={<CustomButton text={'Subscribe'}  />}
                                />
                            </div>
                        </div>
                        <div className='sub-content'>
                            <p className='copyright'>Copyright 2024 ©Showing Bee. All rights reserved</p>
                            <ul className='social-links'>
                                <li><img src={x} alt="X" /></li>
                                <li><img src={fb} alt="Facebook" /></li>
                                <li><img src={mail} alt="Mail" /></li>
                            </ul>
                        </div>
                    </footer>
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default MainFooter;