import { useCallback, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
import { toast } from 'react-toastify';
//Utils
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
import ToastMessage from 'components/ToastMessage';
import Icons from 'components/Icons';
// Assets
import './login.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import Logo from 'components/Logo';
import CustomTextInput from 'components/CustomTextInput';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomButton from 'components/CustomButton';

const Login  = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [viewPassword, setViewPassword] = useState(false);
    const [form, setForm] = useState({
        email: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async(event) => {
        event.preventDefault();

        const { email, password } = form
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (password.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: ''})
        }

        if (validateEmail(email.value) && password.value) {
            isValid = true
        }
        
        if (isValid) {
            try {
                await dispatch(actions.auth.login({ email: email.value, password: password.value }))
                if (location.state) {
                    navigate(location.state.from)
                } else {
                    navigate("/");
                }
                dispatch(actions.auth.retrieveUser())
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [dispatch, form, navigate])

    return (
        <PageWrapper>
            <CustomContainer>
                <div className='login-container'>
                    <form className='login-form' onSubmit={(event) => onSubmit(event)}>
                        <Logo style={{ height: 40 }}  action={() => navigate('/')} />
                        <Seprator height={80} />
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24 }}>
                            <h1 className='header'>Ready for your new Home?</h1>
                            <h2 className='subheader'>Let's get you your dream home now. Sign in to continue.</h2>
                            <CustomTextInput
                                inputProps={{
                                    type: "email",
                                    placeholder: 'Email Address',
                                    id: 'email',
                                    onChange: (event) => onChangeInput({event, type: "email"}),
                                }}
                                size={'large'}
                                style={{ width: 300 }}
                                error={form.email.error}
                            />
                            <CustomTextInput
                                inputProps={{
                                    type: viewPassword ? 'text' : 'password',
                                    placeholder: 'Password',
                                    id: 'email',
                                    onChange: (event) => onChangeInput({event, type: "password"}),
                                }}
                                size={'large'}
                                style={{ width: 300 }}
                                error={form.password.error}
                                rightSlot={<Icons.Eye style={{ cursor: 'pointer' }} color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />}
                            />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 300 }}>
                                <CustomCheckbox
                                    title={'Remember Me'}
                                    id={'remember'}
                                    inputProps={{
                                        checked: true,
                                        value: 'remember',
                                    }}
                                    labelStyle={{ fontSize: 14, marginLeft: 5 }}
                                />
                                <p className='forgot-password' onClick={() => navigate('/forgot-password')}>Forgot Password</p>
                            </div>
                            <CustomButton text={'Sign In'} style={{ width: 300 }} size={'large'} />
                            <p className='sign-up'>Don't have an account? <span onClick={() => navigate('/signup')}>Sign up for free</span></p>
                        </div>
                    </form>
                    <div className="scrolling-background-container desktop-only">
                        <div className="scrolling-background"></div>
                    </div>
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default Login;