// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './confirm.scss'
import Icons from "components/Icons";

const ConfirmRequest = ({ header, subheader }) => {
    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div className="confirm-container">
                <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} />
                <Seprator height={50} />
                <p className="header">{header}</p>
                <Seprator height={26} />
                <p className="subheader">{subheader}</p>
            </div>
        </Section>
    )
}


export default ConfirmRequest;