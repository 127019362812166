import * as React from "react"

const Grid = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.238 2C2.90966 2 2.59477 2.13043 2.3626 2.3626C2.13043 2.59477 2 2.90966 2 3.238V6.09533C2 6.77933 2.554 7.33333 3.238 7.33333H6.09533C6.42367 7.33333 6.73856 7.2029 6.97073 6.97073C7.2029 6.73856 7.33333 6.42367 7.33333 6.09533V3.238C7.33333 2.90966 7.2029 2.59477 6.97073 2.3626C6.73856 2.13043 6.42367 2 6.09533 2H3.238ZM9.90467 2C9.57633 2 9.26144 2.13043 9.02927 2.3626C8.7971 2.59477 8.66667 2.90966 8.66667 3.238V6.09533C8.66667 6.77933 9.22067 7.33333 9.90467 7.33333H12.762C13.0903 7.33333 13.4052 7.2029 13.6374 6.97073C13.8696 6.73856 14 6.42367 14 6.09533V3.238C14 2.90966 13.8696 2.59477 13.6374 2.3626C13.4052 2.13043 13.0903 2 12.762 2H9.90467ZM3.238 8.66667C2.90966 8.66667 2.59477 8.7971 2.3626 9.02927C2.13043 9.26144 2 9.57633 2 9.90467V12.762C2 13.446 2.554 14 3.238 14H6.09533C6.42367 14 6.73856 13.8696 6.97073 13.6374C7.2029 13.4052 7.33333 13.0903 7.33333 12.762V9.90467C7.33333 9.57633 7.2029 9.26144 6.97073 9.02927C6.73856 8.7971 6.42367 8.66667 6.09533 8.66667H3.238ZM9.90467 8.66667C9.57633 8.66667 9.26144 8.7971 9.02927 9.02927C8.7971 9.26144 8.66667 9.57633 8.66667 9.90467V12.762C8.66667 13.446 9.22067 14 9.90467 14H12.762C13.0903 14 13.4052 13.8696 13.6374 13.6374C13.8696 13.4052 14 13.0903 14 12.762V9.90467C14 9.57633 13.8696 9.26144 13.6374 9.02927C13.4052 8.7971 13.0903 8.66667 12.762 8.66667H9.90467Z"
      fill={props.color || "#C9C9C9"}
    />
  </svg>
)


export default Grid
