import * as React from "react"

const Lease = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={13}
    viewBox="0 0 9 13"
    {...props}
  >
    <path
      fill="#fac91f"
      d="M5.25,3.453V0H.563A.586.586,0,0,0,0,.609V12.391A.586.586,0,0,0,.563,13H8.438A.586.586,0,0,0,9,12.391V4.063H5.813A.589.589,0,0,1,5.25,3.453ZM1.5,1.828a.2.2,0,0,1,.188-.2H3.563a.2.2,0,0,1,.188.2v.406a.2.2,0,0,1-.187.2H1.688a.2.2,0,0,1-.187-.2Zm0,1.625a.2.2,0,0,1,.188-.2H3.563a.2.2,0,0,1,.188.2v.406a.2.2,0,0,1-.187.2H1.688a.2.2,0,0,1-.187-.2Zm4.519,6.3H7.125a.408.408,0,0,1,0,.813H6.019a1,1,0,0,1-.906-.606.257.257,0,0,0-.238-.166.253.253,0,0,0-.235.157l-.18.389a.373.373,0,0,1-.335.224H4.1a.38.38,0,0,1-.329-.277L3.375,9l-.249.809a1.022,1.022,0,0,1-.961.75h-.29a.408.408,0,0,1,0-.812h.29a.265.265,0,0,0,.249-.194l.426-1.387a.55.55,0,0,1,1.068,0l.325,1.057a1.008,1.008,0,0,1,1.547.36A.267.267,0,0,0,6.019,9.75ZM8.836,2.666,6.541.178A.541.541,0,0,0,6.143,0H6V3.25H9V3.1A.634.634,0,0,0,8.836,2.666Z"
    />
  </svg>
)


export default Lease
