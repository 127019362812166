import Icons from 'components/Icons';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './deleteConnection.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';

const DeleteConnection = ({ modal, setModal, selectedData }) => {
    const dispatch = useDispatch();

    const onSubmit = useCallback(async() => {
        try {
            await dispatch(actions.buyer.deleteBuyerMortgageConnection(selectedData.id));
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            setModal(prev => !prev);
            toast.success('Connection has been delete.', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [selectedData])

    return (
        <div className={`delete-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Delete Buyer Connection
                </p>
                <Seprator height={10} />
                <p>
                    You are about to delete your connection to buyer <span style={{ color: '#FAC91F', fontWeight: 700 }}>({selectedData.buyer?.first_name} {selectedData.buyer?.last_name})</span>, are you sure?
                </p>
                <Seprator height={30} />
                <button className='button delete small no-glow' onClick={onSubmit}>
                    Confirm
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default DeleteConnection;