import * as React from "react";
const ListingCalendarIcon = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={16} cy={16} r={16} fill={props.background || "#F9F9F9"} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4583 9.33334C12.624 9.33334 12.783 9.39919 12.9002 9.5164C13.0174 9.63361 13.0833 9.79258 13.0833 9.95834V11H18.9166V9.95834C18.9166 9.79258 18.9824 9.63361 19.0996 9.5164C19.2169 9.39919 19.3758 9.33334 19.5416 9.33334C19.7073 9.33334 19.8663 9.39919 19.9835 9.5164C20.1007 9.63361 20.1666 9.79258 20.1666 9.95834V11H20.3749C20.9827 11 21.5656 11.2415 21.9954 11.6712C22.4251 12.101 22.6666 12.6839 22.6666 13.2917V20.375C22.6666 20.9828 22.4251 21.5657 21.9954 21.9955C21.5656 22.4252 20.9827 22.6667 20.3749 22.6667H11.6249C11.0171 22.6667 10.4342 22.4252 10.0045 21.9955C9.57469 21.5657 9.33325 20.9828 9.33325 20.375V13.2917C9.33325 12.6839 9.57469 12.101 10.0045 11.6712C10.4342 11.2415 11.0171 11 11.6249 11H11.8333V9.95834C11.8333 9.79258 11.8991 9.63361 12.0163 9.5164C12.1335 9.39919 12.2925 9.33334 12.4583 9.33334ZM11.6249 13.9167C11.0499 13.9167 10.5833 14.3833 10.5833 14.9583V20.375C10.5833 20.95 11.0499 21.4167 11.6249 21.4167H20.3749C20.9499 21.4167 21.4166 20.95 21.4166 20.375V14.9583C21.4166 14.3833 20.9499 13.9167 20.3749 13.9167H11.6249Z"
            fill={props.color || "#949494"}
        />
    </svg>
);
export default ListingCalendarIcon;