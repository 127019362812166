import Section from 'components/Section'
import './notFound.scss'
import NotFoundGraphics from 'assets/images/graphic-notfound.svg'
import Seprator from 'components/Separator'
import { useNavigate } from 'react-router-dom'

const NotFound = ({ auto }) => {
    const navigate = useNavigate()

    return (
        <Section auto={auto} className='not-found-container'>
            <div className='not-found-info'>
                <p className='capsule'>PAGE NOT FOUND</p>
                <Seprator height={20} />
                <h1 className='jumbotron'>Oops, this page<br/>is not available =(</h1>
                <Seprator height={30} />
                <p className='subtext'>The bees may not be synchronized in their swarm which broke the page.</p>
                <Seprator height={50} />
                <button className='button primary' style={{ height: 56, width: 283 }} onClick={() => navigate('/')}>Go Back to Showing Bee</button>
            </div>
            <img src={NotFoundGraphics} className="not-found-graphic"  alt="not-found" />
        </Section>
    )
}


export default NotFound;