import { useEffect, useRef, useState } from "react"
import Talk from "talkjs";
import { checkImageUrl } from "utils/modules/Handlers";
import NoUser from 'assets/images/no_user.jpg';
import './chatbox.scss';
import Icons from "components/Icons";

const ChatBox = ({ other, convo, currentUser, session, selectedConvo }) => {
    const [talkLoaded, markTalkLoaded] = useState(false);
    const chatboxEl = useRef();
    useEffect(() => {
        const init = async() => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (talkLoaded) {
                // Safe to use the SDK here
                // let conversation;

                if (selectedConvo) {
                    const conversation = session.getOrCreateConversation(selectedConvo.conversation.id);
                    const chatbox = session.createChatbox();
                    chatbox.select(conversation);
                    chatbox.mount(chatboxEl.current);
                    return () => session.destroy();
                } else {
                    if (other) {
                        const otherUser  = new Talk.User({
                            id: other.id,
                            name: `${other.first_name} ${other.last_name}`,
                            email: other.email,
                            photoUrl: checkImageUrl(other.profile_photo, NoUser),
                            role: other.user_type,
                        });
        
                        let selectedConvoData = null;
            
                        await convo.map((item) => {
                            let participants = []
                            for (let key of Object.keys(item.participants)) {
                                participants.push(key)
                            }
                            if (participants.includes(currentUser.id.toString()) && participants.includes(otherUser.id.toString())) {
                                selectedConvoData = item.id
                            }
                        })
            
                        let conversationId = Talk.oneOnOneId(currentUser, otherUser);
            
                        if (selectedConvoData) {
                            conversationId = selectedConvoData
                        }
        
                        const conversation = session.getOrCreateConversation(conversationId);
                        conversation.setParticipant(currentUser);
                        conversation.setParticipant(otherUser);
                        const chatbox = session.createChatbox();
                        chatbox.select(conversation);
                        chatbox.mount(chatboxEl.current);
                        return () => session.destroy();
                    }
                }
            }
        }
        
        init();
    }, [talkLoaded, other, convo, selectedConvo]);

    return (
        <div className="chatbox-container" ref={chatboxEl}>
            <div style={{ marginTop: '10%' }}>
                <Icons.Loading />
            </div>
        </div>
    )
}



export default ChatBox