import * as React from "react";

const SellerIndicator = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <g transform="translate(-260 -349)">
      <circle
        cx={10}
        cy={10}
        r={10}
        transform="translate(260 349)"
        fill={props.color || "#1F26FA"}
      />
      <text
        transform="translate(270 362.5)"
        fill="#fff"
        fontSize={10}
        fontFamily="ProximaNova-Bold, Proxima Nova"
        fontWeight={700}
      >
        <tspan x={-3.335} y={0}>
          {"S"}
        </tspan>
      </text>
    </g>
  </svg>
);


export default SellerIndicator;
