import { useNavigate } from "react-router-dom";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './approveShowing.scss'
import Icons from "components/Icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "store/actions";

const AcceptShowing = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const init = async() => {
            try {
                const data = {
                    uidb64: urlParams.get('uidb64'),
                    token: urlParams.get('token'),
                }
                await dispatch(actions.showing.approveShowingEmail({ id: urlParams.get('id'), data}));
                setLoading(prev => !prev);
            } catch (error) {
                setLoading(prev => !prev);
                setHasError(prev => !prev);
            }
        }
        init();
    },[])

    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="approve-showing-container">
                {
                    loading 
                    ? <Icons.Loading background={'none'} />
                    : <>
                        <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} />
                        <Seprator height={50} />
                        {
                            hasError 
                            ? <p className="header">Showing request for <span>{urlParams.get('listing')}</span> already been approved!</p>
                            : <p className="header">Showing request for <span>{urlParams.get('listing')}</span> has been approved!</p>
                        }
                        <Seprator height={50} />
                        <p className="subheader">Date: <span>{urlParams.get('date')}</span></p>
                        <Seprator height={10} />
                        <p className="subheader">Time: <span>{urlParams.get('time_start')} <mark>to</mark> {urlParams.get('time_end')}</span></p>
                        <Seprator height={70} />
                        <button className="button primary medium no-glow browse" onClick={() => navigate('/')}>Redirect to Homepage</button>
                    </>
                }
            </div>
        </Section>
    )
}


export default AcceptShowing;