import Seprator from 'components/Separator'
import ToastMessage from 'components/ToastMessage';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import './inviteModal.scss'
import Icons from 'components/Icons';


const InviteModal = ({ modal, setModal, refetchData }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('agent')
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onInvite = useCallback(async() => {
        const { email, first_name, last_name } = form
        let isValid = true
        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (isValid) {
            setLoading(true);
            const data = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                user_type: type,
            }
            try {
                if (type === 'agent') {
                    await dispatch(actions.buyer.inviteAgent(data));
                } else {
                    data.buyer_tier = user.buyer_tier;
                    await dispatch(actions.buyer.inviteMortgageBroker(data));
                }
                await refetchData();
                setLoading(false);
                setModal(prev => !prev);
                toast.success('Connection invitation sent!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [form, type])

    return (
        <div className={`invite-connection-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Invite Connection
                </p>
                <Seprator height={30} />
                <div className='form-input'>
                    <label>First Name</label>
                    <input 
                        id='first_name' 
                        placeholder='Enter First Name' 
                        type='text' 
                        className='small' 
                        value={form.first_name.value}
                        onChange={(event) => onChangeInput({event, type: "first_name"})} 
                    />
                    <span className='error-message' style={{ width: 240 }}>{form.first_name.error}</span>
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Last Name</label>
                    <input 
                        id='last_name' 
                        placeholder='Enter Last Name' 
                        type='text' 
                        className='small' 
                        value={form.last_name.value}
                        onChange={(event) => onChangeInput({event, type: "last_name"})} 
                    />
                    <span className='error-message' style={{ width: 240 }}>{form.last_name.error}</span>
                </div>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Email Address</label>
                    <input 
                        id='email' 
                        placeholder='Enter Email Address' 
                        type='text' 
                        className='small' 
                        value={form.email.value}
                        onChange={(event) => onChangeInput({event, type: "email"})} 
                    />
                    <span className='error-message' style={{ width: 240 }}>{form.email.error}</span>
                </div>
                <Seprator height={20} />
                <p className='contact-type-label'>Contact Type</p>
                <div className='choices-container'>
                    <div className='form'>
                        <div className='form-input choices'>
                            <input 
                                type="radio" 
                                id='agent' 
                                name='contact_type'
                                checked={type === 'agent' ? true : false}
                                onChange={() => setType('agent')}
                            />
                            <label htmlFor='agent'>Buyer's Agent</label>
                        </div>
                    </div>
                    <Seprator height={10} />
                    <div className='form'>
                        <div className='form-input choices'>
                            <input 
                                type="radio" 
                                id='mortgage_broker' 
                                name='contact_type' 
                                checked={type === 'mortgage_broker' ? true : false}
                                onChange={() => setType('mortgage_broker')} 
                            />
                            <label htmlFor='mortgage_broker'>Mortgage Broker</label>
                        </div>
                    </div>
                </div>
               
                <Seprator height={45} />
                <button className='button primary small no-glow' onClick={onInvite} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Invite'
                }
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default InviteModal;