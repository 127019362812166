import * as React from "react"

const Save = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.556}
    height={20}
    viewBox="0 0 15.556 20"
    {...props}
  >
    <path
      fill={props.color || 'gray'}
      d="M20.833,4.5H9.722A2.219,2.219,0,0,0,7.511,6.722L7.5,24.5l7.778-3.333L23.056,24.5V6.722A2.229,2.229,0,0,0,20.833,4.5Z"
      transform="translate(-7.5 -4.5)"
    />
  </svg>
)


export default Save
