import Icons from "components/Icons";
import Seprator from "components/Separator";
import './memberList.scss';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { useCallback, useEffect, useState } from "react";
import actions from "store/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ToastMessage from "components/ToastMessage";
import { checkImageUrl, getErrorMessage } from "utils/modules/Handlers";
import { useNavigate, useParams } from "react-router-dom";
import NoUser from 'assets/images/no_user.jpg'

const MemberList = ({ members }) => {
    return (
        <section className="member-list-container">
           <div className='listing-section'>
                <p className="members-count">Members <span>{members.length}</span></p>
                <Seprator height={30} />
                {
                        members.length > 0 ?
                        <>
                        {
                            members.map((item, index) => {
                                return (
                                    <div key={index} className='member-info'>
                                        <div>
                                            <img src={checkImageUrl(item.member?.profile_photo, NoUser)} alt='' />
                                            <Seprator width={6} />
                                            <div>
                                                <p>{item.member?.first_name} {item.member?.last_name}</p>
                                                <Seprator height={6} />
                                                <span>{item.member?.email}</span>
                                            </div>
                                        </div>
                                        <p className="invite-status">{item.date_accepted ? 'Connected' : 'Pending'}</p>
                                    </div>
                                )
                            })
                        }
                        </>
                        : <p className="empty">No Members Available</p>
                }
            </div>
        </section>
    )
}


export default MemberList;