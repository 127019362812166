import { useCallback, useEffect, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
// Assets
import './showingFeedbackForm.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import ForgotPasswordIllus from 'assets/images/request-feedback-illustration.svg'
import Icons from 'components/Icons';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage } from 'utils/modules/Handlers';

const ShowingFeedbackForm  = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState('');
    const [interest, setInterest] = useState(0);
    const [experience, setExperience] = useState(0);
    const [opinion, setOpinion] = useState(0);
    const [listing, setListing] = useState(0);

    useEffect(() => {
        const init = async() => {
            if (urlParams.get('uidb64') && urlParams.get('token')) {
                setLoading(false);
            } else {
                navigate('/showing-feedback/invalid/');
            }
        }
        init();
    }, [])

    const onSubmit = useCallback(async(event) => {
        setLoading(true);
        try {
            const data = {
                uidb64: urlParams.get('uidb64'),
                token: urlParams.get('token'),
                showing: urlParams.get('showing'),
                comments: comments,
                interest_rating: interest,
                experience_rating: experience,
                price_rating: opinion,
                listing_rating: listing,
            }
            console.log('here', data)
            await dispatch(actions.showing.sendShowingFeedback(data));
            setLoading(false);
            navigate('/showing-feedback/sent/');
        } catch (error) {
            setLoading(false);
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
 
    }, [comments])


    const Stars = ({rate, setRate}) => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(<div key={i + 1}><Icons.Star width={30} height={30} color={i+1 <= rate ? '#FAC91F' : '#EFEFEF'} onClick={() => setRate(i+1)} /></div>)
        }
        return stars;
    }

    return (
        <Section style={{ display: 'flex' }}>
            <div className='showing-feedback-form-container'>
               <div className='showing-feedback-form'>
                    <img 
                        src={ShowingBeeLogoBlack} 
                        alt="logo"
                        className='logo'
                    />
                    <Seprator height={60} />
                    {
                        loading
                        ? <div className='loading'>
                            <Icons.Loading />
                        </div>
                        : <>
                            <h1 className='header'>Showing Request Feedback Form</h1>
                            <Seprator height={20} />
                            <h2 className='subheader' style={{ lineHeight: '35px'}}>
                                Thank you for your time, please leave a feedback on your recent showing request experience.
                            </h2>
                            <Seprator height={20} />
                            <img className='illus' src={ForgotPasswordIllus} alt="" />
                            <Seprator height={50} />
                            <div className='form-input'>
                                <label>Is your client interested in this listing?</label>
                                <Seprator height={10} />
                                <div className='rating interest'>
                                    <Stars rate={interest} setRate={setInterest}/>
                                </div>
                            </div>
                            <Seprator height={50} />

                            <div className='form-input'>
                                <label>Please rate your overall experience at this showing.</label>
                                <Seprator height={10} />
                                <div className='rating expi'>
                                    <Stars rate={experience} setRate={setExperience}/>
                                </div>
                            </div>
                            <Seprator height={50} />

                            <div className='form-input'>
                                <label>Your (and your client's) opinion of the price.</label>
                                <Seprator height={10} />
                                <div className='rating opinion'>
                                    <Stars rate={opinion} setRate={setOpinion}/>
                                </div>
                            </div>

                            <Seprator height={50} />

                            <div className='form-input'>
                                <label>What rate should you give on this listing?</label>
                                <Seprator height={10} />
                                <div className='rating listing'>
                                    <Stars rate={listing} setRate={setListing}/>
                                </div>
                            </div>

                            <Seprator height={50} />

                            <div className='form-input'>
                                <label>Feedback Comment</label>
                                <Seprator height={10} />
                                <textarea
                                    onChange={(event) => setComments(event.target.value)}
                                    style={{ height: 150, padding: 15, maxWidth: 500 }}
                                    placeholder='Type here . . .' 
                                    className='small' 
                                ></textarea>
                            </div>
                            <Seprator height={20} />
                            <button className='button primary' onClick={onSubmit} disabled={interest === 0 || experience === 0 || opinion === 0 || listing === 0}>Submit</button>
                        </>
                    }
                </div>
                
            </div>
        </Section>
    )
}


export default ShowingFeedbackForm;