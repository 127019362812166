import API from 'API';
import Seprator from 'components/Separator';
import { useEffect, useState } from 'react';
import './allContacts.scss'
import NoUser from 'assets/images/no_user.jpg'
import { checkImageUrl, formatCurrency } from 'utils/modules/Handlers';
import { useNavigate } from 'react-router-dom';
import InviteModal from '../InviteModal';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import BrokerInfoModal from '../BrokerInfoModal';
import QuestionnairePreview from 'components/QuestionnairePreview';
import AgentInfoModal from '../AgentInfoModal';
import Icons from 'components/Icons';


const AllContacts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [agents, setAgents] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [inviteModal, setInviteModal] = useState(false);
    const [agentModal, setAgentModal] = useState(false);
    const [brokerModal, setBrokerModal] = useState(false);
    const [questionaireModal, setQuestionaireModal] = useState(false);
    const [profile, setProfile] = useState({})
    const [selectedBroker, setSelectedBroker] = useState({})
    const [selectedAgent, setSelectedAgent] = useState({})


    const fetchData = async() => {
        const agentData = await API.agent.fetchBuyerAgentConnection({ offset: 0, limit: 6 });
        const brokerData = await API.buyer.fetchBuyerMortgageConnection({ offset: 0, limit: 6 });
        setAgents(agentData.data.results)
        setBrokers(brokerData.data.results)
    }

    const fetchProfile = async() => {
        const data = await dispatch(actions.buyer.fetchBuyerProfile());
        if (data.length > 0) {
            setProfile(data[0]);
        }
    }
    
    const init = async() => {
        fetchProfile();
        await fetchData();
    }

    useEffect(() => {
        init();
    }, [])

    const changeDefaultAgent = async(item) => {
        try {
            const data = { id: item.id }
            await dispatch(actions.buyer.setDefaultAgent(data)).then(() => {
                toast.success('New default Buyer\'s Agent has been set', {
                    position: toast.POSITION.TOP_CENTER
                });
                init();
            });
        } catch (error) {
            toast.error(<ToastMessage messages={'Something went wrong.'} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const changeDefaultBroker = async(item) => {
        try {
            const data = { id: item.id }
            await dispatch(actions.buyer.setDefaultBroker(data)).then(() => {
                toast.success('New default Mortgage Broker has been set', {
                    position: toast.POSITION.TOP_CENTER
                });
                init();
            });
        } catch (error) {
            toast.error(<ToastMessage messages={'Something went wrong.'} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const onBrokerSelect = (item) => {
        setSelectedBroker(item);
        setBrokerModal(prev => !prev);
    }

    const onAgentSelect = (item) => {
        setSelectedAgent(item);
        setAgentModal(prev => !prev);
    }

    const onPreview = () => {
        setQuestionaireModal(prev => !prev)
    }

    return (
        <div className="all-contacts-container">
            <InviteModal modal={inviteModal} setModal={setInviteModal} refetchData={fetchData} /> 
            <BrokerInfoModal modal={brokerModal} setModal={setBrokerModal} selectedData={selectedBroker} onPreview={onPreview} />
            <QuestionnairePreview modal={questionaireModal} setModal={setQuestionaireModal} selectedData={selectedBroker} />
            <AgentInfoModal modal={agentModal} setModal={setAgentModal} selectedData={selectedAgent} />

            <div className='filters'>
                <p className='header'>Connections</p>
                <button 
                    className='button primary small no-glow' 
                    style={{ width: 160}} 
                    onClick={() => setInviteModal(prev => !prev)}
                >
                    Invite Connections
                </button>
            </div>
            {
                (agents.length === 0 && brokers.length === 0) &&
                <div className='empty'>
                    <p>You have no connections available</p>
                </div>

            }
            <Seprator height={50} />
            {
                agents.length > 0 &&
                <>
                    <div className='item-container'>
                        <div className='header-container'>
                            <p className='header'>Buyer's Agents</p>
                            <p className='view' onClick={() => navigate('/contacts/agents')}>View all</p>
                        </div>
                        <Seprator height={30} />
                        <div className='items'>
                            {
                                agents.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className='details'>
                                                <div>
                                                    <img src={checkImageUrl(item.agent.profile_photo, NoUser)} alt="" />
                                                    <div className='info'>
                                                        <p className='name' onClick={() => onAgentSelect(item)}>{item.agent.first_name} {item.agent.last_name}</p>
                                                        <Seprator height={10} />
                                                        <p className='email'>{item.agent.email}</p>
                                                    </div>
                                                </div>
                                                {
                                                    (item.agent.agent_data?.buyer_agent_compensation_type === 'cash' && item.agent.agent_data?.buyer_agent_compensation_cash)
                                                    && <>
                                                        <div className='compensation'>
                                                            <Icons.Compensation width={12} color={'#FAC91F'} />
                                                            <Seprator width={5} />
                                                            <span>{formatCurrency(item.agent.agent_data?.buyer_agent_compensation_cash)}</span>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    (item.agent.agent_data?.buyer_agent_compensation_type === 'percent' && item.agent.agent_data?.buyer_agent_compensation_percent)
                                                    && <>
                                                        <div className='compensation'>
                                                            <Icons.Compensation width={12} color={'#FAC91F'} />
                                                            <Seprator width={5} />
                                                            <span>{`${item.agent.agent_data?.buyer_agent_compensation_percent}%`}</span>
                                                        </div>
                                                    </>
                                                }
                                                <div>
                                                {
                                                    profile.default_buyer_agent?.id !== item.agent.id 
                                                    ? <p className='set' onClick={() => changeDefaultAgent(item.agent)}>Set Default</p>
                                                    : <p className='current'>Default</p>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <Seprator height={50} />
                </>
            }
            {
                brokers.length > 0 &&
                <div className='item-container'>
                    <div className='header-container'>
                        <p className='header'>Mortgage Brokers</p>
                        <p className='view' onClick={() => navigate('/contacts/mortgage-brokers')}>View all</p>
                    </div>
                    <Seprator height={30} />
                    <div className='items'>
                        {
                            brokers.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className='details'>
                                            {
                                                (item.approval_document || item.questionnaire_filled_out) &&
                                                <div className='icons'>
                                                    {
                                                        item.approval_document &&
                                                        <div className='icon'>
                                                            <Icons.Document height={15} color={'#06C0CD'} />
                                                        </div>
                                                    }
                                                    {
                                                        item.questionnaire_filled_out &&
                                                        <div className='icon'>
                                                            <Icons.Draft height={15} color={'#FAC91F'} />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div>
                                                <img src={checkImageUrl(item.mortgage_broker.profile_photo, NoUser)} alt="" />
                                                <div className='info'>
                                                    <p className='name' onClick={() => onBrokerSelect(item)}>{item.mortgage_broker.first_name} {item.mortgage_broker.last_name}</p>
                                                    <Seprator height={10} />
                                                    <p className='email'>{item.mortgage_broker.email}</p>
                                                </div>
                                            </div>
                                            <div>
                                            {
                                                profile.default_mortgage_broker?.id !== item.mortgage_broker.id 
                                                ? <p className='set' onClick={() => changeDefaultBroker(item.mortgage_broker)}>Set Default</p>
                                                : <p className='current'>Default</p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        
        </div>
    )
}


export default AllContacts;