import Footer from "components/Footer";
import RequestStepper from "components/RequestStepper";
import Separator from "components/Separator";
import ToastMessage from "components/ToastMessage";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import actions from "store/actions";
// Utils
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import './inviteBuyer.scss'

const InviteBuyer = () => {
    const { id: listingID, mls } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onSubmit = useCallback(async(event) => {
        const { email, first_name, last_name, phone_number } = form

        event.preventDefault();
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (validateEmail(email.value) && first_name.value && last_name.value) {
            isValid = true
        }
        if (isValid) {
            try {
                const data = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    phone_number: phone_number.value,
                }
                await dispatch(actions.agent.inviteBuyer(data));
                navigate(`/request/${mls}/${listingID}/calendar`)
                toast.success('Buyer invitation sent!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [form])

    return (
        <section className="invite-buyer-container">
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p className='back-button' onClick={() => navigate(`/request/${mls}/${listingID}/select-buyer`)}>Back</p>
                    <h1 className="title">Buyer Details</h1>
                    {/* <button 
                        className="button medium primary next-button" 
                        style={{ width: 120 }}
                        onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent`)}
                    >
                        Next
                    </button> */}
                </div>
                <p className='subtitle'>Please fill out the information for your buyer.</p>
            </div>
            <div className="form-container">
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <input 
                            type="text" 
                            placeholder='First Name' 
                            onChange={(event) => onChangeInput({event, type: "first_name"})}
                            style={{ width: 240 }}
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.first_name.error}</span>
                    </div>
                    <Separator width={20} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <input 
                            type="text" 
                            placeholder='Last Name' 
                            onChange={(event) => onChangeInput({event, type: "last_name"})} 
                            style={{ width: 240 }}
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.last_name.error}</span>
                    </div>
                </div>
                <Separator height={20} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input 
                        type="email" 
                        placeholder='Email Address'
                        autoComplete="off"
                        onChange={(event) => onChangeInput({event, type: "email"})} 
                        style={{ width: 500 }}
                    />
                    <span className='error-message' style={{ width: 500, alignSelf: 'flex-start' }}>{form.email.error}</span>
                    <Separator height={20} />
                    <div className='phone-icon-container'>
                    <input 
                        type="tel" 
                        maxLength={11}
                        placeholder='Phone Number' 
                        onChange={(event) => onChangeInput({event, type: "phone_number"})} 
                        style={{ width: 500 }}
                    />
                    </div>
                    <Separator height={30} />
                    <button className='button primary' style={{ width: 500 }} onClick={onSubmit}>Next</button>
                </div>
            </div>
            <Separator height={90} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Separator height={160} />
            <Footer subfooter />
        </section>
    )
}


export default InviteBuyer;