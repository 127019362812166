import Separator from "components/Separator";
import AgentsBanner from 'assets/images/AgentsBanner.png'
import AssetsWaggle from 'assets/images/AssetsWaggle.png'
import CustomButton from "components/CustomButton";
import Icons from "components/Icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AgentsInfoPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="agents-details">
            <p className="header">Agents</p>
            <Separator height={8} />
            <p className="subheader">Showing Bee streamlines your real estate process.</p>
            <Separator height={40} />
            <img src={AgentsBanner} alt="Buyers Banner" style={{ width: '100%', height: 'auto' }} />
            <Separator height={120} />
            <Icons.ListingAgentBadge height={60} width={60} />
            <Separator height={24} />
            <p className="title">Listing Agents</p>
            <Separator height={40} />
            <div className="blocks">
                <div style={{ flexDirection: 'column' }}>
                    <div style={{ height: '100%', flex: 1 }}>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">State-of-the-art automated showing platform.</p>
                            <span className="content">With live calendar: Showing Bee's showing system was built by real estate professionals who saw the need for improvement in the existing showing app technology. Taking into account the many nuances involved in the complex showing process, our unique platform makes it easy to approve, track and manage showings.</span>
                        </div>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Showing Bee Plug-in.</p>
                            <span className="content">Enables buyers to use Showing Bee's showing engine within your website. Ensures the capture of buyer info while empowering them to set live showing requests with ease. Leverage the power of your existing utilization of Showing Bee via your own website.</span>
                        </div>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Leads are Always Yours.</p>
                            <span className="content">Add your listings to Showing Bee and get leads back for free!</span>
                        </div>
                    </div>
                    <div style={{ height: '100%', flex: 1 }}>
                        <div className="card" style={{ maxWidth: 848 }}>
                            <p className="title">Waggle.</p>
                            <span className="content">Picture a social media platform like Facebook or Instagram but exclusively for real estate. The "Waggle" feed is where agents can promote themselves or their listings. Anyone who follows you or your specific listings will see your posts.</span>
                        </div>
                        <img className="image-resize" src={AssetsWaggle} alt="" style={{ maxWidth: 416 }} />
                    </div>
                </div>
            </div>
            <Separator height={80} />
            <Icons.BuyerAgentBadge height={60} width={60} />
            <Separator height={24} />
            <p className="title">Buyer's Agent</p>
            <Separator height={40} />
            <div className="blocks">
                <div style={{ flexDirection: 'column' }}>
                    <div style={{ height: '100%', flex: 1 }}>
                        <img className="image-resize" src={AssetsWaggle} alt="" style={{ maxWidth: 416 }} />
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Marketing to Buyers for Your Services.</p>
                            <span className="content">Set up your profile and list any of your qualifications, bio and advertised fees and we'll help match local buyers to you when they search for agents in their area. Want more exposure? Reach local buyers who are requesting showings on Showing Bee by becoming a Showing Bee agent. No outrageous marketing fees; just an affordable monthly marketing charge.</span>
                        </div>
                        <div className="card" style={{ maxWidth: 416 }}>
                            <p className="title">Seamless Process.</p>
                            <span className="content">Request showings, invite your buyers, connect with other professionals and market your services.</span>
                        </div>
                    </div>
                </div>
            </div>
            <Separator height={80} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <span className="content">Let Showing Bee streamline your real estate process.</span>
                {
                    !user.id && 
                    <>
                        <Separator height={24} />
                        <CustomButton text={'Join Showing Bee'} size={'large'} action={() => navigate('/signup')} />
                    </>
                }
            </div>
        </div>
    );
}


export default AgentsInfoPage;