import * as React from "react"

const Reject = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.252}
    height={10.252}
    viewBox="0 0 10.252 10.252"
    {...props}
  >
    <path
      fill="#ff5151"
      d="M12.729,10.164h0l-3.11-3.11,3.11-3.11h0a.321.321,0,0,0,0-.453L11.26,2.022a.321.321,0,0,0-.453,0h0L7.7,5.132l-3.11-3.11h0a.321.321,0,0,0-.453,0L2.664,3.491a.321.321,0,0,0,0,.453h0l3.11,3.11-3.11,3.11h0a.321.321,0,0,0,0,.453l1.469,1.469a.321.321,0,0,0,.453,0h0L7.7,8.976l3.11,3.11h0a.321.321,0,0,0,.453,0l1.469-1.469a.321.321,0,0,0,0-.453Z"
      transform="translate(-2.571 -1.928)"
    />
  </svg>
)


export default Reject
