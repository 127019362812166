import * as React from "react"

const Loading = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      margin: "auto",
      background: props.background ? props.background : "rgb(255, 255, 255)",
      display: "block",
      shapeRendering: "auto",
    }}
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <circle cx={50} cy={50} r={0} fill="none" stroke="#fac91f" strokeWidth={2}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1.3513513513513513s"
        values="0;40"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
        begin="0s"
      />
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1.3513513513513513s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
        begin="0s"
      />
    </circle>
    <circle cx={50} cy={50} r={0} fill="none" stroke="#f8a728" strokeWidth={2}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1.3513513513513513s"
        values="0;40"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
        begin="-0.6756756756756757s"
      />
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1.3513513513513513s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
        begin="-0.6756756756756757s"
      />
    </circle>
  </svg>
)

export default Loading
