import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './requestStepper.scss'

const RequestStepper = () => {
    const { pathname } = useLocation();
    const { user } = useSelector((state) => state.auth)

    return (
        <ul className="step--container">
            <li className="step--item active">
                <div className="step--item-icon"></div>
                <span className="step--item-text">Property</span>
            </li>
            {
                user.user_type === 'agent' 
                ? <li className={`step--item ${(pathname.includes('-buyer') || pathname.includes('/calendar') || pathname.includes('/review')) && 'active' }`}>
                    <div className="step--item-icon"></div>
                    <span className="step--item-text">Buyer</span>
                </li>
                : <li className={`step--item ${(pathname.includes('-agent') || pathname.includes('/calendar') || pathname.includes('/review')) && 'active' }`}>
                    <div className="step--item-icon"></div>
                    <span className="step--item-text">Agent</span>
                </li>
            }
            <li className={`step--item ${(pathname.includes('/calendar') || pathname.includes('/review')) && 'active' }`}>
                <div className="step--item-icon"></div>
                <span className="step--item-text">Calendar</span>
            </li>
            <li className={`step--item ${pathname.includes('/review') && 'active' }`}>
                <div className="step--item-icon"></div>
                <span className="step--item-text">Place Request</span>
            </li>
        </ul>
    )
}


export default RequestStepper;