import * as React from "react"

const BoxArrowLeft = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
    <g transform="translate(-140.366 -390.366)">
      <rect
        data-name="Rectangle 69"
        width={35}
        height={35}
        rx={5}
        transform="translate(140.366 390.366)"
        fill={props.color || "#fac91f"}
      />
      <path
        data-name="Icon ionic-ios-arrow-back"
        d="m155.076 407.864 5.97-5.965a1.129 1.129 0 0 0-1.6-1.592l-6.764 6.759a1.125 1.125 0 0 0-.033 1.555l6.792 6.806a1.129 1.129 0 0 0 1.6-1.592Z"
        fill="#fff"
        stroke="#fff"
      />
    </g>
  </svg>
)


export default BoxArrowLeft
