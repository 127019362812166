import './customSelect.scss'
import { useState } from 'react';

const CustomSelect = ({ inputProps, leftSlot, options = [], label, error, success, size, style, type="primary", onChange = () => { } }) => {
    const [selected, setSelected] = useState(null);
    const [showMenu, setShowMenu] = useState(false);


    const onOptionSelect = ({ text, value }) => {
        if (selected?.value === value) {
            setSelected(null);
            onChange('')
        } else {
            setSelected({ text, value });
            onChange(value)
        }
        setShowMenu(prev => !prev);
    }

    return (
        <div className={`custom-select ${error && 'error'} ${success && 'success'} ${inputProps.disabled && 'disabled'}`}>
            {
                label &&
                <label>{label}</label>
            }
            <div className={`input-wrapper ${type} ${size} ${showMenu && 'show'}`} style={style} onClick={() => setShowMenu(prev => !prev)}>
                <div style={{ display: 'flex', columnGap: 15, width: '100%' }}>
                    <>
                        {
                            leftSlot &&
                            <div className='left-slot'>
                                {leftSlot}
                            </div>
                        }
                        <div className='input-text'>{selected ? selected.text : inputProps.placeholder}</div>
                        <input
                            {...inputProps}
                            onKeyDown={(e) => e.preventDefault()}
                            value={selected?.value || ''}
                            onChange={e => setSelected({ ...selected, value: e.target.value })}
                            hidden
                        />
                    </>
                </div>
                <div className='right-slot'>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.0406 10.0404C11.6501 10.4309 11.0169 10.4309 10.6264 10.0404L8.00016 7.41413L5.37394 10.0404C4.98341 10.4309 4.35025 10.4309 3.95972 10.0404C3.5692 9.64983 3.5692 9.01667 3.95972 8.62614L7.29306 5.29281C7.68358 4.90229 8.31675 4.90229 8.70727 5.29281L12.0406 8.62615C12.4311 9.01667 12.4311 9.64983 12.0406 10.0404Z"
                            fill="#5F5F5F"
                        />
                    </svg>
                </div>
            </div>
            <ul className={`select-menu ${showMenu ? 'show' : 'remove'}`}>
                {
                    options.map((item, index) => {
                        return (
                            <li key={index} className={`${selected?.value === item.value && 'selected'}`} onClick={() => onOptionSelect({ text: item.text, value: item.value })}>{item.text}</li>
                        )
                    })
                }
            </ul>
            {
                error &&
                <span>{error}</span>
            }
        </div>
    )
}

export default CustomSelect;