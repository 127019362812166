import { Link } from "react-router-dom";
import Icons from 'components/Icons'
import ShowingBeeLogoGray from 'assets/images/ShowingBeeLogoGray.png'
import { useEffect } from "react";
import '../subfooter.scss';

const SubFooter = () => {
    useEffect(() => {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <footer className="sub-footer">
            <ul className="sub-footer--items">
                <li><Link to="/">Press kit</Link></li>
                <li><Link to="/">Press Release</Link></li>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/">Company</Link></li>
                <li><Link to="/">Search for Agent</Link></li>
                <li><Link to="/terms-of-use/">Terms of Use</Link></li>
                <li><Link to="/privacy-center/">Privacy Center</Link></li>
                <li><Link to="/cookie-policy/">Cookie Policy</Link></li>
                <li><Link to="/investors/">Investors</Link></li>
                <li><Link to="/mls/">MLS</Link></li>
            </ul>
            <div className="sub-footer--inner">
                <div className="sub-footer--icon-container">
                    <Icons.Twitter color={'gray'}/>
                    <Icons.Facebook color={'gray'} />
                    <Icons.Email color={'gray'} />
                </div>  
                <div className="sub-footer--logo">
                    <img src={ShowingBeeLogoGray} alt="" />
                </div>           
                
                <div className="copy-term">
                    <span>Copyright 2024 ©Showing Bee. All rights reserved</span>
                    <span>Terms & Conditions</span>
                </div>
            </div>
        </footer>
    )
}


export default SubFooter;