import './showingRequest.scss';
import ConfirmPropertyRoute from "./modules/ConfirmProperty";
import LoginToRequestRoute from "./modules/LoginToRequest";
import SelectAgentRoute from "./modules/SelectAgent";
import SelectBuyerRoute from "./modules/SelectBuyer";
import ChooseAgentRoute from "./modules/ChooseAgent";
import InviteAgentRoute from "./modules/InviteAgent";
import InviteBuyerRoute from "./modules/InviteBuyer";
import ShowingBeeAgentRoute from "./modules/ShowingBeeAgent";
import RequestReviewRoute from "./modules/RequestReview";
import CalendarRoute from "./modules/Calendar";
import RequestSubmittedRoute from "./modules/RequestSubmitted";

export const ConfirmProperty = ConfirmPropertyRoute
export const LoginToRequest = LoginToRequestRoute
export const SelectAgent = SelectAgentRoute
export const SelectBuyer = SelectBuyerRoute
export const ChooseAgent = ChooseAgentRoute
export const InviteAgent = InviteAgentRoute
export const InviteBuyer = InviteBuyerRoute
export const ShowingBeeAgent = ShowingBeeAgentRoute
export const RequestReview = RequestReviewRoute
export const Calendar = CalendarRoute
export const RequestSubmitted = RequestSubmittedRoute
