import Icons from 'components/Icons';
import { checkImageUrl } from 'utils/modules/Handlers';
import './brokerInfoModal.scss'
import NoUser from 'assets/images/no_user.jpg'
import Separator from 'components/Separator';
import axios from 'axios';
import fileDownload from 'js-file-download';



const BrokerInfoModal = ({ modal, setModal, selectedData, onPreview }) => {

    const handleDownload = () => {
        axios.get(selectedData.approval_document, {
            responseType: 'blob',
        })
        .then((res) => {
            fileDownload(res.data, `${selectedData.mortgage_broker.first_name} ${selectedData.mortgage_broker.last_name} - Approval Document.${res.data.type.split('/')[1]}`)
        })
    }

    return (
        <div className={`broker-info-modal ${modal && 'visible'}`}>
            <div className='modal-container'>
                <div className='modal-title'>
                    Connection Information (Mortgage Broker)
                    <div className='close' onClick={() => setModal(prev => !prev)}>
                        <Icons.Close />
                    </div>
                </div>
                <Separator height={20} />
                <div className='buyer-details'>
                    <Separator height={20} />
                    <img className="buyer-image" src={checkImageUrl(selectedData.mortgage_broker?.profile_photo, NoUser)} alt="" />
                    <Separator height={40} />
                    <p className="name">{selectedData.mortgage_broker?.first_name} {selectedData.mortgage_broker?.last_name}</p>
                    <Separator height={10} />
                    <div className='content'>
                        <div>
                            <Icons.EmailSmall color={'#6C767C'} />
                            <Separator width={10} />
                            <span>{selectedData.mortgage_broker?.email || 'Not Available'}</span>
                        </div>
                        <Separator width={20} />
                        <div>
                            <Icons.PhoneSmall color={'#6C767C'} />
                            <Separator width={10} />
                            <span>{selectedData.mortgage_broker?.phone_number || 'Not Available'}</span>
                        </div>
                    </div>
                    {
                        (selectedData.approval_document || selectedData.questionnaire_filled_out) &&
                        <>
                            <Separator height={15} />
                            <div className='buttons'>
                                {
                                    selectedData.approval_document &&
                                    <button className='button document small no-glow' onClick={handleDownload}>Document</button>
                                }
                                {
                                    selectedData.questionnaire_filled_out &&
                                    <>
                                        <Separator width={10} />
                                        <button className='button primary small no-glow' onClick={onPreview}>Questionnaire</button>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default BrokerInfoModal