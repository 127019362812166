import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    user: {},
    buyerAgents: [],
    featuredAgents: [],
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload.key;
    },
    retrieveUserSuccess: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.token  = '';
      state.user  = {};
    },
    fetchBuyerAgentsSuccess: (state, action) => {
      state.buyerAgents = action.payload;
    },
    fetchFeaturedAgentsSuccess: (state, action) => {
      state.featuredAgents = action.payload;
    },
  },
});


export default authSlice