import authActions from "./auth";
import feedActions from "./feed";
import listingActions from "./listing";
import showingActions from "./showing";
import agentActions from "./agent";
import buyerActions from "./buyer";
import brokerActions from "./broker";
import teamActions from "./team";
import messageActions from "./message";

const actions = {
    auth: authActions,
    feed: feedActions,
    listing: listingActions,
    showing: showingActions,
    agent: agentActions,
    buyer: buyerActions,
    broker: brokerActions,
    team: teamActions,
    message: messageActions,
}


export default actions;