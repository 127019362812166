import * as React from "react"

const PhoneSmall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.636}
    height={18}
    viewBox="0 0 10.636 18"
    {...props}
  >
    <path
      id="Icon_material-phone-iphone"
      data-name="Icon material-phone-iphone"
      d="M16.091,1.5H9.545A2.046,2.046,0,0,0,7.5,3.545V17.455A2.046,2.046,0,0,0,9.545,19.5h6.545a2.046,2.046,0,0,0,2.045-2.045V3.545A2.046,2.046,0,0,0,16.091,1.5ZM12.818,18.682a1.227,1.227,0,1,1,1.227-1.227A1.226,1.226,0,0,1,12.818,18.682ZM16.5,15.409H9.136V3.955H16.5Z"
      transform="translate(-7.5 -1.5)"
      fill={props.color || "#2a2a2a"}
    />
  </svg>
)


export default PhoneSmall
