import * as React from "react"

const CirclePlus = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.16675 9.83334V12.3333C8.16675 12.5694 8.24675 12.7675 8.40675 12.9275C8.56675 13.0875 8.76452 13.1672 9.00008 13.1667C9.23619 13.1667 9.43425 13.0867 9.59425 12.9267C9.75425 12.7667 9.83397 12.5689 9.83341 12.3333V9.83334H12.3334C12.5695 9.83334 12.7676 9.75334 12.9276 9.59334C13.0876 9.43334 13.1673 9.23556 13.1667 9C13.1667 8.76389 13.0867 8.56584 12.9267 8.40584C12.7667 8.24584 12.569 8.16611 12.3334 8.16667H9.83341V5.66667C9.83341 5.43056 9.75341 5.2325 9.59341 5.0725C9.43341 4.9125 9.23564 4.83278 9.00008 4.83334C8.76397 4.83334 8.56591 4.91334 8.40591 5.07334C8.24591 5.23334 8.16619 5.43112 8.16675 5.66667V8.16667H5.66675C5.43064 8.16667 5.23258 8.24667 5.07258 8.40667C4.91258 8.56667 4.83286 8.76445 4.83341 9C4.83341 9.23611 4.91341 9.43417 5.07341 9.59417C5.23341 9.75417 5.43119 9.83389 5.66675 9.83334H8.16675ZM9.00008 17.3333C7.8473 17.3333 6.76397 17.1144 5.75008 16.6767C4.73619 16.2389 3.85425 15.6453 3.10425 14.8958C2.35425 14.1458 1.76064 13.2639 1.32341 12.25C0.886193 11.2361 0.667304 10.1528 0.666748 9C0.666748 7.84723 0.885637 6.76389 1.32341 5.75C1.76119 4.73612 2.3548 3.85417 3.10425 3.10417C3.85425 2.35417 4.73619 1.76056 5.75008 1.32334C6.76397 0.886116 7.8473 0.667227 9.00008 0.666672C10.1529 0.666672 11.2362 0.885561 12.2501 1.32334C13.264 1.76112 14.1459 2.35473 14.8959 3.10417C15.6459 3.85417 16.2398 4.73612 16.6776 5.75C17.1154 6.76389 17.334 7.84723 17.3334 9C17.3334 10.1528 17.1145 11.2361 16.6767 12.25C16.239 13.2639 15.6454 14.1458 14.8959 14.8958C14.1459 15.6458 13.264 16.2397 12.2501 16.6775C11.2362 17.1153 10.1529 17.3339 9.00008 17.3333Z"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
)


export default CirclePlus
