import * as React from "react"

const Matterport = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.972}
    height={25}
    viewBox="0 0 24.972 25"
    {...props}
  >
    <defs>
      <style>{".matterport-a{fill:#b7babb;}.matterport-b{fill:#fff;}.matterport-c{fill:#999;}"}</style>
    </defs>
    <g transform="translate(-70.68 61.09)">
      <g transform="translate(67 -68)">
        <path
          className="matterport-a"
          d="M25.077,6.91l-8.918,2.5L7.248,6.91l-3.568,1v23l3.568,1,8.918-2.5,8.918,2.5,3.568-1v-23ZM21.508,24.071l-5.349-1.5-5.349,1.5V14.749l5.349,1.5,5.349-1.5Z"
        />
        <g transform="translate(3.68 7.912)">
          <path
            className="matterport-b"
            d="M16.159,11.842v4.841l-8.911-2.5V32.341L3.68,31.347v-23Z"
            transform="translate(-3.68 -8.35)"
          />
          <path
            className="matterport-b"
            d="M26.969,29.059l-5.349-1.5V32.4l8.918,2.5V16.74l-3.568,1Z"
            transform="translate(-9.141 -10.904)"
          />
        </g>
        <g transform="translate(3.68 6.91)">
          <path
            className="matterport-c"
            d="M25.077,6.91l3.561,1L16.159,11.4,3.68,7.912l3.568-1,8.911,2.5Z"
            transform="translate(-3.68 -6.91)"
          />
          <path
            className="matterport-c"
            d="M19.279,29.42l5.349,1.5v2l-5.349-1.5-5.349,1.5v-2Z"
            transform="translate(-6.8 -13.762)"
          />
        </g>
      </g>
    </g>
  </svg>
)


export default Matterport
