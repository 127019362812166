import axios from "axios";
import fileDownload from 'js-file-download'
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import { Config } from "utils";
import './connections.scss'
import ActionBlock from "./modules/ActionBlock";
import InviteConnections from "./modules/InviteConnection";
import UploadDocument from "./modules/UploadDocument";
import QuestionnaireForm from "./modules/QuestionnaireForm";
// import QuestionnairePreview from "./modules/QuestionnairePreview";
import { toast } from "react-toastify";
import ToastMessage from "components/ToastMessage";
import { checkImageUrl, getErrorMessage } from "utils/modules/Handlers";
import DeleteConnection from "./modules/DeleteConnection";
import moment from "moment";
import NoUser from 'assets/images/no_user.jpg'
import BuyerInfoModal from "./modules/BuyerInfoModal";
import debouce from 'lodash.debounce'
import QuestionnairePreview from "components/QuestionnairePreview";
import AmountEdit from "./modules/AmountEdit";
import NotifyBuyerModal from "./modules/NotifyBuyerModal";
import DashboardFiltersContainer from "components/DashboardFiltersContainer";
import CustomButton from "components/CustomButton";
import CustomTextInput from "components/CustomTextInput";

const MortgageConnections = () => {
    const dispatch = useDispatch();
    const { mortgageConnections: { data, previous, next, total } } = useSelector((state) => state.buyer)
    const { user } = useSelector((state) => state.auth)
    const [pages, setPages] = useState([]);
    const [inviteModal, setInviteModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [questionnaireModal, setQuestionnaireModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [buyerModal, setBuyerModal] = useState(false);
    const [amountModal, setAmountModal] = useState(false);
    const [notifyModal, setNotifyModal] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedData, setSelectedData] = useState({});
    const [selectedPage, setSelectedPage] = useState(null);
    const [filters, setFilters] = useState('')


    useEffect(() => {
        const init = async () => {
            await dispatch(actions.buyer.fetchBuyerMortgageConnection())
        }
        init();
    }, [])

    useEffect(() => {
        const init = async () => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total / 20) || 1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    value: filters ? `${Config.API_URL}/buyer-broker-connection/search/?limit=20&offset=${offset}&ordering=-date_created&${filters}` : `${Config.API_URL}/buyer-broker-connection/?limit=20&offset=${offset}&ordering=-date_created`,
                    name: `${offset + 1} - ${i * 20 >= total ? total : i * 20}`,
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [total, filters])

    const loadPageData = async ({ page, pageNumber }) => {
        await dispatch(actions.agent.fetchBuyerAgentConnectionPagination(page));
        setSelectedPage(page);
        setPage(pageNumber);
    }

    const checkStatus = (item) => {
        if (item.approval_document) {
            if (item.date_approved) {
                return <span style={{ color: '#74A3EC' }}>Approved</span>
            } else if (item.date_cancelled) {
                return <span style={{ color: '#949494' }}>Cancelled</span>
            } else if (item.date_rejected) {
                return <span style={{ color: '#EF4546' }}>Rejected</span>
            } else { return <span style={{ color: '#70D6EC' }}>Waiting for Approval</span> }
        } else {
            return <span style={{ color: '#70D6EC' }}>Upload <br />Pre-Approval Letter</span>
        }
    }

    const handleDownload = (connection) => {
        axios.get(connection.approval_document, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, `${connection.buyer.first_name} ${connection.buyer.last_name} - Approval Document.${res.data.type.split('/')[1]}`)
            })
    }

    const updateBuyerTier = async (item, tier) => {
        const data = { id: item.id, buyer_tier: tier }
        try {
            if (tier === 'Q' && !item.questionnaire_filled_out) {
                await setSelectedData(item);
                toast.info('Questionnaire must be filled out before changing to Queen Bee', {
                    position: toast.POSITION.TOP_CENTER
                });
                setQuestionnaireModal((prev) => !prev);
            } else {
                await dispatch(actions.broker.updateBuyerTier(data)).then(async () => {
                    await refetchConnectionData();
                    toast.success('Buyer tier has been updated', {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
            }
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const onSelectEntry = (item) => {
        setSelectedData(item)
        setUploadModal(prev => !prev)
    }

    const onSelectBuyer = (item) => {
        setSelectedData(item)
        setBuyerModal(prev => !prev)
    }

    const onSelectAmount = (item) => {
        setSelectedData(item)
        setAmountModal(prev => !prev)
    }

    const onNotifyBuyer = (item) => {
        setSelectedData(item)
        setNotifyModal(prev => !prev)
    }

    const onSearch = useCallback(async () => {
        try {
            await dispatch(actions.buyer.searchBuyerMortgageConnection({ offset: 0, filters }))
        } catch (error) {
            console.log(error)
        }
    }, [filters])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [filters])

    const onChangeInput = (event) => {
        setFilters(`search=${event.target.value}`)
    }

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 300)
    }, [])


    const refetchConnectionData = useCallback(async () => {
        if (filters) {
            onSearch();
        } else {
            if (selectedPage) {
                await dispatch(actions.buyer.searchBuyerMortgageConnection(selectedPage));
            } else {
                await dispatch(actions.buyer.fetchBuyerMortgageConnection())
            }
        }
    }, [filters])


    const formatCurency = (value) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
        return formatter.format(value);
    }

    const previewDocument = (connection) => {
        window.open(connection.approval_document, '_blank');
    }


    return (
        <div className="mortgage-connections-container">
            <InviteConnections modal={inviteModal} setModal={setInviteModal} />
            <UploadDocument modal={uploadModal} setModal={setUploadModal} selectedData={selectedData} />
            <QuestionnaireForm modal={questionnaireModal} setModal={setQuestionnaireModal} selectedData={selectedData} />
            <QuestionnairePreview modal={previewModal} setModal={setPreviewModal} selectedData={selectedData} />
            <DeleteConnection modal={deleteModal} setModal={setDeleteModal} selectedData={selectedData} />
            <BuyerInfoModal modal={buyerModal} setModal={setBuyerModal} selectedData={selectedData} />
            <AmountEdit modal={amountModal} setModal={setAmountModal} selectedData={selectedData} />
            <NotifyBuyerModal modal={notifyModal} setModal={setNotifyModal} selectedData={selectedData} />
            <DashboardFiltersContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', columnGap: 8 }}>
                <div className="table-filters">
                    <CustomTextInput
                        inputProps={{
                            type: "text",
                            placeholder: 'Search Connection',
                            id: 'email',
                            onChange: handleInput,
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className="buttons" style={{ display: 'flex', alignItems: 'center', columnGap: 32, justifyContent: 'space-between'}}>
                    <CustomButton text={"Invite Buyer"} type="secondary" action={() => setInviteModal(prev => !prev)} />
                    {
                        data.length > 0 &&
                        <div className="pagination">
                            <p>{pages[page - 1]?.name} of {total}</p>
                            <p onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                        fill={previous ? "#5F5F5F" : "#C9C9C9"}
                                    />
                                </svg>
                            </p>
                            <p onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.95964 3.95955C6.35017 3.56903 6.98333 3.56903 7.37385 3.95955L10.7072 7.29288C11.0977 7.68341 11.0977 8.31657 10.7072 8.7071L7.37385 12.0404C6.98333 12.431 6.35017 12.431 5.95964 12.0404C5.56912 11.6499 5.56912 11.0167 5.95964 10.6262L8.58587 7.99999L5.95964 5.37376C5.56912 4.98324 5.56912 4.35007 5.95964 3.95955Z"
                                        fill={next ? "#5F5F5F" : "#C9C9C9"}
                                    />
                                </svg>
                            </p>
                        </div>
                    }
                </div>
            </div>
            </DashboardFiltersContainer>
            <div className="contents">
                <div className="table-container">
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th width="210px"><div>Buyer</div></th>
                                <th width="210px" style={{ textAlign: 'center' }}><div>Document Expiration</div></th>
                                <th width="210px"><div style={{ textAlign: 'center' }}>Document</div></th>
                                <th width="180px"><div>Pre-Approval Amount</div></th>
                                <th width="210px"><div style={{ textAlign: 'center' }}>Status</div></th>
                                <th width="210px"><div style={{ textAlign: 'center' }}>Tier</div></th>
                                <th width="146px"><div style={{ textAlign: 'center' }}>Action</div></th>
                            </tr>
                        </thead>
                        <tbody id="connections-table">
                            {
                                data.length > 0 ?
                                    data.map((connection, index) => {
                                        return (
                                            <tr className="body" key={index}>
                                                <td>
                                                    <div className="buyer-container">
                                                        <img className="buyer-image" src={checkImageUrl(connection.buyer.profile_photo, NoUser)} alt="" />
                                                        <p className="buyer-info" onClick={() => onSelectBuyer(connection)}>{connection.buyer.first_name} {connection.buyer.last_name}</p>
                                                    </div>
                                                </td>
                                                <td align="center" data-label="Document Expiration">
                                                    {
                                                        connection.approval_document_expiry
                                                            ? <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                                                                <p style={{ fontSize: 12, fontWeight: 600, margin: 0, color: '#949494' }}>{moment(connection.approval_document_expiry).format('MMMM DD, YYYY')}</p>
                                                                {
                                                                    parseInt(moment.duration(moment(connection.approval_document_expiry).startOf('day').diff(moment().startOf('day'))).asDays()) < 7 &&
                                                                    <>
                                                                        {
                                                                            (Math.sign(parseInt(moment.duration(moment(connection.approval_document_expiry).diff(moment().startOf('day'))).asDays())) === -1 ||
                                                                                Math.sign(parseInt(moment.duration(moment(connection.approval_document_expiry).diff(moment().startOf('day'))).asDays())) === 0)
                                                                                ? <p style={{ fontSize: 10, color: '#EF4546' }}>Document is expired</p>
                                                                                : <p style={{ fontSize: 10, color: '#EF4546' }}>Document is about to Expire!</p>
                                                                        }
                                                                        {
                                                                            connection.date_notified
                                                                                ? <>
                                                                                    {
                                                                                        (moment.duration(moment(connection.date_notified).startOf('day').diff(moment().add(3, 'days').startOf('day'))).asDays() >= 0)
                                                                                            ? <CustomButton text={'Notify Buyer'} action={() => onNotifyBuyer(connection)} type="secondary" />
                                                                                            : <p className="notified">Notified</p>
                                                                                    }
                                                                                </>
                                                                                : <p className="upgrade" onClick={() => onNotifyBuyer(connection)}>Notify Buyer</p>
                                                                        }
                                                                    </>
                                                                }

                                                            </div>
                                                            : <p style={{ fontSize: 12, fontWeight: 600, color: '#949494' }}>No Document</p>
                                                    }
                                                </td>
                                                <td align="center" data-label="Document">
                                                    <div className="document-wrapper" style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                                                        {
                                                            connection.approval_document ?
                                                                <>
                                                                    {
                                                                        (connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id && moment.duration(moment(connection.approval_document_expiry).startOf('day').diff(moment().startOf('day'))).asDays() > 0) &&
                                                                        <CustomButton text={'Download Document'} action={() => handleDownload(connection)} size={'small'} />
                                                                    }
                                                                    {
                                                                        connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id &&
                                                                        <CustomButton text={'Re-upload Document'} action={() => onSelectEntry(connection)} type="secondary" size={'small'} />
                                                                    }
                                                                    {
                                                                        (connection.buyer.buyer_profile?.default_mortgage_broker?.id !== user.id && moment.duration(moment(connection.approval_document_expiry).startOf('day').diff(moment().startOf('day'))).asDays() > 0) &&
                                                                        <CustomButton text={'Preview Document'} action={() => previewDocument(connection)} size={'small'} type="secondary" />
                                                                    }
                                                                </>
                                                                : <>
                                                                    {
                                                                        !data.questionnaire_filled_out &&
                                                                        <>
                                                                            {
                                                                                connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id ?
                                                                                    <CustomButton text={'Upload Document'} action={() => onSelectEntry(connection)} size={'small'} />
                                                                                : <p style={{ fontSize: 12, fontWeight: 600, color: '#949494' }}>No Document</p>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </td>
                                                <td align="center" data-label="Pre-Approval Amount">
                                                    <div className="document-wrapper">
                                                        {
                                                            connection.approval_amount
                                                                ?
                                                                <>
                                                                    <p style={{ fontSize: 12, fontWeight: 600, margin: 0, paddingBottom: 8 }}>{formatCurency(connection.approval_amount)}</p>
                                                                    {
                                                                        connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id &&
                                                                        <CustomButton text={'Update Amount'} action={() => onSelectAmount(connection)} type="secondary" size={'small'} />
                                                                    }
                                                                </>
                                                                : <>
                                                                    {
                                                                        connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id ?
                                                                            <CustomButton text={'Add Amount'} action={() => onSelectAmount(connection)} size={'small'} />
                                                                        : <p style={{ fontSize: 12, fontWeight: 600, color: '#949494' }}>No Amount</p>
                                                                    }
                                                                </>
                                                                
                                                        }
                                                    </div>
                                                </td>
                                                <td align="center" data-label="Status">
                                                    {
                                                        connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id ?
                                                        <p style={{ fontSize: 12, fontWeight: 600 }}>
                                                            {checkStatus(connection)}
                                                        </p>
                                                        : <p style={{ fontSize: 12, fontWeight: 600, color: '#949494' }}>
                                                            Status Not Available
                                                        </p>
                                                    }
                                                </td>
                                                <td align="center" data-label="Tier">
                                                    <div className="document-wrapper" style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                                                        <p style={{ fontSize: 12, fontWeight: 600, margin: 0 }}>{connection.buyer_tier_display}</p>
                                                        {
                                                            connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id &&
                                                            <>
                                                                {
                                                                    connection.buyer_tier === 'H' &&
                                                                    <CustomButton text={'Upgrade to Queen Bee'} action={() => updateBuyerTier(connection, 'Q')} type="secondary" size={'small'} />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                                <td align="center" data-label="Action">
                                                    {
                                                        connection.buyer.buyer_profile?.default_mortgage_broker?.id === user.id &&
                                                        <ActionBlock
                                                            data={connection}
                                                            setSelectedData={setSelectedData}
                                                            setModal={setUploadModal}
                                                            setQuestionnaireModal={setQuestionnaireModal}
                                                            setPreviewModal={setPreviewModal}
                                                            setDeleteModal={setDeleteModal}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>
                                        <td colSpan="7">
                                            <div className="empty-table">No Available Buyer Connections</div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}


export default MortgageConnections;