import * as React from "react"

const PropertySize = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13.563}
    viewBox="0 0 13 13.563"
    {...props}
  >
    <path
      fill="#fac91f"
      d="M11.175,3.375,13.2,5.491,5.4,13.628,3.375,11.512v5.425h5.2L6.547,14.822l7.8-8.137L16.375,8.8V3.375Z"
      transform="translate(-3.375 -3.375)"
    />
  </svg>
)


export default PropertySize
