import API from 'API'

const fetchTeams = () => async dispatch => {
  const response = await API.team.fetchTeams();
  const payload = response.data;
  return payload
}

const fetchOwnedTeams = (id) => async dispatch => {
  const response = await API.team.fetchOwnedTeams(id);
  const payload = response.data;
  return payload
}

const retrieveTeam = (id) => async dispatch => {
  const response = await API.team.retrieveTeam(id);
  const payload = response.data;
  return payload
}

const postTeam = (data) => async dispatch => {
  const response = await API.team.postTeam(data);
  const payload = response.data;
  return payload
}

const fetchJoinedTeams = (data) => async dispatch => {
  const response = await API.team.fetchJoinedTeams();
  const payload = response.data;
  return payload
}

const fetchMemberInvites = (data) => async dispatch => {
  const response = await API.team.fetchMemberInvites();
  const payload = response.data;
  return payload
}

const fetchTeamMembers = (id) => async dispatch => {
  const response = await API.team.fetchTeamMembers(id);
  const payload = response.data;
  return payload
}

const fetchAllTeamMembers = (ids) => async dispatch => {
  const response = await API.team.fetchAllTeamMembers(ids);
  const payload = response.data;
  return payload
}


const addMember = (data) => async dispatch => {
  const response = await API.team.addMember(data);
  const payload = response.data;
  return payload
}

const addMemberApplication = (data) => async dispatch => {
  const response = await API.team.addMemberApplication(data);
  const payload = response.data;
  return payload
}

const acceptMemberInvite = (id) => async dispatch => {
  const response = await API.team.acceptMemberInvite(id);
  const payload = response.data;
  return payload
}

const declineMemberInvite = (id) => async dispatch => {
  const response = await API.team.acceptMemberInvite(id);
  const payload = response.data;
  return payload
}

const teamActions = {
    fetchTeams,
    fetchOwnedTeams,
    retrieveTeam,
    postTeam,
    fetchJoinedTeams,
    fetchMemberInvites,
    fetchTeamMembers,
    fetchAllTeamMembers,
    addMember,
    addMemberApplication,
    acceptMemberInvite,
    declineMemberInvite,
}


export default teamActions;