import * as React from "react"

const ArrowRight = (props) => (
  <svg
    width={11}
    height={19}
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.29999 0.424998L10.725 8.825C10.825 8.925 10.896 9.03333 10.938 9.15C10.98 9.26667 11.0007 9.39167 11 9.525C11 9.65833 10.979 9.78333 10.937 9.9C10.895 10.0167 10.8243 10.125 10.725 10.225L2.29998 18.65C2.06665 18.8833 1.77498 19 1.42498 19C1.07498 19 0.774984 18.875 0.524984 18.625C0.274984 18.375 0.149984 18.0833 0.149984 17.75C0.149984 17.4167 0.274985 17.125 0.524985 16.875L7.87499 9.525L0.524986 2.175C0.291652 1.94166 0.174985 1.654 0.174986 1.312C0.174986 0.97 0.299986 0.674331 0.549986 0.424998C0.799986 0.174998 1.09165 0.0499984 1.42499 0.0499984C1.75832 0.0499984 2.04999 0.174998 2.29999 0.424998Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default ArrowRight
