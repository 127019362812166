import Icons from 'components/Icons';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './emailReplyModal.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';

const EmailReplyModal = ({ modal, setModal, selectedData, fetchData }) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(async() => {
        setLoading(true);
        try {
            const data = {
                inquiry: selectedData.id,
                message: message,
            }
            await dispatch(actions.agent.replyToBuyerInquiry(data)).then(async() => {
                await fetchData();
                setLoading(false);
                setModal(prev => !prev);
                toast.success('Inquiry reply has been sent.', {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }, [selectedData, message])

    return (
        <div className={`email-reply-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Email Message
                </p>
                <p className='section-title message'>
                    Send an email reply to <span style={{ color: '#FAC91F', fontWeight: 700 }}>{(selectedData && selectedData.inquirer) ? `${selectedData.inquirer?.first_name} ${selectedData.inquirer?.last_name}` : `${selectedData?.first_name} ${selectedData?.last_name}`}</span>.
                </p>
                <Seprator height={20} />
                <div className='form-input'>
                    <label>Message</label>
                    <textarea
                        onChange={(event) => setMessage(event.target.value)}
                        style={{ height: 250, padding: 15 }}
                        placeholder='Message' 
                        className='small' 
                    ></textarea>
                </div>
                <Seprator height={30} />
                <button className='button primary small no-glow' onClick={onSubmit} disabled={loading}>
                {
                    loading 
                    ? <Icons.Loading height={40} width={40} background={'none'} />
                    : 'Send'
                }
                </button>
                <Seprator height={15} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default EmailReplyModal;