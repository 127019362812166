import * as React from "react"

const Location = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={20}
    viewBox="0 0 25 20"
    {...props}
  >
    <path
      className="a"
      fill={props.color || "#fac91f"}
      d="M0,6.074V21.535a.7.7,0,0,0,.952.663l5.992-2.805V2.25L.873,4.748A1.429,1.429,0,0,0,0,6.074ZM8.333,19.393l8.333,2.857V5.107L8.333,2.25ZM24.048,2.3,18.056,5.107V22.25l6.071-2.5A1.429,1.429,0,0,0,25,18.426V2.965A.7.7,0,0,0,24.048,2.3Z"
      transform="translate(0 -2.25)"
    />
  </svg>
)


export default Location
