import * as React from "react"

const Unsaved = (props) => (
  <svg
    width={11}
    height={12}
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8316 10.6724L10.1097 11.4L9.00126 10.2916L6.28421 9.12631L2.30526 10.8316V3.59558L0.599998 1.89031L1.32758 1.16842L10.8316 10.6724ZM10.2632 8.64884V1.73684C10.2632 1.43533 10.1434 1.14617 9.93018 0.932972C9.71698 0.719773 9.42782 0.599998 9.12632 0.599998H3.4421C3.10674 0.599998 2.81116 0.753472 2.58947 0.986525L10.2632 8.64884Z"
      fill="#2A2A2A"
    />
  </svg>
)


export default Unsaved
