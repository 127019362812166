import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Utils
import { checkImageUrl, formatCurrency, getErrorMessage } from 'utils/modules/Handlers';
// Packages
import Talk from 'talkjs';
import moment from 'moment';
// Components
import Icons from 'components/Icons';
import Seprator from 'components/Separator';
import Footer from 'components/Footer';
import RequestStepper from 'components/RequestStepper';
// Assets
import './requestReview.scss';
import house1 from 'assets/images/house1.jpg';
import NoUser from 'assets/images/no_user.jpg';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import html2canvas from 'html2canvas';
import { Config } from 'utils';

const RequestReview = () => {
    const { id: showingID, mls } = useParams();
    const { user } = useSelector((state) => state.auth);
    // const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const urlParams = new URLSearchParams(window.location.search);
    const { showing } = useSelector((state) => state.showing)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [talkLoaded, markTalkLoaded] = useState(false);
    const [relatedUsers, setRelatedUsers] = useState([])
    const [attachment, setAttachment] = useState(null)

    
    useEffect(() => {
        const initSBBot = async() => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (talkLoaded) {
                const showingBeeBot = {
                    name: 'Showing Bee Showings Bot',
                    email: ['showings@showingbee.com'],
                    photoUrl: 'https://i.ibb.co/SdMNTMM/sb-bot.png',
                    role: 'admin',
                }
                await dispatch(actions.message.updateCreateUser({ id: 'showingbee_showings_bot', data: showingBeeBot }));
            }
        }

        initSBBot();
    }, [talkLoaded])


    useEffect(() => {
        const initShowingData = async() => {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id));
        }
        initShowingData();
    }, [])


    useEffect(() => {
        const initRelatedUsers = async() => {
            if (showing.id) {
                let users = [];
                users.push(user);
                if (showing.buyer_agent_data) {
                    users.push(showing.buyer_agent_data?.agent_data.user_data)
                }
                users.push(showing.listing_data?.agent_data.user_data);
                if (showing.agent_members_data && showing.agent_members_data.length > 0) {
                    showing.agent_members_data.map(item => {
                        users.push(item.agent_data.user_data);
                    })
                }
                console.log(users)
                setRelatedUsers(users)
            }
        }

        initRelatedUsers();
    }, [showingID, showing, user])
    
    useEffect(() => {
        const getAttachment = () => {
            if (showing.id) {
                let preview = document.getElementById('showing-preview')
                html2canvas(preview, {useCORS: true, scale: 2}).then(async(canvas) => {
                    console.log(canvas.toDataURL());
                    canvas.toBlob(async(blob) => {
                        const formData = new FormData()
                        const file = new File([blob], `showing_request_${showing.id}__${moment(new Date(showing.date)).format('MM-DD-YY')}.png`,{ type: "image/png" })
                        formData.append('file', file);
                        formData.append('filename ', `showing_request_${showing.id}__${moment(new Date(showing.date)).format('MM-DD-YY')}.png`);
                        const response = await dispatch(actions.message.uploadFiles({ data: formData }));
                        setAttachment(response.attachmentToken)
                    }, 'image/png')
                });
            }
            
        }
        getAttachment();
    }, [showing, showingID])

    const getDate = (data) => {
        const date = moment(data).format('MMM DD, YYYY | dddd')
        return date
    }

    const onSubmit = useCallback(async() => {
        setLoading(true);
        try {
            if (attachment) {
                const messageData = [
                    {
                        text: `Showing Request for Listing #${showing.listing_data?.mls_number} on ${showing.date} around ${showing.start_time} to ${showing.finish_time}`,
                        sender: 'showingbee_showings_bot',
                        type: "SystemMessage",
                    },
                    {
                        attachmentToken: attachment,
                        type: 'UserMessage',
                        sender: 'showingbee_showings_bot',
                    },
                    {
                        text: `Listing Details: ${Config.CLIENT_URL}/listings/${showing.listing_data.mls_source}/${showing.listing_data.mls_number || showing.listing_data.id}/`,
                        type: 'UserMessage',
                        sender: 'showingbee_showings_bot',
                    },
                ]
    
                const showingBeeBot  = new Talk.User({
                    id: 'showingbee_showings_bot',
                    name: 'Showing Bee Showings Bot',
                    photoUrl: 'https://i.ibb.co/SdMNTMM/sb-bot.png',
                    email: 'showings@showingbee.com',
                    role: 'admin',
                });
    
                await Promise.all(
                    relatedUsers.map(async (item) => {
                        const userData  = new Talk.User({
                            id: item.id,
                            name: `${item.first_name} ${item.last_name}`,
                            email: item.email,
                            photoUrl: checkImageUrl(item.profile_photo, NoUser),
                            role: item.user_type,
                        });
                        
                        const session = new Talk.Session({
                            appId: Config.TalkJS_APP_ID,
                            me: userData,
                        });
                        let conversationId = Talk.oneOnOneId(userData, showingBeeBot);
                        const conversation = session.getOrCreateConversation(conversationId)
                        conversation.setParticipant(userData, {access: "Read", notify: true});
                        conversation.setParticipant(showingBeeBot, {access: "Read", notify: true});
        
                        try {
                            await dispatch(actions.message.updateCreateUser({ 
                                id: userData.id, 
                                data: {
                                    name: userData.name,
                                    email: [userData.email],
                                    photoUrl: userData.photoUrl,
                                    role: userData.role,
                                } 
                            }));
                            await dispatch(actions.message.createConversation({id: conversation.id, data: { participants: [userData.id.toString(), showingBeeBot.id.toString()] }}));    
                        } catch (error) {
                            console.log(error);
                        }
                        await dispatch(actions.message.sendMessage({id: conversation.id, data: messageData}))
                    })
                ).then(async() => {
                    await dispatch(actions.showing.showingRequestStep4({ showing: showing.id }))
                    setLoading(false);
                    navigate('/request/submitted')
                })
    
            }
        } catch (error) {
            setLoading(false);
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [relatedUsers, attachment, showing])

    const onChangeAgent = () => {
        navigate(`/request/${mls}/${showingID}/choose-agent/?prev=review&next=review${urlParams.get('back') ? '&back='+urlParams.get('back') : ''}`)
    }

    const addAgent = () => {
        navigate(`/request/${mls}/${showingID}/choose-agent/?prev=review&next=review`)
    }

    const onBack = () => {
        if (urlParams.get('back')) {
            navigate(`/${urlParams.get('back')}?active=drafts`)
        } else {
            navigate(`/request/${mls}/${showingID}/calendar`)
        }
    }

    return (
        <>
            {
                showing.id &&
                <section className="request-review-container">
                    <Seprator height={100} />
                    <button 
                        className='button small primary save' 
                        onClick={onSubmit}
                        disabled={loading || !attachment}
                    >
                        <p>
                            <Icons.BeeIconButton width={20}/>
                            {
                                loading 
                                ? <Icons.Loading height={40} width={40} background={'none'} />
                                : 'Send Request!'
                            }
                        </p>
                    </button>
                    <div className='back-next-container'> 
                        <div>
                            <p className='back-button' onClick={onBack}>Back</p>
                            <h1 className="title">Showing Request Review</h1>
                            {/* <button 
                                className="button medium primary next-button submit"
                                onClick={onSubmit}
                                disabled={loading}
                            >
                                {
                                    loading 
                                    ? <Icons.Loading height={40} width={40} background={'none'} />
                                    : 'Submit Request'
                                }
                            </button> */}
                        </div>
                        <p className='subtitle'>Please review the details of your showing request.</p>
                    </div>
                    <div className='contents-container'>
                        <div className='contents'>
                            <img id='preview' className="preview" src={checkImageUrl(showing.listing_data?.featured_image?.image_url, house1)} alt="#" />
                            <div className='content-details'>
                                <div className='listing-details'>
                                    <div className='listing-info'>
                                        <p className="listing">Listing #{showing.listing_data?.mls_number}</p>
                                        <Seprator height={15} />
                                        <p className="title">{showing.listing_data?.property_type} in {showing.listing_data?.city}</p>
                                        <Seprator height={20} />
                                        <h1 className="address">
                                            {showing.listing_data?.address}
                                        </h1>
                                        <Seprator height={20} />
                                    </div>
                                    <div className='listing-agent'>
                                        {
                                            showing.listing_data?.list_agent_full_name 
                                            ? <>
                                                <img src={checkImageUrl(showing.listing_data?.agent_data.user_data?.profile_photo, NoUser)} alt="" />
                                                <span>{showing.listing_data?.list_agent_full_name}</span>
                                            </>
                                            : <span>No Listing Agent</span>
                                        }
                                        
                                    </div>
                                </div>
                                <Seprator height={20} />
                                <div className="info-container">
                                    <div className="details">
                                        {
                                            showing.buyers_data?.length > 0 
                                            ? showing.buyers_data.map((item, index) => {
                                                return(
                                                    <div key={index}>
                                                        <div className='content'>
                                                            <p className="buyer">{item.first_name} {item.last_name}</p>
                                                            <span className="email">{item.email}</span>
                                                        </div>
                                                    </div>
                                            )
                                            })
                                            : <p className="buyer">Invited Buyer</p>
                                        }
                                        <Seprator height={20} />
                                        <div className='showing'>
                                            <p className="date">{getDate(showing.date)}</p>
                                            <p className='address'>{showing.listing_data?.address}</p>
                                        </div>
                                    </div>
                                    <div className="time">
                                        <p className="duration">{showing.duration}</p>
                                        <p className="specific">{`${showing.start_time} - ${showing.finish_time}`}</p>
                                    </div>
                                </div>
                                <Seprator height={50} />
                                <div style={{ displat: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {
                                            showing.buyer_agent_data &&
                                            <div style={{ display: 'flex' }}>
                                                
                                                <div>
                                                    <p className="agent">
                                                        {
                                                            showing.buyer_agent_data?.id 
                                                            && `${showing.buyer_agent_data?.first_name} ${showing.buyer_agent_data?.last_name}`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {
                                            showing.buyer_agent_data ?
                                            <button className='button no-glow small change' onClick={onChangeAgent}>
                                                Change
                                                <Seprator width={10} />
                                                <Icons.Edit height={13} width={13} color={'#6C767C'} />
                                            </button>
                                            : 
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                <button className='button primary no-glow small add' onClick={addAgent}>
                                                        Add Buyer's Agent
                                                </button>   
                                            </div>
                                        }
                                    </div>
                                    {
                                        showing.buyer_agent_data &&
                                        <>        
                                            <Seprator height={10} />
                                            <div className="buyer-agent-info">
                                                <div><Icons.EmailSmall height={20} width={25} color={'#6C767C'} /> {showing.buyer_agent_data?.agent_data?.user_data?.email || 'Not Indicated'}</div>
                                                <div><Icons.Telephone height={20} width={25} /> {showing.buyer_agent_data?.agent_data?.user_data?.phone_number || 'Not Indicated' }</div>
                                                <div><Icons.Briefcase height={20} width={25} />{showing.buyer_agent_data?.agent_data?.company_name ? showing.buyer_agent_data?.agent_data?.company_name : 'No Company Data'}</div>
                                                <div><Icons.Compensation height={20} width={25} color={'#6C767C'} />
                                                    {
                                                        (showing.buyer_agent_data.agent_data?.buyer_agent_compensation_type === 'cash')
                                                        && <>
                                                        {showing.buyer_agent_data.agent_data?.buyer_agent_compensation_cash ? formatCurrency(showing?.buyer_agent_data?.agent_data?.buyer_agent_compensation_cash) : 'Not Indicated'}
                                                        </>
                                                    }
                                                    {
                                                        (showing.buyer_agent_data.agent_data?.buyer_agent_compensation_type === 'percent')
                                                        && <>
                                                            {showing.buyer_agent_data.agent_data?.buyer_agent_compensation_percent ? `${showing?.buyer_agent_data?.agent_data?.buyer_agent_compensation_percent}%` : 'Not Indicated'}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div id='showing-preview' className='contents'>
                            {/* <img id='preview' className="preview" src={checkImageUrl(showing.listing_data?.featured_image?.image_url, house1)} alt="#" /> */}
                            <div className='preview'>
                                <Icons.SBHeader />
                            </div>
                            <div className='content-details'>
                                <div className='listing-details'>
                                    <div className='listing-info'>
                                        <p className="listing">Listing #{showing.listing_data?.mls_number}</p>
                                        <Seprator height={15} />
                                        <p className="title">{showing.listing_data?.property_type} in {showing.listing_data?.city}</p>
                                        <Seprator height={20} />
                                        <h1 className="address">
                                            {showing.listing_data?.address}
                                        </h1>
                                        <Seprator height={20} />
                                    </div>
                                    <div className='listing-agent'>
                                        {
                                            showing.listing_data?.list_agent_full_name 
                                            ? <>
                                                <img src={checkImageUrl(showing.listing_data?.agent_data.user_data?.profile_photo, NoUser)} alt="" />
                                                <span>{showing.listing_data?.list_agent_full_name}</span>
                                            </>
                                            : <span>No Listing Agent</span>
                                        }
                                        
                                    </div>
                                </div>
                                <Seprator height={20} />
                                <div className="info-container">
                                    <div className="details">
                                        {
                                            showing.buyers_data?.length > 0 
                                            ? showing.buyers_data.map((item, index) => {
                                                return(
                                                    <div key={index}>
                                                        <div className='content'>
                                                            <p className="buyer">{item.first_name} {item.last_name}</p>
                                                            <span className="email">{item.email}</span>
                                                        </div>
                                                    </div>
                                            )
                                            })
                                            : <p className="buyer">Invited Buyer</p>
                                        }
                                        <Seprator height={20} />
                                        <div className='showing'>
                                            <p className="date">{getDate(showing.date)}</p>
                                            <p className='address'>{showing.listing_data?.address}</p>
                                        </div>
                                    </div>
                                    <div className="time">
                                        <p className="duration">{showing.duration}</p>
                                        <p className="specific">{`${showing.start_time} - ${showing.finish_time}`}</p>
                                    </div>
                                </div>
                                {
                                    showing.buyer_agent_data &&
                                    <>
                                        <Seprator height={50} />
                                        <div style={{ displat: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            
                                                    <div style={{ display: 'flex' }}>
                                                        
                                                        <div>
                                                            <p className="agent">
                                                                {
                                                                    showing.buyer_agent_data?.id 
                                                                    && `${showing.buyer_agent_data?.first_name} ${showing.buyer_agent_data?.last_name}`
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                            </div>
                                            <Seprator height={10} />
                                            <div className="buyer-agent-info">
                                                <div><Icons.EmailSmall height={20} width={25} color={'#6C767C'} /> {showing.buyer_agent_data?.agent_data?.user_data?.email || 'Not Indicated'}</div>
                                                <div><Icons.Telephone height={20} width={25} /> {showing.buyer_agent_data?.agent_data?.user_data?.phone_number || 'Not Indicated' }</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <Seprator height={90} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <RequestStepper />
                    </div>
                    <Seprator height={90} />
                    <Footer subfooter />
                </section>
            }
        </>
    )
}


export default RequestReview;