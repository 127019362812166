import { createSlice } from '@reduxjs/toolkit'

const showingSlice = createSlice({
  name: 'showing',
  initialState: {
    data: [],
    active: {
      data: [],
      next: null,
      previous: null,
      offset: 0,
      is_end: false,
      total: 0,
    },
    next: null,
    previous: null,
    offset: 0,
    is_end: false,
    showing: {},
    total: 0,
  },
  reducers: {
    fetchShowingsSuccess: (state, action) => {
      state.data = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.total = action.payload.count;
      state.offset = 0;
      state.is_end = false;
    },
    fetchActiveShowingsSuccess: (state, action) => {
      state.active.data = action.payload.results;
      state.active.next = action.payload.next;
      state.active.previous = action.payload.previous;
      state.active.total = action.payload.count;
      state.active.offset = 0;
      state.active.is_end = false;
    },
    retrieveShowingSuccess: (state, action) => {
      state.showing = action.payload;
    },
    showingRequestStepSuccess: (state, action) => {
      state.showing = action.payload;
    },

    
  },
});


export default showingSlice