import * as React from "react"

const Hive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.99}
    height={15}
    viewBox="0 0 15.99 15"
    {...props}
  >
    <g transform="translate(-1035.645 -212.144)">
      <path
        fill={props.color || '#2a2a2a'}
        d="M4.616,0a1,1,0,0,1,.87.507L6.653,2.565a1,1,0,0,1,0,.986L5.487,5.61a1,1,0,0,1-.87.507h-2.3a1,1,0,0,1-.87-.507L.279,3.551a1,1,0,0,1,0-.986L1.446.507A1,1,0,0,1,2.316,0Z"
        transform="translate(1035.645 221.846) rotate(-30)"
      />
      <path
        fill={props.color || '#2a2a2a'}
        d="M4.616,0a1,1,0,0,1,.87.507L6.653,2.565a1,1,0,0,1,0,.986L5.487,5.61a1,1,0,0,1-.87.507h-2.3a1,1,0,0,1-.87-.507L.279,3.551a1,1,0,0,1,0-.986L1.446.507A1,1,0,0,1,2.316,0Z"
        transform="translate(1039.107 215.61) rotate(-30)"
      />
      <path
        fill={props.color || '#2a2a2a'}
        d="M4.616,0a1,1,0,0,1,.87.507L6.653,2.565a1,1,0,0,1,0,.986L5.487,5.61a1,1,0,0,1-.87.507h-2.3a1,1,0,0,1-.87-.507L.279,3.551a1,1,0,0,1,0-.986L1.446.507A1,1,0,0,1,2.316,0Z"
        transform="translate(1042.573 221.613) rotate(-30)"
      />
    </g>
  </svg>
)


export default Hive
