import * as React from "react"


const Home = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22.095} height={25} {...props}>
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 648" fill={props.color || '#bebebe'} d="M0 0h22.095v25H0z" />
      </clipPath>
    </defs>
    <g data-name="Home Icon">
      <g data-name="Group 392" clipPath="url(#a)">
        <path
          className="menu-item-icon"
          data-name="Path 334"
          d="M13.549 1.04a3.526 3.526 0 0 0-5 0L0 9.586v11.879a3.534 3.534 0 0 0 3.535 3.534H18.56a3.534 3.534 0 0 0 3.535-3.535V9.586Zm1.034 19.541a1.767 1.767 0 0 1-1.768 1.768H9.28a1.761 1.761 0 0 1-1.768-1.768v-3.535a1.767 1.767 0 0 1 1.768-1.768h3.535a1.773 1.773 0 0 1 1.768 1.768Z"
          fill={props.color || '#bebebe'}
        />
      </g>
    </g>
  </svg>
)


export default Home
