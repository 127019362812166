import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
// Redux
import { useDispatch } from "react-redux";
import actions from "store/actions";
// Utils
import { getErrorMessage, inputChange } from "utils/modules/Handlers";
// Components
import Icons from "components/Icons";
import Section from "components/Section";
import Seprator from "components/Separator";
import ToastMessage from "components/ToastMessage";
// Assets
import './setPassword.scss'
import InviteIllus from 'assets/images/invite-illus.svg'

const SetPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        password: {
            value: '',
            error: '',
        },
        confirm_password: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async(event) => {
        event.preventDefault();

        const { password, confirm_password } = form
        let isValid = true

        if (password.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: ''})
        }

        if (confirm_password.value !== password.value) {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: 'Password does not match'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: ''})
        }

        if ((password.value && confirm_password.value) && confirm_password.value === password.value) {
            isValid = true
        }

        if (isValid) {
            setLoading(true);
            try {
                const data = {
                    uidb64: urlParams.get('uidb64'),
                    token: urlParams.get('token'),
                    password: password.value,
                }
                await dispatch(actions.auth.setPassword(data));
                setLoading(false);
                navigate('/confirm');
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [dispatch, form, navigate])

    return (
        <Section style={{ background: '#FAFAFA', display: 'flex', justifyContent: 'center' }}>
            <div className="set-password-container">
                {/* <Icons.CircleCheck color={'#FAC91F'} height={160} width={160} /> */}
                <img src={InviteIllus} alt="verified" />
                <Seprator height={50} />
                <p className="header">Password Setup</p>
                <Seprator height={10} />
                <p className="subheader">Please setup your password to complete your ShowingBee account!</p>
                <Seprator height={25} />
                <div className="form-container">
                    <div className="password small">
                        <input 
                            type={viewPassword ? 'text' : 'password'} 
                            placeholder='Password'
                            autoComplete="new-password"
                            className='small' 
                            onChange={(event) => onChangeInput({event, type: "password"})}
                        />
                        <span className='error-message'>{form.password.error}</span>
                        <Icons.Eye color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />
                    </div>
                    <Seprator height={30} />
                    <div className="password small">
                        <input 
                            type={viewPassword2 ? 'text' : 'password'} 
                            className='small'
                            autoComplete="new-password"
                            placeholder='Confirm Password' 
                            onChange={(event) => onChangeInput({event, type: "confirm_password"})}
                        />
                        <span className='error-message'>{form.confirm_password.error}</span>
                        <Icons.Eye color={viewPassword2 ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword2(prev => !prev)} />
                    </div>
                    <span className='error-message'>{form.confirm_password.error}</span>
                    <Seprator height={50} />
                    <button className="button primary small no-glow browse" disabled={loading} onClick={onSubmit}>
                        {
                            loading 
                            ? <Icons.Loading height={40} width={40} background={'none'} />
                            : 'Submit'
                        }
                    </button>
                </div>
            </div>
        </Section>
    )
}


export default SetPassword;