// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-demo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.request-demo-container .form {
  margin-top: 128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.request-demo-container .form .header {
  font-size: 32px;
  color: var(--secondary-color-1);
  font-weight: 700;
  line-height: 36px;
}
.request-demo-container .form .subheader {
  color: #949494;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  max-width: 600px;
  text-align: center;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/RequestDemoFooterLink/requestDemoFooterPage.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAAI;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AAER;AADQ;EACI,eAAA;EACA,+BAAA;EACA,gBAAA;EACA,iBAAA;AAGZ;AADQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAGZ;AAAI;EACI;IAAO,UAAA;EAGb;EAFM;IAAO,UAAA;EAKb;AACF","sourcesContent":[".request-demo-container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    .form {\n        margin-top: 128px;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        height: 100%;\n        .header {\n            font-size: 32px;\n            color: var(--secondary-color-1);\n            font-weight: 700;\n            line-height: 36px;\n        }\n        .subheader {\n            color: #949494;\n            font-size: 20px;\n            font-weight: 400;\n            line-height: 32px;\n            max-width: 600px;\n            text-align: center;\n        }\n    }\n    @keyframes fadein {\n        from { opacity: 0; }\n        to   { opacity: 1; }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
