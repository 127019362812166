import { useCallback, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
import { toast } from 'react-toastify';
//Utils
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
import ToastMessage from 'components/ToastMessage';
// Assets
import './forgotPassword.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import ForgotPasswordIllus from 'assets/images/forgot-pass-page-illus.svg'
import Icons from 'components/Icons';
import Logo from 'components/Logo';
import CustomButton from 'components/CustomButton';
import CustomTextInput from 'components/CustomTextInput';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        email: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async (event) => {
        event.preventDefault();

        const { email } = form
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }

        if (isValid) {
            setLoading(true)
            try {
                await dispatch(actions.auth.resetPassword({ email: email.value })).then(() => {
                    toast.success('Reset password request sent! Please check your email.', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false)
                    navigate('/login')
                })
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
                setLoading(false)
            }
        }

    }, [dispatch, form, navigate])

    return (
        <PageWrapper>
            <CustomContainer>
                <div className='forgot-password-container'>
                    <form className='forgot-password-form' onSubmit={(event) => onSubmit(event)}>
                        <Logo style={{ height: 40 }} action={() => navigate('/')} />
                        <Seprator height={80} />
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24, alignItems: 'center' }}>
                            <h1 className='header'>Forgot your password?</h1>
                            <h2 className='subheader'>Please provide us your email and we will help you retrieve your account.</h2>
                            <img
                                src={ForgotPasswordIllus}
                                alt=""
                                className='illus'
                            />
                            <CustomTextInput
                                inputProps={{
                                    type: "email",
                                    placeholder: 'Email Address',
                                    id: 'email',
                                    onChange: (event) => onChangeInput({ event, type: "email" }),
                                }}
                                size={'large'}
                                style={{ width: 300 }}
                                error={form.email.error}
                            />
                            <CustomButton text={'Submit'} style={{ width: 300 }} size={'large'} />
                            <p className='sign-up'>Go back to <span onClick={() => navigate('/login')}>login</span></p>
                        </div>
                    </form>
                </div>
            </CustomContainer>
        </PageWrapper>

    )
}


export default ForgotPassword;