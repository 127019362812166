import * as React from "react"

const Logout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.762}
    height={15}
    viewBox="0 0 14.762 15"
    {...props}
  >
    <defs>
      <style>{".logout{fill:#2a2a2a;}"}</style>
    </defs>
    <path
      className="logout"
      d="M12.229,1.61a7.38,7.38,0,1,1-8.577,0,.716.716,0,0,1,1.042.229l.47.836a.714.714,0,0,1-.2.923,5,5,0,1,0,5.949,0,.71.71,0,0,1-.193-.92l.47-.836a.713.713,0,0,1,1.036-.232Zm-3.1,6.247V.714A.713.713,0,0,0,8.42,0H7.467a.713.713,0,0,0-.714.714V7.857a.713.713,0,0,0,.714.714H8.42A.713.713,0,0,0,9.134,7.857Z"
      transform="translate(-0.562)"
    />
  </svg>
)


export default Logout
