import * as React from "react"

const House = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={26.667} viewBox="0 0 30 26.667" {...props}>
    <path
      data-name="Icon awesome-house-damage"
      d="M15 5.987 3.618 16.027a2.41 2.41 0 0 1-.285.174v9.632a.833.833 0 0 0 .833.833h7.772L10 22.874l5.422-3.333-3.133-6.209L20 20.456l-5.422 3.333 2.079 2.878h9.177a.833.833 0 0 0 .833-.833V16.2a2.43 2.43 0 0 1-.274-.167Zm14.723 6.319-3.057-2.7V2.5a.833.833 0 0 0-.833-.833H22.5a.833.833 0 0 0-.833.833v2.692L16.393.537a2.076 2.076 0 0 0-2.784 0L.277 12.306a.834.834 0 0 0-.063 1.177l1.115 1.241a.834.834 0 0 0 1.177.063L14.449 4.252a.834.834 0 0 1 1.1 0L27.5 14.786a.834.834 0 0 0 1.177-.063l1.115-1.241a.833.833 0 0 0-.069-1.176Z"
      fill={props.color || "#fac91f"}
    />
  </svg>
)


export default House
