// Packages
import Icons from 'components/Icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Seprator from 'components/Separator'
import './getAccessModal.scss'
import { useCallback, useState } from 'react';
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
import actions from 'store/actions';
import ToastMessage from 'components/ToastMessage';
import CustomTextInput from 'components/CustomTextInput';
import CustomButton from 'components/CustomButton';


const GetAccessModal = ({ modal, setModal }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        full_name: {
            value: '',
            error: '',
        },
        company: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async (event) => {
        event.preventDefault();

        const { email, full_name, company } = form
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }

        if (!full_name.value) {
            inputChange({ setForm: setForm, key: 'full_name', value: 'error', data: 'Name cannot be blank' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'full_name', value: 'error', data: '' })
        }

        if (!company.value) {
            inputChange({ setForm: setForm, key: 'company', value: 'error', data: 'Company cannot be blank' })
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'company', value: 'error', data: '' })
        }


        if (isValid) {
            setLoading(true)
            try {
                await dispatch(actions.auth.investorRequest({ full_name: full_name.value, email: email.value, company: company.value })).then(() => {
                    toast.success('Request has been sent, please wait for the access code from our team.', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false)
                    setModal(prev => !prev);
                })
            } catch (error) {
                console.log(error)
                // toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                //     position: toast.POSITION.TOP_CENTER
                // });
                setLoading(false)
            }
        }

    }, [form])

    return (
        <div className={`get-access-modal ${modal ? 'open' : 'close'}`}>
            <div className='modal-container'>
                <div className='modal-title'>
                    Get Exclusive Access
                    <div className='close' onClick={() => setModal(prev => !prev)}>
                        <Icons.Close height={13} width={13} />
                    </div>
                </div>
                <Seprator height={40} />
                <div className='content'>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, alignItems: 'center' }}>
                        <CustomTextInput
                            inputProps={{
                                type: "text",
                                placeholder: 'Enter Full Name',
                                id: 'full_name',
                                value: form.full_name.value,
                                onChange: (event) => onChangeInput({ event, type: "full_name" }),
                            }}
                            label={'Full Name'}
                            size={'large'}
                            style={{ width: 300 }}
                            error={form.full_name.error}
                        />
                        <CustomTextInput
                            inputProps={{
                                type: "text",
                                placeholder: 'Enter Email',
                                id: 'email',
                                value: form.email.value,
                                onChange: (event) => onChangeInput({ event, type: "email" }),
                            }}
                            label={'Email'}
                            size={'large'}
                            style={{ width: 300 }}
                            error={form.email.error}
                        />
                        <CustomTextInput
                            inputProps={{
                                type: "text",
                                placeholder: 'Enter Company',
                                id: 'company',
                                value: form.company.value,
                                onChange: (event) => onChangeInput({ event, type: "company" }),
                            }}
                            label={'Company'}
                            size={'large'}
                            style={{ width: 300 }}
                            error={form.company.error}
                        />
                        <CustomButton text={'Get Access'} size={'large'} action={onSubmit} style={{ width: 300 }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetAccessModal;