import API from 'API'
import authSlice from '../reducers/auth'

const { loginSuccess, retrieveUserSuccess, logoutSuccess, fetchBuyerAgentsSuccess, fetchFeaturedAgentsSuccess } = authSlice.actions

const login = ({ email, password }) => async dispatch => {
  const response = await API.auth.login({email, password});
  const payload = response.data;
  localStorage.setItem('token', payload.key);
  dispatch(loginSuccess(payload));
}

const signup = (data) => async dispatch => {
  await API.auth.signup(data);
}

const retrieveUser = () => async dispatch => {
  try {
    const response = await API.auth.retrieveUser();
    const payload = response.data;
    dispatch(retrieveUserSuccess(payload));
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      dispatch(logoutSuccess());
      window.location.href = '/';
    }
  }
}

const updateUser = ({ id, data }) => async dispatch => {
  const response = await API.auth.updateUser({ id, data });
  const payload = response.data;
  return payload
}

const updateProfilePhoto = (data) => async dispatch => {
  const response = await API.auth.updateProfilePhoto(data);
  const payload = response.data;
  return payload
}

const logout = () => async dispatch => {
  localStorage.clear();
  await API.auth.logout();
  dispatch(logoutSuccess());
}

const fetchBuyerAgents = () => async dispatch => {
  const response = await API.auth.fetchBuyerAgents();
  const payload = response.data;
  dispatch(fetchBuyerAgentsSuccess(payload));
}

const fetchFeaturedAgents = () => async dispatch => {
  const response = await API.auth.fetchFeaturedAgents();
  const payload = response.data;
  dispatch(fetchFeaturedAgentsSuccess(payload));
}

const validatePhone = (data) => async dispatch => {
  await API.auth.validatePhone(data);
}

const validateEmail = (data) => async dispatch => {
  await API.auth.validateEmail(data);
}

const setPassword = (data) => async dispatch => {
  await API.auth.setPassword(data);
}

const setAccount = (data) => async dispatch => {
  await API.auth.setAccount(data);
}

const fetchMainCalendarEvents = ({ start, end }) => async dispatch => {
  const response = await API.auth.fetchMainCalendarEvents({ start, end });
  return response.data;
}

const fetchLanguages = () => async dispatch => {
  const response = await API.auth.fetchLanguages();
  return response.data;
}

const fetchRealEstateSpecialization = () => async dispatch => {
  const response = await API.auth.fetchRealEstateSpecialization();
  return response.data;
}

const fetchCertificate = () => async dispatch => {
  const response = await API.auth.fetchCertificate();
  return response.data;
}

const resetPassword = (data) => async dispatch => {
  const response = await API.auth.resetPassword(data);
  return response.data
}

const confirmResetPassword = (data) => async dispatch => {
  const response = await API.auth.confirmResetPassword(data);
  return response.data
}

const deleteAccount = () => async dispatch => {
  localStorage.clear();
  await API.auth.deleteAccount();
  dispatch(logoutSuccess());
}

const deactivateAccount = () => async dispatch => {
  localStorage.clear();
  await API.auth.deactivateAccount();
  dispatch(logoutSuccess());
}

const activateAccount = () => async dispatch => {
  const response = await API.auth.activateAccount();
  return response.data
}

const signupAgentInvite = (data) => async dispatch => {
  const response = await API.auth.signupAgentInvite(data);
  return response.data
}

const investorRequest = (data) => async dispatch => {
  const response = await API.auth.investorRequest(data);
  return response.data
}

const demoRequest = (data) => async dispatch => {
  const response = await API.auth.demoRequest(data);
  return response.data
}

const accountDeletionRequest = (data) => async dispatch => {
  const response = await API.auth.accountDeletionRequest(data);
  return response.data
}


const authActions = {
    login,
    retrieveUser,
    updateUser,
    updateProfilePhoto,
    logout,
    signup,
    fetchBuyerAgents,
    fetchFeaturedAgents,
    validatePhone,
    validateEmail,
    setPassword,
    setAccount,
    fetchMainCalendarEvents,
    fetchLanguages,
    fetchRealEstateSpecialization,
    fetchCertificate,
    resetPassword,
    confirmResetPassword,
    deleteAccount,
    deactivateAccount,
    activateAccount,
    signupAgentInvite,
    investorRequest,
    demoRequest,
    accountDeletionRequest,
}


export default authActions;