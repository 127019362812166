import Icons from 'components/Icons';
import Seprator from 'components/Separator';
import { useNavigate } from 'react-router-dom';
import './formNav.scss'

const FormNav = ({ setModal }) => {
    const navigate = useNavigate();

    return (
        <div className="form-nav-container">
            <div className='navigation'>
                <p className='header'>Update Listing</p>
                <Seprator height={50} />
                <ul className='items'>
                    <li className='active'>
                        <div>
                            <Icons.Feedback height={15} width={15} color={'#2A2A2A'} />
                            <p>Listing Showing Feedback</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='buttons'>
                <button className='button small cancel no-glow' onClick={() => navigate('/manage-listings')}>Back</button>
            </div>
        </div>
    )
}


export default FormNav;