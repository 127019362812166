import * as React from "react"

const Market = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.669}
    height={23}
    viewBox="0 0 27.669 23"
    {...props}
  >
    <path
      d="M27.044,5.328,24.129.674A1.438,1.438,0,0,0,22.912,0H4.763A1.438,1.438,0,0,0,3.546.674L.63,5.328a4.008,4.008,0,0,0,2.641,6.127,4.669,4.669,0,0,0,.615.04A4.436,4.436,0,0,0,7.2,10.009a4.439,4.439,0,0,0,6.63,0,4.439,4.439,0,0,0,6.63,0A4.45,4.45,0,0,0,23.779,11.5a4.618,4.618,0,0,0,.615-.04A4,4,0,0,0,27.044,5.328Zm-3.257,7.61a5.7,5.7,0,0,1-1.325-.171V17.25H5.212V12.767a5.951,5.951,0,0,1-1.325.171,6.061,6.061,0,0,1-.809-.054,5.649,5.649,0,0,1-.737-.162v8.841A1.436,1.436,0,0,0,3.779,23H23.9a1.436,1.436,0,0,0,1.438-1.437V12.722a4.587,4.587,0,0,1-.737.162A6.247,6.247,0,0,1,23.788,12.938Z"
      transform="translate(-0.004)"
      fill={props.color || '#808080'}
    />
  </svg>
)


export default Market
