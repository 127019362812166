import auth from './modules/auth'
import feed from './modules/feed'
import listing from './modules/listing'
import showing from './modules/showing'
import agent from './modules/agent'
import buyer from './modules/buyer'
import broker from './modules/broker'
import team from './modules/team'
import message from './modules/message'

const API = {
    auth,
    feed,
    listing,
    showing,
    agent,
    buyer,
    broker,
    team,
    message,
}

export default API;