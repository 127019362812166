import Separator from 'components/Separator'
import './dashboard.scss'
import { useEffect, useState } from 'react'
import { CountUp } from 'countup.js'
import actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import house1 from 'assets/images/house1.jpg'
import { checkImageUrl, formatCurrency } from 'utils/modules/Handlers'
import Icons from 'components/Icons'
import { useNavigate } from 'react-router-dom'
import DashboardFiltersContainer from 'components/DashboardFiltersContainer'
import ListingStatus from 'components/ListingStatus'
// Packages
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import ShowingTime from 'components/ShowingTime'
import NoUser from 'assets/images/no_user.jpg'
import CustomSelect from 'components/CustomSelect'
import ShowingUsersBlock from 'components/ShowingUsersBlock'
import ShowingStatus from 'components/ShowingStatus'
import UserPlaceholder from 'components/UserPlaceholder'
import CustomTextInput from 'components/CustomTextInput'
import CustomButton from 'components/CustomButton'

const AgentDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth)
    const [showingToday, setShowingToday] = useState([]);
    const [enabledListings, setEnabledListings] = useState([]);
    const [activeShowings, setActiveShowings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [userFilter, setUserFilter] = useState('');
    const [listings, setListings] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await dispatch(actions.agent.listingDashboardData()).then((data) => {
                setListings(data.listings);

                const totalEl = document.getElementById('total')
                const enabledEl = document.getElementById('enabled')
                const activeEl = document.getElementById('active')
                const pendingEl = document.getElementById('pending')
                const leasedEl = document.getElementById('leased')
                const soldEl = document.getElementById('sold')

                const total = new CountUp(totalEl, data.total);
                const enabled = new CountUp(enabledEl, data.enabled_showing);
                const active = new CountUp(activeEl, data.active);
                const pending = new CountUp(pendingEl, data.pending);
                const leased = new CountUp(leasedEl, data.leased);
                const sold = new CountUp(soldEl, data.sold);

                total.start();
                enabled.start();
                active.start();
                pending.start();
                leased.start();
                sold.start();
            })
            await dispatch(actions.agent.showingDashboardData()).then((data) => {
                const totalEl = document.getElementById('showings-total')
                const activeEl = document.getElementById('showings-active')
                const pendingEl = document.getElementById('showings-pending')
                const approvedEl = document.getElementById('showings-approved')

                const total = new CountUp(totalEl, data.total);
                const active = new CountUp(activeEl, data.active);
                const pending = new CountUp(pendingEl, data.pending);
                const approved = new CountUp(approvedEl, data.approved);

                total.start();
                active.start();
                pending.start();
                approved.start();
            })

            await dispatch(actions.agent.showingsToday()).then((data) => {
                setShowingToday(data);
            });

            await dispatch(actions.agent.enabledListings()).then((data) => {
                setEnabledListings(data.results);
            });

            await dispatch(actions.agent.approvedShowings({ filters: `agent_type=all` })).then((data) => {
                setActiveShowings(data.results);
            });
            await setLoading(false);
        }

        init();
    }, [])

    useEffect(() => {
        handleShowingFilter();
    }, [statusFilter, userFilter])

    const handleShowingFilter = async () => {
        let filters = [];
        if (statusFilter) {
            filters.push(`status=${statusFilter}`)
        }
        if (userFilter) {
            filters.push(`agent_type=${userFilter}`)
        }
        await dispatch(actions.agent.approvedShowings({ filters: filters.join('&') })).then((data) => {
            setActiveShowings(data.results);
        });
    }

    const handleGoToForm = (item, event) => {
        event.preventDefault();
        navigate(`/manage-listings/${item.id}/showing-form`)
    }
    const handleGoToCalendar = (item, event) => {
        event.preventDefault();
        navigate(`/manage-listings/${item.id}/calendar`)
    }


    const formatDate = (data) => {
        const startDate = moment(new Date(data)).format('MM/DD/YY');
        return startDate
    }

    const handleSearchChange = (value) => {
        setSearchTerm(value);

        if (!value.trim()) {
            setSuggestions([]);
            setSelectedSuggestion(null);
            return;
        }

        const filtered = listings.filter(listing => {
            const searchLower = value.toLowerCase();
            const mlsMatch = listing.mls_number?.toLowerCase().includes(searchLower);
            const idMatch = listing.id.toString().includes(searchLower);
            return mlsMatch || idMatch;
        });

        setSuggestions(filtered);
    };

    return (
        <div className='agent-dashboard-container'>
            <DashboardFiltersContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', columnGap: 8 }}>
                    <div className="table-filters" style={{ position: 'relative' }}>
                        <div>
                            <CustomTextInput
                                style={{ width: 300, height: 42 }}
                                inputProps={{
                                    placeholder: 'Quick Access by Listing\'s MLS or SB ID',
                                    value: searchTerm,
                                    onChange: (e) => handleSearchChange(e.target.value)
                                }}
                            />
                            {suggestions.length > 0 && searchTerm && (
                                <div className='suggestion'>
                                    {suggestions.map((listing) => (
                                        <div
                                            key={listing.id}
                                            onClick={() => {
                                                const selectedValue = `${listing.id}${listing.mls_number ? ' | ' + listing.mls_number : ''}`;
                                                setSearchTerm(selectedValue);
                                                setSelectedSuggestion(listing.id);
                                                setSuggestions([]);
                                            }}
                                            className='suggestion-item'
                                        >
                                            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '10%' }}>
                                                <p style={{ flex: '40%', fontSize: 12, fontWeight: 700, color: '#2a2a2a', textAlign: 'center' }}>{listing.id}</p>
                                                {listing.mls_number && <p style={{ flex: '40%', fontSize: 12, fontWeight: 700, color: '#f1c40f', textAlign: 'center' }}>{listing.mls_number}</p>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', columnGap: 10 }}>
                            <CustomButton
                                disabled={!selectedSuggestion}
                                style={{ height: 40, width: 60 }}
                                iconOnly={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_1331_16590)">
                                            <path
                                                d="M13.2284 5.7288C13.487 5.46408 13.8265 5.29708 14.1908 5.25553H14.193C14.5274 5.21636 14.8654 5.28422 15.1603 5.44973C15.4552 5.61518 15.6922 5.87008 15.8385 6.179C15.9847 6.48786 16.033 6.8355 15.9766 7.17357C15.9201 7.5117 15.7616 7.82347 15.5233 8.06576L11.0663 12.6062C10.6957 12.9819 10.2324 13.2489 9.72537 13.3792L8.4677 13.7C8.39994 13.7173 8.33016 13.7231 8.26113 13.7172C8.2218 13.7138 8.18285 13.7068 8.14453 13.696C8.03896 13.6663 7.94265 13.6091 7.86525 13.5302C7.78773 13.4513 7.73165 13.3533 7.70246 13.2457C7.67328 13.1381 7.67188 13.0247 7.69853 12.9165L8.01447 11.6352C8.14237 11.1187 8.40451 10.6469 8.77336 10.2693L13.2284 5.7288Z"
                                                fill={!selectedSuggestion ? "#949494" : 'white'}
                                            />
                                            <path
                                                d="M4.49639 1.76999C5.48639 1.18776 6.70639 1.18776 7.69639 1.76999L10.5933 3.47359C11.3853 3.93943 11.9342 4.72141 12.122 5.61188L8.91342 8.79181V7.59536C8.91342 7.55127 8.90811 7.5075 8.89764 7.46485C8.88889 7.4296 8.8767 7.39514 8.86107 7.36188C8.8267 7.28842 8.77639 7.22261 8.71342 7.16882L6.50092 5.27833C6.39014 5.18367 6.24654 5.13135 6.09764 5.13135C6.02498 5.13135 5.95357 5.1438 5.88654 5.1676C5.81623 5.19251 5.75092 5.22987 5.69436 5.27833L3.4817 7.16882C3.41873 7.22261 3.36826 7.28834 3.33389 7.36188C3.29936 7.43533 3.28139 7.5149 3.28139 7.59536V10.2942C3.28139 10.4465 3.34498 10.5924 3.45811 10.7C3.57123 10.8076 3.72482 10.8681 3.88482 10.8681H4.68936C4.84936 10.8681 5.00295 10.8076 5.11607 10.7C5.2292 10.5924 5.29279 10.4465 5.29279 10.2942V8.76403C5.29279 8.71334 5.31404 8.66472 5.3517 8.62883C5.38951 8.59294 5.44061 8.57281 5.49389 8.57281H6.70076C6.7542 8.57281 6.80529 8.59294 6.84311 8.62883C6.88076 8.66472 6.90201 8.71334 6.90201 8.76403V10.2942C6.90201 10.4465 6.96561 10.5924 7.07873 10.7C7.16576 10.7828 7.27686 10.8378 7.39639 10.8586C7.32701 11.0345 7.26779 11.2151 7.21951 11.3994L6.68951 13.4283C6.64482 13.5997 6.64451 13.78 6.68857 13.9516C6.7092 14.0322 6.73936 14.1094 6.77811 14.182C6.82186 14.2641 6.8767 14.34 6.94107 14.4075C6.97842 14.4466 7.01873 14.4826 7.06139 14.515C6.21498 14.7877 5.28311 14.6927 4.49639 14.2299L1.59951 12.5263C0.609512 11.9441 -0.000488281 10.8681 -0.000488281 9.70353V6.29637C-0.000488281 5.13187 0.609512 4.05582 1.59951 3.47359L4.49639 1.76999Z"
                                                fill={!selectedSuggestion ? "#949494" : 'white'}
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1331_16590">
                                                <rect width={16} height={16} fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                }
                                action={() => window.open(`/manage-listings/${selectedSuggestion}/update`, '_blank')}
                            />
                            <CustomButton
                                disabled={!selectedSuggestion}
                                style={{ height: 40, width: 60 }}
                                iconOnly={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.45825 1.33334C4.62401 1.33334 4.78298 1.39919 4.90019 1.5164C5.0174 1.63361 5.08325 1.79258 5.08325 1.95834V3.00001H10.9166V1.95834C10.9166 1.79258 10.9824 1.63361 11.0996 1.5164C11.2169 1.39919 11.3758 1.33334 11.5416 1.33334C11.7073 1.33334 11.8663 1.39919 11.9835 1.5164C12.1007 1.63361 12.1666 1.79258 12.1666 1.95834V3.00001H12.3749C12.9827 3.00001 13.5656 3.24145 13.9954 3.67122C14.4251 4.10099 14.6666 4.68389 14.6666 5.29168V12.375C14.6666 12.9828 14.4251 13.5657 13.9954 13.9955C13.5656 14.4252 12.9827 14.6667 12.3749 14.6667H3.62492C3.01713 14.6667 2.43424 14.4252 2.00447 13.9955C1.57469 13.5657 1.33325 12.9828 1.33325 12.375V5.29168C1.33325 4.68389 1.57469 4.10099 2.00447 3.67122C2.43424 3.24145 3.01713 3.00001 3.62492 3.00001H3.83325V1.95834C3.83325 1.79258 3.8991 1.63361 4.01631 1.5164C4.13352 1.39919 4.29249 1.33334 4.45825 1.33334ZM3.62492 5.91668C3.04992 5.91668 2.58325 6.38334 2.58325 6.95834V12.375C2.58325 12.95 3.04992 13.4167 3.62492 13.4167H12.3749C12.9499 13.4167 13.4166 12.95 13.4166 12.375V6.95834C13.4166 6.38334 12.9499 5.91668 12.3749 5.91668H3.62492Z"
                                            fill={!selectedSuggestion ? "#949494" : 'white'}
                                        />
                                    </svg>
                                }
                                action={() => window.open(`/manage-listings/${selectedSuggestion}/calendar`, '_blank')}
                            />
                        </div>
                    </div>
                </div>
            </DashboardFiltersContainer>
            <div className='contents'>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className='block border-rb no-max-mobile' style={{ maxWidth: 381 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>My Showings</p>
                            <p className='view-all' onClick={() => navigate('/manage-showings')}>View All</p>
                        </div>
                        <p className='subheader'> <span id='showings-total'>0</span> Total showings</p>
                        <Separator height={16} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Ongoing<br /> Showings</p>
                                <Separator height={16} />
                                <span id='showings-active'>0</span>
                            </div>
                            <div>
                                <p className='title'>Approved<br /> Showings</p>
                                <Separator height={16} />
                                <span id='showings-approved'>0</span>
                            </div>
                            <div>
                                <p className='title'>Pending<br /> Showings</p>
                                <Separator height={16} />
                                <span id='showings-pending'>0</span>
                            </div>
                        </div>
                    </div>
                    <div className='block border-b ' style={{ maxWidth: 760 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>My Listings</p>
                            <p className='view-all' onClick={() => navigate('/manage-listings')}>View All</p>
                        </div>
                        <Separator height={27} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Active<br /> Listings</p>
                                <Separator height={16} />
                                <span id='active'>0</span>
                            </div>
                            <div>
                                <p className='title'>Pending<br /> Listings</p>
                                <Separator height={16} />
                                <span id='pending'>0</span>
                            </div>
                            <div>
                                <p className='title'>Leased<br /> Listings</p>
                                <Separator height={16} />
                                <span id='leased'>0</span>
                            </div>
                            <div>
                                <p className='title'>Sold<br /> Listings</p>
                                <Separator height={16} />
                                <span id='sold'>0</span>
                            </div>
                            <div>
                                <p className='title'>Total<br /> Listings</p>
                                <Separator height={16} />
                                <span id='total'>0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className='showings-today-container block border-r' style={{ maxWidth: 381 }}>
                        <Splide
                            hasTrack={false}
                            options={{
                                type: 'slide',
                                rewind: true,
                                pagination: true,
                                arrows: true,
                                focus: 'left',
                                gap: 20,
                                perPage: 1,
                            }}
                        >
                            <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p className='header'>Showing Today</p>
                                <div className="splide__arrows" style={{ display: 'flex' }}>
                                    <div className="splide__arrow splide__arrow--prev">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                                fill="#C9C9C9"
                                            />
                                        </svg>
                                    </div>
                                    <div className="splide__arrow splide__arrow--next">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M5.9594 3.95955C6.34992 3.56903 6.98309 3.56903 7.37361 3.95955L10.7069 7.29288C11.0975 7.68341 11.0975 8.31657 10.7069 8.7071L7.37361 12.0404C6.98309 12.431 6.34992 12.431 5.9594 12.0404C5.56887 11.6499 5.56887 11.0167 5.9594 10.6262L8.58562 7.99999L5.9594 5.37376C5.56887 4.98324 5.56887 4.35007 5.9594 3.95955Z"
                                                fill="#C9C9C9"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <Separator height={24} />
                            <SplideTrack>
                                {
                                    showingToday.map((item, index) => {
                                        return (
                                            <SplideSlide key={index}>
                                                <div className='showings-today'>
                                                    <img src={checkImageUrl(item.listing_first_image_url, house1)} alt="" />
                                                    <Separator height={16} />
                                                    <ListingStatus color={item.listing_data.listing_status_color} text={item.listing_data.listing_status} />
                                                    <Separator height={16} />
                                                    <p style={{ fontSize: 10, color: '#949494' }}>Listing #{item.listing_data.mls_number || item.listing_data.id}</p>
                                                    <Separator height={8} />
                                                    <p style={{ fontSize: 16, fontWeight: 700, color: '#2a2a2a' }}>{item.listing_data.property_type} in {item.listing_data.city}</p>
                                                    <Separator height={8} />
                                                    <p style={{ fontSize: 10, fontWeight: 500, color: '#949494' }}>
                                                        {`${item.listing_data.address}, ${item.listing_data.city} ${item.listing_data.state} ${item.listing_data.zip_code}`}
                                                    </p>
                                                    <Separator height={16} />
                                                    <p style={{ fontSize: 16, fontWeight: 500, color: '#2a2a2a' }}>{formatCurrency(item.listing_data.price)}</p>
                                                    <Separator height={16} />
                                                    <ShowingTime style={{ width: '100%' }} date={item.date} start={item.start_time} end={item.finish_time} status={item.status} />
                                                    {
                                                        item.listing_data?.agent &&
                                                        <div className="user la">
                                                            <img className="profile" src={checkImageUrl(item.listing_data?.agent_data.user_dataprofile_photo, NoUser)} alt="" />
                                                            <Separator width={10} />
                                                            <div className="detail">
                                                                <span>
                                                                    {`${item.listing_data?.agent_data.user_data.first_name} ${item.listing_data?.agent_data.user_data.last_name} `}
                                                                    <Icons.ListingAgentBadge width={8} height={8} />
                                                                </span>
                                                                <span>{item.listing_data?.agent_data.user_data.email}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item.buyer_agent_data?.id &&
                                                        <div className="user ba">
                                                            <img className="profile" src={checkImageUrl(item.buyer_agent_data?.profile_photo, NoUser)} alt="" />
                                                            <Separator width={10} />
                                                            <div className="detail">
                                                                <span>
                                                                    {item.buyer_agent_data?.first_name} {item.buyer_agent_data?.last_name}
                                                                    <Icons.BuyerAgentBadge width={8} height={8} />
                                                                </span>
                                                                <span>{item.buyer_agent_data?.email}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item.buyers.length > 0 &&
                                                        <div className={`user buyer ${item.buyers[0].buyer_tier}`}>
                                                            <img className="profile" src={checkImageUrl(item.buyers[0].profile_photo, NoUser)} alt="" />
                                                            <Separator width={10} />
                                                            <div className="detail">
                                                                <span>
                                                                    {item.buyers[0].first_name} {item.buyers[0].last_name}
                                                                    {
                                                                        item.buyers[0].buyer_tier === 'F'
                                                                            ? <Icons.ForagerBeeTier width={8} height={8} />
                                                                            : item.buyers[0].buyer_tier === 'H'
                                                                                ? <Icons.HouseBeeTier width={8} height={8} />
                                                                                : <Icons.QueenBeeTier width={8} height={8} />
                                                                    }
                                                                </span>
                                                                <span>{item.buyers[0].email}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </SplideSlide>
                                        )
                                    })
                                }
                            </SplideTrack>
                        </Splide>
                        {
                            showingToday.length === 0 &&
                            <p style={{ fontSize: 14, fontWeight: 700, color: '#949494', paddingTop: 6 }}>You have no showings for today.</p>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div className='block border-b' style={{ maxWidth: 760, flex: 0 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <p className='header' style={{ marginBottom: 6 }}>Active Showings</p>
                                    <p className='subheader'> <span id='showings-total'>{activeShowings.length}</span> Showings active</p>
                                </div>
                                <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
                                    <CustomSelect
                                        inputProps={{
                                            placeholder: 'Connection',
                                            id: 'status',
                                        }}
                                        style={{ width: 100 }}
                                        onChange={(value) => { setUserFilter(value) }}
                                        options={[
                                            { text: 'All', value: 'all' },
                                            { text: 'Listing Agent', value: 'listing' },
                                            { text: 'Buyer\'s Agent', value: 'buyer' },
                                            { text: 'Team Member', value: 'member' },
                                        ]}
                                    />
                                    <CustomSelect
                                        inputProps={{
                                            placeholder: 'Status',
                                            id: 'status',
                                        }}
                                        style={{ width: 80 }}
                                        onChange={(value) => { setStatusFilter(value) }}
                                        options={[
                                            { text: 'All', value: '' },
                                            { text: 'Pending', value: 'pending' },
                                            { text: 'Approved', value: 'upcoming' },
                                            { text: 'In Progress', value: 'ongoing' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <Separator height={16} />
                            {
                                !loading
                                    ? <ul className='list' style={{ display: 'flex', flexDirection: 'column', rowGap: '16', height: '100%', minHeight: 150 }}>
                                        {
                                            activeShowings.map((item, index) => {
                                                return (
                                                    <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', height: 64, alignItems: 'center', width: 240 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, justifyContent: 'center' }}>
                                                                {
                                                                    item.buyers_data[0]?.buyer_tier === 'Q' &&
                                                                    <Icons.QueenBeeTier height={16} width={16} />
                                                                }
                                                                {
                                                                    item.listing_data?.agent_members.includes(user?.id) && <Icons.TeamMemberIndicator height={16} width={16} />
                                                                }
                                                                {
                                                                    item.buyer_agent === user?.id && <Icons.BuyerAgentIndicator height={16} width={16} />
                                                                }
                                                                {
                                                                    item.listing_agent === user?.agent_profile?.id && <Icons.ListingAgentIndicator height={16} width={16} />
                                                                }
                                                            </div>
                                                            <Separator width={19} />
                                                            <div className={`divider ${item.listing_data?.agent_members.includes(user?.id) && 'team-member'} ${item.buyer_agent === user?.id && 'buyer-agent'} ${item.listing_agent === user?.agent_profile?.id && 'listing-agent'}`} style={{ height: 47, width: 5, borderRadius: 8 }}></div>
                                                            <Separator width={16} />
                                                            <div style={{ width: 180 }}>
                                                                <p style={{ fontSize: 12, fontWeight: 700, color: '#2a2a2a', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.listing_data.property_type} in {item.listing_data.city}</p>
                                                                <Separator height={8} />
                                                                <p style={{ fontSize: 10, fontWeight: 500, color: '#949494', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {`${item.listing_data.address}, ${item.listing_data.city} ${item.listing_data.state} ${item.listing_data.zip_code}`}
                                                                </p>
                                                                <Separator height={8} />
                                                                <p style={{ fontSize: 10, color: '#5F5F5F' }}>{item.listing_data.mls_number || item.listing_data.id}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: 100 }}>
                                                            <ShowingUsersBlock data={item} />
                                                        </div>
                                                        <p style={{ color: '#5F5F5F', fontSize: 10 }}>{formatDate(item.created_at)}</p>
                                                        <div style={{ display: 'flex', columnGap: 8, alignItems: 'center', fontSize: 10 }}>
                                                            <p style={{ fontWeight: 700 }}>{item.date}</p>
                                                            <p>{item.start_time} - {item.finish_time}</p>
                                                            <p>({item.listing_data.timezone_display.split(" ")[0]})</p>
                                                        </div>
                                                        <ShowingStatus showing={item} />
                                                    </li>
                                                )
                                            })
                                        }
                                        {
                                            activeShowings.length === 0 &&
                                            <li style={{ fontSize: 14, fontWeight: 700, color: '#949494', paddingTop: 20 }}>No active showings available</li>
                                        }
                                    </ul>
                                    : <Icons.Loading width={50} height={50} background={'transparent'} />
                            }
                        </div>
                        <div className='block' style={{ maxWidth: 760, flex: 0 }}>
                            <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p className='header'>Enabled Listings</p>
                            </div>
                            <p className='subheader'> <span id='showings-total'>{enabledListings.length}</span> Enabled Listings</p>
                            <Separator height={16} />
                            {
                                !loading

                                    ? <ul className='list' style={{ display: 'flex', flexDirection: 'column', rowGap: '16', height: '100%', minHeight: 150 }}>
                                        {
                                            enabledListings.map((item, index) => {
                                                return (
                                                    <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', height: 64, alignItems: 'center', width: 240 }}>
                                                            <img style={{ height: 40, width: 40, objectFit: 'cover', borderRadius: 8 }} src={checkImageUrl(item.primary_photo_url, house1)} alt="" />
                                                            <Separator width={16} />
                                                            <div style={{ width: 180 }}>
                                                                <p style={{ fontSize: 14, fontWeight: 700, color: '#2a2a2a', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.property_type_display} in {item.city}</p>
                                                                <Separator height={8} />
                                                                <p style={{ fontSize: 10, fontWeight: 700, color: '#5F5F5F', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {`${item.address}, ${item.city} ${item.state} ${item.zip_code}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: 218, justifyContent: 'center', alignContent: 'center' }}>
                                                            <p style={{ fontSize: 12, color: '#5F5F5F', width: 118 }}>{item.id}</p>
                                                            <p style={{ fontSize: 12, color: '#5F5F5F', width: 100 }}>{item.mls_number}</p>
                                                        </div>
                                                        <div style={{ width: 72 }}>
                                                            {
                                                                item?.sellers.length > 0
                                                                    ? <UserPlaceholder user={item?.sellers[0]} type={'seller'} customStyle={{ border: '1px solid white', height: 24, width: 24 }} />
                                                                    : <p style={{ fontSize: 10, color: '#5F5F5F' }}>No Seller</p>
                                                            }
                                                        </div>
                                                        <ListingStatus color={item.listing_status_color} text={item.listing_status} />
                                                        <ul style={{ display: 'flex', columnGap: 10 }}>
                                                            <li className='link' onClick={(event) => handleGoToCalendar(item, event)}><Icons.ListingCalendarIcon /></li>
                                                            <li className='link' onClick={(event) => handleGoToForm(item, event)}><Icons.ListingFormIcon /></li>
                                                        </ul>
                                                    </li>
                                                )
                                            })
                                        }
                                        {
                                            enabledListings.length === 0 &&
                                            <li style={{ fontSize: 14, fontWeight: 700, color: '#949494', paddingTop: 20 }}>No enabled listings available</li>
                                        }
                                    </ul>
                                    : <Icons.Loading width={50} height={50} background={'transparent'} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AgentDashboard;