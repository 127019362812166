import { combineReducers } from "redux";
import authSlice from "./auth";
import feedSlice from "./feed";
import listingSlice from "./listing";
import showingSlice from "./showing";
import agentSlice from "./agent";
import buyerSlice from "./buyer";
import brokerSlice from "./broker";
import teamSlice from "./team";


export default combineReducers({ 
    auth: authSlice.reducer,
    feed: feedSlice.reducer,
    listing: listingSlice.reducer,
    showing: showingSlice.reducer,
    agent: agentSlice.reducer,
    buyer: buyerSlice.reducer,
    broker: brokerSlice.reducer,
    team: teamSlice.reducer,
});