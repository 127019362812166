import * as React from "react";

const Message = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M14.375 2.5H5.62496C4.57514 2.5 3.56833 2.90577 2.82599 3.62803C2.08366 4.3503 1.66663 5.32991 1.66663 6.35135V13.6486C1.66663 14.1544 1.76901 14.6552 1.96794 15.1225C2.16686 15.5898 2.45843 16.0143 2.82599 16.372C3.56833 17.0942 4.57514 17.5 5.62496 17.5H14.375C15.4241 17.4979 16.4296 17.0914 17.1715 16.3696C17.9133 15.6478 18.3311 14.6694 18.3333 13.6486V6.35135C18.3311 5.33057 17.9133 4.35221 17.1715 3.6304C16.4296 2.9086 15.4241 2.50214 14.375 2.5ZM11.3333 9.24595C10.9233 9.47345 10.4594 9.59309 9.98746 9.59309C9.51547 9.59309 9.05163 9.47345 8.64163 9.24595L2.93329 6.05946C3.0071 5.41511 3.32247 4.81985 3.81906 4.38754C4.31565 3.95523 4.95861 3.71622 5.62496 3.71622H14.375C15.0408 3.71799 15.6828 3.95762 16.179 4.3896C16.6752 4.82157 16.9911 5.41581 17.0666 6.05946L11.3333 9.24595Z"
            fill={props.color || "#949494"}
        />
    </svg>
);


export default Message;
