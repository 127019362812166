import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import actions from 'store/actions'
// Packages
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import moment from 'moment'
import 'moment-timezone';
import { toast } from 'react-toastify'
// Components
import Seprator from 'components/Separator'
import RequestStepper from 'components/RequestStepper'
import Footer from 'components/Footer'
// Assets
import './calendar.scss'
import house1 from 'assets/images/house1.jpg'
import Icons from 'components/Icons'
import { checkImageUrl } from 'utils/modules/Handlers'
import ModalContainer from 'components/ModalContainer'

const Calendar = () => {
    const { id: listingID, mls } = useParams();
    const { showing } = useSelector((state) => state.showing)
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currentShowing, setCurrentShowing] = useState({});
    const [constraints, setConstraints] = useState([]);
    const calendarRef = useRef();
    const [timezone, setTimezone] = useState('America/New_York')
    const [loading, setLoading] = useState(false)

    const [startTime, setStartTime] = useState(moment().startOf('day').format('HH:mm:ss'))
    const [endTime, setEndTime] = useState(moment().endOf('day').format('HH:mm:ss'))
    const [selectedDraft, setSelectedDraft] = useState(null);
    const [modal, setModal] = useState(false);

    const fecthShowing = async () => {
        setLoading(true);
        try {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id))
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fecthShowing();
    }, [])

    useEffect(() => {
        if (showing.id && showing.listing_data) {
            const start = moment().startOf('day').add(showing.listing_data?.availability_start_minutes, 'minute').format('HH:mm:ss');
            const end = moment().startOf('day').add(showing.listing_data?.availability_end_minutes, 'minute').format('HH:mm:ss');
            setStartTime(start)
            setEndTime(end)
        }
    }, [showing]);

    useEffect(() => {
        setLoading(true);
        try {
            if (showing.listing_data) {
                setTimezone(showing.listing_data.timezone)
                const initialConstraints = [{
                    start: moment().tz(timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                    end: roundDate(moment().tz(timezone), 'minutes', 15).format('YYYY-MM-DDTHH:mm:ss'),
                    overlap: false,
                    display: 'background',
                    color: '#888',
                }];
                if (showing.listing_data.effective_notice_required_minutes) {
                    initialConstraints.push({
                        start: roundDate(moment().tz(timezone), 'minutes', 15).format('YYYY-MM-DDTHH:mm:ss'),
                        end: roundDate(moment().tz(timezone), 'minutes', 15).add({ minutes: showing.listing_data.effective_notice_required_minutes }).format('YYYY-MM-DDTHH:mm:ss'),
                        overlap: false,
                        classNames: ['event', 'blackout'],
                        title: 'Blackout',
                    })
                }
                setConstraints(initialConstraints);
                setLoading(false);
            }
            
        } catch (error) {
            setLoading(false);
        }
    }, [showing, timezone]);

    useEffect(() => {
        const init = async () => {
            if (showing.listing) {
                setLoading(true);
                try {
                    await dispatch(actions.listing.fetchListingEvents(showing.listing)).then((items) => {
                        const newCurrentShowing = items.find(event => event.id && event.id === showing.id);
                        if (newCurrentShowing) {
                            setCurrentShowing({ ...formatEvent({ ...newCurrentShowing, state: 'pending' }), id: newCurrentShowing.id, editable: true, title: 'showing (current)', classNames: ['event', 'showing-pending', 'current'] });
                        }
                        setLoading(false);
                    })
                    
                } catch (error) {
                    setLoading(false);
                }
            }
        }
        init();
    }, [showing]);

    const handleSelectMini = useCallback((event) => {
        calendarRef.current.getApi().gotoDate(event.start);
    }, [calendarRef]);

    const dayCellClassNames = (date) => {
        if (date.isPast) {
            return ['past-cell'];
        }
    }

    const handleSelect = useCallback((event) => {
        setCurrentShowing({
            id: "1",
            editable: true,
            start: event.start,
            end: event.end,
            title: 'showing (current)',
            classNames: ['event', 'showing-pending', 'current'],
        });
    }, []);

    const handleSelectAllow = useCallback((selectInfo) => {
        const check = moment(selectInfo.start).format('yyyy-MM-DD');
        const today = moment().subtract(1, 'days').format('yyyy-MM-DD');
        return !(
            showing.listing_data.max_showing_window_minutes !== null &&
            (selectInfo.end - selectInfo.start) > (showing.listing_data.max_showing_window_minutes * 60 * 1000)
        ) && (check > today);
    }, [showing]);

    const fetchListingEvents = useCallback(async (info, success, failure) => {
        const data = await dispatch(actions.listing.fetchListingEventsStartEnd({ listing: showing.listing, start: info.startStr, end: info.endStr }));
        const firstRecurrenceExcluded = data
            .filter(event => !event.occurrences_url) // exclude the original event in favor of first occurrence
            .filter(event => event.id || !data.find(other => other.id && other.url === event.url)); // exclude the default occurrence in favor of the custom recurrence
        const selfExcluded = firstRecurrenceExcluded.filter(event => !event.id || event.id !== showing.id);
        success([...selfExcluded.map(formatEvent), ...constraints, ...[currentShowing].filter(x => x)]);
    }, [constraints, currentShowing, showing]);

    const roundDate = (date, type, offset) => {
        let val = date[type]();
        let roundedVal = Math.ceil((val) / offset) * offset;
        return date[type](roundedVal);
    };

    const formatEvent = (oldEvent) => {
        const event = {
            showing: oldEvent.id,
            title: oldEvent.event_type.replace('_', ' '),
            start: moment.parseZone(oldEvent.start).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment.parseZone(oldEvent.end).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            state: oldEvent.state,
            classNames: ['event'],
            event_type: oldEvent.event_type
        };

        if (!!event.listing_event) {
            event.classNames.push('occurrence');
            if (event.linked_to_parent) {
                event.classNames.push('linkedToParent');
            }
        }
        event.overlap = false;
        if (oldEvent.event_type === 'showing' && oldEvent.state !== 'approved') {
            if (oldEvent.state === 'embryo') {
                event.classNames.push('showing-draft');
            } else {
                event.classNames.push('showing-pending');
            }
            // event.overlap = true;
        } else {
            event.classNames.push(oldEvent.event_type);
        }

        return event;
    };

    const getShowingDate = (current) => {
        if (current.id) {
            const date = moment(current.start).format('MMM DD, YYYY | ddd')
            return date
        }
    }
    const getShowingTime = (current) => {
        if (current.id) {
            const start = moment(current.start).format('hh:mm a')
            const end = moment(current.end).format('hh:mm a')
            return `${start} - ${end}`
        }
    }

    const eventClickInfo = (info) => {
        const start = moment(info.event.start).format('hh:mm a')
        const end = moment(info.event.end).format('hh:mm a')
        const message = `${info.event.title.toUpperCase()} ${start} - ${end}`
        toast.info(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    }

    const onNext = useCallback(async () => {
        const start = moment(currentShowing.start).tz(timezone, true);
        const finish = moment(currentShowing.end).tz(timezone, true);
        const body = {
            showing: showing.id,
            start,
            finish,
        }
        await dispatch(actions.showing.showingRequestStep3(body))
        navigate(`/request/${mls}/${listingID}/review`)

    }, [currentShowing, timezone])

    const onBack = () => {
        if (urlParams.get('back')) {
            navigate(`/${urlParams.get('back')}?active=drafts`)
        } else {
            if (urlParams.get('prev') && urlParams.get('prev') !== 'confirm') {
                navigate(`/request/${mls}/${listingID}/${urlParams.get('prev')}/?prev=${urlParams.get('next')}&next=${urlParams.get('next')}`)
            } else {
                navigate(`/listings/${mls}/${listingID}`);
                toast.info('Showing request progress saved as draft.', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
    }
    
    const onShowingDelete = async({event, item}) => {
        event.stopPropagation();
        if (item?.state === 'embryo') {
            setLoading(true);
            try {
                await dispatch(actions.showing.deleteShowing(item.showing))
                toast.success('Draft showing has been deleted.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    }

    const confirmDraftShowing = useCallback(() => {
        if (selectedDraft?.event.extendedProps?.state === 'embryo') {
            const start = moment(selectedDraft?.event.start).format('hh:mm a')
            const end = moment(selectedDraft?.event.end).format('hh:mm a')
            const message = `Showing Draft (${moment(selectedDraft?.event.start).format('MMMM DD')}, ${start} - ${end}) has been selected.`
            localStorage.setItem('showingId', selectedDraft?.event.extendedProps.showing);
            setModal(prev => !prev);
            fecthShowing();
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }, [selectedDraft])


    const renderEvent = (info) => {
        const handleDraftEventSelect = (info) => {
            const props = info.event.extendedProps;
            if (props.event_type === 'showing' && props.state === 'embryo') {
                if (moment(info.event.start).isSameOrAfter(moment())) {
                    setSelectedDraft(info);
                    setModal(prev => !prev);
                }
                else {
                    toast.info('Cannot select draft showing past the current date.', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        }
        
        return (
            <div className='event-container' onClick={() => handleDraftEventSelect(info)}>
                <div className='event-bubble'>
                    <p className='time'>{info.timeText}</p>
                    {
                        info.event.extendedProps.event_type !== 'blackout' 
                        ? <span className='detail'>
                            {
                                info.event.extendedProps?.state === 'embryo' 
                                ? 'Showing (Draft)' 
                                : info.event.extendedProps?.state === 'approved' 
                                    ? 'Showing (Approved)' 
                                    : (info.event.classNames?.includes('showing-pending') && info.event.classNames?.includes('current'))
                                        ? 'Showing (Current)' 
                                        : info.event.classNames?.includes('showing-pending') 
                                            ? 'Showing (Pending)' 
                                            : info.event.title}
                        </span>
                        : <span className='detail'>Blackout</span>
                    }
                </div>
                {
                    (info.event.extendedProps?.state === 'embryo' && info.event.extendedProps.event_type !== 'blackout') &&
                    <div className='actions' onClick={(event) => event.preventDefault()}>
                        <Icons.CircleClose color={'#FF3535'} height={15} className="delete" onClick={(event) => onShowingDelete({event, item: info.event.extendedProps})} />
                    </div>
                }
            </div>
        )
    }

    return (
        <section className="showing-calendar-container">
            {/* <small>Timezone: {timezone}</small> */}
            <ModalContainer modal={modal} setModal={setModal} title={'Load Draft Showing'}>
                <p>You are about to load a <b>Draft Showing Request {`(${moment(selectedDraft?.event.start).format('MMMM DD')}, ${moment(selectedDraft?.event.start).format('hh:mm a')} - ${moment(selectedDraft?.event.end).format('hh:mm a')})`}</b>, if you confirm this action the calendar will load the drafted showing request and it will continue from there.</p>
                <Seprator height={20} />
                <button className='button primary no-glow small' style={{ width: '100%' }} onClick={confirmDraftShowing}>Confirm Action</button>
            </ModalContainer>
            <Seprator height={100} />
            <div className='back-next-container'>
                <div>
                    <p className='back-button' onClick={onBack}>Back</p>
                    <h1 className="title">Showing Bee Calendar</h1>
                    {
                        currentShowing.id &&
                        <button 
                            className="button medium primary next-button" 
                            style={{ width: 120 }}
                            onClick={onNext}
                        >
                            Next
                        </button>
                    }
                </div>
                <p className='subtitle'>Set a schedule for your appointment by dragging the calendar below.</p>
            </div>
            <div className="calendars">
                {
                    (showing.id && !loading) 
                    ? <>
                        <div className="sb-fc">
                            <FullCalendar
                                ref={calendarRef}
                                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
                                height="auto"
                                initialView="timeGridWeek"
                                dayCellClassNames={dayCellClassNames}
                                headerToolbar={{
                                    left: 'dayGridMonth,timeGridWeek,timeGridDay',
                                    center: 'title',
                                    right: 'prev,next',
                                }}
                                eventContent={renderEvent}
                                selectable={true}
                                allDaySlot={false}
                                firstDay={1}
                                slotDuration="00:15:00"
                                slotLabelInterval="01:00"
                                slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZSeroMinute: false, meridiem: 'lowercase' }}
                                eventTimeFormat={{ hour: 'numeric', minute: '2-digit', omitZSeroMinute: false, meridiem: 'lowercase' }}
                                slotMinTime={startTime}
                                slotMaxTime={endTime}
                                nowIndicator={true}
                                now={moment().tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
                                longPressDelay={500}
                                initialDate={moment().tz(timezone).format('YYYY-MM-DD')}
                                businessHours={[
                                    {
                                        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                                        startTime: startTime,
                                        endTime: endTime,
                                    },
                                ]}
                                events={fetchListingEvents}
                                selectOverlap={false}
                                // selectOverlap={(event) => event.classNames.includes('showing-pending')}
                                select={handleSelect}
                                selectAllow={handleSelectAllow}
                                eventAllow={handleSelectAllow}
                                eventDrop={(info) => handleSelect(info.event)}
                                eventResize={(info) => handleSelect(info.event)}
                            />
                        </div>
                        <Seprator width={40} />
                        <div className='extra-details'>
                            <div className="sb-fc-mini">
                                <FullCalendar
                                    plugins={[interactionPlugin, dayGridPlugin]}
                                    height="auto"
                                    initialView="dayGridMonth"
                                    headerToolbar={{
                                        left: 'title',
                                        right: 'prev,next',
                                    }}
                                    selectable={true}
                                    nowIndicator={true}
                                    now={moment().tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
                                    longPressDelay={500}
                                    initialDate={moment().tz(timezone).format('YYYY-MM-DD')}
                                    firstDay={1}
                                    // validRange={{
                                    //     start: moment().tz(timezone).format('YYYY-MM-DD'),
                                    // }}
                                    eventClick={eventClickInfo}
                                    events={fetchListingEvents}
                                    select={handleSelectMini}
                                    showNonCurrentDates={true}
                                />
                            </div>
                            <Seprator height={20} />
                            <div className='listing-detail'>
                                <div className='preview'>
                                    <img src={checkImageUrl(showing?.listing_first_image_url, house1)} alt="" />
                                </div>
                                <div className='details'>
                                    <div>
                                        <p className="title">{showing?.address}</p>
                                        <p className="id">#{showing?.listing_data?.mls_number || showing?.listing_data?.id}</p>
                                    </div>
                                    <div className='selected-showing'>
                                        <p className='title'>Schedule:</p>
                                        {
                                            currentShowing.id &&
                                            <div className='showing-schedule'>
                                                <p className='date'>{`${getShowingDate(currentShowing)} | ${getShowingTime(currentShowing)}`}&nbsp;</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <div className="loader">
                        <Icons.Loading background={'none'} height={100} width={100} />
                    </div>
                }
            </div>
            <Seprator height={40} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Seprator height={90} />
            <Footer subfooter />
        </section>
    );
}


export default Calendar;