// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-filters-container {
  display: flex;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid #E4E4E4;
  width: calc(100% - 300px);
  position: fixed;
  z-index: 5;
  padding-right: 24px;
  max-width: 1140px;
}

@media (min-width: 768px) and (max-width: 1024px), (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .dashboard-filters-container {
    width: 100%;
    padding-left: 24px;
  }
}
@media (min-width: 481px) and (max-width: 767px), (min-width: 320px) and (max-width: 480px) {
  .dashboard-filters-container {
    width: 100%;
    padding-left: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardFiltersContainer/dashboardFiltersContainer.scss","webpack://./src/assets/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,YAAA;EACA,uBAAA;EACA,gCAAA;EACA,yBAAA;EACA,eAAA;EACA,UAAA;EACA,mBAAA;EACA,iBAAA;AADJ;;ACAY;EDKR;IACI,WAAA;IACA,kBAAA;EADN;AACF;ACdY;EDYR;IACI,WAAA;IACA,kBAAA;EAKN;AACF","sourcesContent":["@import 'assets/styles/_mixins.scss';\n\n.dashboard-filters-container {\n    display: flex;\n    height: 60px;\n    background-color: white;\n    border-bottom: 1px solid #E4E4E4;\n    width:  calc(100% - 300px);\n    position: fixed;\n    z-index: 5;\n    padding-right: 24px;\n    max-width: 1140px;\n}\n\n@include media(tablet, mobile) {\n    .dashboard-filters-container {\n        width: 100%;\n        padding-left: 24px;\n    }\n}","@mixin media($keys...) {\r\n    @each $key in $keys {\r\n        @if ($key==mobile) {\r\n            @media (min-width: 481px) and (max-width: 767px),\r\n            (min-width: 320px) and (max-width: 480px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==tablet) {\r\n            @media (min-width: 768px) and (max-width: 1024px),\r\n            (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==nitty-gritt) {\r\n            @media (width: 1024px) and (max-height: 833px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==small-desktop) {\r\n            @media (max-width: 1440px) and (max-height: 833px) {\r\n                @content;\r\n            }\r\n        }\r\n\r\n        @else if ($key==desktop) {\r\n            @media (min-width: 1024px) and (max-width: 1440px) {\r\n                @content;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
