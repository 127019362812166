import * as React from "react"

const Filter = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_603_268)">
      <path
        d="M23.8271 0H1.1729C0.94156 0.00103895 0.715694 0.0704659 0.523713 0.199548C0.331732 0.328629 0.18221 0.511601 0.0939547 0.725446C0.00569985 0.939292 -0.0173462 1.17446 0.0277155 1.40137C0.0727773 1.62828 0.183934 1.8368 0.347204 2.00069L9.37498 11.0326V21.0937C9.37508 21.2821 9.42057 21.4677 9.50761 21.6348C9.59464 21.8018 9.72066 21.9454 9.87498 22.0535L13.7812 24.7868C13.9569 24.9093 14.1628 24.9813 14.3765 24.9951C14.5902 25.0089 14.8037 24.964 14.9936 24.8651C15.1836 24.7662 15.3429 24.6172 15.4542 24.4342C15.5655 24.2512 15.6245 24.0413 15.625 23.8271V11.0319L24.6528 2C24.8158 1.83607 24.9267 1.62761 24.9717 1.40082C25.0166 1.17403 24.9935 0.939014 24.9053 0.72531C24.817 0.511607 24.6676 0.328744 24.4758 0.199696C24.284 0.070649 24.0583 0.00117392 23.8271 0V0Z"
        fill={props.color || "#2A2A2A"}
      />
    </g>
    <defs>
      <clipPath id="clip0_603_268">
        <rect width={25} height={25} fill="white" />
      </clipPath>
    </defs>
  </svg>
)


export default Filter
