import * as React from "react"

const Facebook = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <path
      data-name="Icon metro-facebook"
      d="M25 0H5a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h10V16.872h-3.75v-3.75H15v-2.809a4.687 4.687 0 0 1 4.688-4.687h4.688v3.746h-4.689a.938.938 0 0 0-.937.938v2.813h5.156l-.937 3.75H18.75V30H25a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5Z"
      fill={props.color || '#fffdf7'}
    />
  </svg>
)


export default Facebook