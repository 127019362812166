import * as React from "react"

const Dashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1731_3633)">
      <path
        d="M12.5417 0.666656H5.45835C2.8121 0.666656 0.666687 2.81207 0.666687 5.45832V12.5417C0.666687 15.1879 2.8121 17.3333 5.45835 17.3333H12.5417C15.1879 17.3333 17.3334 15.1879 17.3334 12.5417V5.45832C17.3334 2.81207 15.1879 0.666656 12.5417 0.666656ZM8.0046 13.1667C8.0046 13.8571 7.44502 14.4167 6.7546 14.4167H5.08794C4.39752 14.4167 3.83794 13.8571 3.83794 13.1667V4.83332C3.83794 4.14291 4.39752 3.58332 5.08794 3.58332H6.7546C7.44502 3.58332 8.0046 4.14291 8.0046 4.83332V13.1667ZM14.1621 12.9121C14.1621 13.6025 13.6025 14.1621 12.9121 14.1621H11.2454C10.555 14.1621 9.99544 13.6025 9.99544 12.9121V11.2454C9.99544 10.555 10.555 9.99541 11.2454 9.99541H12.9121C13.6025 9.99541 14.1621 10.555 14.1621 11.2454V12.9121ZM14.1621 6.75457C14.1621 7.44499 13.6025 8.00457 12.9121 8.00457H11.2454C10.555 8.00457 9.99544 7.44499 9.99544 6.75457V5.08791C9.99544 4.39749 10.555 3.83791 11.2454 3.83791H12.9121C13.6025 3.83791 14.1621 4.39749 14.1621 5.08791V6.75457Z"
        fill={props.color || "white"}
      />
    </g>
    <defs>
      <clipPath id="clip0_1731_3633">
        <rect
          width={16.6667}
          height={16.6667}
          fill={props.color || "white"}
          transform="translate(0.666687 0.666656)"
        />
      </clipPath>
    </defs>
  </svg>
)


export default Dashboard;
