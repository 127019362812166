import CustomContainer from "components/CustomContainer";
import Logo from "components/Logo";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import BuyersInfoPage from "./modules/BuyersInfoPage";
import SellersInfoPage from "./modules/SellersInfoPage";
import AgentsInfoPage from "./modules/AgentsInfoPage";
import "./whyChooseUs.scss"
import Separator from "components/Separator";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";

const WhyChooseUs = () => {
    const navigate = useNavigate();
    const [detail, setDetail] = useState('')
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (urlParams.get('info')) {
            setDetail(urlParams.get('info'))
        } else {
            setDetail('buyers')
        }
    }, [])

    return (
        <>
            <PageWrapper>
                <CustomContainer>
                    <div className="why-choose-us-container">
                        <div className="nav">
                            <Logo style={{ height: 20, cursor: 'pointer' }} action={() => navigate('/')}/>
                        </div>
                        <ul className="navigation">
                            <li className={`${detail === 'buyers' && 'active'}`} onClick={() => setDetail('buyers')}>Buyers</li>
                            <li className={`${detail === 'agents' && 'active'}`} onClick={() => setDetail('agents')}>Agents</li>
                            <li className={`${detail === 'sellers' && 'active'}`} onClick={() => setDetail('sellers')}>Sellers</li>
                        </ul>
                        <Separator height={60} />
                        <div className="page-content">
                            { detail === 'buyers' && <BuyersInfoPage /> }
                            { detail === 'sellers' && <SellersInfoPage /> }
                            { detail === 'agents' && <AgentsInfoPage /> }
                        </div>
                    </div>
                </CustomContainer>
            </PageWrapper>
            <Separator height={80} />
            <Footer />
        </>
    )
}


export default WhyChooseUs;