import * as React from "react"

const LocationAlt = (props) => (
  <svg
    width={12}
    height={15}
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.74062 14.625C8.34375 12.7441 12 8.18555 12 5.625C12 2.51953 9.3125 0 6 0C2.6875 0 0 2.51953 0 5.625C0 8.18555 3.65625 12.7441 5.25938 14.625C5.64375 15.0732 6.35625 15.0732 6.74062 14.625ZM6 7.5C4.89687 7.5 4 6.65918 4 5.625C4 4.59082 4.89687 3.75 6 3.75C7.10313 3.75 8 4.59082 8 5.625C8 6.65918 7.10313 7.5 6 7.5Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default LocationAlt
