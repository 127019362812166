import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.914}
    height={11.914}
    viewBox="0 0 11.914 11.914"
    {...props}
  >
    <path
      d="M18 8.557 16.943 7.5l-4.193 4.193L8.557 7.5 7.5 8.557l4.193 4.193L7.5 16.943 8.557 18l4.193-4.193L16.943 18 18 16.943l-4.193-4.193Z"
      transform="translate(-6.793 -6.793)"
      fill={ props.color || "#2a2a2a" }
      stroke={ props.color ||"#2a2a2a" }
    />
  </svg>
)

export default SvgComponent
