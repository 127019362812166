import Separator from 'components/Separator';
import './tierList.scss'
import Icons from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import actions from 'store/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import NoUser from 'assets/images/no_user.jpg'
import CustomButton from 'components/CustomButton';
import ModalContainer from 'components/ModalContainer';
import DefaultBrokerModal from '../DefaultBrokerModal';

const TierList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef(null);
    const { user } = useSelector((state) => state.auth)
    const [connections, setConnections] = useState(null);
    const [profile, setProfile] = useState(null)
    const [brokersModal, setBrokersModal] = useState(false);

    const tier =
    {
        F: {
            Icon: <Icons.ForagerBeeTier height={80} width={80} />,
            name: "Forager Bee",
            description: "Sellers and agents may see you as a looker.",
            status: "Passive",
            perks: ["Save searches and add to favorites."],
            perks_description: "Documentation"
        },
        H: {
            Icon: <Icons.HouseBeeTier height={80} width={80} />,
            name: "House Bee",
            description: "Sellers and agents may see you as a serious buyer.",
            status: "Basic",
            perks: ["Upload Pre-Qualification letter.", "Fill out buyer questionnaire.", "Save searches and add to favorites.", "Request Showings via Live Calendar."],
            perks_description: "Pre-Qualified"
        },
        Q: {
            Icon: <Icons.QueenBeeTier height={80} width={80} />,
            name: "Queen Bee",
            description: "Sellers and agents will see you as a ready buyer.",
            status: "Hive Master",
            perks: ["Cash buyers are auto-approved.", "Upload Pre-Approval letter.", "Get pre-approved through a verified ShowingBee lender.", "Save searches and add to favorites.", "Request Showings via Live Calendar."],
            perks_description: "Pre-Approved"
        }
    };

    const broker = {
        name: "John Frederick Buenafe",
        email: "frederickbuenafe@gmail.com",
        phone: "0000-000-0000",
        avatarUrl: "/path/to/broker-avatar.jpg"
    };

    const checkButtonStatus = () => {
        const elementsList = document.querySelectorAll(".tier-button");
        let underReview = false

        elementsList.forEach(element => {
            if (element.textContent === 'Pre-Approval Letter Uploaded') {
                underReview = true
                element.disabled = true;
            }
            if (element.textContent === 'Active') {
                element.classList.add('disabled')
                element.disabled = true;
            }
        });

        if (underReview) {
            elementsList.forEach(element => {
                element.disabled = true;
            });
        }
    }

    const scrollToHash = () => {
        const yOffset = -100;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    const initProfile = async () => {
        if (user.id) {
            const data = await dispatch(actions.buyer.fetchBuyerProfile())
            setProfile(data[0])
        }
    }
    useEffect(() => {
        initProfile()
    }, [user])

    const initConnection = async () => {
        if (user.id && profile) {
            try {
                let broker = null
                if (profile.default_mortgage_broker) {
                    broker = profile.default_mortgage_broker.id
                }
                const data = await dispatch(actions.buyer.fetchBuyerMortgageConnectionByBroker({ broker: broker }));
                console.log(data.results[0])
                setConnections(data.results[0] || null)
                checkButtonStatus();
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        initConnection()
    }, [user, profile])

    // useEffect(() => {
    //     const init = async () => {
    //         const connect = data.filter(item => !item.date_rejected)
    //         setConnections(connect)
    //     }

    //     init()
    // }, [data])

    const onTierSelect = (tier) => {
        if (tier === 'H') {
            navigate('/bee-tier/upgrade/house')
        } else {
            navigate('/bee-tier/upgrade/queen')
        }
    }

    const onForgerBee = async () => {
        try {
            let data = {
                buyer_tier: 'F'
            }
            await dispatch(actions.buyer.foragerBuyerDowngrade(data));
            await dispatch(actions.auth.retrieveUser());
            toast.success('You are now a Forager Bee!', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <PageWrapper>
            <CustomContainer>
                <DefaultBrokerModal modal={brokersModal} setModal={setBrokersModal} profile={profile} refetch={() => {initProfile(); initConnection()}} />

                <div className="tier-list-container">
                    <div className="tier-info-container">
                        <div className="tier-details">
                            <h2 style={{ color: '#2A2A2A', fontSize: 16, fontWeight: 700, margin: 0 }}>Your Tier</h2>
                            <Separator height={16} />
                            <div className={`tier-content ${user.buyer_tier}`}>
                                <div>
                                    <div>
                                        {tier[user.buyer_tier].Icon}
                                        <Separator height={16} />
                                        <h3 style={{ fontSize: 24, fontWeight: 700, color: '#2a2a2a', margin: 0 }}>{tier[user.buyer_tier].name}</h3>
                                        <Separator height={8} />
                                        <span style={{ fontSize: 12, fontWeight: 400, color: '#949494', margin: 0 }}>{tier[user.buyer_tier].status}</span>
                                        <Separator height={16} />
                                        <p style={{ margin: 0, fontWeight: 400, color: '#2a2a2a', fontSize: 14 }}>{tier[user.buyer_tier].description}</p>
                                    </div>
                                    <p className='tier-perks-description'>{tier[user.buyer_tier].perks_description}</p>
                                </div>
                                <div>
                                    <div>
                                        <p style={{ fontSize: 16, fontWeight: 700, color: '#2a2a2a', margin: 0 }}>Perks in your tier</p>
                                        <Separator height={16} />
                                        <ul>
                                            {tier[user.buyer_tier].perks.map((perk, index) => (
                                                <li key={index}><Icons.CircleCheck height={12} width={12} color='#2a2a2a' />{perk}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    {/* <CustomButton text="Manage Tier" type="dark" style={{ width: 'fit-content' }} /> */}
                                </div>
                            </div>
                        </div>

                        <div className="broker-details">
                            <h2 style={{ color: '#5F5F5F', fontSize: 14, fontWeight: 700 }}>Current Mortgage Broker</h2>
                            <Separator height={32} />
                            <div className="broker-content">
                                {
                                    profile?.default_mortgage_broker 
                                    ? <>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className='broker-avatar-container' style={{ position: 'relative' }}>
                                                <img src={checkImageUrl(connections?.mortgage_broker?.profile_photo, NoUser)} alt={connections?.mortgage_broker?.first_name} className="broker-avatar" />
                                                <Icons.BrokerBadge width={24} height={24} />
                                            </div>
                                            <Separator height={24} />
                                            <h3 style={{ fontSize: 16, fontWeight: 700, color: '#2a2a2a', margin: 0 }}>{connections?.mortgage_broker?.first_name} {connections?.mortgage_broker?.last_name}</h3>
                                            <Separator height={8} />
                                            <p style={{ fontSize: 14, fontWeight: 400, color: '#2a2a2a', margin: 0 }}>{connections?.mortgage_broker?.email}</p>
                                            <Separator height={8} />
                                            <p style={{ fontSize: 14, fontWeight: 400, color: '#2a2a2a', margin: 0 }}>{connections?.mortgage_broker?.phone || 'Phone Number Not Available'}</p>
                                        </div> 
                                        <CustomButton text="Change Broker" type="secondary" action={() => setBrokersModal(prev => !prev)} />
                                    </>
                                    : <p style={{ fontSize: 14, fontWeight: 700, color: '#2a2a2a', margin: 0, textAlign: 'center' }}>No Default Mortgage Broker <br /> <br />
                                    <>
                                        {
                                            user.buyer_type === 'C'
                                            ? <div>
                                                <span style={{ fontWeight: 400 }}>
                                                    You're currently a Cash Buyer, if you want to have a default broker your can choose from your connections or invite your own.
                                                </span>
                                            </div>
                                            : <span style={{ fontWeight: 400 }}>
                                                Upgrade to a higher tier to choose a mortgage broker or invite a your own mortgage broker in your connections.
                                            </span>
                                        }
                                    </>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        (user.buyer_tier === 'F' || user.buyer_tier === 'H') &&
                        <div className='tier-upgrades'>
                            <h2 style={{ color: '#2A2A2A', fontSize: 16, fontWeight: 700, margin: 0 }}>Perks on the next tiers</h2>
                            <Separator height={16} />
                            <div className='tier-cards'>
                                {
                                    user.buyer_tier !== 'H' &&
                                    <div className='tier-card'>
                                        <div className='tier-card-content'>
                                            <div>
                                                {tier['H'].Icon}
                                                <Separator height={16} />
                                                <h3 style={{ fontSize: 24, fontWeight: 700, color: '#2a2a2a', margin: 0 }}>{tier['H'].name}</h3>
                                                <Separator height={8} />
                                                <span style={{ fontSize: 12, fontWeight: 400, color: '#949494', margin: 0 }}>{tier['H'].status}</span>
                                                <Separator height={16} />
                                                <p style={{ margin: 0, fontWeight: 400, color: '#2a2a2a', fontSize: 14 }}>{tier['H'].description}</p>
                                                <Separator height={24} />
                                                <p className='tier-perks-description house'>{tier['H'].perks_description}</p>
                                            </div>
                                        </div>
                                        <div className='tier-perks'>
                                            <div>
                                                <p style={{ fontSize: 16, fontWeight: 700, color: '#2a2a2a', margin: 0 }}>Perks in your tier</p>
                                                <Separator height={16} />
                                                <ul>
                                                    {tier['H'].perks.map((perk, index) => (
                                                        <li key={index}><Icons.CircleCheck height={12} width={12} color='#2a2a2a' />{perk}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <CustomButton disabled={connections?.request_upgrade && !connections?.date_approved} text={connections?.request_upgrade && !connections?.date_approved && connections?.buyer_tier === 'H' ? 'Approval In Progress' : 'Choose this tier'} type="dark" style={{ width: 130 }} size={'small'} action={() => onTierSelect('H')} />
                                        </div>
                                    </div>

                                }
                                {
                                    user.buyer_tier !== 'Q' &&
                                    <div className='tier-card queen'>
                                        <div className='tier-card-content'>
                                            <div>
                                                {tier['Q'].Icon}
                                                <Separator height={16} />
                                                <h3 style={{ fontSize: 24, fontWeight: 700, color: '#fff', margin: 0 }}>{tier['Q'].name}</h3>
                                                <Separator height={8} />
                                                <span style={{ fontSize: 12, fontWeight: 400, color: '#fff', margin: 0 }}>{tier['Q'].status}</span>
                                                <Separator height={16} />
                                                <p style={{ margin: 0, fontWeight: 400, color: '#fff', fontSize: 14 }}>{tier['Q'].description}</p>
                                                <Separator height={24} />
                                                <p className='tier-perks-description queen'>{tier['H'].perks_description}</p>
                                            </div>
                                        </div>
                                        <div className='tier-perks'>
                                            <div>
                                                <p style={{ fontSize: 16, fontWeight: 700, color: '#2a2a2a', margin: 0 }}>Perks in your tier</p>
                                                <Separator height={16} />
                                                <ul>
                                                    {tier['Q'].perks.map((perk, index) => (
                                                        <li key={index}><Icons.CircleCheck height={12} width={12} color='#2a2a2a' />{perk}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <CustomButton disabled={connections?.request_upgrade && !connections?.date_approved} text={connections?.request_upgrade && !connections?.date_approved && connections?.buyer_tier === 'Q' ? 'Approval In Progress' : 'Choose this tier'} type="dark" style={{ width: 130 }} size={'small'} action={() => onTierSelect('Q')} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default TierList;