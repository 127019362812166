import * as React from "react"

const Heart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.857}
    height={20}
    viewBox="0 0 22.857 20"
    {...props}
  >
    <path
      fill={props.color || 'gray'}
      d="M20.638,3.615a6.1,6.1,0,0,0-8.33.607l-.879.906-.879-.906a6.1,6.1,0,0,0-8.33-.607A6.41,6.41,0,0,0,1.776,12.9l8.638,8.919a1.4,1.4,0,0,0,2.022,0L21.075,12.9a6.406,6.406,0,0,0-.437-9.281Z"
      transform="translate(0.001 -2.248)"
    />
  </svg>
)

export default Heart
