import './showingUsersBlock.scss'
import NoUser from 'assets/images/no_user.jpg'
import { checkImageUrl } from 'utils/modules/Handlers';
import Icons from 'components/Icons';
import UserPlaceholder from 'components/UserPlaceholder';
import { useSelector } from 'react-redux';

const ShowingUsersBlock = ({ data }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className='showing-user-block-container'>
            <div className={`${data.created_by === data?.listing_data?.agent_data?.user_data.id && 'requested'}`}>
                {
                    data?.listing_data?.agent_data?.user_data.profile_photo
                    ? <img className="user-image buyer" src={checkImageUrl(data?.listing_data?.agent_data?.user_data.profile_photo, NoUser)} alt="" />
                    : <UserPlaceholder user={data?.listing_data?.agent_data?.user_data} type={'la'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                }
            </div>
            {
                data?.buyer_agent_data &&
                <div className={`${data.created_by === data?.buyer_agent_data?.id && 'requested'}`}>
                    {
                        (data?.buyer_agent_data && data?.buyer_agent_data?.profile_photo) 
                        ? <img className="user-image buyer" src={checkImageUrl(data?.buyer_agent_data?.profile_photo, NoUser)} alt="" />
                        : <UserPlaceholder user={data?.buyer_agent_data} type={'ba'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                    }
                </div>
            }
            {
                data.buyers.length > 0 &&
                <div className={`${data.created_by === data.buyers_data[0].id && 'requested'}`}>
                    {
                        data.buyers_data.map((buyer, index) => {
                            return (
                                <div key={index}>
                                    {
                                        (data?.buyer_agent_data && data?.buyer_agent_data?.id === user.id)
                                        ? <>
                                            {
                                                buyer?.profile_photo
                                                ? <img className="user-image buyer" src={checkImageUrl(buyer?.profile_photo, NoUser)} alt="" key={index} />
                                                : <UserPlaceholder user={buyer} type={'buyer'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                                            }
                                        </>
                                        : <UserPlaceholder type={'buyer'} placeholder={'B'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                data?.sellers?.length > 0 &&
                <div>
                    <>
                        {
                            data?.listing_data?.agent_data?.user_data.id === user.id 
                            ? <>
                                {
                                    data?.sellers[0]?.profile_photo
                                    ? <img className="user-image" src={checkImageUrl(data?.sellers[0]?.profile_photo, NoUser)} alt="" />
                                    : <UserPlaceholder user={data?.sellers[0]} type={'seller'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                                }
                            </>
                            : <UserPlaceholder type={'seller'} placeholder={'S'} customStyle={{ marginLeft: '-8px', border: '1px solid white', height: 24, width: 24 }} />
                        }
                    </>
                </div>
            }

            {
                (data?.sellers?.length > 1) &&
                <div>
                    <Icons.MoreUser className="user-image" width={24} heigth={24}/>
                </div>
            }

        </div>
    )
}


export default ShowingUsersBlock;