import { useCallback, useEffect, useMemo, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
// Packages
import moment from 'moment';
import debouce from 'lodash.debounce'
//  Modules
import ListingBlock from "./modules/ListingBlock";
import SellerBlock from "./modules/SellerBlock";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './manageListings.scss';
import { Config } from "utils";
import Icons from "components/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "components/ToastMessage";
import { getErrorMessage } from "utils/modules/Handlers";
import HomeownersModal from "./modules/HomeownersModal";
import ListingStatus from "components/ListingStatus";
import TableHeader from "components/TableHeader";
import DashboardFiltersContainer from "components/DashboardFiltersContainer";
import CustomTextInput from "components/CustomTextInput";

const ManageListing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: listings, previous, next, total } = useSelector((state) => state.listing);
    const [statusFilter, setStatusFilter] = useState('all');
    const [serviceFilter, setServiceFilter] = useState('all');
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedPage, setSelectedPage] = useState(null);
    const [search, setSearch] = useState(null);
    const [serviceMenu, setServiceMenu] = useState(false);
    const [statusMenu, setStatusMenu] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [showAction, setShowAction] = useState(null)
    const [duration, setDuration] = useState('active');

    const [filters, setFilters] = useState({
        status: '',
        service: '',
    })

    // useEffect(() => {
    //     const init = async() => {
    //         await dispatch(actions.listing.fetchListings())
    //     }
    //     init();
    // }, [])

    useEffect(() => {
        const init = async () => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total / 20) || 1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    value: search ? `${Config.API_URL}/listing/search/?limit=20&offset=${offset}&duration=${duration}&${search}` : `${Config.API_URL}/listing/?limit=20&offset=${offset}&duration=${duration}`,
                    name: `${offset + 1} - ${i * 20 >= total ? total : i * 20}`,
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [total, search, duration])

    const loadPageData = async ({ page, pageNumber }) => {
        await dispatch(actions.listing.fetchListingsPagination(page));
        setSelectedPage(page);
        setPage(pageNumber);
    }

    // const onSearch = useCallback(async() => {
    //     try {
    //         await dispatch(actions.listing.searchListings({ offset: 0, filters: search }))
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [search])

    const formatDate = (data) => {
        const startDate = moment(new Date(data)).format('MM/DD/YY');
        return startDate
    }


    const onSearch = useCallback(async () => {
        setIsLoading(true);
        try {
            let data = [];
            if (search) {
                data.push(search)
            }
            if (filters.status) {
                data.push(filters.status)
            }
            if (filters.service) {
                data.push(filters.service)
            }
            await dispatch(actions.listing.searchListings({ offset: 0, filters: data.join('&'), duration: duration })).then(() => {
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }, [filters, search, duration])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [search, filters, duration])

    const onChangeInput = (event) => {
        setSearch(`search=${event.target.value}`)
    }

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 300)
    }, [])

    const ShowingServiceMessage = ({ title, status, errors }) => {
        return (
            <div>
                <p style={{ fontWeight: 700, color: '#2a2a2a' }}>{title}</p>
                <span style={{ fontWeight: 600 }}>{status}</span>
                {
                    errors &&
                    <ul style={{ fontSize: 14, paddingTop: 5, marginLeft: 15 }}>
                        {
                            errors.map((error, index) => {
                                return (
                                    <li key={index} style={{ paddingTop: 10 }}>-{error}</li>
                                )
                            })
                        }
                    </ul>
                }
            </div>
        )
    }

    const onChangeService = async (data) => {
        if (data.showing_notes && data.buyer_only_showing_notes && (data.sellers.length > 0 || data.seller_not_required)) {
            if (data.showing_service_status === 'enabled') {
                try {
                    await dispatch(actions.listing.disableShowingService(data.id));
                    toast.info(
                        <ShowingServiceMessage
                            title={data.mls_number ? data.full_address : `${data.address}, ${data.city} ${data.state} ${data.zip_code}`}
                            status={'Showing service disabled'}
                        />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } catch (error) {
                    toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                try {
                    await dispatch(actions.listing.enableShowingService(data.id));
                    toast.info(
                        <ShowingServiceMessage
                            title={data.mls_number ? data.full_address : `${data.address}, ${data.city} ${data.state} ${data.zip_code}`}
                            status={'Showing service enabled'}
                        />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } catch (error) {
                    toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            if (search || filters.status || filters.service) {
                onSearch();
            } else {
                if (selectedPage) {
                    await dispatch(actions.listing.fetchListingsPagination(selectedPage));
                } else {
                    await dispatch(actions.listing.fetchListings());
                }
            }

        } else {
            const errors = [];
            if (!data.showing_notes) {
                errors.push('Add showing instructions for the Buyer\'s Agent')
            }
            if (!data.buyer_only_showing_notes) {
                errors.push('Add showing instructions for the Solo Buyer')
            }
            if (data.sellers.length === 0 && !data.seller_not_required) {
                errors.push('Add Owner/s')
            }
            if (!data.default_seller && !data.seller_not_required) {
                errors.push('Add a Default Owner')
            }
            toast.warning(
                <ShowingServiceMessage
                    title={`${data.address}, ${data.city} ${data.state} ${data.zip_code}`}
                    status={'To change the showing service:'}
                    errors={errors}
                />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const onStatusSelect = useCallback((status) => {
        setStatusFilter(status);
        if (status === 'all') {
            setFilters({ ...filters, status: '' });
        } else {
            setFilters({ ...filters, status: `status=${status}` });
        }
        setStatusMenu(false)
    }, [statusMenu])

    const onServiceSelect = useCallback((service) => {
        setServiceFilter(service);
        if (service === 'all') {
            setFilters({ ...filters, service: '' });
        } else {
            setFilters({ ...filters, service: `service=${service}` });
        }
        setServiceMenu(false)
    }, [serviceMenu])

    const onShowActionClick = (value) => {
        if (value === showAction) {
            setShowAction(null)
        } else {
            setShowAction(value)
        }
    }

    return (
        <div className="manage-listing-container">
            <DashboardFiltersContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', columnGap: 8 }}>
                    <div className="table-filters">
                        <div>
                            <CustomTextInput
                                inputProps={{
                                    disabled: false,
                                    placeholder: 'Search Listing',
                                    onChange: handleInput,
                                }}
                                leftSlot={<Icons.Search />}
                            />
                            {/* <input className="search" type="text" placeholder="Search Listing" onChange={handleInput} /> */}
                            <div className={`status service ${serviceMenu ? 'active' : 'inactive'}`} onClick={() => setServiceMenu((prev) => !prev)}>
                                <p className={`${serviceFilter}`}>
                                    {
                                        serviceFilter === 'all'
                                            ? 'All Service'
                                            : serviceFilter === 'enabled'
                                                ? 'Enabled Service'
                                                : 'Disabled Service'
                                    }
                                </p>
                                <div className={`status-menu ${serviceMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                                    <p className={`${serviceFilter === 'all' && 'active'}`} onClick={() => onServiceSelect('all')}><span className="all"></span> All Service</p>
                                    <p className={`${serviceFilter === 'enabled' && 'active'}`} onClick={() => onServiceSelect('enabled')}><span className="enabled"></span> Enabled Service</p>
                                    <p className={`${serviceFilter === 'disabled' && 'active'}`} onClick={() => onServiceSelect('disabled')}><span className="disabled"></span> Disabled Service</p>
                                </div>
                            </div>
                            <div className={`status ${statusMenu ? 'active' : 'inactive'}`} onClick={() => setStatusMenu((prev) => !prev)}>
                                <p className={`${statusFilter}`}>
                                    {statusFilter}
                                </p>
                                <div className={`status-menu ${statusMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                                    <p className={`${statusFilter === 'all' && 'active'}`} onClick={() => onStatusSelect('all')}><span className="all"></span> All</p>
                                    {
                                        duration === 'active' &&
                                        <>
                                            <p className={`${statusFilter === 'active' && 'active'}`} onClick={() => onStatusSelect('active')}><span className="active"></span> Active</p>
                                            <p className={`${statusFilter === 'pending' && 'active'}`} onClick={() => onStatusSelect('pending')}><span className="pending"></span> Pending</p>
                                            <p className={`${statusFilter === 'soon' && 'active'}`} onClick={() => onStatusSelect('soon')}><span className="soon"></span> Coming Soon</p>
                                        </>
                                    }
                                    {
                                        duration === 'inactive' &&
                                        <>
                                            <p className={`${statusFilter === 'leased' && 'active'}`} onClick={() => onStatusSelect('leased')}><span className="leased"></span> Leased</p>
                                            <p className={`${statusFilter === 'sold' && 'active'}`} onClick={() => onStatusSelect('sold')}><span className="sold"></span> Sold</p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', columnGap: 35, alignItems: 'center' }}>
                        <ul className="tab-choice">
                            <li className={`${duration === 'active' && 'active'}`} onClick={() => setDuration('active')}>
                                <Icons.Manage color={`${duration === 'active' ? '#fff' : '#5F5F5F'}`} />
                                <p>Listings</p>
                            </li>
                            <li className={`${duration === 'inactive' && 'active'}`} onClick={() => setDuration('inactive')}>
                                <Icons.Archived color={`${duration === 'inactive' ? '#fff' : '#5F5F5F'}`}/>
                                <p>Archived</p>
                            </li>
                        </ul>
                        {
                            listings.length > 0 &&
                            <div className="pagination">
                                <p>{pages[page - 1]?.name} of {total}</p>
                                <p onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                            fill={previous ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                                <p onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.95964 3.95955C6.35017 3.56903 6.98333 3.56903 7.37385 3.95955L10.7072 7.29288C11.0977 7.68341 11.0977 8.31657 10.7072 8.7071L7.37385 12.0404C6.98333 12.431 6.35017 12.431 5.95964 12.0404C5.56912 11.6499 5.56912 11.0167 5.95964 10.6262L8.58587 7.99999L5.95964 5.37376C5.56912 4.98324 5.56912 4.35007 5.95964 3.95955Z"
                                            fill={next ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </DashboardFiltersContainer>
            <HomeownersModal modal={modal} setModal={setModal} data={selectedData} />
            <div className="contents">
                <div className="table-container">
                    {
                        isLoading &&
                        <div className="loader">
                            <Icons.Loading background={'none'} height={100} width={100} />
                        </div>
                    }
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th width="243px"><div style={{ marginLeft: 8 }}>Listing</div></th>
                                <th width="120px"><div >Showing Bee ID</div></th>
                                <th width="107px"><div >MLS Number</div></th>
                                <th width="108px"><div >Homeowner(s)</div></th>
                                {
                                    duration === 'active' &&
                                    <th width="123px"><div >Showing Service</div></th>
                                }
                                <th width="96px"><div >Status</div></th>
                                <th width="100px"><div >Updated At</div></th>
                                <th width="244px"><div style={{ textAlign: 'center' }}>Action</div></th>
                            </tr>
                        </thead>
                        <tbody id="showings-table">
                            {
                                listings.length > 0 ?
                                    listings.map((listing, index) => {
                                        return (
                                            <>
                                                <div className={`action-wrapper ${showAction === listing.id && 'show'}`} onClick={() => onShowActionClick(listing.id)}></div>
                                                <tr className="body" key={index}>
                                                    <td>
                                                        <div className="listing-block-container" onClick={() => navigate(`/listings/${listing.mls_source}/${listing.mls_number || listing.id}`)}>
                                                            <ListingBlock data={listing} />
                                                        </div>
                                                    </td>
                                                    <td align="center" data-label="Showing Bee ID">
                                                        <div className="id">
                                                            <p>{listing.id}</p>
                                                        </div>
                                                    </td>
                                                    <td align="center" data-label="MLS Number">
                                                        <div className="id">
                                                            <p>{listing.mls_number}</p>
                                                        </div>
                                                    </td>
                                                    <td align="center" data-label="Client">
                                                        <SellerBlock data={listing} setModal={setModal} setSelectedData={setSelectedData} />
                                                    </td>
                                                    {
                                                        duration === 'active' &&
                                                        <td align="center" data-label="Showing Service">
                                                            <div className="switch-container">
                                                                <label className='switch'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className={`${listing.showing_approval_type === 'auto' && 'auto'}`}
                                                                        checked={listing.showing_service_status === 'enabled' ? true : false}
                                                                        disabled={listing.listing_status === 'Pending' & true}
                                                                        onChange={() => onChangeService(listing)}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td align="center" data-label="Status">
                                                        {/* <StatusBlock data={listing} /> */}
                                                        <div className="status-container">
                                                            <ListingStatus color={listing.listing_status_color} text={listing.listing_status} />
                                                        </div>
                                                    </td>
                                                    <td align="center" data-label="Updated At">
                                                        <div className="id">
                                                            <p>{formatDate(listing.updated_at)}</p>
                                                        </div>
                                                    </td>

                                                    <td align="center" data-label="Action" style={{ position: 'relative' }}>
                                                        <div className="show-actions" onClick={() => onShowActionClick(listing.id)}>
                                                            <Icons.Ellipsis height={20} width={20} color={showAction === listing.id ? '#FAC91F' : '#949494'} />
                                                        </div>
                                                        <div className={`action-icons ${showAction === listing.id && 'show'}`}>
                                                            <div data-name="Edit" className={`${(!listing.showing_notes || !listing.buyer_only_showing_notes || (listing.sellers.length === 0 && !listing.seller_not_required) || (!listing.default_seller && !listing.seller_not_required)) && 'notice'}`}>
                                                                <Icons.ListingEditIcon height={32} width={32} onClick={() => navigate(`${listing.id}/update`)} />
                                                            </div>
                                                            <div data-name="Images" className={`${!listing.primary_photo_url && 'notice'}`}>
                                                                <Icons.ListingImageIcon height={32} width={32} onClick={() => navigate(`${listing.id}/images`)} />
                                                            </div>
                                                            <div data-name="Documents">
                                                                <Icons.ListingDocumentIcon height={32} width={32} onClick={() => navigate(`${listing.id}/documents`)} />
                                                            </div>
                                                            <div data-name="Feedback">
                                                                <Icons.ListingFeedbackIcon height={32} width={32} onClick={() => navigate(`${listing.id}/feedbacks`)} />
                                                            </div>
                                                            {
                                                                duration === 'active' &&
                                                                <div data-name="Calendar">
                                                                    <Icons.ListingCalendarIcon height={32} width={32} onClick={() => navigate(`${listing.id}/calendar`)} />
                                                                </div>
                                                            }
                                                            {
                                                                listing.showing_service_status === 'enabled' &&
                                                                <div data-name="Form">
                                                                    <Icons.ListingFormIcon height={32} width={32} onClick={() => navigate(`${listing.id}/showing-form`)} />
                                                                </div>
                                                            }

                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }) :
                                    <tr>
                                        <td colSpan={`${duration === 'active' ? 8 : 7}`}>
                                            <div className="empty-table" style={{ fontSize: 14, color: '#949494' }}>
                                                {
                                                    !isLoading &&
                                                    'No Available Listings'
                                                }
                                            </div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


export default ManageListing;