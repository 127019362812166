import {
    APIProvider,
    Map,
} from "@vis.gl/react-google-maps";
import { Config } from 'utils';
import Directions from "./modules/Directions";
import { useEffect, useState } from "react";
import actions from "store/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "components/Icons/modules/Loading";
import './showingsMap.scss';

const ShowingsMap = () => {
    const dispatch = useDispatch();
    const { buyer_id } = useParams();
    const [showings, setShowings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [center, setCenter] = useState({ lat: 27.994402, lng: -81.760254 });
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        const init = async () => {
            getUserLocation();
            await dispatch(actions.showing.fetchBuyerShowings({ offset: 0, buyer_id })).then((data) => {
                setShowings(data);
            });
            setLoading(false);
        }
        init();
    }, []);

    const getUserLocation = () => {
        // if geolocation is supported by the users browser
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    // update the value of userlocation variable
                    setUserLocation({ latitude, longitude });
                    setCenter({ lat: latitude, lng: longitude });
                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }
        // if geolocation is not supported by the users browser
        else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    console.log(userLocation, '===userLocation');


    return (
        <div style={{ height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
                (loading && showings.length === 0)
                    ? <Loading background={'none'} />
                    : <APIProvider apiKey={Config.GOOGLE_MAP_KEY}>
                        <Map
                            className="showings-map"
                            defaultCenter={center}
                            defaultZoom={17}
                            gestureHandling={'greedy'}
                        >
                            <Directions showings={showings} center={center} userLocation={userLocation} />
                        </Map>
                    </APIProvider>
            }
        </div>
    )
}


export default ShowingsMap;