// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-deletion-container {
  flex: 1 1;
  display: flex;
  font-family: "Proxima Nova", sans-serif;
  animation: fadeIn 1s;
  justify-content: center;
  text-align: center;
}
.account-deletion-container .account-deletion-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.account-deletion-container .account-deletion-form .header, .account-deletion-container .account-deletion-form .subheader {
  margin: 0;
}
.account-deletion-container .account-deletion-form .header {
  font-size: 36px;
  color: var(--secondary-color-1);
  font-weight: 700;
}
.account-deletion-container .account-deletion-form .subheader {
  font-size: 24px;
  color: var(--secondary-color-3);
  font-weight: 700;
}
.account-deletion-container .account-deletion-form .forgot-password {
  margin: 0;
  font-size: 10px;
  font-weight: 700;
  color: var(--primary-color-1);
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.account-deletion-container .illus {
  height: 200px;
}
.account-deletion-container .illus-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}
.account-deletion-container .illus-container > img {
  width: 100%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/Response/modules/AccountDeletionForm/accountDeletionForm.scss"],"names":[],"mappings":"AAEA;EACI,SAAA;EACA,aAAA;EACA,uCAAA;EACA,oBAAA;EACA,uBAAA;EACA,kBAAA;AADJ;AAEI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAR;AACQ;EACI,SAAA;AACZ;AACQ;EACI,eAAA;EACA,+BAAA;EACA,gBAAA;AACZ;AACQ;EACI,eAAA;EACA,+BAAA;EACA,gBAAA;AACZ;AACQ;EACI,SAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AACZ;AAEI;EACI,aAAA;AAAR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAAR;AACQ;EACI,WAAA;AACZ;AAEI;EACI;IAAK,UAAA;EACX;EAAM;IAAO,UAAA;EAGb;AACF","sourcesContent":["@import 'assets/styles/_mixins.scss';\n\n.account-deletion-container {\n    flex: 1;\n    display: flex;\n    font-family: 'Proxima Nova', sans-serif;    \n    animation: fadeIn 1s;\n    justify-content: center;\n    text-align: center;\n    .account-deletion-form {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        height: 100vh;\n        .header, .subheader { \n            margin: 0;\n        }\n        .header {\n            font-size: 36px;\n            color: var(--secondary-color-1);\n            font-weight: 700;\n        }\n        .subheader {\n            font-size: 24px;\n            color: var(--secondary-color-3);\n            font-weight: 700;\n        }\n        .forgot-password {\n            margin: 0;\n            font-size: 10px;\n            font-weight: 700;\n            color: var(--primary-color-1);\n            cursor: pointer;\n            user-select: none;\n        }\n    }\n    .illus {\n        height: 200px;\n    }\n    .illus-container {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 0 50px;\n        > img {\n            width: 100%;\n        }\n    }\n    @keyframes fadeIn {\n        0% { opacity: 0; }\n        100% { opacity: 1; }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
