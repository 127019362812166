import * as React from "react";

const Telephone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={20}
    viewBox="0 0 25 20"
    fill="none"
    {...props}
  >
    <path
      d="M21.7736 0.960952L17.7102 0.0233595C17.4959 -0.0252203 17.2714 0.00248317 17.0753 0.101695C16.8792 0.200907 16.7239 0.365411 16.6361 0.566854L14.7609 4.94572C14.6808 5.13247 14.6638 5.34021 14.7124 5.5375C14.761 5.73479 14.8725 5.91088 15.03 6.03915L17.3972 7.971C15.9634 11.0122 13.5159 13.4597 10.4746 14.8935L8.54276 12.5263C8.41436 12.3689 8.23826 12.2575 8.04102 12.209C7.84378 12.1604 7.6361 12.1773 7.44931 12.2571L3.07037 14.1323C2.86806 14.2204 2.70275 14.3762 2.60286 14.5729C2.50296 14.7696 2.47472 14.995 2.523 15.2103L3.46061 19.2736C3.50839 19.4803 3.62483 19.6646 3.79092 19.7966C3.95701 19.9285 4.16292 20.0002 4.37503 20C9.18197 19.9997 13.7919 18.09 17.1909 14.691C20.59 11.2921 22.4997 6.68221 22.5 1.87536C22.5004 1.66319 22.4288 1.45717 22.2968 1.29104C22.1649 1.12491 21.9804 1.00852 21.7736 0.960952Z"
      fill={props.color || "#6C767C"}
    />
  </svg>
)


export default Telephone;
