import ModalContainer from 'components/ModalContainer';
import UserCard from 'components/UserCard';

const HomeownersModal = ({ data, modal, setModal }) => {
  return (
    <ModalContainer modal={modal} setModal={setModal} title={'Homeowners'}>
      <>
        {
          data?.sellers?.length > 0 &&
          <div>
            {
              data?.sellers.map((seller, index) => {
                return (<UserCard data={seller} key={index} />)
              })
            }
          </div>
        }
      </>
    </ModalContainer>
  )
}


export default HomeownersModal;