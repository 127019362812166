// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showing-status-component {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--secondary-color-1);
  font-size: 8px;
  width: 80px;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 6px 0;
}
.showing-status-component.all {
  color: var(--primary-color-1);
}
.showing-status-component.pending, .showing-status-component.requested {
  color: #0097A1;
  background-color: #CCEAEC;
}
.showing-status-component.rejected {
  color: #EF4546;
  background-color: #FCDADA;
}
.showing-status-component.ongoing {
  color: #B15DD9;
  background-color: #EFDFF7;
}
.showing-status-component.approved, .showing-status-component.upcoming {
  color: #74A3EC;
  background-color: #E3EDFB;
}
.showing-status-component.finished {
  color: #10B980;
  background-color: #ECFDF5;
}
.showing-status-component.cancelled {
  color: #949494;
  background-color: #DFDFDF;
}`, "",{"version":3,"sources":["webpack://./src/components/ShowingStatus/showingStatus.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,+BAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;AACJ;AACI;EACI,6BAAA;AACR;AACI;EACI,cAAA;EACA,yBAAA;AACR;AACI;EACI,cAAA;EACA,yBAAA;AACR;AACI;EACI,cAAA;EACA,yBAAA;AACR;AACI;EACI,cAAA;EACA,yBAAA;AACR;AACI;EACI,cAAA;EACA,yBAAA;AACR;AACI;EACI,cAAA;EACA,yBAAA;AACR","sourcesContent":[".showing-status-component {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-weight: 700;\n    color: var(--secondary-color-1);\n    font-size: 8px;\n    width: 80px;\n    border-radius: 8px;\n    text-transform: uppercase;\n    padding: 6px 0;\n\n    &.all { \n        color: var(--primary-color-1); \n    }\n    &.pending, &.requested { \n        color: #0097A1;\n        background-color: #CCEAEC; \n    }\n    &.rejected { \n        color: #EF4546;\n        background-color: #FCDADA; \n    }\n    &.ongoing { \n        color: #B15DD9;\n        background-color: #EFDFF7;\n    }\n    &.approved, &.upcoming  { \n        color: #74A3EC;\n        background-color: #E3EDFB; \n    }\n    &.finished { \n        color: #10B980;\n        background-color: #ECFDF5; \n    }\n    &.cancelled { \n        color: #949494;\n        background-color: #DFDFDF; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
