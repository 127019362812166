import * as React from "react"

const Clock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill={props.color || '#facb1f'}
      d="M13.989,3A11,11,0,1,0,25,14,10.995,10.995,0,0,0,13.989,3ZM14,22.8A8.8,8.8,0,1,1,22.8,14,8.8,8.8,0,0,1,14,22.8Zm.55-14.3H12.9v6.6l5.775,3.465.825-1.353-4.95-2.937Z"
      transform="translate(-3 -3)"
    />
  </svg>
)


export default Clock
