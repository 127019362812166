import * as React from "react";

const Search = (props) => (


    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <path
            d="M13.8367 12.3725L11.4993 10.0355C11.447 9.98323 11.3848 9.94184 11.3164 9.91367C11.2481 9.88549 11.1748 9.87109 11.1008 9.87127H10.7167C11.4801 8.89312 11.8416 7.66066 11.7275 6.42519C11.6134 5.18973 11.0323 4.0443 10.1027 3.22245C9.17301 2.4006 7.96485 1.96422 6.72451 2.0023C5.48417 2.04037 4.30505 2.55003 3.42759 3.42735C2.55012 4.30467 2.04038 5.48359 2.0023 6.72373C1.96422 7.96386 2.40066 9.17183 3.22265 10.1013C4.04464 11.0308 5.19026 11.6118 6.42592 11.7259C7.66159 11.84 8.89425 11.4785 9.87257 10.7153V11.0993C9.87238 11.1733 9.88679 11.2465 9.91497 11.3149C9.94315 11.3833 9.98455 11.4454 10.0368 11.4978L12.3742 13.8348C12.4263 13.8872 12.4882 13.9287 12.5564 13.9571C12.6246 13.9854 12.6977 14 12.7715 14C12.8454 14 12.9185 13.9854 12.9867 13.9571C13.0549 13.9287 13.1168 13.8872 13.1688 13.8348L13.8324 13.1713C13.9389 13.0659 13.9992 12.9226 14 12.7728C14.0008 12.623 13.9421 12.479 13.8367 12.3725ZM6.87399 9.86935C6.28038 9.86973 5.7 9.69406 5.20628 9.36456C4.71255 9.03506 4.32767 8.56654 4.10033 8.01829C3.873 7.47003 3.81341 6.86667 3.92913 6.28454C4.04484 5.70242 4.33065 5.16769 4.75039 4.74802C5.17014 4.32835 5.70495 4.04258 6.28717 3.92689C6.86939 3.81119 7.47285 3.87077 8.0212 4.09807C8.56955 4.32537 9.03814 4.71019 9.3677 5.20383C9.69725 5.69747 9.87295 6.27776 9.87257 6.87126C9.87244 7.66637 9.55648 8.42887 8.99417 8.99109C8.43185 9.55332 7.66922 9.86922 6.87399 9.86935Z"
            fill={props.color || '#808080'}
        />
    </svg>
);


export default Search;
