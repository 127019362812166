import * as React from "react"

const Pencil = (props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3667 4.44992L8.53333 1.64992L9.46667 0.716585C9.72222 0.46103 10.0362 0.333252 10.4087 0.333252C10.7807 0.333252 11.0944 0.46103 11.35 0.716585L12.2833 1.64992C12.5389 1.90547 12.6722 2.21392 12.6833 2.57525C12.6944 2.93614 12.5722 3.24436 12.3167 3.49992L11.3667 4.44992ZM10.4 5.43325L3.33333 12.4999H0.5V9.66659L7.56667 2.59992L10.4 5.43325Z"
      fill={props.color || "#2A2A2A"}
    />
  </svg>
)


export default Pencil
