import Section from "components/Section";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import { Config } from "utils";
import './contacts.scss'

const MortgageContacts = () => {
    const dispatch = useDispatch();
    const { mortgageConnections: { data, previous, next, total } } = useSelector((state) => state.buyer)
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    
    useEffect(() => {
        const init = async() => {
            await dispatch(actions.buyer.fetchBuyerMortgageConnection())
        }
        init();
    }, [])

    useEffect(() => {
        const init = async() => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total/20)||1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    name: i,
                    value: `${Config.API_URL}/buyer-broker-connection/?limit=20&offset=${offset}&ordering=-date_created`
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [total])

    const loadPageData = async({ page, pageNumber }) => {
        await dispatch(actions.agent.fetchBuyerAgentConnectionPagination(page));
        setPage(pageNumber);
    }



    return (
        <Section className="mortgage-contacts-container" style={{ minHeight: '91.5vh' }}>
            <h1 className="header">Buyer Contacts</h1>
            {/* <div className="table-filters">
                <input className="search" type="text" style={{ width: 580 }} placeholder="Search Byer" />
                <button 
                    className='button secondary' 
                    style={{ width: 240, height: 60 }} 
                    onClick={() => setModalVisible(prev => !prev)}
                >
                    Invite Contacts
                </button>
            </div>
            <Seprator height={20} /> */}
            <div className="table-container">
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th width="210px"><div>Name</div></th>
                            <th width="210px"><div>Phone</div></th>
                            <th width="385px"><div>Email</div></th>
                        </tr>
                    </thead>
                    <tbody id="showings-table">
                    {
                            data.length > 0 ?
                            data.map((connection, index) => {
                                return (
                                    <tr className="body" key={index}>
                                        <td align="center">
                                            <p>{connection.buyer.first_name} {connection.buyer.last_name}</p>
                                        </td>
                                        <td align="center">
                                            <p>{connection.buyer.phone_number}</p>
                                        </td>
                                        <td align="center">
                                            <p>{connection.buyer.email}</p>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="3">
                                    <div className="empty-table">No Available Buyer Connections</div>
                                </td>
                            </tr> 
                        }
                    </tbody>
                </table>
            </div>
            {
                data.length > 0 &&
                <div className="pagination">
                    <p className="previous" onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}> { previous && 'Previous' } </p>
                    <ul> 
                        {
                            pages.map((item, index) => {
                                return(
                                    <li 
                                        key={index}
                                        className={`${item.name === page && 'active'}`}
                                        onClick={() => loadPageData({ page: item.value, pageNumber: item.name })}
                                    >
                                        {item.name}
                                    </li>)
                            })
                        }
                    </ul>
                    <p className="next" onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}> { next && 'Next' } </p>
                </div>
            }
        </Section>
    )
}


export default MortgageContacts;