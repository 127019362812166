import * as React from "react";

const ListingDocumentIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#F9F9F9" />
    <path
      d="M11.8789 9.33334C11.2091 9.33334 10.6667 9.86668 10.6667 10.5238V21.4762C10.6667 22.1333 11.2098 22.6667 11.8789 22.6667H20.1213C20.7904 22.6667 21.3334 22.1333 21.3334 21.4762V16.4762C21.3334 15.8447 21.078 15.2391 20.6234 14.7926C20.1687 14.3461 19.5521 14.0952 18.9092 14.0952H17.6971C17.3756 14.0952 17.0673 13.9698 16.84 13.7466C16.6126 13.5233 16.4849 13.2205 16.4849 12.9048V11.7143C16.4849 11.0828 16.2295 10.4772 15.7749 10.0307C15.3203 9.58419 14.7036 9.33334 14.0607 9.33334H11.8789Z"
      fill="#949494"
    />
    <path
      d="M16.6278 9.53398C17.1621 10.139 17.4558 10.9134 17.4546 11.7143V12.9048C17.4546 13.0362 17.5632 13.1429 17.6971 13.1429H18.9092C19.7247 13.1418 20.5131 13.4302 21.1291 13.9549C20.8446 12.8926 20.2779 11.9235 19.487 11.1467C18.6962 10.37 17.7095 9.81344 16.6278 9.53398Z"
      fill="#949494"
    />
  </svg>
);


export default ListingDocumentIcon;
